import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Paper,
  Avatar,
  Tabs,
  Tab,
  useMediaQuery,
  TextField,
  InputAdornment,
  Alert,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  FormControl,
  FormGroup,
  Checkbox,
  Select,
  MenuItem,
  Link,
  Tooltip,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Drawer,
  FormLabel,
  Autocomplete,
  Chip,
  TableFooter,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { PublicFluid } from 'layouts';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import {
  capitalizeText,
  checkIfNumberExistsBetweenRange,
  validateEmail,
} from 'utils';
import { useHistory } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Add,
  ArrowBack,
  AutoAwesome,
  CheckCircle,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  Edit,
  PostAdd,
  Warning,
} from '@mui/icons-material';
import getDealDiscoveryQuestionsList from 'redux/actions/Common/getDealDiscoveryQuestionsList';
import DragNDropSortableList from 'components/DragNDropSortableList';
import getDiscoveryTreeTopics from 'redux/actions/Common/getDiscoveryTreeTopics';
import getDealArtifacts from 'redux/actions/Common/getDealArtifacts';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import addDealArtifact from 'redux/actions/Common/addDealArtifact';
import scrapCompanyInfo from 'redux/actions/Common/scrapCompanyInfo';
import scrapCompanyEmployees from 'redux/actions/Common/scrapCompanyEmployees';
import updateDeal from 'redux/actions/Candidate/updateDeal';
import showAlert from 'redux/actions/Common/showAlert';
import getDealById from 'redux/actions/Candidate/getDealById';
import createNewConversation from 'redux/actions/Candidate/createNewConversation';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import updateConversationStatus from 'redux/actions/Candidate/updateConversationStatus';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import { v4 as uuidv4 } from 'uuid';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import updateDealArtifact from 'redux/actions/Common/updateDealArtifact';
import processDealArtifact from 'redux/actions/Common/processDealArtifact';
import ArtifactStatus from 'components/ArtifactStatus';
import {
  artifactProcessingStatuses,
  artifactStatusMap,
  artifactTypesWithoutMedia,
} from 'data';
import parse from 'html-react-parser';
import MediaPreview from 'components/@2024/MediaPreview';
import ParticipationPieChart from 'components/@2024/ParticipationPieChart';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import checkSalesCallScoreStatus from 'redux/actions/Common/checkSalesCallScoreStatus';
import StakeholderDetection from 'components/StakeholderDetection';
import ArtifactUpload from 'components/ArtifactUpload';
import ArtifactRisksSelectionList from 'components/ArtifactRisksSelectionList';
import NextBestActions from 'components/NextBestActions';
import getArtifactRisks from 'redux/actions/Common/getArtifactRisks';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const DealPage = ({ fullWidth, defaultTeamMembersTab }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const params = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const dealId = pathParams?.dealId;
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const { userDetails } = useSelector((state) => state.userDetails);
  const {
    dashboardSidebarExpanded,
    pinnedTeams,
    currentTeamToManage,
  } = useSelector((state) => state.uiStates);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const onboardingSettings = useSelector((state) => state.onboardingSettings);
  const tourState = useSelector((state) => state.tourState);
  const chatsScrollview = useRef(null);
  const checkProgressTimer = useRef(null);
  const videoRef = useRef(null);

  const browser = detect();
  const { qList, fetching, fetchingPagination } = useSelector(
    (state) => state.discoveryQList,
  );
  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { conversationDetails } = conversationDetailsById;
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.addDealArtifactState,
  );

  const [fetchingDiscoveryQList, setFetchingDiscoveryQList] = useState(false);

  const [viewType, setViewType] = useState('timeline');
  const [questionTopics, setQuestionTopics] = useState([]);
  const [questionSubTopics, setQuestionSubTopics] = useState([]);

  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyLocation, setCompanyLocation] = useState('');
  const [linkedIn, setLinkedIn] = useState('');
  const [stakeholders, setStakeholders] = useState('');
  const [scrappingCompany, setScrappingCompany] = useState(false);
  const [fetchingStakeholders, setFetchingStakeholders] = useState(false);
  const [stakeHolders, setStakeHolders] = useState([]);

  const [savingDealCompanyInfo, setSavingDealCompanyInfo] = useState(false);

  const [dealArtifacts, setDealArtifacts] = useState([]);
  const [timelineYears, setTimelineYears] = useState([]);
  const [timelineMonths, setTimelineMonths] = useState([]);
  const [timelineDates, setTimeLineDates] = useState([]);
  const [dealArtifactsLoading, setDealArtifactsLoading] = useState(false);

  const [uploadingArtifact, setUploadingArtifact] = useState(false);
  const [artifactType, setArtifactType] = useState('email-inbound');
  const [artifactTitle, setArtifactTitle] = useState(
    moment(new Date().getTime()).format('MMMM Do YYYY, h:mm:ss a'),
  );
  const [artifactTime, setArtifactTime] = useState(new Date().getTime());
  const [speaker, setSpeaker] = useState('Speaker 1');
  const [speakers, setSpeakers] = useState({});
  const [activeArtifact, setActiveArtifact] = useState(null);
  const [dealTitle, setDealTitle] = useState('');

  const [activeArtifactTab, setActiveArtifactTab] = useState('summary');
  const [currentArtifactDetailTab, setCurrentArtifactDetailTab] = useState(
    'summary',
  );

  const [showAskDonna, setShowAskDonna] = useState(null);
  const [askAIQuestion, setAskAIQuestion] = useState('');
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [fetchingConversationById, setFetchingConversationById] = useState(
    false,
  );
  const [wordsLimit, setWordsLimit] = useState(50);
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [showAddStakeholdersDialog, setShowAddStakeholderDialog] = useState(
    false,
  );
  const [stackholderName, setStackholderName] = useState('');
  const [stackholderEmail, setStackholderEmail] = useState('');
  const [stackholderLinkedin, setStackholderLinkedin] = useState('');
  const [stackholderRole, setStackholderRole] = useState('');
  const [addingStackholder, setAddingStackholder] = useState(false);

  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    {},
  );
  const checkSalesCallScoreStatusTimer = useRef(null);
  const [scoreType, setScoreType] = useState('Speaker 1');
  const [
    currentDealSpecificConversationId,
    setCurrentDealSpecificConversationId,
  ] = useState(null);

  const [
    showConfirmDeleteStakeholder,
    setShowConfirmDeleteStakeholder,
  ] = useState(false);

  const [deletingStakeholder, setDeletingStakeholder] = useState(false);
  const [currentDealStakeholders, setCurrentDealStakeholders] = useState([]);
  const [
    refreshCurrentDealStakeholders,
    setRefreshCurrentDealStakeholders,
  ] = useState(false);

  const [fetchingArtifactNBAs, setFetchingArtifactNBAs] = useState(false);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleViewChange = (viewType) => {
    setViewType(viewType);
    if (onboardingSettings.onBoarding) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: {
          stepIndex: tourState.stepIndex + 1,
        },
      });
    }
  };

  useEffect(() => {
    const seekVideo = (time) => {
      console.log(time);
      const seconds =
        Number(time?.split(':')?.[0] || 0) * 60 +
        Number(time?.split(':')?.[1] || 0);
      console.log(seconds);
      if (videoRef?.current) {
        videoRef.current.currentTime = seconds;
        videoRef.current.play();
      }
    };
    if (
      [].slice.call(document.getElementsByClassName('highlight')) &&
      [].slice.call(document.getElementsByClassName('highlight'))?.length > 0
    ) {
      [].slice.call(document.getElementsByClassName('highlight'))?.map((el) => {
        el.addEventListener('click', () => {
          seekVideo(el?.innerText);
        });
      });
    }

    return () => {};
  }, [[].slice.call(document.getElementsByClassName('highlight'))]);

  useEffect(() => {
    if (activeArtifact?.customField?.salesReviewFile) {
      setSpeakers(activeArtifact?.customField?.salesReviewFile?.speakers);
      setSpeaker(
        activeArtifact?.customField?.salesReviewFile?.speaker || 'Speaker 1',
      );
      setScoreType(
        Object.keys(
          activeArtifact?.customField?.salesReviewFile?.speakerConfigMap || {},
        )?.find(
          (key) =>
            key !== activeArtifact?.customField?.salesReviewFile?.speaker,
        ) || 'Speaker 1',
      );
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (
      activeArtifact?.customField?.salesReviewFile?.speakerScoreMap &&
      Object.keys(
        activeArtifact?.customField?.salesReviewFile?.speakerScoreMap || {},
      ) &&
      Object.keys(
        activeArtifact?.customField?.salesReviewFile?.speakerScoreMap || {},
      )?.length > 0
    ) {
      setScoreCalculationInProcess(
        Object.keys(
          activeArtifact?.customField?.salesReviewFile?.speakerScoreMap || {},
        )?.reduce((acc, curr) => {
          acc[curr] =
            activeArtifact?.customField?.salesReviewFile?.speakerScoreMap?.[
              curr
            ]?.status;
          return acc;
        }, {}),
      );
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (
      activeArtifact?.selectedRisks &&
      ((activeArtifact?.nextBestActionsMap &&
        Object.keys(activeArtifact?.nextBestActionsMap || {})?.length === 0) ||
        !activeArtifact?.nextBestActionsMap)
    ) {
      dispatch(
        getArtifactRisks(activeArtifact?.id, (data) => {
          setActiveArtifact((prev) => {
            return {
              ...prev,
              nextBestActionsMap: data?.nextBestActions,
            };
          });
        }),
      );
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (
      scoreCalculationInProcess &&
      Object.keys(scoreCalculationInProcess || {})?.length > 0 &&
      scoreCalculationInProcess[scoreType] !== 'completed' &&
      scoreCalculationInProcess[scoreType] !== 'failed'
    ) {
      checkSalesCallScoreStatusTimer.current = setInterval(() => {
        dispatch(
          checkSalesCallScoreStatus(
            activeArtifact?.customField?.salesReviewFile?.id,
            (data) => {
              if (
                data?.allSpeakers &&
                Object.keys(data?.allSpeakers || {})?.length > 0
              ) {
                setScoreCalculationInProcess(
                  Object.keys(data?.allSpeakers || {})?.reduce((acc, curr) => {
                    acc[curr] = data?.allSpeakers?.[curr]?.status;
                    return acc;
                  }, {}),
                );
              }
              if (
                Object.values(data?.allSpeakers || {}) &&
                Object.values(data?.allSpeakers || {})?.length > 0 &&
                Object.values(data?.allSpeakers || {})?.find(
                  (scoreMap) =>
                    scoreMap?.status !== 'completed' &&
                    scoreMap?.status !== 'failed',
                ) === undefined
              ) {
                clearInterval(checkSalesCallScoreStatusTimer.current);
                if (
                  Object.values(data?.allSpeakers || {}) &&
                  Object.values(data?.allSpeakers || {})?.length > 0 &&
                  Object.values(data?.allSpeakers || {})?.find(
                    (scoreMap) =>
                      scoreMap &&
                      Object.keys(scoreMap || {}) &&
                      Object.keys(scoreMap || {})?.length > 0 &&
                      Object.keys(scoreMap || {}).filter(
                        (key) => key !== 'status',
                      ) &&
                      Object.keys(scoreMap || {}).filter(
                        (key) => key !== 'status',
                      )?.length > 0,
                  )
                ) {
                  setActiveArtifact((prev) => {
                    return {
                      ...prev,
                      customField: {
                        ...(prev?.customField || {}),
                        salesReviewFile: {
                          ...(prev?.customField?.salesReviewFile || {}),
                          speakerScoreMap: data?.allSpeakers,
                        },
                      },
                    };
                  });
                }
              }
            },
            () => {
              setScoreCalculationInProcess(false);
              clearInterval(checkSalesCallScoreStatusTimer.current);
            },
          ),
        );
      }, 10000);
    }
    return () => {
      clearInterval(checkSalesCallScoreStatusTimer.current);
    };
  }, [scoreCalculationInProcess, scoreType]);

  useEffect(() => {
    if (dealId && viewType === 'timeline') {
      setDealArtifactsLoading(true);
      dispatch(
        getDealArtifacts(dealId, (data) => {
          setDealArtifactsLoading(false);
          console.log(data);
          setDealArtifacts(
            data?.sort((a, b) => b?.interactionTime - a?.interactionTime),
          );
          setActiveArtifact(
            data?.sort((a, b) => b?.interactionTime - a?.interactionTime)?.[0],
          );
          setTimelineYears(
            Array.from(
              new Set(
                data?.map((d) => new Date(d?.interactionTime)?.getFullYear()),
              ),
            ) || [],
          );
          setTimelineMonths(
            Array.from(
              new Set(
                data?.map((d) => new Date(d?.interactionTime)?.getFullYear()),
              ),
            )?.reduce((acc, curr) => {
              if (acc[curr] === undefined) {
                acc[curr] = Array.from(
                  new Set(
                    data
                      ?.filter(
                        (d) =>
                          new Date(d?.interactionTime)?.getFullYear() === curr,
                      )
                      ?.map(
                        (d) => months[new Date(d?.interactionTime)?.getMonth()],
                      ),
                  ),
                );
              }
              return acc;
            }, {}),
          );
        }),
      );
    }
    if (dealId) {
      dispatch(
        getDealById(dealId, (data) => {
          console.log(data);
          setCompanyName(data?.company?.name);
          setCompanySize(data?.company?.size || '');
          setCompanyDescription(data?.company?.description);
          setCompanyLogo(data?.company?.logoUrl);
          setCompanyWebsite(data?.company?.website);
          setCompanyLocation(data?.company?.location || '');
          setLinkedIn(data?.company?.linkedIn);
          setStakeholders(data?.company?.stackHolderTitle?.join(', ') || '');
          setStakeHolders(data?.dealStackHolders || []);
          setDealTitle(data?.name || '');
          setCurrentDealSpecificConversationId(
            data?.currentDealSpecificConversationId || null,
          );
          setCurrentDealStakeholders(data?.dealStackHolders || []);
        }),
      );
    }
  }, [dealId, viewType]);

  useEffect(() => {
    if (refreshCurrentDealStakeholders) {
      dispatch(
        getDealById(dealId, (data) => {
          setRefreshCurrentDealStakeholders(false);
          setCurrentDealStakeholders(data?.dealStackHolders || []);
        }),
      );
    }
  }, [refreshCurrentDealStakeholders]);

  useEffect(() => {
    if (typeof showAddStakeholdersDialog === 'object') {
      setStackholderEmail(showAddStakeholdersDialog?.email);
      setStackholderName(showAddStakeholdersDialog?.name);
      setStackholderRole(showAddStakeholdersDialog?.designation);
      setStackholderLinkedin(showAddStakeholdersDialog?.linkedIn);
    }
  }, [showAddStakeholdersDialog]);

  useEffect(() => {
    if (orgConfigs?.discoveryTreeId) {
      dispatch(
        getDiscoveryTreeTopics(orgConfigs?.discoveryTreeId, (data) => {
          console.log(data);
          // if (data?.risk && data?.risk?.length > 0) {
          setQuestionTopics(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            }) || [],
          );
          setQuestionSubTopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
        }),
      );
      setFetchingDiscoveryQList(true);
      dispatch(
        getDealDiscoveryQuestionsList(
          orgConfigs?.discoveryTreeId,
          null,
          null,
          (data) => {
            console.log(data);
            setFetchingDiscoveryQList(false);
          },
        ),
      );
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (showAskDonna && userDetails) {
      if (currentDealSpecificConversationId) {
        setFetchingConversationById(true);
        dispatch(
          getConversationById(
            currentDealSpecificConversationId,
            null,
            (data) => {
              setFetchingConversationById(false);
            },
          ),
        );
      }
    } else {
      dispatch({
        type: 'RESET_CONVERSATION_BY_ID',
      });
    }
  }, [showAskDonna, userDetails]);

  useEffect(() => {
    setActiveArtifactTab('summary');
  }, [viewType]);

  useEffect(() => {
    setCurrentArtifactDetailTab('summary');
  }, [activeArtifactTab]);

  useEffect(() => {
    if (params && params.get('tab')) {
      setViewType(params.get('tab'));
    } else {
      setViewType('timeline');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (
      params &&
      params.get('activeArtifact') &&
      dealArtifacts &&
      dealArtifacts?.length > 0
    ) {
      setActiveArtifact(
        dealArtifacts?.find((art) => art?.id === params.get('activeArtifact')),
      );
    }
  }, [window.location.href, dealArtifacts]);

  // console.log(activeArtifact);

  // console.log(scoreCalculationInProcess);
  console.log(params.get('activeArtifact'));

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? (fullWidth ? 1 : 'calc(100% - 80px)') : 1,
          md: isLoggedIn
            ? fullWidth
              ? 1
              : dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 80px)'
            : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md: isLoggedIn
            ? fullWidth
              ? 0
              : dashboardSidebarExpanded
              ? '240px'
              : '80px'
            : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Container
        paddingX={
          isLoggedIn
            ? fullWidth
              ? 2
              : dashboardSidebarExpanded
              ? 2
              : { lg: 16, md: 8, sm: 4, xs: 1 }
            : 8
        }
        paddingY={fullWidth ? 1 : { lg: 1, md: 2, sm: 2, xs: 3 }}
        maxWidth={{ xs: 1, sm: 1, md: isLoggedIn ? 1 : 1236 }}
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            justifyContent: 'space-between',
            borderBottom: '1px solid #d3d3d3',
            paddingX: dashboardSidebarExpanded ? 3 : 18,
            paddingY: 1,
            position: 'fixed',
            top: 56,
            left: dashboardSidebarExpanded ? 240 : 84,
            width: dashboardSidebarExpanded
              ? 'calc(100% - 240px)'
              : 'calc(100% - 84px)',
            background: '#fff',
            zIndex: 2,
            // borderTop: '1px solid #d3d3d3',
          }}
        >
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Link
              underline="none"
              component="a"
              href="/deals"
              // color="text.primary"
              variant={'body1'}
              sx={{
                textDecoration: 'underline',
              }}
            >
              Deals
            </Link>
            <Typography variant={'body1'} sx={{ fontSize: 20 }}>
              {' '}
              &rsaquo;{' '}
            </Typography>
            <Typography fontWeight={'bold'}>
              {dealTitle}
              {/* -{' '}
              {viewType === 'timeline'
                ? 'Timeline'
                : viewType === 'calendar'
                ? 'Discovery Meeting Plan'
                : viewType === 'shield'
                ? 'Deal Risks'
                : viewType === 'edit'
                ? 'Buyer Info'
                : viewType === 'upload'
                ? 'Artifact Upload'
                : ''} */}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Tooltip
              arrow
              placement="top"
              title="Upload Artifact"
              className="uploadArtifactTab"
            >
              <IconButton
                onClick={() => handleViewChange('upload')}
                sx={{
                  filter: viewType !== 'upload' ? 'grayscale(100%)' : 'none',
                  border:
                    viewType === 'upload'
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <PostAdd
                  sx={{ fontSize: 24, color: theme.palette.primary.main }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="top" title="Timeline">
              <IconButton
                onClick={() => handleViewChange('timeline')}
                sx={{
                  filter: viewType !== 'timeline' ? 'grayscale(100%)' : 'none',
                  border:
                    viewType === 'timeline'
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <img src="/dh-icons/timeline-icon.svg" width={20} height={20} />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="top" title="Meeting Setup">
              <IconButton
                onClick={() => handleViewChange('calendar')}
                sx={{
                  filter: viewType !== 'calendar' ? 'grayscale(100%)' : 'none',
                  border:
                    viewType === 'calendar'
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <img src="/dh-icons/Calendar.svg" width={20} height={20} />
              </IconButton>
            </Tooltip>
            {/* <Tooltip arrow placement="top" title="Deal Risks">
              <IconButton
                onClick={() => handleViewChange('shield')}
                sx={{
                  filter: viewType !== 'shield' ? 'grayscale(100%)' : 'none',
                  border:
                    viewType === 'shield'
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <img src="/dh-icons/Shield.svg" width={20} height={20} />
              </IconButton>
            </Tooltip> */}
            <Tooltip arrow placement="top" title="Deal Setup">
              <IconButton
                onClick={() => handleViewChange('edit')}
                sx={{
                  filter: viewType !== 'edit' ? 'grayscale(100%)' : 'none',
                  border:
                    viewType === 'edit'
                      ? `1px solid ${theme.palette.primary.main}`
                      : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <img src="/dh-icons/edit-alt.svg" width={20} height={20} />
              </IconButton>
            </Tooltip>
            {/* <Tooltip arrow placement="top" title="Ask Donna">
              <IconButton
                onClick={() => setShowAskDonna((prev) => !prev)}
                sx={{
                  filter: !showAskDonna ? 'grayscale(100%)' : 'none',
                  border: showAskDonna
                    ? `1px solid ${theme.palette.primary.main}`
                    : '1px solid #fff',
                  padding: '6px',
                  borderRadius: '50%',
                }}
              >
                <AutoAwesome
                  sx={{
                    fontSize: 20,
                    color: showAskDonna
                      ? theme.palette.primary.main
                      : '##818181',
                  }}
                />
              </IconButton>
            </Tooltip> */}
          </Box>
        </Box>
        <Box
          sx={{
            paddingTop: 6,
          }}
        >
          {viewType === 'upload' ? (
            <Box
              sx={{
                maxHeight: 'calc(100vh - 145px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
              }}
            >
              <Box
                sx={{
                  padding: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  paddingTop: 4,
                }}
              >
                <ArtifactUpload dealId={dealId} setViewType={setViewType} />
              </Box>
            </Box>
          ) : viewType === 'timeline' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {/* {activeArtifact?.nextBestActionsMap &&
              Object.keys(activeArtifact?.nextBestActionsMap || {}) &&
              Object.keys(activeArtifact?.nextBestActionsMap || {})?.length >
                0 ? (
                <> */}
              <Box
                sx={{
                  paddingX: 1,
                  marginTop: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
              >
                <Typography
                  color={'text.secondary'}
                  fontWeight={'bold'}
                  sx={{ fontSize: 18 }}
                >
                  Execution Guidance
                </Typography>
                <NextBestActions
                  readOnly={
                    activeArtifact?.nextBestActionsMap &&
                    Object.keys(activeArtifact?.nextBestActionsMap || {}) &&
                    Object.keys(activeArtifact?.nextBestActionsMap || {})
                      ?.length > 0
                  }
                  activeArtifact={activeArtifact}
                  setCurrentArtifactDetailTab={setCurrentArtifactDetailTab}
                  setActiveArtifactTab={setActiveArtifactTab}
                />
              </Box>
              {/* <Divider orientation="horizontal" flexItem /> */}
              {/* </>
              ) : null} */}
              <Box
                sx={{
                  paddingX: 1,
                  marginTop: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                }}
                className="artifactsList"
              >
                <Typography
                  color={'text.secondary'}
                  fontWeight={'bold'}
                  sx={{ fontSize: 20, padingY: 1 }}
                >
                  Timeline
                </Typography>
                {dealArtifactsLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      padding: 2,
                    }}
                  >
                    <CircularProgress size={20} />
                    <Typography>Fetching Artifacts</Typography>
                  </Box>
                ) : (
                  <>
                    {dealArtifacts?.length > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          // gap: 2,
                          width: 1,
                          position: 'relative',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: 1,
                            minHeight: 'calc(100vh - 145px)',
                            maxHeight: 'calc(100vh - 145px)',
                            overflowY: 'scroll',
                            // '&::-webkit-scrollbar': {
                            //   width: '0px',
                            // },
                            // '&::-webkit-scrollbar-track': {
                            //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            //   webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            // },
                            // '&::-webkit-scrollbar-thumb': {
                            //   backgroundColor: theme.palette.primary.main,
                            //   borderRadius: 2,
                            //   // outline: '1px solid slategrey',
                            // },
                            padding: 1,
                            // borderRight: '1px solid #d3d3d3',
                            flex: 1,
                            width: '80px',
                          }}
                        >
                          {timelineYears?.map((year) => (
                            <Box
                              key={year}
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                              }}
                            >
                              <Typography
                                fontWeight={'bold'}
                                fontSize={14}
                                paddingY={1}
                              >
                                {year}
                              </Typography>
                              {timelineMonths?.[year]?.map((month) => (
                                <Box
                                  key={month}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // gap: 1,
                                  }}
                                >
                                  <Typography
                                    fontWeight={'bold'}
                                    fontSize={12}
                                    pY={1}
                                    mb={1}
                                  >
                                    {month}
                                  </Typography>
                                  {dealArtifacts
                                    ?.filter(
                                      (da) =>
                                        new Date(
                                          da?.interactionTime,
                                        )?.getFullYear() === year &&
                                        months[
                                          new Date(
                                            da?.interactionTime,
                                          )?.getMonth()
                                        ] === month,
                                    )
                                    ?.sort(
                                      (a, b) =>
                                        b?.interactionTime - a?.interactionTime,
                                    )
                                    ?.map((dealArtifact) => (
                                      <Box
                                        key={dealArtifact?.id}
                                        display={'flex'}
                                        alignItems={
                                          activeArtifactTab === 'summary'
                                            ? 'center'
                                            : 'start'
                                        }
                                        gap={2}
                                      >
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            gap: 2,
                                            borderBottom: '1px solid #d3d3d3',
                                            padding: 1,
                                            cursor: 'pointer',
                                            background:
                                              activeArtifact?.id ===
                                              dealArtifact?.id
                                                ? '#C2DBFF'
                                                : 'transparent',
                                            minWidth: 70,
                                          }}
                                          onClick={() => {
                                            setActiveArtifact(dealArtifact);
                                          }}
                                        >
                                          <Typography
                                            fontSize={12}
                                            color={
                                              activeArtifact?.id ===
                                              dealArtifact?.id
                                                ? 'text.primary'
                                                : 'text.secondary'
                                            }
                                            fontWeight={
                                              activeArtifact?.id ===
                                              dealArtifact?.id
                                                ? 'bold'
                                                : ''
                                            }
                                          >
                                            {new Date(
                                              dealArtifact?.interactionTime,
                                            )?.getDate() > 9
                                              ? new Date(
                                                  dealArtifact?.interactionTime,
                                                )?.getDate()
                                              : `0${new Date(
                                                  dealArtifact?.interactionTime,
                                                )?.getDate()}`}
                                          </Typography>
                                          <img
                                            src={
                                              dealArtifact?.interactionType ===
                                              'transcript'
                                                ? '/dh-icons/transcript.svg'
                                                : dealArtifact?.interactionType ===
                                                  'email-internal'
                                                ? '/dh-icons/email.svg'
                                                : dealArtifact?.interactionType ===
                                                  'email-inbound'
                                                ? '/dh-icons/email-reply.svg'
                                                : dealArtifact?.interactionType ===
                                                  'email-outbound'
                                                ? '/dh-icons/email-forward.svg'
                                                : dealArtifact?.interactionType ===
                                                  'call'
                                                ? '/dh-icons/call.svg'
                                                : dealArtifact?.interactionType ===
                                                  'call-internal'
                                                ? '/dh-icons/call.svg'
                                                : dealArtifact?.interactionType ===
                                                  'note'
                                                ? '/dh-icons/transcript.svg'
                                                : dealArtifact?.interactionType ===
                                                  'meeting'
                                                ? '/dh-icons/meet.svg'
                                                : dealArtifact?.interactionType ===
                                                  'meeting-internal'
                                                ? '/dh-icons/meet.svg'
                                                : ''
                                            }
                                            width={20}
                                            height={20}
                                          />
                                        </Box>
                                        {activeArtifactTab === 'summary' ? (
                                          <Box
                                            sx={{
                                              flex: 1,
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 2,
                                              justifyContent: 'space-between',
                                              borderBottom: '1px solid #d3d3d3',
                                              padding: '6px',
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                flex: 3,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                                color:
                                                  !dealArtifact?.content &&
                                                  !dealArtifact?.customField
                                                    ?.salesReviewFile
                                                    ?.transcript
                                                    ? 'text.secondary'
                                                    : activeArtifact?.id ===
                                                      dealArtifact?.id
                                                    ? theme.palette.primary.main
                                                    : 'text.primary',
                                                cursor: 'pointer',
                                                ':hover': {
                                                  color:
                                                    theme.palette.primary.main,
                                                },
                                              }}
                                              onClick={() => {
                                                setActiveArtifact(dealArtifact);
                                                setActiveArtifactTab('detail');
                                              }}
                                            >
                                              {artifactProcessingStatuses.includes(
                                                dealArtifact?.postProcessingStatus,
                                              )
                                                ? 'Summary generation is in progress.'
                                                : dealArtifact?.interactionSummaryShort?.replace(
                                                    /"/g,
                                                    '',
                                                  ) ||
                                                  dealArtifact?.content ||
                                                  dealArtifact?.customField
                                                    ?.salesReviewFile
                                                    ?.transcript}
                                            </Typography>
                                            <Box
                                              sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'flex-end',
                                                gap: 1,
                                              }}
                                            >
                                              {/* <StakeholderDetection
                                              dealId={dealId}
                                              artifact={dealArtifact}
                                              currentDealStakeholders={
                                                currentDealStakeholders
                                              }
                                              setStakeHolders={setStakeHolders}
                                              setRefreshCurrentDealStakeholders={
                                                setRefreshCurrentDealStakeholders
                                              }
                                            /> */}
                                              <ArtifactStatus
                                                dealId={dealId}
                                                artifact={dealArtifact}
                                                setDealArtifacts={
                                                  setDealArtifacts
                                                }
                                                setActiveArtifact={
                                                  setActiveArtifact
                                                }
                                              />
                                            </Box>
                                          </Box>
                                        ) : activeArtifactTab === 'detail' &&
                                          activeArtifact?.id ===
                                            dealArtifact?.id ? (
                                          <>
                                            <Box
                                              sx={{
                                                flex: 1,
                                                border: '1px solid #d3d3d3',
                                                // background: '#ddd',
                                                position: 'absolute',
                                                top: 70,
                                                left: 94,
                                                width: 'calc(100% - 94px)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                              }}
                                            >
                                              <Tabs
                                                value={currentArtifactDetailTab}
                                                onChange={(e, newValue) =>
                                                  setCurrentArtifactDetailTab(
                                                    newValue,
                                                  )
                                                }
                                                aria-label="Parameter Tabs"
                                                orientation={'horizontal'}
                                                // indicatorColor={theme.palette.background.default}
                                                variant="scrollable"
                                                // scrollButtons={!isMd}
                                                allowScrollButtonsMobile
                                                sx={{
                                                  borderBottom:
                                                    '1px solid #d3d3d3',
                                                }}
                                              >
                                                <Tab
                                                  value={'summary'}
                                                  label={
                                                    <Typography variant="body1">
                                                      Summary
                                                    </Typography>
                                                  }
                                                  id={`simple-tab-${0}`}
                                                  aria-controls={`simple-tabpanel-${0}`}
                                                  sx={{}}
                                                />
                                                <Tab
                                                  value={'detail'}
                                                  label={
                                                    <Typography variant="body1">
                                                      {artifactTypesWithoutMedia.includes(
                                                        activeArtifact?.interactionType,
                                                      )
                                                        ? 'Content'
                                                        : 'Transcript'}
                                                    </Typography>
                                                  }
                                                  id={`simple-tab-${1}`}
                                                  aria-controls={`simple-tabpanel-${1}`}
                                                  sx={{}}
                                                />
                                                <Tab
                                                  value={'risk'}
                                                  label={
                                                    <Typography variant="body1">
                                                      Risks
                                                    </Typography>
                                                  }
                                                  id={`simple-tab-${1}`}
                                                  aria-controls={`simple-tabpanel-${1}`}
                                                  sx={{}}
                                                />
                                                <Tab
                                                  value={'actions'}
                                                  label={
                                                    <Typography variant="body1">
                                                      Recommended Actions
                                                    </Typography>
                                                  }
                                                  id={`simple-tab-${1}`}
                                                  aria-controls={`simple-tabpanel-${1}`}
                                                  sx={{}}
                                                />
                                              </Tabs>
                                              <Box
                                                sx={{
                                                  minHeight:
                                                    'calc(100vh - 280px)',
                                                  maxHeight:
                                                    'calc(100vh - 280px)',
                                                  overflowY: 'scroll',
                                                  '&::-webkit-scrollbar': {
                                                    width: '5px',
                                                  },
                                                  '&::-webkit-scrollbar-track': {
                                                    boxShadow:
                                                      'inset 0 0 6px rgba(0,0,0,0.00)',
                                                    webkitBoxShadow:
                                                      'inset 0 0 6px rgba(0,0,0,0.00)',
                                                  },
                                                  '&::-webkit-scrollbar-thumb': {
                                                    backgroundColor:
                                                      theme.palette.primary
                                                        .main,
                                                    borderRadius: 2,
                                                  },
                                                }}
                                              >
                                                {activeArtifact?.postProcessingStatus !==
                                                  'completed' &&
                                                activeArtifact?.postProcessingStatus !==
                                                  'interaction_analysis_completed' &&
                                                activeArtifact?.postProcessingStatus !==
                                                  'interaction_nba_completed' ? (
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      justifyContent: 'center',
                                                      alignItems: 'center',
                                                      gap: 2,
                                                      height: 1,
                                                      width: 1,
                                                      minHeight: 300,
                                                      padding: 4,
                                                      textAlign: 'center',
                                                    }}
                                                  >
                                                    {artifactProcessingStatuses.includes(
                                                      activeArtifact?.postProcessingStatus,
                                                    ) ? (
                                                      <CircularProgress
                                                        size={20}
                                                      />
                                                    ) : null}
                                                    <Typography
                                                      variant="body1"
                                                      color={'text.secondary'}
                                                    >
                                                      {artifactStatusMap[
                                                        activeArtifact
                                                          ?.postProcessingStatus
                                                      ] || ''}
                                                    </Typography>
                                                  </Box>
                                                ) : (
                                                  <>
                                                    {currentArtifactDetailTab ===
                                                    'summary' ? (
                                                      <Typography
                                                        sx={{
                                                          whiteSpace:
                                                            'pre-wrap',
                                                          padding: 2,
                                                          flex: 1,
                                                        }}
                                                      >
                                                        {activeArtifact?.mediaFileBasedInteraction
                                                          ? activeArtifact
                                                              ?.customField
                                                              ?.salesReviewFile
                                                              ?.analysis
                                                              ?.methodAnalysis?.[
                                                              'callSummary'
                                                            ]?.analysisHtml
                                                            ? parse(
                                                                activeArtifact
                                                                  ?.customField
                                                                  ?.salesReviewFile
                                                                  ?.analysis
                                                                  ?.methodAnalysis?.[
                                                                  'callSummary'
                                                                ]
                                                                  ?.analysisHtml ||
                                                                  '',
                                                              )
                                                            : activeArtifact
                                                                ?.customField
                                                                ?.salesReviewFile
                                                                ?.analysis
                                                                ?.methodAnalysis?.[
                                                                'callSummary'
                                                              ]?.analysis || ''
                                                          : artifactTypesWithoutMedia?.includes(
                                                              activeArtifact?.interactionType,
                                                            )
                                                          ? activeArtifact?.interactionSummaryShort ||
                                                            'Not able to generate summary'
                                                          : parse(
                                                              activeArtifact?.interactionSummaryHtml ||
                                                                '',
                                                            )}
                                                      </Typography>
                                                    ) : currentArtifactDetailTab ===
                                                      'detail' ? (
                                                      <Box
                                                        sx={{
                                                          display: 'flex',
                                                        }}
                                                      >
                                                        <Typography
                                                          sx={{
                                                            whiteSpace:
                                                              'pre-wrap',
                                                            padding: 2,
                                                            flex: 1,
                                                            borderRight:
                                                              '1px solid #d3d3d3',
                                                            minHeight:
                                                              'calc(100vh - 280px)',
                                                          }}
                                                        >
                                                          {activeArtifact?.mediaFileBasedInteraction
                                                            ? parse(
                                                                activeArtifact?.customField?.salesReviewFile?.transcript?.replace(
                                                                  new RegExp(
                                                                    '(([0-5])?[0-9]):([0-5][0-9])',
                                                                    'gmi',
                                                                  ),
                                                                  (
                                                                    highlight,
                                                                  ) => {
                                                                    return `<button class="highlight">${highlight}</button>`;
                                                                  },
                                                                ) || '',
                                                              )
                                                            : activeArtifact?.content}
                                                        </Typography>
                                                        {artifactTypesWithoutMedia?.includes(
                                                          activeArtifact?.interactionType,
                                                        ) ||
                                                        !activeArtifact?.mediaFileBasedInteraction ? null : (
                                                          <Box
                                                            sx={{
                                                              flex: 1,
                                                              display: 'flex',
                                                              flexDirection:
                                                                'column',
                                                              gap: 2,
                                                              alignItems:
                                                                'center',
                                                            }}
                                                          >
                                                            {activeArtifact?.mediaFileBasedInteraction ? (
                                                              <MediaPreview
                                                                videoRef={
                                                                  videoRef
                                                                }
                                                                styles={{
                                                                  flex: 1,
                                                                  width: '100%',
                                                                  padding: 2,
                                                                  display:
                                                                    'flex',
                                                                  justifyContent:
                                                                    'center',
                                                                  alignItems:
                                                                    'center',
                                                                  flexDirection:
                                                                    'column',
                                                                }}
                                                                url={
                                                                  activeArtifact
                                                                    ?.customField
                                                                    ?.salesReviewFile
                                                                    ?.customData
                                                                    ?.url || ''
                                                                }
                                                                mediaType={
                                                                  activeArtifact
                                                                    ?.customField
                                                                    ?.salesReviewFile
                                                                    ?.saleReviewMediaContentType ||
                                                                  ''
                                                                }
                                                                handleVideoSeekTo
                                                              />
                                                            ) : null}
                                                            <ParticipationPieChart
                                                              styles={
                                                                {
                                                                  // maxWidth: 180,
                                                                }
                                                              }
                                                              title={
                                                                'Call Participation'
                                                              }
                                                              labels={
                                                                Object.keys(
                                                                  activeArtifact
                                                                    ?.customField
                                                                    ?.salesReviewFile
                                                                    ?.analysis
                                                                    ?.speakProportion ||
                                                                    {},
                                                                )
                                                                  ?.filter(
                                                                    (sp) =>
                                                                      sp !==
                                                                      'silence',
                                                                  )
                                                                  ?.sort(
                                                                    (a, b) =>
                                                                      a?.split(
                                                                        '_',
                                                                      )?.[1] -
                                                                      b?.split(
                                                                        '_',
                                                                      )?.[1],
                                                                  ) || []
                                                              }
                                                              speakers={
                                                                activeArtifact
                                                                  ?.customField
                                                                  ?.salesReviewFile
                                                                  ?.speakers ||
                                                                ''
                                                              }
                                                              data={Object.keys(
                                                                activeArtifact
                                                                  ?.customField
                                                                  ?.salesReviewFile
                                                                  ?.analysis
                                                                  ?.speakProportion ||
                                                                  {},
                                                              )
                                                                ?.filter(
                                                                  (key) =>
                                                                    key !==
                                                                    'silence',
                                                                )
                                                                ?.sort(
                                                                  (a, b) =>
                                                                    a?.split(
                                                                      '_',
                                                                    )?.[1] -
                                                                    b?.split(
                                                                      '_',
                                                                    )?.[1],
                                                                )
                                                                ?.map(
                                                                  (
                                                                    key,
                                                                    index,
                                                                  ) =>
                                                                    Math.round(
                                                                      (activeArtifact
                                                                        ?.customField
                                                                        ?.salesReviewFile
                                                                        ?.analysis
                                                                        ?.speakProportion[
                                                                        key
                                                                      ] /
                                                                        Object.keys(
                                                                          activeArtifact
                                                                            ?.customField
                                                                            ?.salesReviewFile
                                                                            ?.analysis
                                                                            ?.speakProportion ||
                                                                            {},
                                                                        )
                                                                          ?.filter(
                                                                            (
                                                                              key,
                                                                            ) =>
                                                                              key !==
                                                                              'silence',
                                                                          )
                                                                          ?.sort(
                                                                            (
                                                                              a,
                                                                              b,
                                                                            ) =>
                                                                              a?.split(
                                                                                '_',
                                                                              )?.[1] -
                                                                              b?.split(
                                                                                '_',
                                                                              )?.[1],
                                                                          )
                                                                          ?.reduce(
                                                                            (
                                                                              acc,
                                                                              curr,
                                                                            ) =>
                                                                              acc +
                                                                              activeArtifact
                                                                                ?.customField
                                                                                ?.salesReviewFile
                                                                                ?.analysis
                                                                                ?.speakProportion[
                                                                                curr
                                                                              ],
                                                                            0,
                                                                          )) *
                                                                        100,
                                                                    ),
                                                                )}
                                                            />
                                                            {scoreCalculationInProcess &&
                                                            scoreCalculationInProcess?.[
                                                              scoreType
                                                            ] &&
                                                            scoreCalculationInProcess?.[
                                                              scoreType
                                                            ] !== 'completed' &&
                                                            scoreCalculationInProcess?.[
                                                              scoreType
                                                            ] !== 'failed' ? (
                                                              <Box
                                                                display={'flex'}
                                                                flexDirection={
                                                                  'column'
                                                                }
                                                                justifyContent={
                                                                  'center'
                                                                }
                                                                alignItems={
                                                                  'center'
                                                                }
                                                                gap={2}
                                                                flex={1}
                                                              >
                                                                <CircularProgress
                                                                  size={20}
                                                                />
                                                                <Typography
                                                                  variant="caption"
                                                                  color={
                                                                    'text.secondary'
                                                                  }
                                                                >
                                                                  Score
                                                                  generation is
                                                                  in process...
                                                                </Typography>
                                                              </Box>
                                                            ) : (
                                                              <Box
                                                                display={'flex'}
                                                                flexDirection={
                                                                  'column'
                                                                }
                                                                alignItems={
                                                                  'center'
                                                                }
                                                                gap={1}
                                                              >
                                                                <Box
                                                                  display={
                                                                    'flex'
                                                                  }
                                                                  alignItems={
                                                                    'center'
                                                                  }
                                                                >
                                                                  {Object.keys(
                                                                    activeArtifact
                                                                      ?.customField
                                                                      ?.salesReviewFile
                                                                      ?.speakers ||
                                                                      {},
                                                                  )
                                                                    ?.filter(
                                                                      (spk) =>
                                                                        spk !==
                                                                        speaker,
                                                                    )
                                                                    ?.map(
                                                                      (
                                                                        key,
                                                                        index,
                                                                        ar,
                                                                      ) => (
                                                                        <Box
                                                                          key={
                                                                            key
                                                                          }
                                                                          sx={{
                                                                            background:
                                                                              scoreType !==
                                                                              key
                                                                                ? '#d3d3d3'
                                                                                : theme
                                                                                    .palette
                                                                                    .primary
                                                                                    .main,
                                                                            color:
                                                                              scoreType !==
                                                                              key
                                                                                ? '#000'
                                                                                : '#fff',
                                                                            padding:
                                                                              '2px 8px',
                                                                            borderTopLeftRadius:
                                                                              index ===
                                                                              0
                                                                                ? 20
                                                                                : 0,
                                                                            borderBottomLeftRadius:
                                                                              index ===
                                                                              0
                                                                                ? 20
                                                                                : 0,
                                                                            borderBottomRightRadius:
                                                                              index ===
                                                                              ar?.length -
                                                                                1
                                                                                ? 20
                                                                                : 0,
                                                                            borderTopRightRadius:
                                                                              index ===
                                                                              ar?.length -
                                                                                1
                                                                                ? 20
                                                                                : 0,
                                                                            cursor:
                                                                              'pointer',
                                                                          }}
                                                                          onClick={() =>
                                                                            setScoreType(
                                                                              key,
                                                                            )
                                                                          }
                                                                        >
                                                                          <Typography variant="caption">
                                                                            {capitalizeText(
                                                                              activeArtifact
                                                                                ?.customField
                                                                                ?.salesReviewFile
                                                                                ?.speakers?.[
                                                                                key
                                                                              ],
                                                                            )}
                                                                          </Typography>
                                                                        </Box>
                                                                      ),
                                                                    )}
                                                                </Box>
                                                                <Typography
                                                                  variant="caption"
                                                                  color={
                                                                    'text.secondary'
                                                                  }
                                                                  mt={1}
                                                                >
                                                                  Scores
                                                                </Typography>
                                                                <Bar
                                                                  style={{
                                                                    width:
                                                                      '100%',
                                                                  }}
                                                                  options={{
                                                                    responsive: true,
                                                                    layout: {
                                                                      padding: 10,
                                                                    },
                                                                    plugins: {
                                                                      legend: {
                                                                        display: false,
                                                                        // position: 'top',
                                                                      },
                                                                      tooltip: {
                                                                        enabled: true,
                                                                      },

                                                                      title: {
                                                                        display: false,
                                                                        text:
                                                                          'Skills Score',
                                                                      },
                                                                      datalabels: {
                                                                        color:
                                                                          'white',
                                                                        font: {
                                                                          weight:
                                                                            'bold',
                                                                          size: 14,
                                                                        },
                                                                        // rotation: -90,
                                                                        formatter: function(
                                                                          value,
                                                                          context,
                                                                        ) {
                                                                          return `${value}`;
                                                                        },
                                                                      },
                                                                    },
                                                                    elements: {
                                                                      bar: {
                                                                        borderRadius: 6,
                                                                        borderSkipped: false,
                                                                      },
                                                                    },
                                                                    scales: {
                                                                      y: {
                                                                        beginAtZero: true,
                                                                        max: 100,
                                                                      },
                                                                    },
                                                                  }}
                                                                  data={{
                                                                    labels:
                                                                      scoreType ===
                                                                      activeArtifact
                                                                        ?.customField
                                                                        ?.salesReviewFile
                                                                        ?.speaker
                                                                        ? [
                                                                            'Pace',
                                                                            'Fluency',
                                                                            'Confidence',
                                                                            'Enthusiasm',
                                                                            'Clarity',
                                                                          ]
                                                                        : [
                                                                            'Fluency',
                                                                            'Confidence',
                                                                            'Enthusiasm',
                                                                          ],
                                                                    datasets: [
                                                                      {
                                                                        label:
                                                                          'Score',
                                                                        data:
                                                                          scoreType ===
                                                                          activeArtifact
                                                                            ?.customField
                                                                            ?.salesReviewFile
                                                                            ?.speaker
                                                                            ? [
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.analysis
                                                                                  ?.paceScore ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.analysis
                                                                                        ?.paceScore,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.fluency ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.fluency,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.confidence ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.confidence,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.enthusiasm ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.enthusiasm,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.analysis
                                                                                  ?.clarity ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.analysis
                                                                                        ?.clarity,
                                                                                    ),
                                                                              ]
                                                                            : [
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.fluency ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.fluency,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.confidence ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.confidence,
                                                                                    ),
                                                                                activeArtifact
                                                                                  ?.customField
                                                                                  ?.salesReviewFile
                                                                                  ?.speakerScoreMap?.[
                                                                                  scoreType
                                                                                ]
                                                                                  ?.enthusiasm ===
                                                                                undefined
                                                                                  ? '-'
                                                                                  : Math.round(
                                                                                      activeArtifact
                                                                                        ?.customField
                                                                                        ?.salesReviewFile
                                                                                        ?.speakerScoreMap?.[
                                                                                        scoreType
                                                                                      ]
                                                                                        ?.enthusiasm,
                                                                                    ),
                                                                              ],
                                                                        backgroundColor:
                                                                          theme
                                                                            .palette
                                                                            .primary
                                                                            .main,
                                                                        barThickness: 20,
                                                                        pointStyle:
                                                                          'circle',
                                                                        fill: true,
                                                                      },
                                                                    ],
                                                                  }}
                                                                />
                                                              </Box>
                                                            )}
                                                          </Box>
                                                        )}
                                                      </Box>
                                                    ) : currentArtifactDetailTab ===
                                                      'risk' ? (
                                                      <ArtifactRisksSelectionList
                                                        viewType={'dealDetails'}
                                                        readOnly={
                                                          fetchingArtifactNBAs ||
                                                          (activeArtifact?.nextBestActionsMap &&
                                                            Object.keys(
                                                              activeArtifact?.nextBestActionsMap ||
                                                                {},
                                                            ) &&
                                                            Object.keys(
                                                              activeArtifact?.nextBestActionsMap ||
                                                                {},
                                                            )?.length > 0)
                                                        }
                                                        activeArtifact={
                                                          activeArtifact
                                                        }
                                                        successCallback={(
                                                          risks,
                                                          actions,
                                                        ) => {
                                                          setActiveArtifact(
                                                            (prev) => {
                                                              return {
                                                                ...prev,
                                                                selectedRisks: risks,
                                                                nextBestActionsMap: actions,
                                                              };
                                                            },
                                                          );
                                                          setCurrentArtifactDetailTab(
                                                            'actions',
                                                          );
                                                        }}
                                                      />
                                                    ) : currentArtifactDetailTab ===
                                                      'actions' ? (
                                                      <NextBestActions
                                                        readOnly={
                                                          activeArtifact?.nextBestActionsMap &&
                                                          Object.keys(
                                                            activeArtifact?.nextBestActionsMap ||
                                                              {},
                                                          ) &&
                                                          Object.keys(
                                                            activeArtifact?.nextBestActionsMap ||
                                                              {},
                                                          )?.length > 0
                                                        }
                                                        activeArtifact={
                                                          activeArtifact
                                                        }
                                                        sx={{
                                                          margin: 2,
                                                        }}
                                                        setCurrentArtifactDetailTab={
                                                          setCurrentArtifactDetailTab
                                                        }
                                                        setActiveArtifactTab={
                                                          setActiveArtifactTab
                                                        }
                                                      />
                                                    ) : null}
                                                  </>
                                                )}
                                              </Box>
                                            </Box>
                                            <Box
                                              sx={{
                                                position: 'absolute',
                                                top: 85,
                                                right: 10,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                              }}
                                            >
                                              {/* <StakeholderDetection
                                              dealId={dealId}
                                              artifact={dealArtifact}
                                              currentDealStakeholders={
                                                currentDealStakeholders
                                              }
                                              setStakeHolders={setStakeHolders}
                                              setRefreshCurrentDealStakeholders={
                                                setRefreshCurrentDealStakeholders
                                              }
                                            /> */}
                                              <ArtifactStatus
                                                dealId={dealId}
                                                artifact={dealArtifact}
                                                setDealArtifacts={
                                                  setDealArtifacts
                                                }
                                                setActiveArtifact={
                                                  setActiveArtifact
                                                }
                                              />
                                            </Box>
                                          </>
                                        ) : null}
                                        {showAskDonna ? (
                                          <Box
                                            sx={{
                                              position: 'absolute',
                                              top: 70,
                                              right: 0,
                                              border: '1px solid #d3d3d3',
                                              flex: 1,
                                              width: '50%',
                                              minHeight: 'calc(100vh - 220px)',
                                              maxHeight: 'calc(100vh - 220px)',
                                              overflowY: 'scroll',
                                              background: '#fff',
                                              '&::-webkit-scrollbar': {
                                                width: '5px',
                                              },
                                              '&::-webkit-scrollbar-track': {
                                                boxShadow:
                                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                                                webkitBoxShadow:
                                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                                              },
                                              '&::-webkit-scrollbar-thumb': {
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                                borderRadius: 2,
                                              },
                                              zIndex: 1000,
                                            }}
                                          >
                                            <Box
                                              p={1}
                                              sx={{
                                                borderBottom:
                                                  '1px solid #d3d3d3',
                                                display: 'flex',
                                                alignItems: 'center',
                                              }}
                                            >
                                              <IconButton
                                                onClick={() =>
                                                  setShowAskDonna(null)
                                                }
                                              >
                                                <ChevronRight sx={{}} />
                                              </IconButton>
                                              <Typography
                                                color={'text.secondary'}
                                              >
                                                {'Ask Donna'}
                                              </Typography>
                                            </Box>
                                            <Box
                                              sx={{
                                                minHeight:
                                                  'calc(100vh - 280px)',
                                                maxHeight:
                                                  'calc(100vh - 280px)',
                                                overflowY: 'scroll',
                                                '&::-webkit-scrollbar': {
                                                  width: '5px',
                                                },
                                                '&::-webkit-scrollbar-track': {
                                                  boxShadow:
                                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                                  webkitBoxShadow:
                                                    'inset 0 0 6px rgba(0,0,0,0.00)',
                                                },
                                                '&::-webkit-scrollbar-thumb': {
                                                  backgroundColor:
                                                    theme.palette.primary.main,
                                                  borderRadius: 2,
                                                  // outline: '1px solid slategrey',
                                                },
                                                // background: '#343a40',
                                                flex: 1,
                                              }}
                                            >
                                              <Box
                                                display={'flex'}
                                                flexDirection={'column'}
                                                gap={2}
                                              >
                                                {fetchingConversationById ? (
                                                  <Box
                                                    width={1}
                                                    height={1}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    flexDirection={'column'}
                                                    p={2}
                                                  >
                                                    <CircularProgress
                                                      size={20}
                                                    />
                                                    <Typography
                                                      variant="body1"
                                                      color={'text.primary'}
                                                      mt={2}
                                                    >
                                                      Loading all chats ...
                                                    </Typography>
                                                  </Box>
                                                ) : conversationDetails ? (
                                                  <>
                                                    {conversationDetails?.messages &&
                                                    conversationDetails
                                                      ?.messages?.length > 0 ? (
                                                      <Box
                                                        flex={1}
                                                        display={'flex'}
                                                        flexDirection={'column'}
                                                        gap={1}
                                                      >
                                                        <Box
                                                          ref={chatsScrollview}
                                                          sx={{
                                                            minHeight:
                                                              'calc(100vh - 340px)',
                                                            maxHeight:
                                                              'calc(100vh - 340px)',
                                                            overflowY: 'scroll',
                                                            '&::-webkit-scrollbar': {
                                                              width: '5px',
                                                            },
                                                            '&::-webkit-scrollbar-track': {
                                                              boxShadow:
                                                                'inset 0 0 6px rgba(0,0,0,0.00)',
                                                              webkitBoxShadow:
                                                                'inset 0 0 6px rgba(0,0,0,0.00)',
                                                            },
                                                            '&::-webkit-scrollbar-thumb': {
                                                              backgroundColor:
                                                                theme.palette
                                                                  .primary.main,
                                                              borderRadius: 2,
                                                            },
                                                            padding: 2,
                                                            display: 'flex',
                                                            flexDirection:
                                                              'column',
                                                            gap: 1,
                                                          }}
                                                        >
                                                          {conversationDetails?.messages?.map(
                                                            (msg, index) => (
                                                              <>
                                                                {msg ? (
                                                                  <Box
                                                                    key={
                                                                      msg?.id
                                                                    }
                                                                    sx={{
                                                                      borderTopLeftRadius:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? 16
                                                                          : 0,
                                                                      borderTopRightRadius:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? 0
                                                                          : 16,
                                                                      borderBottomLeftRadius:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? 8
                                                                          : 16,
                                                                      borderBottomRightRadius:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? 16
                                                                          : 8,
                                                                      maxWidth:
                                                                        '80%',
                                                                      alignSelf:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? 'end'
                                                                          : 'start',
                                                                      display:
                                                                        'flex',
                                                                      flexDirection:
                                                                        'column',
                                                                      // gap: 1,
                                                                      boxShadow:
                                                                        theme
                                                                          .shadows[8],
                                                                      background:
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.role ===
                                                                        'user'
                                                                          ? theme
                                                                              .palette
                                                                              .primary
                                                                              .main
                                                                          : '#fff',
                                                                    }}
                                                                  >
                                                                    <Box
                                                                      display={
                                                                        'flex'
                                                                      }
                                                                      alignItems={
                                                                        'center'
                                                                      }
                                                                      justifyContent={
                                                                        'space-between'
                                                                      }
                                                                      sx={{
                                                                        padding:
                                                                          '8px 16px',
                                                                        gap: 4,
                                                                      }}
                                                                    >
                                                                      {msg
                                                                        ?.gptMessage
                                                                        ?.role ===
                                                                      'user' ? (
                                                                        <Typography
                                                                          variant="caption"
                                                                          sx={{
                                                                            color:
                                                                              msg
                                                                                ?.gptMessage
                                                                                ?.role ===
                                                                              'user'
                                                                                ? '#f1f1f1'
                                                                                : theme
                                                                                    .palette
                                                                                    .text
                                                                                    .secondary,
                                                                          }}
                                                                        >
                                                                          {
                                                                            'You'
                                                                          }
                                                                        </Typography>
                                                                      ) : (
                                                                        <Typography
                                                                          variant="caption"
                                                                          sx={{
                                                                            color:
                                                                              msg
                                                                                ?.gptMessage
                                                                                ?.role ===
                                                                              'user'
                                                                                ? '#f1f1f1'
                                                                                : theme
                                                                                    .palette
                                                                                    .text
                                                                                    .secondary,
                                                                          }}
                                                                        >
                                                                          Donna
                                                                        </Typography>
                                                                      )}
                                                                      <Typography
                                                                        variant="caption"
                                                                        sx={{
                                                                          color:
                                                                            msg
                                                                              ?.gptMessage
                                                                              ?.role ===
                                                                            'user'
                                                                              ? '#f1f1f1'
                                                                              : theme
                                                                                  .palette
                                                                                  .text
                                                                                  .secondary,
                                                                        }}
                                                                      >
                                                                        {moment(
                                                                          msg?.createdOn,
                                                                        ).format(
                                                                          'DD MMMM YYYY hh:mm:ss A',
                                                                        )}
                                                                      </Typography>
                                                                    </Box>
                                                                    <Typography
                                                                      sx={{
                                                                        whiteSpace:
                                                                          'pre-wrap',
                                                                        color:
                                                                          msg
                                                                            ?.gptMessage
                                                                            ?.role ===
                                                                          'user'
                                                                            ? '#fff'
                                                                            : theme
                                                                                .palette
                                                                                .text
                                                                                .primary,
                                                                        padding:
                                                                          '0px 16px 16px 16px',
                                                                      }}
                                                                    >
                                                                      {
                                                                        msg
                                                                          ?.gptMessage
                                                                          ?.content
                                                                      }
                                                                    </Typography>
                                                                  </Box>
                                                                ) : null}
                                                              </>
                                                            ),
                                                          )}
                                                        </Box>
                                                      </Box>
                                                    ) : !conversationDetails?.messages ||
                                                      (conversationDetails?.messages &&
                                                        conversationDetails
                                                          ?.messages?.length ===
                                                          0) ? (
                                                      <Box
                                                        width={1}
                                                        height={1}
                                                        display={'flex'}
                                                        justifyContent={
                                                          'center'
                                                        }
                                                        alignItems={'center'}
                                                        p={2}
                                                        flexDirection={'column'}
                                                      >
                                                        <Typography
                                                          variant="body1"
                                                          color={
                                                            'text.secondary'
                                                          }
                                                          mt={4}
                                                        >
                                                          No conversation done
                                                          yet.
                                                        </Typography>
                                                      </Box>
                                                    ) : (
                                                      <Box
                                                        width={1}
                                                        height={1}
                                                        display={'flex'}
                                                        justifyContent={
                                                          'center'
                                                        }
                                                        alignItems={'center'}
                                                        p={2}
                                                        flexDirection={'column'}
                                                      >
                                                        <CircularProgress
                                                          size={20}
                                                        />
                                                        <Typography
                                                          variant="body1"
                                                          color={'text.primary'}
                                                          mt={4}
                                                        >
                                                          This will take a few
                                                          seconds. Please check
                                                          back after a short
                                                          while.
                                                        </Typography>
                                                      </Box>
                                                    )}
                                                  </>
                                                ) : (
                                                  <Box
                                                    flex={3}
                                                    display={'flex'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    flexDirection={'column'}
                                                    gap={2}
                                                  >
                                                    <Typography
                                                      variant="body1"
                                                      color={'text.secondary'}
                                                      mt={4}
                                                    >
                                                      No conversation done yet.
                                                    </Typography>
                                                  </Box>
                                                )}
                                                <form
                                                  onSubmit={(e) => {
                                                    e.preventDefault();
                                                    if (
                                                      currentDealSpecificConversationId
                                                    ) {
                                                      setGeneratingResponse(
                                                        true,
                                                      );
                                                      dispatch(
                                                        getMessageResponse(
                                                          currentDealSpecificConversationId,
                                                          askAIQuestion?.trim(),
                                                          '',
                                                          null,
                                                          null,
                                                          'v2',
                                                          wordsLimit,
                                                          dealId,
                                                          async (data) => {
                                                            // console.log(data);
                                                            setGeneratingResponse(
                                                              false,
                                                            );
                                                            setAskAIQuestion(
                                                              '',
                                                            );
                                                            dispatch({
                                                              type:
                                                                'UPDATE_CONVERSATION_MESSAGES',
                                                              payload: [
                                                                {
                                                                  gptMessage: {
                                                                    role:
                                                                      'user',
                                                                    content: askAIQuestion?.trim(),
                                                                  },
                                                                  id: uuidv4(),
                                                                  createdOn: Date.now(),
                                                                  audioId: uuidv4(),
                                                                  userId: null,
                                                                  customFields: {},
                                                                },
                                                                ...data,
                                                              ],
                                                            });
                                                            if (
                                                              chatsScrollview?.current
                                                            ) {
                                                              chatsScrollview?.current?.scroll(
                                                                {
                                                                  top:
                                                                    chatsScrollview
                                                                      ?.current
                                                                      ?.scrollHeight,
                                                                  behaviour:
                                                                    'smooth',
                                                                },
                                                              );
                                                            }
                                                          },
                                                          (errorCode) => {
                                                            setGeneratingResponse(
                                                              false,
                                                            );
                                                            if (
                                                              errorCode ===
                                                              'quota exceeded'
                                                            ) {
                                                              // setShowLimitExhausted(errorCode);
                                                            }
                                                          },
                                                        ),
                                                      );
                                                    } else {
                                                      setCreatingConversation(
                                                        true,
                                                      );
                                                      dispatch(
                                                        createNewConversation(
                                                          {
                                                            // description: '',
                                                            title: askAIQuestion?.trim(),
                                                            botId:
                                                              orgConfigs
                                                                ?.dealsExpertBot
                                                                ?.id,
                                                            conversationMod:
                                                              'chat',
                                                          },
                                                          (conId) => {
                                                            setGeneratingResponse(
                                                              true,
                                                            );
                                                            dispatch(
                                                              getConversationById(
                                                                conId,
                                                                null,
                                                                (data) => {
                                                                  dispatch(
                                                                    updateDeal(
                                                                      dealId,
                                                                      {
                                                                        currentDealSpecificConversationId: conId,
                                                                      },
                                                                      (
                                                                        dealData,
                                                                        error,
                                                                      ) => {
                                                                        console.log(
                                                                          dealData,
                                                                        );
                                                                        if (
                                                                          error
                                                                        ) {
                                                                          dispatch(
                                                                            showAlert(
                                                                              {
                                                                                message:
                                                                                  'Oops, Something went wront! Please try again.',
                                                                                showCross: true,
                                                                                title: null,
                                                                                type:
                                                                                  'error',
                                                                                autoHideDuration: 2000,
                                                                                vertical:
                                                                                  'top',
                                                                                horizontal:
                                                                                  'center',
                                                                              },
                                                                            ),
                                                                          );
                                                                        } else {
                                                                          setCurrentDealSpecificConversationId(
                                                                            conId,
                                                                          );
                                                                        }
                                                                      },
                                                                    ),
                                                                  );
                                                                  dispatch(
                                                                    updateConversationStatus(
                                                                      conId,
                                                                      'in_process',
                                                                      () => {
                                                                        dispatch(
                                                                          getMessageResponse(
                                                                            conId,
                                                                            askAIQuestion?.trim(),
                                                                            '',
                                                                            null,
                                                                            null,
                                                                            'v2',
                                                                            wordsLimit,
                                                                            dealId,
                                                                            async (
                                                                              data,
                                                                            ) => {
                                                                              // console.log(data);
                                                                              setGeneratingResponse(
                                                                                false,
                                                                              );
                                                                              setAskAIQuestion(
                                                                                '',
                                                                              );
                                                                              dispatch(
                                                                                {
                                                                                  type:
                                                                                    'UPDATE_CONVERSATION_MESSAGES',
                                                                                  payload: [
                                                                                    {
                                                                                      gptMessage: {
                                                                                        role:
                                                                                          'user',
                                                                                        content: askAIQuestion?.trim(),
                                                                                      },
                                                                                      id: uuidv4(),
                                                                                      createdOn: Date.now(),
                                                                                      audioId: uuidv4(),
                                                                                      userId: null,
                                                                                      customFields: {},
                                                                                    },
                                                                                    ...data,
                                                                                  ],
                                                                                },
                                                                              );
                                                                              if (
                                                                                chatsScrollview?.current
                                                                              ) {
                                                                                chatsScrollview?.current?.scroll(
                                                                                  {
                                                                                    top:
                                                                                      chatsScrollview
                                                                                        ?.current
                                                                                        ?.scrollHeight,
                                                                                    behaviour:
                                                                                      'smooth',
                                                                                  },
                                                                                );
                                                                              }
                                                                            },
                                                                            (
                                                                              errorCode,
                                                                            ) => {
                                                                              setGeneratingResponse(
                                                                                false,
                                                                              );
                                                                              if (
                                                                                errorCode ===
                                                                                'quota exceeded'
                                                                              ) {
                                                                                // setShowLimitExhausted(errorCode);
                                                                              }
                                                                            },
                                                                          ),
                                                                        );
                                                                      },
                                                                    ),
                                                                  );
                                                                },
                                                              ),
                                                            );
                                                            setCreatingConversation(
                                                              false,
                                                            );
                                                          },
                                                        ),
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <Box
                                                    display={'flex'}
                                                    flexDirection={'row'}
                                                    alignItems={'center'}
                                                    gap={1}
                                                    borderTop={
                                                      '1px solid #d3d3d3'
                                                    }
                                                    p={1}
                                                    sx={{
                                                      position: 'absolute',
                                                      bottom: 0,
                                                      left: 0,
                                                      zIndex: 11,
                                                      width: 1,
                                                      background: '#fff',
                                                    }}
                                                  >
                                                    <TextField
                                                      autoFocus
                                                      placeholder="Ask any question"
                                                      variant="outlined"
                                                      color="primary"
                                                      size="small"
                                                      name="askAIQuestion"
                                                      value={askAIQuestion}
                                                      onChange={(e) =>
                                                        setAskAIQuestion(
                                                          e.target.value,
                                                        )
                                                      }
                                                      sx={{ flex: 4 }}
                                                      autoComplete="off"
                                                    />
                                                    {/* <FormControl
                                                    size="small"
                                                    sx={{ minWidth: 100 }}
                                                  >
                                                    <InputLabel id="wordsLimit">
                                                      Words Limit
                                                    </InputLabel>
                                                    <Select
                                                      variant="outlined"
                                                      size="small"
                                                      labelId={'wordsLimit'}
                                                      label={'Words Limit'}
                                                      id={'wordsLimit'}
                                                      name={'wordsLimit'}
                                                      MenuProps={{
                                                        disableScrollLock: true,
                                                      }}
                                                      onChange={(e) => {
                                                        setWordsLimit(
                                                          e.target.value,
                                                        );
                                                      }}
                                                      value={wordsLimit}
                                                    >
                                                      <MenuItem value={50}>
                                                        <Typography variant="subtitle2">
                                                          50
                                                        </Typography>
                                                      </MenuItem>
                                                      <MenuItem value={100}>
                                                        <Typography variant="subtitle2">
                                                          100
                                                        </Typography>
                                                      </MenuItem>
                                                      <MenuItem value={150}>
                                                        <Typography variant="subtitle2">
                                                          150
                                                        </Typography>
                                                      </MenuItem>
                                                      <MenuItem value={200}>
                                                        <Typography variant="subtitle2">
                                                          200
                                                        </Typography>
                                                      </MenuItem>
                                                      {process.env
                                                        .REACT_APP_TARGET_ENV ===
                                                        'local' ||
                                                      process.env
                                                        .REACT_APP_TARGET_ENV ===
                                                        'development' ? (
                                                        <MenuItem value={500}>
                                                          <Typography variant="subtitle2">
                                                            500
                                                          </Typography>
                                                        </MenuItem>
                                                      ) : null}
                                                    </Select>
                                                  </FormControl> */}
                                                    <Button
                                                      sx={{ flex: 1 }}
                                                      variant="contained"
                                                      size="small"
                                                      color="primary"
                                                      disabled={
                                                        generatingResponse ||
                                                        !askAIQuestion.trim()
                                                        // conversationDetails?.presetQuestionStatus !== 'completed'
                                                      }
                                                      startIcon={
                                                        generatingResponse ? (
                                                          <CircularProgress
                                                            size={20}
                                                          />
                                                        ) : (
                                                          <AutoAwesome />
                                                        )
                                                      }
                                                      type="submit"
                                                    >
                                                      Ask Donna
                                                    </Button>
                                                  </Box>
                                                </form>
                                              </Box>
                                            </Box>
                                          </Box>
                                        ) : null}
                                      </Box>
                                    ))}
                                </Box>
                              ))}
                            </Box>
                          ))}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 16,
                              left: 94,
                              width: '80%',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                              }}
                            >
                              {activeArtifactTab === 'detail' ? (
                                <>
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    sx={{ padding: '4px 8px' }}
                                    startIcon={<ArrowBack />}
                                    onClick={() =>
                                      setActiveArtifactTab('summary')
                                    }
                                  >
                                    Go Back
                                  </Button>
                                  <Typography>
                                    {activeArtifact?.title}
                                  </Typography>
                                </>
                              ) : null}
                            </Box>
                          </Box>
                          <Button
                            sx={{
                              position: 'absolute',
                              top: 16,
                              right: 10,
                              padding: '4px 8px',
                            }}
                            startIcon={<AutoAwesome />}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => setShowAskDonna(true)}
                          >
                            Ask Donna
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minHeight: 300,
                          width: 1,
                        }}
                      >
                        <Typography>No events found.</Typography>
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Box>
          ) : viewType === 'calendar' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                minHeight: 'calc(100vh - 145px)',
                maxHeight: 'calc(100vh - 145px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                padding: 2,
              }}
            >
              {/* <Typography>Calendar</Typography> */}
              {qList && qList?.length > 0 ? (
                <DragNDropSortableList
                  qList={qList}
                  questionTopics={questionTopics}
                  questionSubTopics={questionSubTopics}
                />
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: 300,
                    gap: 2,
                  }}
                >
                  <Typography>No discovery questions found.</Typography>
                </Box>
              )}
            </Box>
          ) : viewType === 'shield' ? (
            <Box sx={{ padding: 2 }}>
              <Typography>Deal Risks</Typography>
            </Box>
          ) : viewType === 'edit' ? (
            <Box display={'flex'} gap={2} sx={{ padding: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'flex-start',
                  flex: 1,
                  background: '#F2F6FC',
                  padding: 2,
                  minHeight: 'calc(100vh - 180px)',
                }}
                className={'manageDeal'}
              >
                {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                  }}
                >
                  <Avatar
                    variant="square"
                    src={companyLogo}
                    sx={{
                      width: 100,
                      height: '100%',
                      minHeight: 100,
                      border: '1px solid #d3d3d3',
                      borderRadius: 1,
                      padding: 1,
                    }}
                    alt="Logo"
                  >
                    {'Logo'}
                  </Avatar>
                  <Box>
                    <Typography fontWeight={'bold'}>{companyName}</Typography>
                    {companySize ? (
                      <Typography color={'text.secondary'} variant="subtitle2">
                        Company Size - {companySize}
                      </Typography>
                    ) : null}
                    <Tooltip title={companyDescription} arrow placement="top">
                      <Typography
                        variant={'subtitle2'}
                        sx={{
                          cursor: 'pointer',
                          // maxWidth: 200,
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          WebkitLineClamp: '2',
                          WebkitBoxOrient: 'vertical',
                        }}
                      >
                        {companyDescription}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box> */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <Typography fontWeight={'bold'}>Company Name</Typography>
                  <TextField
                    placeholder="Enter Company Name"
                    variant="outlined"
                    fullWidth
                    name={'companyName'}
                    size="small"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                  <Typography fontWeight={'bold'}>Website*</Typography>
                  <TextField
                    placeholder="Enter company website*"
                    variant="outlined"
                    name={'companyWebsite'}
                    size="small"
                    value={companyWebsite}
                    fullWidth
                    onChange={(e) =>
                      setCompanyWebsite(
                        e.target.value?.includes('https://')
                          ? e.target.value?.trim()?.split('https://')?.[1]
                          : e.target.value?.includes('http://')
                          ? e.target.value?.trim()?.split('http://')?.[1]
                          : e.target.value,
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.primary.main}
                          >
                            https://
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Typography fontWeight={'bold'}>LinkedIn</Typography>
                  <TextField
                    placeholder="Enter LinkedIn"
                    variant="outlined"
                    name={'linkedIn'}
                    size="small"
                    fullWidth
                    value={linkedIn}
                    onChange={(e) =>
                      setLinkedIn(
                        e.target.value?.includes('https://')
                          ? e.target.value?.trim()?.split('https://')?.[1]
                          : e.target.value?.includes('http://')
                          ? e.target.value?.trim()?.split('http://')?.[1]
                          : e.target.value,
                      )
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography
                            variant="subtitle2"
                            color={theme.palette.primary.main}
                          >
                            https://
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* <Typography fontWeight={'bold'}>
                      Company Location
                    </Typography>
                    <TextField
                      placeholder="Enter company location"
                      variant="outlined"
                      fullWidth
                      name={'companyLocation'}
                      size="small"
                      value={companyLocation}
                      onChange={(e) => setCompanyLocation(e.target.value)}
                    /> */}
                  {/* <Typography fontWeight={'bold'}>
                    Stakeholders (titles)*
                  </Typography>
                  <TextField
                    placeholder="Enter Stakeholders(Comma Separated)*"
                    variant="outlined"
                    fullWidth
                    name={'stakeholders'}
                    size="small"
                    value={stakeholders}
                    onChange={(e) => setStakeholders(e.target.value)}
                  /> */}
                </Box>
                <Button
                  variant="contained"
                  startIcon={
                    savingDealCompanyInfo ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                  onClick={() => {
                    setSavingDealCompanyInfo(true);
                    // dispatch(
                    //   scrapCompanyInfo(
                    //     {
                    //       companyDomain: companyWebsite,
                    //       companyLinkedin: linkedIn,
                    //       country: companyLocation,
                    //       role: stakeholders,
                    //     },
                    //     (scrappedCompanyInfo, error) => {
                    dispatch(
                      updateDeal(
                        dealId,
                        {
                          company: {
                            // description:
                            //   scrappedCompanyInfo?.profile?.description,
                            linkedIn: linkedIn,
                            // logoUrl:
                            //   scrappedCompanyInfo?.profile
                            //     ?.profile_pic_url,
                            name: companyName,
                            // stackHolderTitle: stakeholders
                            //   ?.split(',')
                            //   ?.map((stakeholder) => stakeholder?.trim()),
                            website: companyWebsite,
                          },
                        },
                        (dealData, error) => {
                          console.log(dealData);
                          if (error) {
                            dispatch(
                              showAlert({
                                message:
                                  'Oops, Something went wront! Please try again.',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setSavingDealCompanyInfo(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Company info is successfully updated.',
                                showCross: true,
                                title: null,
                                type: 'success',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          }
                        },
                      ),
                    );
                    //     },
                    //   ),
                    // );
                  }}
                  color="primary"
                  size="small"
                  disabled={
                    !companyWebsite?.trim()
                    // !stakeholders?.trim() ||
                    // scrappingCompany ||
                    // fetchingStakeholders
                  }
                >
                  Save
                </Button>
                {/* <Button
                  variant="contained"
                  startIcon={
                    scrappingCompany || fetchingStakeholders ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                  onClick={() => {
                    setScrappingCompany(true);
                    dispatch(
                      scrapCompanyInfo(
                        {
                          companyDomain: companyWebsite,
                          companyLinkedin: linkedIn,
                          country: companyLocation,
                          role: stakeholders,
                        },
                        (scrappedCompanyInfo, error) => {
                          dispatch(
                            updateDeal(
                              dealId,
                              {
                                company: {
                                  description:
                                    scrappedCompanyInfo?.profile?.description,
                                  linkedIn: scrappedCompanyInfo?.url,
                                  logoUrl:
                                    scrappedCompanyInfo?.profile
                                      ?.profile_pic_url,
                                  name: scrappedCompanyInfo?.profile?.name,
                                  stackHolderTitle: stakeholders
                                    ?.split(',')
                                    ?.map((stakeholder) => stakeholder?.trim()),
                                  website: companyWebsite,
                                },
                              },
                              (dealData, error) => {
                                console.log(dealData);
                                if (error) {
                                  dispatch(
                                    showAlert({
                                      message:
                                        'Oops, Something went wront! Please try again.',
                                      showCross: true,
                                      title: null,
                                      type: 'error',
                                      autoHideDuration: 2000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                } else {
                                  setFetchingStakeholders(true);
                                  dispatch(
                                    scrapCompanyEmployees(
                                      {
                                        companyDomain: companyWebsite,
                                        companyLinkedin:
                                          scrappedCompanyInfo?.url,
                                        country: 'us',
                                        role: stakeholders,
                                      },
                                      (scrappedStakeholders, error) => {
                                        dispatch(
                                          updateDeal(
                                            dealId,
                                            {
                                              dealStackHolders: scrappedStakeholders?.results?.map(
                                                (stakeholder) => {
                                                  return {
                                                    designation:
                                                      stakeholder?.profile
                                                        ?.occupation,
                                                    email: '',
                                                    linkedIn:
                                                      stakeholder?.[
                                                        'linkedin_profile_url'
                                                      ],
                                                    name:
                                                      stakeholder?.profile?.[
                                                        'full_name'
                                                      ],
                                                    profilePictureUrl:
                                                      stakeholder?.profile?.[
                                                        'profile_pic_url'
                                                      ],
                                                  };
                                                },
                                              ),
                                            },
                                            (dealData, error) => {
                                              console.log(dealData);
                                              if (error) {
                                                dispatch(
                                                  showAlert({
                                                    message:
                                                      'Oops, Something went wront! Please try again.',
                                                    showCross: true,
                                                    title: null,
                                                    type: 'error',
                                                    autoHideDuration: 2000,
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                  }),
                                                );
                                              } else {
                                                setFetchingStakeholders(false);
                                                setScrappingCompany(false);
                                              }
                                            },
                                          ),
                                        );
                                      },
                                    ),
                                  );
                                }
                              },
                            ),
                          );
                        },
                      ),
                    );
                  }}
                  color="primary"
                  size="small"
                  disabled={
                    !companyWebsite?.trim() ||
                    !stakeholders?.trim() ||
                    scrappingCompany ||
                    fetchingStakeholders
                  }
                >
                  Look Up
                </Button> */}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  alignItems: 'flex-start',
                  flex: 1,
                  background: '#F2F6FC',
                  padding: 2,
                  position: 'relative',
                }}
              >
                {fetchingStakeholders ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      minHeight: 300,
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: 1,
                    }}
                  >
                    <CircularProgress size={20} />
                    <Typography>Fetching Stakeholders ...</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      width: 1,
                    }}
                  >
                    <Typography fontWeight={'bold'}>Stakeholders</Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: 1,
                      }}
                    >
                      {stakeHolders?.map((stakeholder) => (
                        <Box
                          key={stakeholder?.linkedIn}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            border: '1px solid #d3d3d3',
                            width: 1,
                            borderRadius: 2,
                            position: 'relative',
                          }}
                        >
                          <Avatar
                            src={stakeholder?.['profilePictureUrl']}
                            variant="square"
                            sx={{
                              width: 70,
                              height: 70,
                              border: '1px solid #d3d3d3',
                              padding: 1,
                            }}
                            alt=""
                          >
                            {stakeholder?.['name']?.[0]}
                          </Avatar>
                          <Box
                            sx={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Typography
                              fontWeight={'bold'}
                              sx={{ textDecoration: 'underline' }}
                            >
                              {stakeholder?.['name']}
                            </Typography>
                            <Typography variant="subtitle2">
                              {stakeholder?.designation}
                            </Typography>
                            {stakeholder?.email ? (
                              <Typography
                                variant="subtitle2"
                                sx={{ textDecoration: 'underline' }}
                              >
                                {stakeholder?.email}
                              </Typography>
                            ) : null}
                          </Box>
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              padding: 2,
                              display: 'flex',
                              alignItems: 'center',
                              borderLeft: '1px solid #d3d3d3',
                              height: 1,
                            }}
                          >
                            <Tooltip
                              title={'Edit Details'}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={(e) => {
                                  setShowAddStakeholderDialog(stakeholder);
                                }}
                              >
                                <Edit
                                  color={'primary'}
                                  sx={{
                                    fontSize: 20,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                            {/* {!uId ? ( */}
                            <Tooltip
                              arrow
                              placement="top"
                              title={'Delete Stakeholder'}
                            >
                              <IconButton
                                onClick={(e) => {
                                  setShowConfirmDeleteStakeholder(stakeholder);
                                }}
                              >
                                <Delete
                                  sx={{
                                    fontSize: 20,
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                    <Button
                      size="small"
                      variant="outlined"
                      startIcon={<Add />}
                      sx={{
                        position: 'absolute',
                        bottom: 5,
                        right: 5,
                      }}
                      onClick={() => {
                        setShowAddStakeholderDialog(true);
                      }}
                    >
                      Add Stakeholder
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Container>
      <Dialog
        open={Boolean(showConfirmDeleteStakeholder)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this Stakeholder?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `confirm_delete_dialog_close`);
              setShowConfirmDeleteStakeholder(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `delete_stakeholder`);
              setDeletingStakeholder(true);
              dispatch(
                updateDeal(
                  dealId,
                  {
                    dealStackHolders: stakeHolders?.filter(
                      (sh) => sh?.name !== showConfirmDeleteStakeholder?.name,
                    ),
                  },
                  (dealData, error) => {
                    console.log(dealData);
                    setDeletingStakeholder(false);
                    if (error) {
                      dispatch(
                        showAlert({
                          message:
                            'Oops, Something went wront! Please try again.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    } else {
                      setDeletingStakeholder(false);
                      setShowConfirmDeleteStakeholder(false);
                      dispatch(
                        getDealById(dealId, (data) => {
                          console.log(data);
                          setCompanyName(data?.company?.name);
                          setCompanySize(data?.company?.size || '');
                          setCompanyDescription(data?.company?.description);
                          setCompanyLogo(data?.company?.logoUrl);
                          setCompanyWebsite(data?.company?.website);
                          setCompanyLocation(data?.company?.location || '');
                          setLinkedIn(data?.company?.linkedIn);
                          setStakeholders(
                            data?.company?.stackHolderTitle?.join(', ') || '',
                          );
                          setStakeHolders(data?.dealStackHolders || []);
                          setDealTitle(data?.name || '');
                        }),
                      );
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={Boolean(showAddStakeholdersDialog)}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            outline: 'none',
            maxHeight: '90vh',
            maxWidth: '40vw',
            minWidth: '40vw',
            overflowY: 'auto',
            borderRadius: 1,
            // padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setShowAddStakeholderDialog(false);
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box
            p={2}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography color={'text.secondary'}>
              {typeof showAddStakeholdersDialog === 'object'
                ? 'Update Stakeholder Details'
                : 'Add Stakeholder'}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              // minHeight: 'calc(80vh - 58px)',
              maxHeight: 'calc(90vh - 58px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              // background: '#343a40',
              margin: '0 auto',
              overflowX: 'hidden',
              p: 2,
            }}
          >
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <TextField
                autoFocus
                label="Stakeholder Name*"
                placeholder="Enter Stakeholder Name*"
                variant="outlined"
                color="primary"
                size="small"
                name="stackholderName"
                value={stackholderName}
                onChange={(e) => setStackholderName(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <TextField
                // autoFocus
                label="Stakeholder Email"
                placeholder="Enter Stakeholder Email"
                variant="outlined"
                color="primary"
                size="small"
                name="stackholderEmail"
                value={stackholderEmail}
                onChange={(e) => setStackholderEmail(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <TextField
                // autoFocus
                label="Stakeholder Designation"
                placeholder="Enter Stakeholder Designation"
                variant="outlined"
                color="primary"
                size="small"
                name="stackholderRole"
                value={stackholderRole}
                onChange={(e) => setStackholderRole(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <TextField
                // autoFocus
                label="Stakeholder linkedin"
                placeholder="Enter Stakeholder linkedin"
                variant="outlined"
                color="primary"
                size="small"
                name="stackholderLinkedin"
                value={stackholderLinkedin}
                onChange={(e) => setStackholderLinkedin(e.target.value)}
                sx={{
                  flex: 1,
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={addingStackholder || !stackholderName?.trim()}
                onClick={() => {
                  setAddingStackholder(true);
                  dispatch(
                    updateDeal(
                      dealId,
                      {
                        dealStackHolders:
                          typeof showAddStakeholdersDialog === 'object'
                            ? stakeHolders?.map((stakeholder) => {
                                if (
                                  stakeholder?.name ===
                                  showAddStakeholdersDialog?.name
                                ) {
                                  return {
                                    designation: stackholderRole,
                                    email: stackholderEmail,
                                    linkedIn: stackholderLinkedin,
                                    name: stackholderName,
                                  };
                                } else {
                                  return stakeholder;
                                }
                              })
                            : [
                                ...stakeHolders,
                                {
                                  designation: stackholderRole,
                                  email: stackholderEmail,
                                  linkedIn: stackholderLinkedin,
                                  name: stackholderName,
                                },
                              ],
                      },
                      (dealData, error) => {
                        console.log(dealData);
                        setAddingStackholder(false);
                        if (error) {
                          dispatch(
                            showAlert({
                              message:
                                'Oops, Something went wront! Please try again.',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        } else {
                          setAddingStackholder(false);
                          setShowAddStakeholderDialog(false);
                          dispatch(
                            getDealById(dealId, (data) => {
                              console.log(data);
                              setCompanyName(data?.company?.name);
                              setCompanySize(data?.company?.size || '');
                              setCompanyDescription(data?.company?.description);
                              setCompanyLogo(data?.company?.logoUrl);
                              setCompanyWebsite(data?.company?.website);
                              setCompanyLocation(data?.company?.location || '');
                              setLinkedIn(data?.company?.linkedIn);
                              setStakeholders(
                                data?.company?.stackHolderTitle?.join(', ') ||
                                  '',
                              );
                              setStakeHolders(data?.dealStackHolders || []);
                              setDealTitle(data?.name || '');
                            }),
                          );
                        }
                      },
                    ),
                  );
                }}
              >
                {typeof showAddStakeholdersDialog === 'object'
                  ? 'Update Stakeholder Details'
                  : 'Add Stakeholder'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* <Drawer
        open={Boolean(showBotRequest)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_bot_drawer');
          setShowBotRequest(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_bot_drawer');
              setShowBotRequest(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Bot Details
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Vertical?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.domain}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Do you sell to a specific Designation?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.designation}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.botName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Voice
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.gender}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Role
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.role}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Experience (in Years)
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.experience}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Buyer&apos;s (Bot) Company Name
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companyName}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            About Company
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.aboutCompany}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Company Size
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.companySize}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            What might be the top challenge for buyer?
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.challenges}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Products the compete with yours
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.competitors?.map((cmpt) => cmpt?.name)?.join(',')}
          </Typography>
          <Typography
            variant="caption"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            Other information
          </Typography>
          <Typography
            variant="body1"
            color={'text.secondary'}
            // fontWeight={'bold'}
          >
            {showBotRequest?.newInfo}
          </Typography>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showPlaybook)}
        anchor="right"
        onClose={(e) => {
          handleTrackEvent(e, 'hide_playbook_drawer');
          setShowPlaybook(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
          }}
        >
          <IconButton
            onClick={(e) => {
              handleTrackEvent(e, 'hide_playbook_drawer');
              setShowPlaybook(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
        </Box>
        <Box
          p={2}
          sx={{
            minWidth: 500,
            maxWidth: 500,
            minHeight: 'calc(100vh - 60px)',
            maxHeight: 'calc(100vh - 60px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            flex: 1,
            gap: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body2" color={'text.secondary'}>
            Playbook
          </Typography>
          {typeof showPlaybook === 'object' ? (
            <>
              {' '}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Company Name
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['companyName'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Description
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['productDescription'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Key Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['productKeyFeatures'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Product Secondary Features
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['secondaryFeatures'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Qualification Questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['customerQualificationQuestions'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Any follow up questions
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['followUpQuestions'] || ''}
                onChange={() => {}}
                style={{
                  whiteSpace: 'pre-wrap',
                }}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Customer Objections and Answers
              </Typography>
              {showPlaybook?.customerQuestions?.map((qa) => (
                <>
                  <Typography>Question: {qa.question}</Typography>
                  <Typography>Answer: {qa.answer}</Typography>
                </>
              ))}
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Expected Call To Action
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['expectedCallToAction'] || ''}
                onChange={() => {}}
                // readOnly
              />
              <Typography
                variant="body2"
                color={'text.secondary'}
                fontWeight={'bold'}
              >
                Competing Products and Companies
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth
                value={showPlaybook?.['competingProducts'] || ''}
                onChange={() => {}}
                // readOnly
              />
            </>
          ) : (
            <RichTextEditor
              value={showPlaybook || ''}
              onChange={() => {}}
              readOnly
            />
          )}
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to remove this user from the team?
            </DialogContentText>
          </Box>
          <Typography variant="caption">
            This will remove user from the team. You can still add this member
            again in the same team or new team.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={removingUser}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setRemovingUser(true);
              dispatch(
                addRemoveUserFromTeam(
                  'remove',
                  teamId,
                  {
                    usersPermissions: {
                      [showConfirmDeleteDialog]: {
                        read: true,
                        write: true,
                        teamRole: 'TEAM_ADMIN',
                      },
                    },
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setRemovingUser(false);
                          setShowConfirmDeleteDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showManageDefaultConfigurations)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {showManageDefaultConfigurations?.fullName}
            </Typography>
            <Typography variant="caption">
              {showManageDefaultConfigurations?.email}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText
              variant="subtitle2"
              id="alert-dialog-description"
              flex={1}
            >
              Change these default language and call settings and hit save
            </DialogContentText>
          </Box>
          {orgConfigs?.languagesForAnalysis?.length > 1 ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              sx={{
                border: '1px solid #d3d3d3',
                width: 1,
                borderRadius: 1,
                padding: 2,
                minHeight: 20,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: -12,
                  left: 10,
                  background: '#fff',
                }}
                variant="subtitle2"
                color={'text.secondary'}
              >
                Multi Language Analysis
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ flex: 1 }}
                  >
                    Supported Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="analysis-lang-support-select-label"
                      id="analysis-lang-support-select"
                      name="supportedAnalysisLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setSupportedAnalysisLanguages(e.target.value);
                      }}
                      value={supportedAnalysisLanguages}
                    >
                      {orgConfigs?.languagesForAnalysis?.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          <Typography variant="caption">
                            {capitalizeText(lang)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // sx={{ marginBottom: 1 }}
                    sx={{ flex: 1 }}
                  >
                    Default Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="analysis-default-language-select-label"
                      id="analysis-default-language-select"
                      name="defaultAnalysisLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setDefaultAnalysisLanguages(e.target.value);
                      }}
                      value={defaultAnalysisLanguages}
                    >
                      {orgConfigs?.languagesForAnalysis?.map((lang) => (
                        <MenuItem key={lang} value={lang}>
                          <Typography variant="caption">
                            {capitalizeText(lang)}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          ) : null}
          {orgConfigs?.languagesForRolePlay?.length > 1 ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              position={'relative'}
              sx={{
                border: '1px solid #d3d3d3',
                width: 1,
                borderRadius: 1,
                padding: 2,
                minHeight: 20,
                mt: 2,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  top: -12,
                  left: 10,
                  background: '#fff',
                }}
                variant="subtitle2"
                color={'text.secondary'}
              >
                Multi Language Roleplay
              </Typography>
              <Box display={'flex'} flexDirection={'column'} gap={1}>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    sx={{ flex: 1 }}
                  >
                    Supported Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="roleplay-lang-support-select-label"
                      id="roleplay-lang-support-select"
                      name="supportedRoleplayLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setSupportedRoleplayLanguages(e.target.value);
                      }}
                      value={supportedRoleplayLanguages}
                    >
                      <MenuItem value={'english'}>
                        <Typography variant="caption">English</Typography>
                      </MenuItem>
                      <MenuItem value={'hindi'}>
                        <Typography variant="caption">Hindi</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Box display={'flex'} alignItems={'center'} gap={1} width={1}>
                  <Typography
                    variant="subtitle2"
                    color={'text.secondary'}
                    // sx={{ marginBottom: 1 }}
                    sx={{ flex: 1 }}
                  >
                    Default Languages
                  </Typography>
                  <FormControl size="small">
                    <Select
                      size="small"
                      multiple
                      labelId="roleplay-default-language-select-label"
                      id="roleplay-default-language-select"
                      name="defaultRoleplayLanguages"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => {
                        setDefaultAnalysisLanguages(e.target.value);
                      }}
                      value={defaultRoleplayLanguages}
                    >
                      <MenuItem value={'english'}>
                        <Typography variant="caption">English</Typography>
                      </MenuItem>
                      <MenuItem value={'hindi'}>
                        <Typography variant="caption">Hindi</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowManageDefaultConfigurations(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={updatingUserConfigurations}
            startIcon={
              updatingUserConfigurations ? <CircularProgress size={20} /> : null
            }
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setUpdatingUserConfigurations(true);
              dispatch(
                updateUserSettingsV2(
                  showManageDefaultConfigurations?.id,
                  {
                    userSetting: {
                      defaultLanguagesForAnalysis: defaultAnalysisLanguages,
                      defaultLanguagesForRolePlay: defaultRoleplayLanguages,
                      languagesForAnalysis: supportedAnalysisLanguages,
                      languagesForRolePlay: supportedRoleplayLanguages,
                      salesCallType: defaultCallType,
                    },
                  },
                  () => {
                    setUpdatingUserConfigurations(false);
                    resetConfigurationFields();
                    setShowManageDefaultConfigurations(null);
                    dispatch(
                      showAlert({
                        message: 'Default configurations updated successfully',
                        showCross: true,
                        title: null,
                        type: 'success',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showCrmUserMappingDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showCrmUserMappingDialog,
                )?.fullName
              }
            </Typography>
            <Typography variant="caption">
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showCrmUserMappingDialog,
                )?.email
              }
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {allTeamMembers?.members?.find(
            (member) => member?.id === showCrmUserMappingDialog,
          )?.custom?.crmUser ? (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Selected account is already linked to a crm user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                If you want to change the linked crm user, select the associated
                crm email from the dropdown and hit save.
              </Typography>
            </>
          ) : (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Pick a crm user email that you want to map with the selected
                  user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                This will link the crm user to your team member
              </Typography>
            </>
          )}
          <FormControl fullWidth size="small">
            <Select
              size="small"
              labelId="user-select-label"
              id="user-select"
              name="userSelect"
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                setCrmEmail(e.target.value);
              }}
              value={crmEmail}
            >
              {crmUsers.map((u) => (
                <MenuItem key={u?.crmUserId} value={u?.crmEmail}>
                  {u?.crmEmail}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowCrmUserMappingDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={mappingCrmUser || !crmEmail}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setMappingCrmUser(true);
              dispatch(
                mapCrmUserToTeamMember(
                  {
                    [showCrmUserMappingDialog]: crmEmail,
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setMappingCrmUser(false);
                          setShowCrmUserMappingDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showHubspotUserMappingDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showHubspotUserMappingDialog,
                )?.fullName
              }
            </Typography>
            <Typography variant="caption">
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showHubspotUserMappingDialog,
                )?.email
              }
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {allTeamMembers?.members?.find(
            (member) => member?.id === showHubspotUserMappingDialog,
          )?.custom?.hubspotUser ? (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Selected account is already linked to a hubspot user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                If you want to change the linked hubspot user, select the
                associated hubspot email from the dropdown and hit save.
              </Typography>
            </>
          ) : (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Pick a hubspot user email that you want to map with the
                  selected user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                This will link the hubspot user to your team member
              </Typography>
            </>
          )}
          <FormControl fullWidth size="small">
            <Select
              size="small"
              labelId="user-select-label"
              id="user-select"
              name="userSelect"
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                setHubspotEmail(e.target.value);
              }}
              value={hubspotEmail}
            >
              {hubspotUsers.map((u) => (
                <MenuItem key={u?.id} value={u?.email}>
                  {u?.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowHubspotUserMappingDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={mappingUser || !hubspotEmail}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setMappinguser(true);
              dispatch(
                mapHubspotUserToTeamMember(
                  {
                    [showHubspotUserMappingDialog]: hubspotEmail,
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setMappinguser(false);
                          setShowHubspotUserMappingDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showZohoUserMappingDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          // zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            display={'flex'}
            flexDirection={'column'}
            // gap={1}
          >
            <Typography fontWeight={'bold'} color={'text.secondary'}>
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showZohoUserMappingDialog,
                )?.fullName
              }
            </Typography>
            <Typography variant="caption">
              {
                allTeamMembers?.members?.find(
                  (member) => member?.id === showZohoUserMappingDialog,
                )?.email
              }
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          {allTeamMembers?.members?.find(
            (member) => member?.id === showZohoUserMappingDialog,
          )?.custom?.zohoUser ? (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Selected account is already linked to a Zoho user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                If you want to change the linked Zoho user, select the
                associated Zoho email from the dropdown and hit save.
              </Typography>
            </>
          ) : (
            <>
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <DialogContentText id="alert-dialog-description" flex={1}>
                  Pick a Zoho user email that you want to map with the selected
                  user.
                </DialogContentText>
              </Box>
              <Typography variant="caption">
                This will link the Zoho user to your team member
              </Typography>
            </>
          )}
          <FormControl fullWidth size="small">
            <Select
              size="small"
              labelId="user-select-label"
              id="user-select"
              name="userSelect"
              MenuProps={{
                disableScrollLock: true,
              }}
              onChange={(e) => {
                setZohoEmail(e.target.value);
              }}
              value={zohoEmail}
            >
              {zohoUsers.map((u) => (
                <MenuItem key={u?.id} value={u?.email}>
                  {u?.email} ({u?.first_name} {u?.last_name})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              // handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowZohoUserMappingDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            disabled={mappingZohoUser || !zohoEmail}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setMappingZohoUser(true);
              dispatch(
                mapZohoUserToTeamMember(
                  {
                    [showZohoUserMappingDialog]: zohoEmail,
                  },
                  () => {
                    dispatch(
                      getAllTeamMembers(
                        teamId || null,
                        null,
                        true,
                        numberOfDays,
                        25,
                        null,
                        (data) => {
                          setMappingZohoUser(false);
                          setShowZohoUserMappingDialog(null);
                        },
                      ),
                    );
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={Boolean(showCalendarInviteModal)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowCalendarInviteModal(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            maxHeight: '90vh',
            maxWidth: '60vw',
            minWidth: '500px',
            outline: 'none',
            borderRadius: 1,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              background: '#d3d3d3',
              borderRadius: 1,
              padding: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="body1">Schedule Roleplay</Typography>
            <IconButton onClick={() => setShowCalendarInviteModal(null)}>
              <Close sx={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              padding: 2,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Select timezone
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-timezone-label"
                    id="select-timezone"
                    name="selectTimeZone"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setTimeZone(e.target.value)}
                    value={timeZone}
                  >
                    <MenuItem value={'select'}>
                      <Typography>Select time zone</Typography>
                    </MenuItem>
                    {timeZones && timeZones?.length > 0
                      ? timeZones?.map((tm) => (
                          <MenuItem key={tm?.value} value={tm?.utc?.[0]}>
                            <Typography>{tm?.text}</Typography>
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Start Time
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    sx={{
                      width: 1,
                    }}
                    // label="Meeting Time"
                    value={dayjs(startDate)}
                    onChange={(newValue) => {
                      console.log(new Date(newValue).getTime());
                      setStartDate(new Date(newValue).getTime());
                      setEndDate(new Date(newValue).getTime() + 15 * 60 * 1000);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Select Duration
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-duration-label"
                    id="select-duration"
                    name="selectDuration"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setEndDate(startDate + e.target.value);
                    }}
                    value={endDate - startDate}
                  >
                    <MenuItem value={15 * 60 * 1000}>
                      <Typography>15 Minutes</Typography>
                    </MenuItem>
                    <MenuItem value={30 * 60 * 1000}>
                      <Typography>30 Minutes</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  fontWeight={'bold'}
                  color={'text.secondary'}
                >
                  Repeats
                </Typography>
                <FormControl fullWidth>
                  <Select
                    fullWidth
                    size="small"
                    labelId="select-repeat-label"
                    id="select-repeat"
                    name="selectRepeat"
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => setFrequency(e.target.value)}
                    value={frequency}
                  >
                    <MenuItem value={'norepeat'}>
                      <Typography>Do not repeat</Typography>
                    </MenuItem>
                    <MenuItem value={'DAILY'}>
                      <Typography>Daily</Typography>
                    </MenuItem>
                    <MenuItem value={'WEEKLY'}>
                      <Typography>Weekly</Typography>
                    </MenuItem>
                    <MenuItem value={'MONTHLY'}>
                      <Typography>Monthly</Typography>
                    </MenuItem>
                    <MenuItem value={'YEARLY'}>
                      <Typography>Yearly</Typography>
                    </MenuItem>
                    <MenuItem value={'CUSTOM'}>
                      <Typography>Custom</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}></Grid>
              {frequency !== 'norepeat' && (
                <>
                  {frequency === 'CUSTOM' && (
                    <Grid item xs={12}>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Typography
                          variant="subtitle2"
                          fontWeight={'bold'}
                          color={'text.secondary'}
                        >
                          Repeat Every
                        </Typography>
                      </Box>
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <TextField
                          placeholder="Repeat Every"
                          variant="outlined"
                          name={'recurrenceInterval'}
                          size="small"
                          value={recurrenceInterval}
                          onChange={(e) =>
                            setRecurrenceInterval(e.target.value)
                          }
                          type="number"
                        />
                        <FormControl>
                          <Select
                            fullWidth
                            size="small"
                            labelId="select-repeat-label"
                            id="select-repeat"
                            name="selectRepeat"
                            MenuProps={{ disableScrollLock: true }}
                            onChange={(e) => setCustomFrequency(e.target.value)}
                            value={customFrequency}
                          >
                            <MenuItem value={'DAILY'}>
                              <Typography>Day</Typography>
                            </MenuItem>
                            <MenuItem value={'WEEKLY'}>
                              <Typography>Week</Typography>
                            </MenuItem>
                            <MenuItem value={'MONTHLY'}>
                              <Typography>Month</Typography>
                            </MenuItem>
                            <MenuItem value={'YEARLY'}>
                              <Typography>Year</Typography>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontWeight={'bold'}
                      color={'text.secondary'}
                    >
                      Ends
                    </Typography>
                    <FormControl>
                      <RadioGroup
                        // row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={endType}
                        onChange={(e) => {
                          // handleTrackEvent(e, `selected_bot_change`);
                          setEndType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          value={'never'}
                          control={<Radio />}
                          label={'Never'}
                        />
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          gap={3}
                          mt={2}
                        >
                          <FormControlLabel
                            value={'date'}
                            control={<Radio />}
                            label={'On'}
                          />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              sx={{
                                width: 1,
                              }}
                              disabled={endType !== 'date'}
                              value={dayjs(endRepeat)}
                              onChange={(newValue) => {
                                console.log(new Date(newValue).getTime());
                                setEndRepeat(new Date(newValue).getTime());
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'flex-start'}
                          gap={3}
                          mt={2}
                        >
                          <FormControlLabel
                            value={'recurrences'}
                            control={<Radio />}
                            label={'After'}
                          />
                          <TextField
                            // placeholder=""
                            disabled={endType !== 'recurrences'}
                            variant="outlined"
                            name={'recurrenceCount'}
                            size="small"
                            value={recurrenceCount}
                            onChange={(e) => setRecurrenceCount(e.target.value)}
                            type="number"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Typography
                                    variant="subtitle2"
                                    color={'text.secondary'}
                                  >
                                    occurrences
                                  </Typography>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={2}
            justifyContent={'space-between'}
            p={1}
            borderTop={'1px solid #d3d3d3'}
          >
            <Box>
              {calendarInviteError && (
                <Alert severity="error">{calendarInviteError}</Alert>
              )}
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={1}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => setShowCalendarInviteModal(null)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                disabled={sendingCalendarInvite}
                startIcon={
                  sendingCalendarInvite ? <CircularProgress size={14} /> : null
                }
                onClick={() => {
                  if (endDate < startDate) {
                    setCalendarInviteError(
                      'End time should be after the start time',
                    );
                  } else {
                    setCalendarInviteError(false);
                    setSendingCalendarInvite(true);
                    dispatch(
                      sendTeamInvite(
                        {
                          invitationType: 'practice_call',
                          receiverDtls: [
                            {
                              email: showCalendarInviteModal?.email,
                              firstName: showCalendarInviteModal?.firstName,
                              icsFileRequestDto: {
                                endDate: endDate,
                                endRepeat: endType === 'date' ? endRepeat : 0,
                                frequency:
                                  frequency === 'CUSTOM'
                                    ? customFrequency
                                    : frequency !== 'norepeat'
                                    ? frequency
                                    : null,
                                recurrenceCount:
                                  endType === 'recurrences'
                                    ? recurrenceCount
                                    : null,
                                recurrenceInterval:
                                  endType === 'never'
                                    ? -1
                                    : frequency !== 'norepeat'
                                    ? Number(recurrenceInterval)
                                    : 1,
                                startDate: startDate,
                                timeZoneId: timeZone,
                                teamId: teamId ? teamId : null,
                              },
                            },
                          ],
                        },
                        (error) => {
                          if (error) {
                            setSendingCalendarInvite(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Failed to schedule roleplay. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setSendingCalendarInvite(false);
                            setShowCalendarInviteModal(null);
                            dispatch(
                              showAlert({
                                message:
                                  '"Roleplay schedule email sent successfully".',
                                showCross: true,
                                title: null,
                                type: 'success',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal> */}
    </Box>
  );
};

DealPage.propTypes = {
  fullWidth: PropTypes.bool,
  defaultTeamMembersTab: PropTypes.string,
};

export default DealPage;
