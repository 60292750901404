const initialstate = {
  key: new Date(),
  run: false,
  continuous: true,
  disableCloseOnEsc: true,
  loading: false,
  stepIndex: 0,
};

const setTourStateReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'START_TOUR':
      return { ...state, run: true };
    case 'RESET_TOUR':
      return { ...state, stepIndex: 0 };
    case 'STOP_TOUR':
      return { ...state, run: false };
    case 'NEXT_OR_PREV':
      return { ...state, ...action.payload };
    case 'RESTART_TOUR':
      return {
        ...state,
        stepIndex: 0,
        run: true,
        loading: false,
        key: new Date(),
      };
    default:
      return state;
  }
};

export default setTourStateReducer;
