import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Add, Check, Sync } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';

const QuestionNode = memo(function QuestionNode({
  id,
  question,
  index,
  moveCard,
  findCard,
  questionTopics,
  questionSubTopics,
}) {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);

  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'question',
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard],
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'question',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard],
  );

  const opacity = isDragging ? 0 : 1;

  return (
    <Box
      ref={(node) => drag(drop(node))}
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        opacity,
      }}
    >
      <Box
        sx={{
          background: question?.pinned ? '#C2DBFF' : '#F2F6FC',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 2,
          flex: 1,
          borderRadius: 2,
          minHeight: 80,
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            flex: 1,
            minHeight: 80,
          }}
        >
          <Typography
            sx={{
              background: '#d3e2fe',
              // padding: 2,
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
              minHeight: 90,
              minWidth: 80,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontWeight: 'bold',
              cursor: 'pointer',
            }}
            onMouseEnter={(e) => {
              setAnchorElDetails(e.currentTarget);
              setPopperDetails(question?.exp || 'No explanation available');
            }}
            onMouseLeave={() => {
              setAnchorElDetails(null);
              setPopperDetails(null);
            }}
          >
            {index < 9 ? 0 : ''}
            {index + 1}
          </Typography>
          <Popper
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
              zIndex: 1111111,
            }}
            open={Boolean(anchorElDetails)}
            anchorEl={anchorElDetails}
            placement="top"
            onClose={() => {
              setAnchorElDetails(null);
            }}
            // disableRestoreFocus
          >
            <Box
              sx={{
                border: '1px solid #d3d3d3',
                borderRadius: 1,
                padding: 1,
                background: '#fff',
                maxWidth: 400,
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
                // boxShadow: theme.shadows[1],
              }}
            >
              <Typography
                variant={'body1'}
                color={'text.secondary'}
                sx={{
                  padding: 1,
                }}
              >
                {popperDetails || ''}
              </Typography>
            </Box>
          </Popper>
          <Box
            display={'flex'}
            gap={1}
            sx={{
              width: 1,
              padding: 1,
              alignItems: 'center',
              justifyContent: 'space-between',
              textDecoration: question?.deleted ? 'line-through' : 'none',
              position: 'relative',
              minHeight: 90,
            }}
          >
            <Typography
              sx={{
                width: '90%',
              }}
            >
              {question?.cq}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                borderRadius: 8,
                alignSelf: 'end',
                position: 'absolute',
                bottom: 6,
                right: 6,
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  background: '#d3d3d3',
                  borderTopLeftRadius: 8,
                  borderBottomLeftRadius: 8,
                  p: '2px 6px',
                }}
              >
                {
                  questionTopics?.find(
                    (qt) => qt.id === question?.path?.split('>')?.[1],
                  )?.riskTitle
                }
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  background: theme.palette.primary.light,
                  borderTopRightRadius: 8,
                  borderBottomRightRadius: 8,
                  color: '#fff',
                  p: '2px 6px',
                }}
              >
                {
                  questionSubTopics?.find((qst) => qst.id === question?.nodeId)
                    ?.riskTitle
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

QuestionNode.propTypes = {
  id: PropTypes.string,
  question: PropTypes.object,
  index: PropTypes.number,
  moveCard: PropTypes.func,
  findCard: PropTypes.func,
  questionTopics: PropTypes.object,
  questionSubTopics: PropTypes.object,
};

export default QuestionNode;
