const setOnboarding = (payload, callback) => {
  return async dispatch => {
    dispatch({ type: 'SET_ONBOARDING', payload });
    if (typeof callback === 'function') {
      callback();
    }
  };
};

export default setOnboarding;
