import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Container from 'components/Container';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import sendOpcLink from 'redux/actions/Common/sendOpcLink';
import showAlert from 'redux/actions/Common/showAlert';
import getDefaultPrompt from 'redux/actions/Common/getDefaultPrompt';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import validateGoogleOAuthCallback from 'redux/actions/Common/validateGoogleOAuthCallback';
import integrateHubspot from 'redux/actions/Common/integrateHubspot';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { capitalizeText } from 'utils';
import validateZohoCrmOAuthUrl from 'redux/actions/Common/validateZohoCrmOAuthUrl';

const GoogleCalendarConnect = () => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const alertState = useSelector((state) => state.alertState);

  const params = new URLSearchParams(window.location.search);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [location, setLocation] = useState('');
  const [accountsServer, setAccountsServer] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    setCode(params.get('code'));
    setLocation(params.get('location'));
    setAccountsServer(params.get('accounts-server'));
    if (params?.get('error')) {
      setLoading(false);
      setError(
        capitalizeText(
          params
            ?.get('error')
            ?.split('_')
            ?.join(' '),
        ),
      );
    }
  }, [params]);

  useEffect(() => {
    if (accountsServer && code) {
      dispatch(
        validateZohoCrmOAuthUrl(
          userInfo?.organizationId,
          code,
          error,
          location,
          accountsServer,
          (data) => {
            console.log(data);
            setLoading(false);
            setError('');
            dispatch(
              showAlert({
                message: 'Zoho CRM is integrated successfully.',
                showCross: true,
                title: null,
                type: 'success',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
            if (data?.domain) {
              console.log('coming here');
              window.open(
                `https://${data?.domain}/profile-settings?t=integrations&it=crm`,
                '_self',
              );
            } else {
              dispatch(getUserDetails(() => {}));
              history.push(
                '/profile-settings?t=integrations&it=crm',
                // '/profile-settings?t=integrations&it=crm&openproperties',
              );
            }
          },
          () => {
            setLoading(false);
            setError('Failed to integrate. Please try again later!');
          },
        ),
      );
    }
  }, [accountsServer, code, location]);

  useEffect(() => {}, []);

  // console.log(params);

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
        minHeight: 'calc(100vh)',
      }}
      display={'flex'}
      flexDirection={{ xs: 'column', md: 'column' }}
      position={'relative'}
      justifyContent="center"
      alignItems={'center'}
    >
      <Container maxWidth={700}>
        <Box
          width={1}
          height={1}
          display={'flex'}
          gap={2}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          {loading ? (
            <>
              <CircularProgress size={20} />
              <Typography textAlign={'center'}>
                Integration is being done. Please wait ...
              </Typography>
            </>
          ) : error ? (
            <>
              <Typography textAlign={'center'}>{error}</Typography>
              <Button
                onClick={() =>
                  history.push('/profile-settings?t=integrations&it=crm')
                }
              >
                Go to Settings Page
              </Button>
            </>
          ) : (
            <>
              <CircularProgress size={20} />
              <Typography textAlign={'center'}>
                You will be automatically redirected to integrations page in a
                moment. Please wait ...
              </Typography>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default GoogleCalendarConnect;
