import React, { useState, useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { Audiotrack } from '@mui/icons-material';

const MediaPreview = React.memo(function MediaPreview({
  url,
  mediaType,
  styles = {},
  videoRef,
  setDuration,
}) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        ...styles,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        maxHeight: 300,
      }}
    >
      <video
        ref={videoRef}
        src={url}
        controls
        style={{
          objectFit: 'contain',
          backgroundColor: mediaType
            ? mediaType?.split('/')?.includes('audio')
              ? '#d3d3d3'
              : '#000'
            : '#d3d3d3',
          width: '100%',
          height: '100%',
          padding: 16,
        }}
        onError={(e) => {
          console.log(e);
        }}
        controlsList="nodownload"
        disablePictureInPicture
        onLoadedMetadata={(event) => {
          if (setDuration && typeof setDuration === 'function') {
            setDuration(event.target.duration);
          }
          // console.log(event.target.duration);
        }}
      ></video>
      {mediaType?.split('/')?.includes('audio') || !mediaType ? (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 1,
            height: 'calc(100% - 80px)',
          }}
        >
          <Audiotrack
            sx={{
              fontSize: 70,
              fontWeight: 'bold',
              color: 'text.secondary',
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
});

MediaPreview.propTypes = {
  url: PropTypes.string.isRequired,
  mediaType: PropTypes.string.isRequired,
  styles: PropTypes.object,
  videoRef: PropTypes.object,
  setDuration: PropTypes.func,
};

export default MediaPreview;
