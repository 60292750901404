import axios from 'utils/axios';
// import { v4 as uuidv4 } from 'uuid';

const validateInvitationToken = (invitationId, email, callback, errCb) => {
  return async (dispatch) => {
    dispatch({ type: 'VALIDATING_TOKEN' });
    try {
      // const reqId = uuidv4();
      let response = await axios.get(`/us/invitations/${invitationId}`, {
        params: {
          // 'request-id': reqId,
          email,
        },
      });
      if (response.status === 200) {
        dispatch({
          type: 'VALIDATING_TOKEN_DONE',
          payload: {
            ...response?.data,
          },
        });
        if (typeof callback === 'function') {
          callback(response?.data);
        }
      } else {
        dispatch({
          type: 'VALIDATING_TOKEN_FAILED',
          payload: 'Link is invalid or expired.',
        });
        if (typeof errCb === 'function') {
          errCb(response?.response?.data?.error);
        }
      }
    } catch (e) {
      dispatch({
        type: 'VALIDATING_TOKEN_FAILED',
        payload: 'Link is invalid or expired.',
      });
      if (typeof errCb === 'function') {
        errCb(e);
      }
    }
  };
};

export default validateInvitationToken;
