import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Chip,
  Button,
  Tooltip,
  Modal,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Popper,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';

import { PropTypes } from 'prop-types';
import getSellingStyles from 'redux/actions/Common/getSellingStyles';
import getDealDiscoveryQuestionsList from 'redux/actions/Common/getDealDiscoveryQuestionsList';
import getDiscoveryTreeTopics from 'redux/actions/Common/getDiscoveryTreeTopics';
import {
  Add,
  AddBoxOutlined,
  Check,
  Clear,
  Close,
  DoneAll,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Loop,
  Redo,
  Save,
} from '@mui/icons-material';
import updateDiscoveryQuestionBulk from 'redux/actions/Compass/updateDiscoveryQuestionBulk';
import showAlert from 'redux/actions/Common/showAlert';
import createNewDiscoveryQuestion from 'redux/actions/Common/createNewDiscoveryQuestion';
import updateDiscoveryQuestion from 'redux/actions/Compass/updateDiscoveryQuestion';

const DiscoveryTemplate = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const {
    dashboardSidebarExpanded,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
    pinnedTeams,
    navExpandedStatus,
  } = useSelector((state) => state.uiStates);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const { qList, fetching, fetchingPagination } = useSelector(
    (state) => state.discoveryQList,
  );
  const [
    fetchingMoreDiscoveryQuestions,
    setFetchingMoreDiscoveryQuestions,
  ] = useState(false);

  const [sellingStyle, setSellingStyle] = useState(
    'persuasive_or_traditional_selling',
  );
  const [fetchingSellingStyles, setFetchingSellingStyles] = useState(false);
  const [sellingStyles, setSellingStyles] = useState([]);
  const [fetchingDiscoveryQList, setFetchingDiscoveryQList] = useState(false);

  const [questionTopics, setQuestionTopics] = useState([]);
  const [questionSubTopics, setQuestionSubTopics] = useState([]);
  const [expandedSubtopics, setExpandedSubtopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');
  const [showDeleted, setShowDeleted] = useState(false);

  const [showNewQuestionDialog, setShowNewQuestionDialog] = useState(false);
  const [subtopicIdForNewQuestion, setSubtopicIdForNewQuestion] = useState('');
  const [creatingNewQuestion, setCreatingNewQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newQuestionSellingStyle, setNewQuestionSellingStyle] = useState(
    'persuasive_or_traditional_selling',
  );

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);

  const [questionEditMode, setQuestionEditMode] = useState(false);
  const [editedQuestionText, setEditedQuestionText] = useState('');
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const [
    regeneratingQuestionInSpecificStyle,
    setRegeneratingQuestionInSpecificStyle,
  ] = useState(false);

  useEffect(() => {
    setSellingStyle(
      orgConfigs?.sellingStyle || 'persuasive_or_traditional_selling',
    );
    if (orgConfigs?.discoveryTreeId) {
      dispatch(
        getDiscoveryTreeTopics(orgConfigs?.discoveryTreeId, (data) => {
          console.log(data);
          // if (data?.risk && data?.risk?.length > 0) {
          setQuestionTopics(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            }) || [],
          );
          setQuestionSubTopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setExpandedSubtopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setSelectedTopic(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            })?.[0]?.id || '',
          );
          // } else {
          //   setQuestionTopics(data?.map((aq) => aq?.topic) || []);
          //   setQuestionSubTopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setExpandedSubtopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setSelectedTopic(data?.map((aq) => aq?.topic)?.[0] || '');
          // }
        }),
      );
      setFetchingDiscoveryQList(true);
      dispatch(
        getDealDiscoveryQuestionsList(
          orgConfigs?.discoveryTreeId,
          null,
          null,
          (data) => {
            console.log(data);
            setFetchingDiscoveryQList(false);
          },
        ),
      );
    }
  }, [orgConfigs]);

  useEffect(() => {
    setFetchingSellingStyles(true);
    dispatch(
      getSellingStyles((data) => {
        setSellingStyles(data || []);
        // setTimeout(() => {
        setFetchingSellingStyles(false);
        // }, 3000);
      }),
    );
  }, []);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2,
            padding: 2,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Typography sx={{ fontSize: 20 }}>
              Here are your discovery questions for your first call. They are
              crafted to reduce risk from the start. Please review them
              carefully, edit if required and select those you like.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 1,
            }}
          >
            <Box
              display={'flex'}
              alignItems={'center'}
              gap={2}
              justifyContent={'space-between'}
              sx={{
                borderBottom: '1px solid #d3d3d3',
                width: 1,
                paddingBottom: 2,
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography fontWeight={'bold'}>
                  Discovery Template for all deals
                </Typography>
                <Chip
                  color="primary"
                  variant="outlined"
                  size="small"
                  label={
                    sellingStyles?.find(
                      (ss) => ss?.sellingStyle === sellingStyle,
                    )?.title
                  }
                />
              </Box>
              <Box display={'flex'} alignItems={'center'} gap={1}>
                {/* { ? ( */}
                <Box display={'flex'} alignItems={'center'} gap={1} sx={{}}>
                  {qList?.filter((qs) => !qs?.deleted && qs?.pinned)?.length >
                  0 ? (
                    <>
                      <Typography fontWeight={'bold'}>
                        {
                          qList?.filter((qs) => !qs?.deleted && qs?.pinned)
                            ?.length
                        }{' '}
                        Selected
                      </Typography>
                      {/* <Button
                        size="small"
                        color="primary"
                        variant="outlined"
                        startIcon={<Clear />}
                        sx={{
                          padding: '4px 8px',
                        }}
                        onClick={() => {
                          dispatch(
                            updateDiscoveryQuestionBulk(
                              orgConfigs?.discoveryTreeId,
                              qList?.map((dq) => {
                                return {
                                  questionId: dq?.id,
                                  pinned: false,
                                };
                              }),
                              () => {
                                dispatch({
                                  type: 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                                  payload: {
                                    data: qList?.map((dq) => {
                                      return {
                                        ...dq,
                                        pinned: false,
                                      };
                                    }),
                                  },
                                });
                              },
                              () => {
                                dispatch(
                                  showAlert({
                                    message: 'Failed to update question',
                                    showCross: true,
                                    title: null,
                                    type: 'error',
                                    autoHideDuration: 2000,
                                    vertical: 'top',
                                    horizontal: 'center',
                                  }),
                                );
                              },
                            ),
                          );
                        }}
                      >
                        Clear All
                      </Button> */}
                    </>
                  ) : null}
                  {/* <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    startIcon={<DoneAll />}
                    disabled={
                      qList?.filter((qs) => !qs?.deleted && qs?.pinned)
                        ?.length === qList?.length
                    }
                    sx={{
                      padding: '4px 8px',
                    }}
                    onClick={() => {
                      dispatch(
                        updateDiscoveryQuestionBulk(
                          orgConfigs?.discoveryTreeId,
                          qList?.map((dq) => {
                            return {
                              questionId: dq?.id,
                              pinned: true,
                            };
                          }),
                          () => {
                            dispatch({
                              type: 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                              payload: {
                                data: qList?.map((dq) => {
                                  return {
                                    ...dq,
                                    pinned: true,
                                  };
                                }),
                              },
                            });
                          },
                          () => {
                            dispatch(
                              showAlert({
                                message: 'Failed to update question',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          },
                        ),
                      );
                    }}
                  >
                    Select All
                  </Button> */}
                </Box>
                {/* ) : null} */}
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    padding: '4px 8px',
                  }}
                  disabled={
                    expandedSubtopics?.length === questionSubTopics?.length
                  }
                  onClick={() => {
                    setExpandedSubtopics([...questionSubTopics]);
                  }}
                >
                  Expand All
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    padding: '4px 8px',
                  }}
                  disabled={expandedSubtopics?.length === 0}
                  onClick={() => {
                    setExpandedSubtopics([]);
                  }}
                >
                  Collapse All
                </Button>
                {/* <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{
                    padding: '4px 8px',
                  }}
                  disabled={expandedSubtopics?.length === 0}
                  onClick={() => setShowDeleted((prev) => !prev)}
                >
                  {showDeleted ? 'Hide Deleted' : 'Show Deleted'}
                </Button> */}
              </Box>
            </Box>
            {questionTopics && questionTopics?.length > 0 ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: 1,
                }}
              >
                <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                  <Box
                    sx={{
                      flex: 1,
                      background: '#F2F6FC',
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 2,
                      padding: 2,
                      borderRadius: 2,
                      maxHeight: 'calc(100vh - 235px)',
                      minHeight: 'calc(100vh - 235px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      paddingX: 1,
                    }}
                  >
                    {questionTopics?.map((topic) => (
                      <Box
                        key={topic?.id}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          background:
                            selectedTopic === topic?.id
                              ? '#C2DBFF'
                              : 'transparent',
                          padding: 1,
                          cursor: 'pointer',
                          borderRadius: 2,
                          width: 1,
                        }}
                        onClick={() => {
                          setSelectedTopic(topic?.id);
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            width: 1,
                          }}
                        >
                          <Typography
                            color={'text.primary'}
                            fontWeight={'bold'}
                          >
                            {topic?.riskTitle}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  <Box
                    sx={{
                      flex: 4,
                      // background: '#F2F6FC',
                      // padding: 2,
                      maxHeight: 'calc(100vh - 235px)',
                      minHeight: 'calc(100vh - 235px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      paddingX: 1,
                    }}
                  >
                    {questionSubTopics
                      ?.filter(
                        (qst) => qst?.path?.split('>')?.[1] === selectedTopic,
                      )
                      ?.map((subtopic) => (
                        <Box
                          key={subtopic?.id}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: 1,
                            // marginLeft: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: 2,
                              width: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                // marginLeft: 2,
                                // gap: 1,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                if (
                                  expandedSubtopics?.find(
                                    (sc) => sc?.id === subtopic?.id,
                                  )
                                ) {
                                  setExpandedSubtopics((prev) =>
                                    prev.filter(
                                      (st) => st?.id !== subtopic?.id,
                                    ),
                                  );
                                } else {
                                  setExpandedSubtopics((prev) => [
                                    ...prev,
                                    subtopic,
                                  ]);
                                }
                              }}
                            >
                              {expandedSubtopics?.find(
                                (sc) => sc?.id === subtopic?.id,
                              ) ? (
                                <KeyboardArrowDown
                                  sx={{
                                    fontSize: 34,
                                    color: '#747775',
                                  }}
                                />
                              ) : (
                                <KeyboardArrowRight
                                  sx={{
                                    fontSize: 34,
                                    color: '#747775',
                                  }}
                                />
                              )}
                              <Typography
                                color={'text.primary'}
                                fontWeight={'bold'}
                              >
                                {subtopic?.riskTitle}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                // marginRight: 8,
                              }}
                            >
                              {fetchingMoreDiscoveryQuestions ===
                              subtopic?.id ? (
                                <Typography variant="caption">
                                  Generating More Question
                                </Typography>
                              ) : null}
                              <Tooltip
                                arrow
                                placement="top"
                                title="Generate more like this"
                              >
                                <Loop
                                  sx={{
                                    color: '#747775',
                                    cursor: 'pointer',
                                    animation:
                                      fetchingMoreDiscoveryQuestions ===
                                      subtopic?.id
                                        ? 'spin 2s linear infinite'
                                        : 'none',
                                    '@keyframes spin': {
                                      '0%': {
                                        transform: 'rotate(360deg)',
                                      },
                                      '100%': {
                                        transform: 'rotate(0deg)',
                                      },
                                    },
                                  }}
                                  onClick={() => {
                                    setFetchingMoreDiscoveryQuestions(
                                      subtopic?.id,
                                    );
                                    dispatch(
                                      getDealDiscoveryQuestionsList(
                                        orgConfigs?.discoveryTreeId,
                                        qList?.filter(
                                          (dq) =>
                                            (!dq?.deleted || showDeleted) &&
                                            dq?.nodeId === subtopic?.id,
                                        )?.[
                                          qList?.filter(
                                            (dq) =>
                                              (!dq?.deleted || showDeleted) &&
                                              dq?.nodeId === subtopic?.id,
                                          )?.length - 1
                                        ]?.id,
                                        qList?.filter(
                                          (dq) =>
                                            (!dq?.deleted || showDeleted) &&
                                            dq?.nodeId === subtopic?.id,
                                        )?.[
                                          qList?.filter(
                                            (dq) =>
                                              (!dq?.deleted || showDeleted) &&
                                              dq?.nodeId === subtopic?.id,
                                          )?.length - 1
                                        ]?.nodeId,
                                        (data) => {
                                          console.log(data);
                                          setFetchingMoreDiscoveryQuestions(
                                            false,
                                          );
                                        },
                                      ),
                                    );
                                  }}
                                />
                              </Tooltip>
                              <Tooltip
                                arrow
                                placement="top"
                                title="Add more into this category"
                              >
                                <AddBoxOutlined
                                  sx={{
                                    color: '#747775',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowNewQuestionDialog(true);
                                    setSubtopicIdForNewQuestion(
                                      qList?.filter(
                                        (dq) =>
                                          (!dq?.deleted || showDeleted) &&
                                          dq?.nodeId === subtopic?.id,
                                      )?.[
                                        qList?.filter(
                                          (dq) =>
                                            (!dq?.deleted || showDeleted) &&
                                            dq?.nodeId === subtopic?.id,
                                        )?.length - 1
                                      ]?.nodeId,
                                    );
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          {expandedSubtopics?.find(
                            (sc) => sc?.id === subtopic?.id,
                          ) ? (
                            <>
                              {qList
                                ?.filter(
                                  (dq) =>
                                    (!dq?.deleted || showDeleted) &&
                                    dq?.nodeId === subtopic?.id,
                                )
                                ?.sort((a, b) => {
                                  if (a.pinned && !b.pinned) return -1;
                                  if (!a.pinned && b.pinned) return 1;
                                  return 0;
                                })
                                ?.map((dq, index) => (
                                  <Box
                                    key={dq?.id}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      borderRadius: 2,
                                      width: 1,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      if (!dq?.deleted && !questionEditMode) {
                                        setUpdatingQuestion(true);
                                        dispatch(
                                          updateDiscoveryQuestion(
                                            orgConfigs?.discoveryTreeId,
                                            dq?.id,
                                            {
                                              ...dq,
                                              pinned: !dq?.pinned,
                                            },
                                            () => {
                                              setUpdatingQuestion(false);
                                              dispatch({
                                                type:
                                                  'UPDATE_DISCOVERY_QUESTION',
                                                payload: {
                                                  id: dq?.id,
                                                  data: {
                                                    ...dq,
                                                    pinned: !dq?.pinned,
                                                  },
                                                },
                                              });
                                            },
                                            () => {
                                              dispatch(
                                                showAlert({
                                                  message:
                                                    'Failed to update question',
                                                  showCross: true,
                                                  title: null,
                                                  type: 'error',
                                                  autoHideDuration: 2000,
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }),
                                              );
                                            },
                                          ),
                                        );
                                      }
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        background: dq?.pinned
                                          ? '#C2DBFF'
                                          : '#F2F6FC',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                        flex: 1,
                                        borderRadius: 2,
                                        minHeight: 80,
                                        // border: dq?.pinned
                                        //   ? '1px solid #beb9e7'
                                        //   : 'none',
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 2,
                                          flex: 1,
                                          borderRadius: 2,
                                          paddingLeft: 2,
                                        }}
                                      >
                                        {/* <Typography
                                                              sx={{
                                                                // background: '#d3e2fe',
                                                                padding: 2,
                                                                borderTopLeftRadius: 8,
                                                                borderBottomLeftRadius: 8,
                                                                minHeight: 80,
                                                                minWidth: 80,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                fontWeight: 'bold',
                                                                cursor: 'pointer',
                                                              }}
                                                              onMouseEnter={(e) => {
                                                                setAnchorElDetails(
                                                                  e.currentTarget,
                                                                );
                                                                setPopperDetails(
                                                                  'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                                );
                                                              }}
                                                              onMouseLeave={() => {
                                                                setAnchorElDetails(null);
                                                                setPopperDetails(null);
                                                              }}
                                                            >
                                                              {index < 9 ? 0 : ''}
                                                              {index + 1}
                                                            </Typography>
                                                            <Popper
                                                              id="mouse-over-popover"
                                                              sx={{
                                                                pointerEvents: 'none',
                                                                zIndex: 1111111,
                                                              }}
                                                              open={Boolean(
                                                                anchorElDetails,
                                                              )}
                                                              anchorEl={anchorElDetails}
                                                              placement="top"
                                                              onClose={() => {
                                                                setAnchorElDetails(null);
                                                              }}
                                                              // disableRestoreFocus
                                                            >
                                                              <Box
                                                                sx={{
                                                                  border:
                                                                    '1px solid #d3d3d3',
                                                                  borderRadius: 1,
                                                                  padding: 1,
                                                                  background: '#fff',
                                                                  maxWidth: 400,
                                                                  display: 'flex',
                                                                  flexDirection: 'column',
                                                                  gap: 1,
                                                                  // boxShadow: theme.shadows[1],
                                                                }}
                                                              >
                                                                <Typography
                                                                  variant={'body1'}
                                                                  color={'text.secondary'}
                                                                  sx={{
                                                                    padding: 1,
                                                                  }}
                                                                >
                                                                  {popperDetails || ''}
                                                                </Typography>
                                                              </Box>
                                                            </Popper> */}
                                        {questionEditMode === dq?.id ? (
                                          <>
                                            <TextField
                                              autoFocus
                                              placeholder="Question"
                                              variant="outlined"
                                              color="primary"
                                              size="small"
                                              name="question"
                                              value={editedQuestionText}
                                              disabled={
                                                regeneratingQuestionInSpecificStyle
                                              }
                                              onChange={(e) => {
                                                setEditedQuestionText(
                                                  e.target.value,
                                                );
                                              }}
                                              fullWidth
                                            />
                                            <FormControl
                                              size="small"
                                              sx={{ minWidth: 150 }}
                                            >
                                              <InputLabel id="sellingStyle">
                                                Selling Style
                                              </InputLabel>
                                              <Select
                                                variant="outlined"
                                                size="small"
                                                labelId={'sellingStyle'}
                                                label={'Selling Style'}
                                                id={'sellingStyle'}
                                                name={'sellingStyle'}
                                                MenuProps={{
                                                  disableScrollLock: true,
                                                }}
                                                onChange={(e) => {
                                                  e.stopPropagation();
                                                  setRegeneratingQuestionInSpecificStyle(
                                                    true,
                                                  );
                                                  dispatch(
                                                    updateDiscoveryQuestion(
                                                      orgConfigs?.discoveryTreeId,
                                                      dq?.id,
                                                      {
                                                        ...dq,
                                                        sellingStyle:
                                                          e.target.value,
                                                      },
                                                      (data) => {
                                                        console.log(data);
                                                        setRegeneratingQuestionInSpecificStyle(
                                                          false,
                                                        );
                                                        setQuestionEditMode(
                                                          null,
                                                        );
                                                        setEditedQuestionText(
                                                          '',
                                                        );
                                                        dispatch({
                                                          type:
                                                            'UPDATE_DISCOVERY_QUESTION',
                                                          payload: {
                                                            id: dq?.id,
                                                            data: {
                                                              ...dq,
                                                              cq: data?.cq,
                                                              sellingStyle:
                                                                e.target.value,
                                                            },
                                                          },
                                                        });
                                                      },
                                                      () => {
                                                        dispatch(
                                                          showAlert({
                                                            message:
                                                              'Failed to update question',
                                                            showCross: true,
                                                            title: null,
                                                            type: 'error',
                                                            autoHideDuration: 2000,
                                                            vertical: 'top',
                                                            horizontal:
                                                              'center',
                                                          }),
                                                        );
                                                      },
                                                    ),
                                                  );
                                                }}
                                                value={
                                                  dq?.sellingStyle ||
                                                  sellingStyle ||
                                                  'persuasive_or_traditional_selling'
                                                }
                                              >
                                                {sellingStyles?.map((ss) => (
                                                  <MenuItem
                                                    key={ss?.sellingStyle}
                                                    value={ss?.sellingStyle}
                                                  >
                                                    <Typography variant="subtitle2">
                                                      {ss?.title}
                                                    </Typography>
                                                  </MenuItem>
                                                ))}
                                              </Select>
                                            </FormControl>
                                          </>
                                        ) : (
                                          <Box
                                            display={'flex'}
                                            gap={1}
                                            sx={{
                                              width: 1,
                                              padding: 1,
                                              textDecoration: dq?.deleted
                                                ? 'line-through'
                                                : 'none',
                                            }}
                                          >
                                            <InfoOutlined
                                              sx={{
                                                cursor: 'pointer',
                                              }}
                                              onMouseEnter={(e) => {
                                                setAnchorElDetails(
                                                  e.currentTarget,
                                                );
                                                setPopperDetails(
                                                  dq?.exp ||
                                                    'No explanation available',
                                                );
                                              }}
                                              onMouseLeave={() => {
                                                setAnchorElDetails(null);
                                                setPopperDetails(null);
                                              }}
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            />
                                            <Popper
                                              id="mouse-over-popover"
                                              sx={{
                                                pointerEvents: 'none',
                                                zIndex: 1111111,
                                              }}
                                              open={Boolean(anchorElDetails)}
                                              anchorEl={anchorElDetails}
                                              placement="top"
                                              onClose={() => {
                                                setAnchorElDetails(null);
                                              }}
                                              // disableRestoreFocus
                                            >
                                              <Box
                                                sx={{
                                                  border: '1px solid #d3d3d3',
                                                  borderRadius: 1,
                                                  padding: 1,
                                                  background: '#fff',
                                                  maxWidth: 400,
                                                  display: 'flex',
                                                  flexDirection: 'column',
                                                  gap: 1,
                                                  // boxShadow: theme.shadows[1],
                                                }}
                                              >
                                                <Typography
                                                  variant={'body1'}
                                                  color={'text.secondary'}
                                                  sx={{
                                                    padding: 1,
                                                  }}
                                                >
                                                  {popperDetails || ''}
                                                </Typography>
                                              </Box>
                                            </Popper>
                                            <Typography>{dq?.cq}</Typography>
                                          </Box>
                                        )}
                                      </Box>
                                      <Box
                                        sx={{
                                          // background: '#d3e2fe',
                                          padding: 2,
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          gap: 1,
                                          borderTopRightRadius: 8,
                                          borderBottomRightRadius: 8,
                                          minHeight: 80,
                                          position: 'relative',
                                        }}
                                      >
                                        {questionEditMode === dq?.id ? (
                                          updatingQuestion ||
                                          regeneratingQuestionInSpecificStyle ? (
                                            <CircularProgress size={20} />
                                          ) : (
                                            <>
                                              <Tooltip
                                                title="Cancel"
                                                arrow
                                                placement="top"
                                              >
                                                <Close
                                                  sx={{
                                                    cursor: 'pointer',
                                                    fontSize: 20,
                                                  }}
                                                  onClick={() => {
                                                    setQuestionEditMode(null);
                                                    setEditedQuestionText('');
                                                  }}
                                                />
                                              </Tooltip>
                                              <Tooltip
                                                title="Save"
                                                arrow
                                                placement="top"
                                              >
                                                <Check
                                                  sx={{
                                                    cursor: 'pointer',
                                                    fontSize: 20,
                                                  }}
                                                  onClick={() => {
                                                    setUpdatingQuestion(true);
                                                    dispatch(
                                                      updateDiscoveryQuestion(
                                                        orgConfigs?.discoveryTreeId,
                                                        questionEditMode,
                                                        {
                                                          q: editedQuestionText,
                                                          // sellingStyle: dq?.sellingStyle
                                                        },
                                                        () => {
                                                          setUpdatingQuestion(
                                                            false,
                                                          );
                                                          setQuestionEditMode(
                                                            null,
                                                          );
                                                          dispatch({
                                                            type:
                                                              'UPDATE_DISCOVERY_QUESTION',
                                                            payload: {
                                                              id: questionEditMode,
                                                              data: {
                                                                ...dq,
                                                                cq: editedQuestionText,
                                                                // sellingStyle: dq?.sellingStyle
                                                              },
                                                            },
                                                          });
                                                        },
                                                        () => {
                                                          dispatch(
                                                            showAlert({
                                                              message:
                                                                'Failed to update question',
                                                              showCross: true,
                                                              title: null,
                                                              type: 'error',
                                                              autoHideDuration: 2000,
                                                              vertical: 'top',
                                                              horizontal:
                                                                'center',
                                                            }),
                                                          );
                                                        },
                                                      ),
                                                    );
                                                  }}
                                                />
                                              </Tooltip>
                                            </>
                                          )
                                        ) : (
                                          <>
                                            {/* <HelpOutline
                                                                  sx={{
                                                                    cursor: 'pointer',
                                                                  }}
                                                                  onMouseEnter={(e) => {
                                                                    setAnchorElDetails(
                                                                      e.currentTarget,
                                                                    );
                                                                    setPopperDetails(
                                                                      'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                                    );
                                                                  }}
                                                                  onMouseLeave={() => {
                                                                    setAnchorElDetails(
                                                                      null,
                                                                    );
                                                                    setPopperDetails(
                                                                      null,
                                                                    );
                                                                  }}
                                                                />
                                                                <Popper
                                                                  id="mouse-over-popover"
                                                                  sx={{
                                                                    pointerEvents: 'none',
                                                                    zIndex: 1111111,
                                                                  }}
                                                                  open={Boolean(
                                                                    anchorElDetails,
                                                                  )}
                                                                  anchorEl={
                                                                    anchorElDetails
                                                                  }
                                                                  placement="left"
                                                                  onClose={() => {
                                                                    setAnchorElDetails(
                                                                      null,
                                                                    );
                                                                  }}
                                                                  // disableRestoreFocus
                                                                >
                                                                  <Box
                                                                    sx={{
                                                                      border:
                                                                        '1px solid #d3d3d3',
                                                                      borderRadius: 1,
                                                                      padding: 1,
                                                                      background: '#fff',
                                                                      maxWidth: 400,
                                                                      display: 'flex',
                                                                      flexDirection:
                                                                        'column',
                                                                      gap: 1,
                                                                      // boxShadow: theme.shadows[1],
                                                                    }}
                                                                  >
                                                                    <Typography
                                                                      variant={'body1'}
                                                                      color={
                                                                        'text.secondary'
                                                                      }
                                                                      sx={{
                                                                        padding: 1,
                                                                      }}
                                                                    >
                                                                      {popperDetails ||
                                                                        ''}
                                                                    </Typography>
                                                                  </Box>
                                                                </Popper> */}
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title="Edit"
                                            >
                                              <img
                                                src={'/dh-icons/edit.svg'}
                                                style={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={(e) => {
                                                  if (!dq?.deleted) {
                                                    e.stopPropagation();
                                                    setQuestionEditMode(dq?.id);
                                                    setEditedQuestionText(
                                                      dq?.cq,
                                                    );
                                                  }
                                                }}
                                              />
                                            </Tooltip>
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                dq?.pinned
                                                  ? 'Unselect'
                                                  : 'Select'
                                              }
                                            >
                                              {dq?.pinned ? (
                                                <img
                                                  src={'/dh-icons/pinned.svg'}
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setUpdatingQuestion(true);
                                                    dispatch(
                                                      updateDiscoveryQuestion(
                                                        orgConfigs?.discoveryTreeId,
                                                        dq?.id,
                                                        {
                                                          ...dq,
                                                          pinned: !dq?.pinned,
                                                          // sellingStyle: dq?.sellingStyle
                                                        },
                                                        () => {
                                                          setUpdatingQuestion(
                                                            false,
                                                          );
                                                          dispatch({
                                                            type:
                                                              'UPDATE_DISCOVERY_QUESTION',
                                                            payload: {
                                                              id: dq?.id,
                                                              data: {
                                                                ...dq,
                                                                pinned: !dq?.pinned,
                                                              },
                                                            },
                                                          });
                                                        },
                                                        () => {
                                                          dispatch(
                                                            showAlert({
                                                              message:
                                                                'Failed to update question',
                                                              showCross: true,
                                                              title: null,
                                                              type: 'error',
                                                              autoHideDuration: 2000,
                                                              vertical: 'top',
                                                              horizontal:
                                                                'center',
                                                            }),
                                                          );
                                                        },
                                                      ),
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <img
                                                  src={'/dh-icons/unpinned.svg'}
                                                  style={{
                                                    cursor: 'pointer',
                                                  }}
                                                  onClick={(e) => {
                                                    if (!dq?.deleted) {
                                                      e.stopPropagation();
                                                      setUpdatingQuestion(true);
                                                      dispatch(
                                                        updateDiscoveryQuestion(
                                                          orgConfigs?.discoveryTreeId,
                                                          dq?.id,
                                                          {
                                                            ...dq,
                                                            pinned: !dq?.pinned,
                                                            // sellingStyle: dq?.sellingStyle
                                                          },
                                                          () => {
                                                            setUpdatingQuestion(
                                                              false,
                                                            );
                                                            dispatch({
                                                              type:
                                                                'UPDATE_DISCOVERY_QUESTION',
                                                              payload: {
                                                                id: dq?.id,
                                                                data: {
                                                                  ...dq,
                                                                  pinned: !dq?.pinned,
                                                                },
                                                              },
                                                            });
                                                          },
                                                          () => {
                                                            dispatch(
                                                              showAlert({
                                                                message:
                                                                  'Failed to update question',
                                                                showCross: true,
                                                                title: null,
                                                                type: 'error',
                                                                autoHideDuration: 2000,
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'center',
                                                              }),
                                                            );
                                                          },
                                                        ),
                                                      );
                                                    }
                                                  }}
                                                />
                                              )}
                                            </Tooltip>
                                            {dq?.pinned ? null : (
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title={
                                                  dq?.deleted
                                                    ? 'Restore'
                                                    : 'Remove'
                                                }
                                              >
                                                {dq?.deleted ? (
                                                  <Redo
                                                    sx={{
                                                      color: '#fff',
                                                      cursor: 'pointer',
                                                      fontSize: 16,
                                                      position: 'absolute',
                                                      top: -2,
                                                      right: -2,
                                                      background:
                                                        'rgba(128,105,191,0.5)',
                                                      borderRadius: '50%',
                                                      padding: '2px',
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setUpdatingQuestion(true);
                                                      dispatch(
                                                        updateDiscoveryQuestion(
                                                          orgConfigs?.discoveryTreeId,
                                                          dq?.id,
                                                          {
                                                            ...dq,
                                                            deleted: !dq?.deleted,
                                                            // sellingStyle: dq?.sellingStyle
                                                          },
                                                          () => {
                                                            setUpdatingQuestion(
                                                              false,
                                                            );
                                                            dispatch({
                                                              type:
                                                                'UPDATE_DISCOVERY_QUESTION',
                                                              payload: {
                                                                id: dq?.id,
                                                                data: {
                                                                  ...dq,
                                                                  deleted: !dq?.deleted,
                                                                },
                                                              },
                                                            });
                                                          },
                                                          () => {
                                                            dispatch(
                                                              showAlert({
                                                                message:
                                                                  'Failed to update question',
                                                                showCross: true,
                                                                title: null,
                                                                type: 'error',
                                                                autoHideDuration: 2000,
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'center',
                                                              }),
                                                            );
                                                          },
                                                        ),
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <Close
                                                    sx={{
                                                      color: '#000',
                                                      cursor: 'pointer',
                                                      fontSize: 18,
                                                      position: 'absolute',
                                                      top: -2,
                                                      right: -2,
                                                      background: '#F2F6FC',
                                                      borderRadius: '50%',
                                                      border:
                                                        '1px solid #C2DBFF',
                                                      padding: '2px',
                                                      zIndex: 1000,
                                                    }}
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      setUpdatingQuestion(true);
                                                      dispatch(
                                                        updateDiscoveryQuestion(
                                                          orgConfigs?.discoveryTreeId,
                                                          dq?.id,
                                                          {
                                                            ...dq,
                                                            deleted: !dq?.deleted,
                                                            // sellingStyle: dq?.sellingStyle
                                                          },
                                                          () => {
                                                            setUpdatingQuestion(
                                                              false,
                                                            );
                                                            dispatch({
                                                              type:
                                                                'UPDATE_DISCOVERY_QUESTION',
                                                              payload: {
                                                                id: dq?.id,
                                                                data: {
                                                                  ...dq,
                                                                  deleted: !dq?.deleted,
                                                                },
                                                              },
                                                            });
                                                          },
                                                          () => {
                                                            dispatch(
                                                              showAlert({
                                                                message:
                                                                  'Failed to update question',
                                                                showCross: true,
                                                                title: null,
                                                                type: 'error',
                                                                autoHideDuration: 2000,
                                                                vertical: 'top',
                                                                horizontal:
                                                                  'center',
                                                              }),
                                                            );
                                                          },
                                                        ),
                                                      );
                                                    }}
                                                  />
                                                )}
                                              </Tooltip>
                                            )}
                                          </>
                                        )}
                                      </Box>
                                    </Box>
                                  </Box>
                                ))}
                            </>
                          ) : null}
                        </Box>
                      ))}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 300,
                  gap: 2,
                }}
              >
                <Typography>No discovery questions found.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        open={Boolean(showNewQuestionDialog)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowNewQuestionDialog(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '500px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              p: 2,
            }}
          >
            <Typography>Add new discovery question</Typography>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={2} display={'flex'} flexDirection={'column'} gap={2}>
            <TextField
              autoFocus
              placeholder="Enter Question"
              variant="outlined"
              color="primary"
              size="small"
              name="newQuestion"
              multiline
              rows={4}
              value={newQuestion}
              onChange={(e) => setNewQuestion(e.target.value)}
              fullWidth
            />
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel id="newQuestionSellingStyle">
                Selling Style
              </InputLabel>
              <Select
                variant="outlined"
                size="small"
                labelId={'newQuestionSellingStyle'}
                label={'Selling Style'}
                id={'newQuestionSellingStyle'}
                name={'newQuestionSellingStyle'}
                MenuProps={{
                  disableScrollLock: true,
                }}
                onChange={(e) => {
                  setNewQuestionSellingStyle(e.target.value);
                }}
                value={
                  newQuestionSellingStyle || 'persuasive_or_traditional_selling'
                }
              >
                {sellingStyles.map((ss) => (
                  <MenuItem key={ss?.sellingStyle} value={ss?.sellingStyle}>
                    <Typography variant="subtitle2">{ss?.title}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              width: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 1,
              borderTop: '1px solid #d3d3d3',
            }}
          >
            <Button
              data-trackid={'send_calendar_event_to_email'}
              size="small"
              variant="outlined"
              color="primary"
              onClick={(e) => {
                setShowNewQuestionDialog(null);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              startIcon={
                creatingNewQuestion ? <CircularProgress size={20} /> : <Add />
              }
              disabled={creatingNewQuestion}
              data-trackid={'download_event_ics_file'}
              color="primary"
              onClick={(e) => {
                setCreatingNewQuestion(true);
                dispatch(
                  createNewDiscoveryQuestion(
                    orgConfigs?.discoveryTreeId,
                    subtopicIdForNewQuestion,
                    {
                      q: newQuestion,
                      sellingStyle: newQuestionSellingStyle,
                    },
                    (data, error) => {
                      console.log(data);
                      setCreatingNewQuestion(false);
                      if (error) {
                        dispatch(
                          showAlert({
                            message:
                              'Failed to create new question. Please try again!',
                            showCross: true,
                            title: null,
                            type: 'error',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      } else {
                        setNewQuestion('');
                        setNewQuestionSellingStyle(
                          'persuasive_or_traditional_selling',
                        );
                        dispatch({
                          type: 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                          payload: {
                            data: [...qList, data],
                          },
                        });
                      }
                    },
                  ),
                );
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

DiscoveryTemplate.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default DiscoveryTemplate;
