import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Avatar,
  Alert,
  ListItemText,
  OutlinedInput,
} from '@mui/material';
import { keyframes } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  ArrowBack,
  Article,
  Audiotrack,
  Cached,
  Call,
  CallEnd,
  ChevronRight,
  Circle,
  Close,
  Delete,
  Info,
  Lock,
  Mic,
  MicOff,
  PlayArrow,
  RecordVoiceOverOutlined,
  Replay,
  StopCircle,
  SupportAgent,
  Troubleshoot,
  Undo,
  VoiceOverOffOutlined,
  VolumeUp,
  WarningAmber,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import getAllConversationsMain from 'redux/actions/Candidate/getAllConversationsMain';

import parse from 'html-react-parser';
import createNewConversation from 'redux/actions/Candidate/createNewConversation';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { RecordRTCPromisesHandler } from 'recordrtc';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import uploadAudio from 'redux/actions/Common/uploadAudio';
import verifyAudioUpload from 'redux/actions/Common/verifyAudioUpload';
import getMergedConversation from 'redux/actions/Candidate/getMergedConversation';
import deleteConversation from 'redux/actions/Candidate/deleteConversation';
import updateConversationStatus from 'redux/actions/Candidate/updateConversationStatus';
import checkMergedAudioStatus from 'redux/actions/Common/checkMergedAudioStatus';
import logError from 'redux/actions/Common/logError';
import getBots from 'redux/actions/Common/getBots';
import analyzeConversation from 'redux/actions/Common/analyzeConversation';
import RichTextEditor from 'components/RichTextEditor';
import checkConversationTranscriptStatus from 'redux/actions/Common/checkConversationTranscriptStatus';
import { PropTypes } from 'prop-types';

import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineGraph from 'components/LineGraph';
import showAlert from 'redux/actions/Common/showAlert';
import checkPracticeCallScoreStatus from 'redux/actions/Common/checkPracticeCallScoreStatus';
import trackEvent from 'redux/actions/Common/trackEvent';
import { capitalizeText, convertToTimerFormat, formatSeconds } from 'utils';
import getAllConversationsMainByUserId from 'redux/actions/Candidate/getAllConversationsMainByUserId';
import getFeedbacksOnEntity from 'redux/actions/Candidate/getFeedbacksOnEntity';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import ReportPage from 'pages/ReportPage';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import MediaPreview from 'components/@2024/MediaPreview';
import Vapi from '@vapi-ai/web';
import { isPublicKeyMissingError } from 'utils';
import getRemainingCredits from 'redux/actions/Common/getRemainingCredits';
import sendDemoRequest from 'redux/actions/Common/sendDemoRequest';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const vapiKey =
  process.env.REACT_APP_TARGET_ENV === 'local'
    ? '44012327-48cf-4e79-9af9-30d1789fc1f2'
    : '447207dc-4c1f-411a-a9d6-cf79470abbca';

const vapi = new Vapi(vapiKey);

// console.log(vapiKey);

const usePublicKeyInvalid = () => {
  const [
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  ] = useState(false);

  // close public key invalid message after delay
  useEffect(() => {
    if (showPublicKeyInvalidMessage) {
      setTimeout(() => {
        setShowPublicKeyInvalidMessage(false);
      }, 3000);
    }
  }, [showPublicKeyInvalidMessage]);

  return {
    showPublicKeyInvalidMessage,
    setShowPublicKeyInvalidMessage,
  };
};

const ConversationAIMain = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const browser = detect();
  const params = new URLSearchParams(window.location.search);
  const pathParams = useParams();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const { userDetails } = useSelector((state) => state.userDetails);
  const { dashboardSidebarExpanded, creditsRemaining } = useSelector(
    (state) => state.uiStates,
  );
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    conversations,
    fetching,
    fetchingPagination,
    lastSeen,
    emptyList,
  } = useSelector((state) =>
    uId ? state.allConversationsByUserId : state.allConversationsMain,
  );
  const feedbacksOnEntity = useSelector((state) => state.feedbacksOnEntity);

  const {
    showCompetitionDetailsOnTopbar,
    showBackButtonOnTopbar,
    currentContextData,
    currentNavigationLevel,
    loggedInUserTeamDetails,
  } = useSelector((state) => state.uiStates);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const { progressIds } = useSelector((state) => state.mergedAudioProgressIds);

  const [currentTab, setCurrentTab] = useState('create');

  const [conversationTitle, setConversationTitle] = useState(
    moment(new Date().getTime()).format('MMMM Do YYYY, h:mm:ss a'),
  );

  const [viewType, setViewType] = useState('list');

  const [gender, setGender] = useState('MALE');
  const [creatingConversation, setCreatingConversation] = useState(false);
  const [currentConversation, setCurrentConversation] = useState(null);

  const [promptType, setPromptType] = useState('default_with_name');
  const [companyName, setCompanyName] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [context, setContext] = useState('');

  const [recorder, setRecorder] = useState(null);
  const [showPermissionDeniedError, setShowPermissionDeniedError] = useState(
    false,
  );
  const [permissionDenied, setPermissionDenied] = useState(null);
  const [permissionPersisting, setPermissionPersisting] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [recordedBlobs, setRecordedBlobs] = useState([]);
  const [recording, setRecording] = useState(null);
  const streamRef = useRef();
  const playBackRef = useRef();
  const timer = useRef(null);
  const timer2 = useRef(null);
  const [timerSs, setTimerSs] = useState(0);
  const [timerMs, setTimerMs] = useState(0);
  const [mediaStream, setMediaStream] = useState(null);
  const [sampleRate, setSampleRate] = useState(48000);
  const [availableAudioDevices, setAvailableAudioDevices] = useState(null);
  const [firstRenderAudio, setFirstRenderAudio] = useState(false);
  const [audioSource, setAudioSource] = useState('');
  const [autoPlay, setAutoPlay] = useState(false);
  const [autoRecord, setAutoRecord] = useState(true);
  const [autoStart, setAutoStart] = useState(false);
  const [message, setMessage] = useState('');
  const [microphoneLevel, setMicrophoneLevel] = useState(0);

  const [conversationStarted, setConversationStarted] = useState(false);
  const [numberOfDots, setNumberOfDots] = useState(0);
  const [numberOfAltDots, setNumberOfAltDots] = useState(0);
  const dotsTimerRef = useRef(null);
  const dots2TimerRef = useRef(null);
  const [playingResponse, setPlayingResponse] = useState(false);
  const [responseAudioUrl, setResponseAudioUrl] = useState('');

  const [gettingResponse, setGettingResponse] = useState(false);
  const [
    generatingMergedConversation,
    setGeneratingMergedConversation,
  ] = useState([]);

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);

  const [showEmptySpeech, setShowEmptySpeech] = useState(false);

  const responseAudioRef = useRef(null);

  const checkPauseTimerRef = useRef(null);
  const [pauseTimer, setPauseTimer] = useState(0);
  const [pauseTimeLimit, setPauseTimeLimit] = useState(2500);

  const [analysisInProgress, setAnalysisInProgress] = useState([]);
  const [endConversationAlert, setEndConversationAlert] = useState(false);

  const [publicBots, setPublicBots] = useState([]);
  const [privateBots, setPrivateBots] = useState([]);
  const [botId, setBotId] = useState('surprise');

  const [showReAnalyzeDrawer, setShowReAnalyzeDrawer] = useState(null);
  const [methods, setMethods] = useState(['meddpic']);
  const [playbook, setPlaybook] = useState('');
  const [speaker, setSpeaker] = useState('spk_0');
  const [speakers, setSpeakers] = useState({});
  const checkTranscriptionProgressTimer = useRef(null);
  const [transcriptReady, setTranscriptReady] = useState(false);
  const [transcriptionFailed, setTranscriptionFailed] = useState(false);
  const [salesReview, setSalesReview] = useState(null);

  const [scriptReadingFillerWords, setScriptReadingFillerWords] = useState([]);
  const [scriptRepeatWords, setScriptRepeatWords] = useState([]);
  const [fillerWordsRegexp, setFillerWordsRegexp] = useState(null);
  const [repeatWordsRegexp, setRepeatWordRegexp] = useState(null);

  const [currentParam, setCurrentParam] = useState('transcript');
  const [showPlaybook, setShowPlaybook] = useState(false);
  const [currentMethod, setCurrentMethod] = useState('meddpic');
  const checkMergedAudioProgressTimerRef = useRef(null);

  const checkPracticeCallScoreStatusTimer = useRef(null);

  const scrollDivRef = useRef(null);
  const scrollDiv1Ref = useRef(null);
  const [scoreCalculationInProcess, setScoreCalculationInProcess] = useState(
    true,
  );

  const [vertical, setVertical] = useState('surprise');
  const [designation, setDesignation] = useState('surprise');
  const [feedbackOnEntity, setFeedbackOnEntity] = useState('');
  const [postingFeedback, setPostingFeedback] = useState(false);

  const [fieldsRequired, setFieldsRequired] = useState([]);
  const [showLimitExhausted, setShowLimitExhausted] = useState('');
  const [showSyncHubspotDialog, setShowSyncHubspotDialog] = useState(false);
  const [meetingTitle, setMeetingTitle] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactFirstName, setContactFirstName] = useState('');
  const [contactLastName, setContactLastName] = useState('');
  const [organizer, setOrganizer] = useState('');
  const [dateTime, setDateTime] = useState(new Date().getTime());
  const [syncingWithHubspot, setSyncingWithHubspot] = useState(false);

  const [playbooks, setPlaybooks] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState('select');
  const [playbookTitle, setPlaybookTitle] = useState('');

  const [showPreviewSalesCall, setShowPreviewSalesCall] = useState(false);
  const [selectedBotId, setSelectedBotId] = useState('');
  const [fetchingPlaybooks, setFetchingPlaybooks] = useState(false);
  const [showFetchingMenuItem, setShowFetchingMenuItem] = useState(true);

  const [vapiEnabled, setVapiEnabled] = useState(false);
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);

  const [assistantIsSpeaking, setAssistantIsSpeaking] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(0);
  const [sendingDemoRequest, setSendingDemoRequest] = useState(false);
  const [
    showDemoRequestSuccessDialog,
    setShowDemoRequestSuccessDialog,
  ] = useState(false);

  const [showInsufficientCredits, setShowInsufficientCredits] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(120);
  const [showTimeRemaining, setShowTimeRemaining] = useState(false);
  const timeRemainingTimer = useRef(null);

  const [reportGenerated, setReportGenerated] = useState(false);
  const [showReportAfterAnalysis, setShowReportAfterAnalysis] = useState(true);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    finalTranscript,
  } = useSpeechRecognition();

  const handleTrackEvent = (e, type) => {
    // console.log(e.target);
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const getRecordingPermission = async () => {
    try {
      const timeStampBeforePermission = Date.now();
      let stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: audioSource ? { exact: audioSource } : undefined },
      });
      const timeStampAfterPermission = Date.now();
      setPermissionDenied(false);
      if (timeStampAfterPermission - timeStampBeforePermission < 1500) {
        setPermissionPersisting(true);
      } else {
        setPermissionPersisting(false);
      }
      streamRef.current = stream;
      const sampleRateBlock = stream.getAudioTracks()[0].getSettings()
        .sampleRate;
      setSampleRate(sampleRateBlock);
      // console.log('sampleRateBlock', sampleRateBlock);
      setMediaStream(stream);
      if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
        console.log('enumerateDevices() not supported.');
        return;
      }
      const devices = await navigator.mediaDevices.enumerateDevices();
      setAvailableAudioDevices(
        devices.filter((device) => device.kind === 'audioinput'),
      );
    } catch (e) {
      console.log(e?.name);
      if (e?.name === 'NotAllowedError') {
        setShowPermissionDeniedError(true);
        setPermissionDenied(true);
        setError(true);
        setErrorMessage(
          'Camera and microphone are blocked. Please allow the permissions and start recording.',
        );
      } else {
        setError(true);
        setErrorMessage(
          'Oops we are unable to detect your camera. Please refresh this page and try again.',
        );
      }
    }
  };

  const handleRecordClick = async () => {
    setConversationStarted(true);
    setAutoStart(false);
    if (permissionDenied) {
      setShowPermissionDeniedError(true);
    } else {
      try {
        setSelectedAudio(null);
        setRecordedBlobs([]);
        setRecording(true);
        startTimer();
        if (playBackRef && playBackRef.current) {
          playBackRef.current.src = null;
        }
        SpeechRecognition.startListening({ continuous: true });
      } catch (error) {
        handleRecordStopClick();
        alert('An error occurred while recording: ' + error.message);
      }
    }
  };

  const handleRecordStopClick = async (end) => {
    try {
      if (recording) {
        await recorder.stopRecording();
        let blob = await recorder.getBlob();
        // console.log('blob', blob);
        setSelectedAudio(blob);
        stopTimer();
        setRecording(false);
        SpeechRecognition.stopListening();
        handleGetResponse(blob, end);
      } else {
        if (end) {
          if (autoRecord) {
            setAutoStart(false);
          }
          dispatch(
            updateConversationStatus(
              currentConversation?.id,
              'completed',
              () => {
                setCurrentConversation((prev) => {
                  return {
                    ...prev,
                    status: 'completed',
                  };
                });
              },
            ),
          );
          setShowTimeRemaining(false);
          clearInterval(timeRemainingTimer.current);
          setTimeRemaining(120);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleClearRecordingClick = () => {
    setSelectedAudio(null);
    setRecordedBlobs([]);
    setRecording(null);
    setMessage('');
    resetTranscript();
    if (selectedAudio) {
      playBackRef.current.src = null;
    }
    if (streamRef && streamRef.current)
      streamRef.current.getTracks().forEach((track) => track.stop());
    getRecordingPermission();
  };

  const startTimer = () => {
    timer.current = setInterval(() => {
      setTimerSs((prev) => {
        if (prev === 59) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 1000);
    timer2.current = setInterval(() => {
      setTimerMs((prev) => prev + 1);
    }, 60000);
  };

  const stopTimer = () => {
    // console.log(timer.current, timer2.current);
    clearInterval(timer.current);
    clearInterval(timer2.current);
    setTimerSs(0);
    setTimerMs(0);
  };

  // const handleGetResponse = () => {};

  const handleGetResponse = (audio, end) => {
    if (message.trim()) {
      setGettingResponse(true);
      dispatch(
        getMessageResponse(
          currentConversation?.id,
          message,
          null,
          end ? 'completed' : null,
          null,
          null,
          null,
          null,
          async (data) => {
            if (data?.userAudioUploadLink && data?.userAudioId && audio) {
              dispatch(
                uploadAudio(data?.userAudioUploadLink, audio, () => {
                  dispatch(
                    verifyAudioUpload(
                      data?.userAudioUploadLink && data?.userAudioId,
                      null,
                      () => {
                        setSelectedAudio(null);
                      },
                    ),
                  );
                }),
              );
            }
            setGettingResponse(false);
            setMessage('');
            resetTranscript();
            // if (audio) {
            //   playBackRef.current.src = null;
            // }
            if (streamRef && streamRef.current)
              streamRef.current.getTracks().forEach((track) => track.stop());
            // if (browser?.name !== 'safari') {
            getRecordingPermission();
            // setCurrentConversation(data);
            if (end) {
              if (autoRecord) {
                setAutoStart(false);
              }
              dispatch(
                updateConversationStatus(
                  currentConversation?.id,
                  'completed',
                  () => {
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        status: 'completed',
                      };
                    });
                  },
                ),
              );

              setShowTimeRemaining(false);
              clearInterval(timeRemainingTimer.current);
              setTimeRemaining(120);
            } else {
              setAutoPlay(true);
              setPlayingResponse(true);
              if (autoRecord) {
                setAutoStart(true);
              }
            }
            if (currentConversation?.audioService.includes('ELEVEN_LABS')) {
              setResponseAudioUrl(data?.elevenLabsAudioLink);
            } else if (currentConversation?.audioService.includes('CHAT_GPT')) {
              setResponseAudioUrl(data?.gptTTsLink);
            } else {
              setResponseAudioUrl(data?.gcpAudioLink);
            }
          },
          (errorCode) => {
            if (errorCode === 'quota exceeded') {
              setShowLimitExhausted(errorCode);
            }
            // console.log('coming here');
            setGettingResponse(false);
            setMessage('');
            resetTranscript();
          },
        ),
      );
    } else {
      if (end) {
        if (autoRecord) {
          setAutoStart(false);
        }
        dispatch(
          updateConversationStatus(currentConversation?.id, 'completed', () => {
            setCurrentConversation((prev) => {
              return {
                ...prev,
                status: 'completed',
              };
            });
          }),
        );
        setShowTimeRemaining(false);
        clearInterval(timeRemainingTimer.current);
        setTimeRemaining(120);
      } else {
        setShowEmptySpeech(true);
        dispatch(
          updateConversationStatus(
            currentConversation?.id,
            'in_process',
            () => {
              setCurrentConversation((prev) => {
                return {
                  ...prev,
                  status: 'in_process',
                };
              });
            },
          ),
        );
      }
    }
  };

  const handleChange = (e) => {
    if (e.target.checked) {
      setMethods((prev) => [...prev, e.target.value]);
    } else {
      setMethods((prev) => prev.filter((item) => item !== e.target.value));
    }
  };

  const handleAnalyze = (srId, mode, ar) => {
    if (userDetails?.custom?.plan?.price?.pricingScheme === 'seat_based') {
      if (
        userDetails?.custom?.userUsage?.currentUsage
          ?.number_of_practice_call_analysis !== undefined &&
        userDetails?.custom?.userUsage?.currentUsage
          ?.number_of_practice_call_analysis >=
          Object.values(
            Object.values(userDetails?.custom?.plan?.features || {})?.find(
              (obj) => obj?.feature === 'practice_call',
            )?.restrictions || {},
          )?.find(
            (obj) => obj?.restriction === 'number_of_practice_call_analysis',
          )?.limit
      ) {
        setShowLimitExhausted('analysis_exhausted');
      } else {
        setAnalysisInProgress((prev) => [...prev, srId]);
        console.log('checking merged audio status');
        const mergedAudioStatus = conversations?.find((cs) => cs?.id === srId)
          ?.mergedAudioStatus;
        if (mergedAudioStatus === 'completed') {
          console.log('merged audio available');
          console.log('checking audio transcription status');
          if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            'available'
          ) {
            console.log('transcript available');
            console.log('analysing conversation');
            dispatch(
              analyzeConversation(
                srId,
                {
                  analysisMethods: methods,
                  playbookText: playbook,
                  playbookTitle: playbookTitle,
                  playbookHistoryId: selectedPlaybook,
                  teamId: teamId || null,
                },
                (data) => {
                  console.log('anaysed conversation response', data);
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                  setShowReAnalyzeDrawer(null);
                  dispatch({
                    type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                    payload: {
                      id: srId,
                      data: data,
                    },
                  });
                  setSalesReview(data);
                  setReportGenerated(true);
                },
                () => {
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  dispatch(
                    showAlert({
                      message: 'Something went wrong. Please try again!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                },
              ),
            );
          } else if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            'in_process'
          ) {
            console.log('transcription is in process');
            console.log('checking audio transcription status');
            checkTranscriptionProgressTimer.current = setInterval(() => {
              dispatch(
                checkConversationTranscriptStatus(
                  showReAnalyzeDrawer,
                  (data) => {
                    console.log('transcriptionStatus', data);
                    if (data?.status === 'available') {
                      console.log('transcript available');
                      console.log('analysing conversation');
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptionFailed(false);
                      setTranscriptReady(true);
                      if (viewType === 'list') {
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcriptStatus',
                            value: 'available',
                          },
                        });
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcript',
                            value: data?.transcript,
                          },
                        });
                      }
                      dispatch(
                        analyzeConversation(
                          srId,
                          {
                            analysisMethods: methods,
                            playbookText: playbook,
                            playbookTitle: playbookTitle,
                            playbookHistoryId: selectedPlaybook,
                            teamId: teamId || null,
                          },
                          (data) => {
                            console.log('anaysed conversation response', data);
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                            setShowReAnalyzeDrawer(null);
                            dispatch({
                              type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                              payload: {
                                id: srId,
                                data: data,
                              },
                            });
                            setSalesReview(data);
                            setReportGenerated(true);
                          },
                          () => {
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          },
                        ),
                      );
                    } else if (data?.status === 'failed') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptReady(false);
                      setTranscriptionFailed(true);
                      dispatch(
                        showAlert({
                          message: 'Something went wrong. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                      const updatedAnalysisInProgress = analysisInProgress.filter(
                        (ap) => ap !== srId,
                      );
                      setAnalysisInProgress(updatedAnalysisInProgress);
                    }
                  },
                  () => {
                    clearInterval(checkTranscriptionProgressTimer.current);
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    const updatedAnalysisInProgress = analysisInProgress.filter(
                      (ap) => ap !== srId,
                    );
                    setAnalysisInProgress(updatedAnalysisInProgress);
                  },
                ),
              );
            }, 5000);
          } else if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            null
          ) {
            console.log('checking audio transcription status');
            checkTranscriptionProgressTimer.current = setInterval(() => {
              dispatch(
                checkConversationTranscriptStatus(
                  showReAnalyzeDrawer,
                  (data) => {
                    console.log('transcriptionStatus', data);
                    if (data?.status === 'available') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptionFailed(false);
                      setTranscriptReady(true);
                      if (viewType === 'list') {
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcriptStatus',
                            value: 'available',
                          },
                        });
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcript',
                            value: data?.transcript,
                          },
                        });
                      }
                      console.log('analysing conversation');
                      dispatch(
                        analyzeConversation(
                          srId,
                          {
                            analysisMethods: methods,
                            playbookText: playbook,
                            playbookTitle: playbookTitle,
                            playbookHistoryId: selectedPlaybook,
                            teamId: teamId || null,
                          },
                          (data) => {
                            console.log('anaysed conversation response', data);
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                            setShowReAnalyzeDrawer(null);
                            dispatch({
                              type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                              payload: {
                                id: srId,
                                data: data,
                              },
                            });
                            setSalesReview(data);
                            setReportGenerated(true);
                          },
                          () => {
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          },
                        ),
                      );
                    } else if (data?.status === 'failed') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptReady(false);
                      setTranscriptionFailed(true);
                      dispatch(
                        showAlert({
                          message: 'Something went wrong. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                      const updatedAnalysisInProgress = analysisInProgress.filter(
                        (ap) => ap !== srId,
                      );
                      setAnalysisInProgress(updatedAnalysisInProgress);
                    }
                  },
                  () => {
                    clearInterval(checkTranscriptionProgressTimer.current);
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    const updatedAnalysisInProgress = analysisInProgress.filter(
                      (ap) => ap !== srId,
                    );
                    setAnalysisInProgress(updatedAnalysisInProgress);
                  },
                ),
              );
            }, 5000);
          }
        } else {
          console.log('merge audio not available');
          // console.log('merging audio');
          // dispatch(
          //   getMergedConversation(
          //     srId,
          //     (data) => {
          //       console.log('mergeConversationStatus', data);
          console.log('checking merged audio status');
          dispatch(
            checkMergedAudioStatus(
              srId,
              (status) => {
                if (status?.status === 'completed') {
                  console.log('merged audio available');
                  if (viewType === 'list') {
                    dispatch({
                      type: 'UPDATE_CONVERSATION_BY_KEY',
                      payload: {
                        id: srId,
                        key: 'mergedAudioStatus',
                        value: 'completed',
                      },
                    });
                  }
                  console.log('checking audio transcription status');
                  checkTranscriptionProgressTimer.current = setInterval(() => {
                    dispatch(
                      checkConversationTranscriptStatus(
                        showReAnalyzeDrawer,
                        (data) => {
                          console.log('transcriptionStatus', data);
                          if (data?.status === 'available') {
                            clearInterval(
                              checkTranscriptionProgressTimer.current,
                            );
                            setTranscriptReady(true);
                            setTranscriptionFailed(false);
                            if (viewType === 'list') {
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'transcriptStatus',
                                  value: 'available',
                                },
                              });
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'transcript',
                                  value: data?.transcript,
                                },
                              });
                            }
                            console.log('analysing conversation');
                            dispatch(
                              analyzeConversation(
                                srId,
                                {
                                  analysisMethods: methods,
                                  playbookText: playbook,
                                  playbookTitle: playbookTitle,
                                  playbookHistoryId: selectedPlaybook,
                                  teamId: teamId || null,
                                },
                                (data) => {
                                  console.log(
                                    'anaysed conversation response',
                                    data,
                                  );
                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                    (ap) => ap !== srId,
                                  );
                                  setAnalysisInProgress(
                                    updatedAnalysisInProgress,
                                  );
                                  setShowReAnalyzeDrawer(null);
                                  dispatch({
                                    type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                                    payload: {
                                      id: srId,
                                      data: data,
                                    },
                                  });
                                  setSalesReview(data);
                                  setReportGenerated(true);
                                },
                                () => {
                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                    (ap) => ap !== srId,
                                  );
                                  dispatch(
                                    showAlert({
                                      message:
                                        'Something went wrong. Please try again!',
                                      showCross: true,
                                      title: null,
                                      type: 'error',
                                      autoHideDuration: 2000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                  setAnalysisInProgress(
                                    updatedAnalysisInProgress,
                                  );
                                },
                              ),
                            );
                          } else if (data?.status === 'failed') {
                            clearInterval(
                              checkTranscriptionProgressTimer.current,
                            );
                            setTranscriptReady(false);
                            setTranscriptionFailed(true);
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          }
                        },
                        () => {
                          clearInterval(
                            checkTranscriptionProgressTimer.current,
                          );
                          setTranscriptReady(false);
                          setTranscriptionFailed(true);
                          dispatch(
                            showAlert({
                              message:
                                'Something went wrong. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                          const updatedAnalysisInProgress = analysisInProgress.filter(
                            (ap) => ap !== srId,
                          );
                          setAnalysisInProgress(updatedAnalysisInProgress);
                        },
                      ),
                    );
                  }, 5000);
                } else {
                  console.log('merged audio not available');
                  console.log('checking merged audio status');
                  checkMergedAudioProgressTimerRef.current = setInterval(() => {
                    dispatch(
                      checkMergedAudioStatus(
                        srId,
                        (status) => {
                          console.log(status);
                          if (status?.status === 'completed') {
                            console.log('merged audio available now');
                            clearInterval(
                              checkMergedAudioProgressTimerRef.current,
                            );
                            if (viewType === 'list') {
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'mergedAudioStatus',
                                  value: 'completed',
                                },
                              });
                            }
                            console.log('checking transcription status');
                            checkTranscriptionProgressTimer.current = setInterval(
                              () => {
                                dispatch(
                                  checkConversationTranscriptStatus(
                                    showReAnalyzeDrawer,
                                    (data) => {
                                      console.log('transcriptionStatus', data);
                                      if (data?.status === 'available') {
                                        console.log('transcript available');
                                        clearInterval(
                                          checkTranscriptionProgressTimer.current,
                                        );
                                        setTranscriptReady(true);
                                        setTranscriptionFailed(false);
                                        if (viewType === 'list') {
                                          dispatch({
                                            type: 'UPDATE_CONVERSATION_BY_KEY',
                                            payload: {
                                              id: srId,
                                              key: 'transcriptStatus',
                                              value: 'available',
                                            },
                                          });
                                          dispatch({
                                            type: 'UPDATE_CONVERSATION_BY_KEY',
                                            payload: {
                                              id: srId,
                                              key: 'transcript',
                                              value: data?.transcript,
                                            },
                                          });
                                        }
                                        console.log('analysing conversation');
                                        dispatch(
                                          analyzeConversation(
                                            srId,
                                            {
                                              analysisMethods: methods,
                                              playbookText: playbook,
                                              playbookTitle: playbookTitle,
                                              playbookHistoryId: selectedPlaybook,
                                              teamId: teamId || null,
                                            },
                                            (data) => {
                                              console.log(
                                                'anaysed conversation response',
                                                data,
                                              );
                                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                                (ap) => ap !== srId,
                                              );
                                              setAnalysisInProgress(
                                                updatedAnalysisInProgress,
                                              );
                                              setShowReAnalyzeDrawer(null);
                                              dispatch({
                                                type:
                                                  'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                                                payload: {
                                                  id: srId,
                                                  data: data,
                                                },
                                              });
                                              setSalesReview(data);
                                              setReportGenerated(true);
                                            },
                                            () => {
                                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                                (ap) => ap !== srId,
                                              );
                                              dispatch(
                                                showAlert({
                                                  message:
                                                    'Something went wrong. Please try again!',
                                                  showCross: true,
                                                  title: null,
                                                  type: 'error',
                                                  autoHideDuration: 2000,
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }),
                                              );
                                              setAnalysisInProgress(
                                                updatedAnalysisInProgress,
                                              );
                                            },
                                          ),
                                        );
                                      } else if (data?.status === 'failed') {
                                        clearInterval(
                                          checkTranscriptionProgressTimer.current,
                                        );
                                        setTranscriptReady(false);
                                        setTranscriptionFailed(true);
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Something went wrong. Please try again!',
                                            showCross: true,
                                            title: null,
                                            type: 'error',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                        const updatedAnalysisInProgress = analysisInProgress.filter(
                                          (ap) => ap !== srId,
                                        );
                                        setAnalysisInProgress(
                                          updatedAnalysisInProgress,
                                        );
                                      }
                                    },
                                    () => {
                                      clearInterval(
                                        checkTranscriptionProgressTimer.current,
                                      );
                                      setTranscriptReady(false);
                                      setTranscriptionFailed(true);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                      const updatedAnalysisInProgress = analysisInProgress.filter(
                                        (ap) => ap !== srId,
                                      );
                                      setAnalysisInProgress(
                                        updatedAnalysisInProgress,
                                      );
                                    },
                                  ),
                                );
                              },
                              5000,
                            );
                          } else if (status?.status === 'failed') {
                            clearInterval(
                              checkMergedAudioProgressTimerRef.current,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          }
                        },
                        () => {
                          clearInterval(
                            checkMergedAudioProgressTimerRef.current,
                          );
                          dispatch(
                            showAlert({
                              message:
                                'Something went wrong. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                          const updatedAnalysisInProgress = analysisInProgress.filter(
                            (ap) => ap !== srId,
                          );
                          setAnalysisInProgress(updatedAnalysisInProgress);
                        },
                      ),
                    );
                  }, 5000);
                }
              },
              () => {
                dispatch(
                  showAlert({
                    message: 'Something went wrong. Please try again!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
                const updatedAnalysisInProgress = analysisInProgress.filter(
                  (ap) => ap !== srId,
                );
                setAnalysisInProgress(updatedAnalysisInProgress);
              },
            ),
          );
          //     },
          //     () => {
          //       dispatch(
          //         showAlert({
          //           message: 'Something went wrong. Please try again!',
          //           showCross: true,
          //           title: null,
          //           type: 'error',
          //           autoHideDuration: 2000,
          //           vertical: 'top',
          //           horizontal: 'center',
          //         }),
          //       );
          //       const updatedAnalysisInProgress = analysisInProgress.filter(
          //         (ap) => ap !== srId,
          //       );
          //       setAnalysisInProgress(updatedAnalysisInProgress);
          //     },
          //   ),
          // );
        }
      }
    } else {
      if (creditsRemaining >= 1) {
        setAnalysisInProgress((prev) => [...prev, srId]);
        console.log('checking merged audio status');
        const mergedAudioStatus = conversations?.find((cs) => cs?.id === srId)
          ?.mergedAudioStatus;
        if (mergedAudioStatus === 'completed') {
          console.log('merged audio available');
          console.log('checking audio transcription status');
          if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            'available'
          ) {
            console.log('transcript available');
            console.log('analysing conversation');
            dispatch(
              analyzeConversation(
                srId,
                {
                  analysisMethods: methods,
                  playbookText: playbook,
                  playbookTitle: playbookTitle,
                  playbookHistoryId: selectedPlaybook,
                  teamId: teamId || null,
                },
                (data) => {
                  console.log('anaysed conversation response', data);
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                  setShowReAnalyzeDrawer(null);
                  dispatch({
                    type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                    payload: {
                      id: srId,
                      data: data,
                    },
                  });
                  setSalesReview(data);
                  setReportGenerated(true);
                },
                () => {
                  const updatedAnalysisInProgress = analysisInProgress.filter(
                    (ap) => ap !== srId,
                  );
                  dispatch(
                    showAlert({
                      message: 'Something went wrong. Please try again!',
                      showCross: true,
                      title: null,
                      type: 'error',
                      autoHideDuration: 2000,
                      vertical: 'top',
                      horizontal: 'center',
                    }),
                  );
                  setAnalysisInProgress(updatedAnalysisInProgress);
                },
              ),
            );
          } else if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            'in_process'
          ) {
            console.log('transcription is in process');
            console.log('checking audio transcription status');
            checkTranscriptionProgressTimer.current = setInterval(() => {
              dispatch(
                checkConversationTranscriptStatus(
                  showReAnalyzeDrawer,
                  (data) => {
                    console.log('transcriptionStatus', data);
                    if (data?.status === 'available') {
                      console.log('transcript available');
                      console.log('analysing conversation');
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptionFailed(false);
                      setTranscriptReady(true);
                      if (viewType === 'list') {
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcriptStatus',
                            value: 'available',
                          },
                        });
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcript',
                            value: data?.transcript,
                          },
                        });
                      }
                      dispatch(
                        analyzeConversation(
                          srId,
                          {
                            analysisMethods: methods,
                            playbookText: playbook,
                            playbookTitle: playbookTitle,
                            playbookHistoryId: selectedPlaybook,
                            teamId: teamId || null,
                          },
                          (data) => {
                            console.log('anaysed conversation response', data);
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                            setShowReAnalyzeDrawer(null);
                            dispatch({
                              type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                              payload: {
                                id: srId,
                                data: data,
                              },
                            });
                            setSalesReview(data);
                            setReportGenerated(true);
                          },
                          () => {
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          },
                        ),
                      );
                    } else if (data?.status === 'failed') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptReady(false);
                      setTranscriptionFailed(true);
                      dispatch(
                        showAlert({
                          message: 'Something went wrong. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                      const updatedAnalysisInProgress = analysisInProgress.filter(
                        (ap) => ap !== srId,
                      );
                      setAnalysisInProgress(updatedAnalysisInProgress);
                    }
                  },
                  () => {
                    clearInterval(checkTranscriptionProgressTimer.current);
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    const updatedAnalysisInProgress = analysisInProgress.filter(
                      (ap) => ap !== srId,
                    );
                    setAnalysisInProgress(updatedAnalysisInProgress);
                  },
                ),
              );
            }, 5000);
          } else if (
            conversations?.find((cs) => cs?.id === srId)?.transcriptStatus ===
            null
          ) {
            console.log('checking audio transcription status');
            checkTranscriptionProgressTimer.current = setInterval(() => {
              dispatch(
                checkConversationTranscriptStatus(
                  showReAnalyzeDrawer,
                  (data) => {
                    console.log('transcriptionStatus', data);
                    if (data?.status === 'available') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptionFailed(false);
                      setTranscriptReady(true);
                      if (viewType === 'list') {
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcriptStatus',
                            value: 'available',
                          },
                        });
                        dispatch({
                          type: 'UPDATE_CONVERSATION_BY_KEY',
                          payload: {
                            id: srId,
                            key: 'transcript',
                            value: data?.transcript,
                          },
                        });
                      }
                      console.log('analysing conversation');
                      dispatch(
                        analyzeConversation(
                          srId,
                          {
                            analysisMethods: methods,
                            playbookText: playbook,
                            playbookTitle: playbookTitle,
                            playbookHistoryId: selectedPlaybook,
                            teamId: teamId || null,
                          },
                          (data) => {
                            console.log('anaysed conversation response', data);
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                            setShowReAnalyzeDrawer(null);
                            dispatch({
                              type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                              payload: {
                                id: srId,
                                data: data,
                              },
                            });
                            setSalesReview(data);
                            setReportGenerated(true);
                          },
                          () => {
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          },
                        ),
                      );
                    } else if (data?.status === 'failed') {
                      clearInterval(checkTranscriptionProgressTimer.current);
                      setTranscriptReady(false);
                      setTranscriptionFailed(true);
                      dispatch(
                        showAlert({
                          message: 'Something went wrong. Please try again!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 2000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                      const updatedAnalysisInProgress = analysisInProgress.filter(
                        (ap) => ap !== srId,
                      );
                      setAnalysisInProgress(updatedAnalysisInProgress);
                    }
                  },
                  () => {
                    clearInterval(checkTranscriptionProgressTimer.current);
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                    const updatedAnalysisInProgress = analysisInProgress.filter(
                      (ap) => ap !== srId,
                    );
                    setAnalysisInProgress(updatedAnalysisInProgress);
                  },
                ),
              );
            }, 5000);
          }
        } else {
          // console.log('merge audio not available');
          // console.log('merging audio');
          // dispatch(
          //   getMergedConversation(
          //     srId,
          //     (data) => {
          //       console.log('mergeConversationStatus', data);
          console.log('checking merged audio status');
          dispatch(
            checkMergedAudioStatus(
              srId,
              (status) => {
                if (status?.status === 'completed') {
                  console.log('merged audio available');
                  if (viewType === 'list') {
                    dispatch({
                      type: 'UPDATE_CONVERSATION_BY_KEY',
                      payload: {
                        id: srId,
                        key: 'mergedAudioStatus',
                        value: 'completed',
                      },
                    });
                  }
                  console.log('checking audio transcription status');
                  checkTranscriptionProgressTimer.current = setInterval(() => {
                    dispatch(
                      checkConversationTranscriptStatus(
                        showReAnalyzeDrawer,
                        (data) => {
                          console.log('transcriptionStatus', data);
                          if (data?.status === 'available') {
                            clearInterval(
                              checkTranscriptionProgressTimer.current,
                            );
                            setTranscriptReady(true);
                            setTranscriptionFailed(false);
                            if (viewType === 'list') {
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'transcriptStatus',
                                  value: 'available',
                                },
                              });
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'transcript',
                                  value: data?.transcript,
                                },
                              });
                            }
                            console.log('analysing conversation');
                            dispatch(
                              analyzeConversation(
                                srId,
                                {
                                  analysisMethods: methods,
                                  playbookText: playbook,
                                  playbookTitle: playbookTitle,
                                  playbookHistoryId: selectedPlaybook,
                                  teamId: teamId || null,
                                },
                                (data) => {
                                  console.log(
                                    'anaysed conversation response',
                                    data,
                                  );
                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                    (ap) => ap !== srId,
                                  );
                                  setAnalysisInProgress(
                                    updatedAnalysisInProgress,
                                  );
                                  setShowReAnalyzeDrawer(null);
                                  dispatch({
                                    type: 'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                                    payload: {
                                      id: srId,
                                      data: data,
                                    },
                                  });
                                  setSalesReview(data);
                                  setReportGenerated(true);
                                },
                                () => {
                                  const updatedAnalysisInProgress = analysisInProgress.filter(
                                    (ap) => ap !== srId,
                                  );
                                  dispatch(
                                    showAlert({
                                      message:
                                        'Something went wrong. Please try again!',
                                      showCross: true,
                                      title: null,
                                      type: 'error',
                                      autoHideDuration: 2000,
                                      vertical: 'top',
                                      horizontal: 'center',
                                    }),
                                  );
                                  setAnalysisInProgress(
                                    updatedAnalysisInProgress,
                                  );
                                },
                              ),
                            );
                          } else if (data?.status === 'failed') {
                            clearInterval(
                              checkTranscriptionProgressTimer.current,
                            );
                            setTranscriptReady(false);
                            setTranscriptionFailed(true);
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          }
                        },
                        () => {
                          clearInterval(
                            checkTranscriptionProgressTimer.current,
                          );
                          setTranscriptReady(false);
                          setTranscriptionFailed(true);
                          dispatch(
                            showAlert({
                              message:
                                'Something went wrong. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                          const updatedAnalysisInProgress = analysisInProgress.filter(
                            (ap) => ap !== srId,
                          );
                          setAnalysisInProgress(updatedAnalysisInProgress);
                        },
                      ),
                    );
                  }, 5000);
                } else {
                  console.log('merged audio not available');
                  console.log('checking merged audio status');
                  checkMergedAudioProgressTimerRef.current = setInterval(() => {
                    dispatch(
                      checkMergedAudioStatus(
                        srId,
                        (status) => {
                          console.log(status);
                          if (status?.status === 'completed') {
                            console.log('merged audio available now');
                            clearInterval(
                              checkMergedAudioProgressTimerRef.current,
                            );
                            if (viewType === 'list') {
                              dispatch({
                                type: 'UPDATE_CONVERSATION_BY_KEY',
                                payload: {
                                  id: srId,
                                  key: 'mergedAudioStatus',
                                  value: 'completed',
                                },
                              });
                            }
                            console.log('checking transcription status');
                            checkTranscriptionProgressTimer.current = setInterval(
                              () => {
                                dispatch(
                                  checkConversationTranscriptStatus(
                                    showReAnalyzeDrawer,
                                    (data) => {
                                      console.log('transcriptionStatus', data);
                                      if (data?.status === 'available') {
                                        console.log('transcript available');
                                        clearInterval(
                                          checkTranscriptionProgressTimer.current,
                                        );
                                        setTranscriptReady(true);
                                        setTranscriptionFailed(false);
                                        if (viewType === 'list') {
                                          dispatch({
                                            type: 'UPDATE_CONVERSATION_BY_KEY',
                                            payload: {
                                              id: srId,
                                              key: 'transcriptStatus',
                                              value: 'available',
                                            },
                                          });
                                          dispatch({
                                            type: 'UPDATE_CONVERSATION_BY_KEY',
                                            payload: {
                                              id: srId,
                                              key: 'transcript',
                                              value: data?.transcript,
                                            },
                                          });
                                        }
                                        console.log('analysing conversation');
                                        dispatch(
                                          analyzeConversation(
                                            srId,
                                            {
                                              analysisMethods: methods,
                                              playbookText: playbook,
                                              playbookTitle: playbookTitle,
                                              playbookHistoryId: selectedPlaybook,
                                              teamId: teamId || null,
                                            },
                                            (data) => {
                                              console.log(
                                                'anaysed conversation response',
                                                data,
                                              );
                                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                                (ap) => ap !== srId,
                                              );
                                              setAnalysisInProgress(
                                                updatedAnalysisInProgress,
                                              );
                                              setShowReAnalyzeDrawer(null);
                                              dispatch({
                                                type:
                                                  'UPDATE_CONVERSATIONS_AFTER_ANALYSIS',
                                                payload: {
                                                  id: srId,
                                                  data: data,
                                                },
                                              });
                                              setSalesReview(data);
                                              setReportGenerated(true);
                                            },
                                            () => {
                                              const updatedAnalysisInProgress = analysisInProgress.filter(
                                                (ap) => ap !== srId,
                                              );
                                              dispatch(
                                                showAlert({
                                                  message:
                                                    'Something went wrong. Please try again!',
                                                  showCross: true,
                                                  title: null,
                                                  type: 'error',
                                                  autoHideDuration: 2000,
                                                  vertical: 'top',
                                                  horizontal: 'center',
                                                }),
                                              );
                                              setAnalysisInProgress(
                                                updatedAnalysisInProgress,
                                              );
                                            },
                                          ),
                                        );
                                      } else if (data?.status === 'failed') {
                                        clearInterval(
                                          checkTranscriptionProgressTimer.current,
                                        );
                                        setTranscriptReady(false);
                                        setTranscriptionFailed(true);
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Something went wrong. Please try again!',
                                            showCross: true,
                                            title: null,
                                            type: 'error',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                        const updatedAnalysisInProgress = analysisInProgress.filter(
                                          (ap) => ap !== srId,
                                        );
                                        setAnalysisInProgress(
                                          updatedAnalysisInProgress,
                                        );
                                      }
                                    },
                                    () => {
                                      clearInterval(
                                        checkTranscriptionProgressTimer.current,
                                      );
                                      setTranscriptReady(false);
                                      setTranscriptionFailed(true);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                      const updatedAnalysisInProgress = analysisInProgress.filter(
                                        (ap) => ap !== srId,
                                      );
                                      setAnalysisInProgress(
                                        updatedAnalysisInProgress,
                                      );
                                    },
                                  ),
                                );
                              },
                              5000,
                            );
                          } else if (status?.status === 'failed') {
                            clearInterval(
                              checkMergedAudioProgressTimerRef.current,
                            );
                            dispatch(
                              showAlert({
                                message:
                                  'Something went wrong. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                            const updatedAnalysisInProgress = analysisInProgress.filter(
                              (ap) => ap !== srId,
                            );
                            setAnalysisInProgress(updatedAnalysisInProgress);
                          }
                        },
                        () => {
                          clearInterval(
                            checkMergedAudioProgressTimerRef.current,
                          );
                          dispatch(
                            showAlert({
                              message:
                                'Something went wrong. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                          const updatedAnalysisInProgress = analysisInProgress.filter(
                            (ap) => ap !== srId,
                          );
                          setAnalysisInProgress(updatedAnalysisInProgress);
                        },
                      ),
                    );
                  }, 5000);
                }
              },
              () => {
                dispatch(
                  showAlert({
                    message: 'Something went wrong. Please try again!',
                    showCross: true,
                    title: null,
                    type: 'error',
                    autoHideDuration: 2000,
                    vertical: 'top',
                    horizontal: 'center',
                  }),
                );
                const updatedAnalysisInProgress = analysisInProgress.filter(
                  (ap) => ap !== srId,
                );
                setAnalysisInProgress(updatedAnalysisInProgress);
              },
            ),
          );
          //     },
          //     () => {
          //       dispatch(
          //         showAlert({
          //           message: 'Something went wrong. Please try again!',
          //           showCross: true,
          //           title: null,
          //           type: 'error',
          //           autoHideDuration: 2000,
          //           vertical: 'top',
          //           horizontal: 'center',
          //         }),
          //       );
          //       const updatedAnalysisInProgress = analysisInProgress.filter(
          //         (ap) => ap !== srId,
          //       );
          //       setAnalysisInProgress(updatedAnalysisInProgress);
          //     },
          //   ),
          // );
        }
      } else {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showCreditsRequired',
            value: true,
          },
        });
      }
    }
  };

  const handleListScroll = (e, paginate) => {
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        conversations &&
        conversations.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      if (uId) {
        dispatch(
          getAllConversationsMainByUserId(
            teamId || null,
            uId,
            10,
            lastSeen,
            () => {},
          ),
        );
      } else {
        dispatch(
          getAllConversationsMain(teamId || null, null, 10, lastSeen, () => {}),
        );
      }
    }
  };

  const handleCreateVapiConversation = () => {
    // dispatch(createVapiConversation());
  };

  useEffect(() => {
    if (transcript) {
      if (recording) {
        checkPauseTimerRef.current = setInterval(() => {
          if (recording) {
            handleRecordStopClick();
          }
        }, pauseTimeLimit + 200);
      } else {
        if (checkPauseTimerRef.current) {
          clearInterval(checkPauseTimerRef.current);
          setPauseTimer(0);
        }
      }
    }
    return () => {
      if (checkPauseTimerRef.current) {
        clearInterval(checkPauseTimerRef.current);
        setPauseTimer(0);
      }
    };
  }, [transcript, recording]);

  // useEffect(() => {
  //   if (pauseTimer > pauseTimeLimit) {

  //   }
  // }, [pauseTimer]);

  useEffect(() => {
    if (transcript) {
      setMessage(transcript);
    } else {
      setMessage('');
    }
  }, [transcript]);

  useEffect(() => {
    if (selectedAudio && recording === false) {
      let recordedVideoUrl = window.URL.createObjectURL(selectedAudio);
      // setRecording(false);
      if (playBackRef.current) {
        playBackRef.current.src = recordedVideoUrl;
        // playBackRef.current.play();
      }
    }
    // if (streamRef && streamRef.current)
    //   streamRef.current.getTracks().forEach((track) => track.stop());
  }, [selectedAudio, recording, playBackRef?.current]);

  useEffect(() => {
    let options = {
      mimeType: 'audio/webm',
    };
    try {
      if (mediaStream !== null) {
        setRecorder(new RecordRTCPromisesHandler(mediaStream, options));
      }
    } catch (e) {
      console.error(e);
      setError(true);
      setErrorMessage('Failed to get media device');
    }
  }, [mediaStream]);

  useEffect(() => {
    if (
      recording === true &&
      recordedBlobs.length === 0 &&
      selectedAudio === null
    ) {
      try {
        recorder.onerror = (e) => {
          console.log(e);
          setError(true);
          setErrorMessage(
            'Oops! Something went wrong. Please refresh this page and try again. ',
          );
        };
        recorder.startRecording();
      } catch (e) {
        console.log(e);
        console.log('mediaRecorder API not supported for this browser');
        setError(true);
        setErrorMessage(
          'Oops! Something went wrong. Please refresh this page and try again. ',
        );
      }
    }
  }, [selectedAudio, recordedBlobs, recording]);

  useEffect(() => {
    if (
      timerSs % 15 === 0 &&
      (timerSs !== 0 || (timerSs === 0 && timerMs !== 0))
    ) {
      (async function() {
        await SpeechRecognition.stopListening();
        await SpeechRecognition.startListening({ continuous: true });
      })();
      // console.log('restart');
    }
  }, [timerSs, timerMs]);

  useEffect(() => {
    if (
      userDetails?.custom?.plan?.price?.pricingScheme === 'usage_based' &&
      creditsRemaining * 2 * 60 - (timerMs * 60 + timerSs) < 120
    ) {
      setShowTimeRemaining(true);
      // console.log('restart');
    }
  }, [timerSs, timerMs]);

  useEffect(() => {
    if (showTimeRemaining) {
      timeRemainingTimer.current = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev === 0) {
            return 0;
          } else {
            return prev - 1;
          }
        });
      }, 1000);
    }
  }, [showTimeRemaining]);

  useEffect(() => {
    if (timeRemaining === 0) {
      if (vapiEnabled) {
        if (connected) {
          vapi.stop();
          handleRecordStopClick(true);
        }
      } else {
        if (conversationStarted) {
          if (playingResponse) {
            setPlayingResponse(false);
            responseAudioRef?.current?.pause();
          }
          setConversationStarted(false);
          handleRecordStopClick(true);
        }
      }
      if (userDetails?.custom?.plan?.price?.pricingScheme === 'usage_based') {
        dispatch(
          getRemainingCredits((data) => {
            console.log(data);
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'creditsRemaining',
                value: data,
              },
            });
          }),
        );
      }
      dispatch(
        showAlert({
          message: 'Conversation ended because of exhaustion of the quota.',
          showCross: true,
          title: null,
          type: 'info',
          autoHideDuration: 2000,
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  }, [timeRemaining]);

  useEffect(() => {
    if (!firstRenderAudio) {
      if (availableAudioDevices && availableAudioDevices.length > 0) {
        setAudioSource(availableAudioDevices[0].deviceId);
        setFirstRenderAudio(true);
      }
    }
  }, [availableAudioDevices]);

  useEffect(() => {
    if (currentConversation) {
      if (currentConversation?.vapiCallId) {
        setVapiEnabled(true);
        if (streamRef && streamRef.current)
          streamRef.current.getTracks().forEach((track) => track.stop());
      } else {
        setVapiEnabled(false);
        getRecordingPermission();
      }
    } else {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    }
    return () => {
      if (streamRef && streamRef.current)
        streamRef.current.getTracks().forEach((track) => track.stop());
    };
  }, [currentConversation]);

  useEffect(() => {
    if (vapiEnabled) {
      if (connected) {
        startTimer();
      } else {
        stopTimer();
      }
    }
  }, [vapiEnabled, connected]);

  useEffect(() => {
    if (currentTab === 'list' && viewType === 'list') {
      if (uId) {
        dispatch(
          getAllConversationsMainByUserId(
            teamId || null,
            uId,
            10,
            null,
            () => {},
          ),
        );
      } else {
        dispatch(
          getAllConversationsMain(teamId || null, null, 10, null, () => {}),
        );
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: null,
          },
        });
      }
    }
  }, [currentTab, viewType, teamId]);

  useEffect(() => {
    if (selectedPlaybook === 'select') {
      if (orgConfigs?.playbook) {
        setPlaybook(orgConfigs?.playbook);
      }
    } else {
      setPlaybook(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.playbook || '',
      );
    }
  }, [orgConfigs, selectedPlaybook]);

  useEffect(() => {
    setMethods(orgConfigs?.selectedMethodologies || []);
  }, [orgConfigs]);

  useEffect(() => {
    if (currentTab === 'create') {
      dispatch(
        getBots(null, (data) => {
          // console.log(data);
          setPublicBots(data?.public);
          setPrivateBots(data?.personal);
        }),
      );
    }
  }, [currentTab]);

  useEffect(() => {
    if (recording || connected) {
      dotsTimerRef.current = setInterval(() => {
        setNumberOfDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dotsTimerRef.current);
    }
  }, [recording, connected]);

  useEffect(() => {
    if (playingResponse || assistantIsSpeaking) {
      dots2TimerRef.current = setInterval(() => {
        setNumberOfAltDots((prev) => (prev >= 3 ? 0 : prev + 1));
      }, 500);
    } else {
      clearInterval(dots2TimerRef.current);
    }
  }, [playingResponse, assistantIsSpeaking]);

  useEffect(() => {
    if (message) {
      setShowEmptySpeech(false);
    }
  }, [message]);

  useEffect(() => {
    if (salesReview) {
      setMeetingTitle(salesReview?.title);
      setSpeakers(salesReview?.speakers);
      if (salesReview?.analysis?.summary) {
        setCurrentMethod('summary');
      } else if (salesReview?.playbook) {
        setCurrentMethod('playbook');
      }
    }
  }, [salesReview]);

  useEffect(() => {
    if (reportGenerated && showReportAfterAnalysis) {
      setViewType('report');
    }
  }, [reportGenerated, showReportAfterAnalysis]);

  useEffect(() => {
    if (
      salesReview?.analysis?.fillerInfo?.fillers &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers) &&
      Object.keys(salesReview?.analysis?.fillerInfo?.fillers).length > 0
    ) {
      setScriptReadingFillerWords(
        Object.keys(salesReview?.analysis?.fillerInfo?.fillers),
      );
    }
    if (
      salesReview?.analysis?.repetitionInfo?.repetitions &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions) &&
      Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions).length > 0
    ) {
      setScriptRepeatWords(
        Object.keys(salesReview?.analysis?.repetitionInfo?.repetitions),
      );
    }
  }, [salesReview]);

  useEffect(() => {
    if (
      scriptReadingFillerWords &&
      Array.isArray(scriptReadingFillerWords) &&
      scriptReadingFillerWords.length > 0
    ) {
      setFillerWordsRegexp(
        new RegExp(
          scriptReadingFillerWords.map((w) => `{${w}}`).join('|'),
          'ig',
        ),
      );
    }
  }, [scriptReadingFillerWords]);

  useEffect(() => {
    if (
      scriptRepeatWords &&
      Array.isArray(scriptRepeatWords) &&
      scriptRepeatWords.length > 0
    ) {
      setRepeatWordRegexp(
        new RegExp(scriptRepeatWords.map((w) => `{${w}}`).join('|'), 'ig'),
      );
    }
  }, [scriptRepeatWords]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      if (conversationStarted || gettingResponse) {
        setEndConversationAlert(true);
      } else {
        setViewType('list');
        setCurrentConversation(null);
      }
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    if (viewType === 'report') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            title: salesReview?.title || '',
          },
        },
      });
    } else if (viewType === 'conversation') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            title: '',
          },
        },
      });
    } else {
      if (!uId) {
        setShowEmptySpeech(false);
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  useEffect(() => {
    if (viewType === 'report') {
      if (userDetails?.custom?.plan?.price?.pricingScheme === 'usage_based') {
        dispatch(
          getRemainingCredits((data) => {
            console.log(data);
            dispatch({
              type: 'UPDATE_UI_STATE',
              payload: {
                key: 'creditsRemaining',
                value: data,
              },
            });
          }),
        );
      }
    }
  }, [viewType, userDetails]);

  useEffect(() => {
    if (currentTab === 'create') {
      setConversationTitle(
        moment(new Date().getTime()).format('MMMM Do YYYY, h:mm:ss a'),
      );
    }
  }, [currentTab, viewType]);

  useEffect(() => {
    if (member && uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            name: member?.fullName,
            email: member?.email,
          },
        },
      });
    }
  }, [member, uId]);

  useEffect(() => {
    if (
      (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
      (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
    ) {
      setViewType('report');
    } else {
      setViewType('list');
    }
  }, [currentNavigationLevel]);

  useEffect(() => {
    if (currentMethod === 'feedback') {
      dispatch(getFeedbacksOnEntity(salesReview?.id, 10, null, () => {}));
    }
  }, [currentMethod]);

  useEffect(() => {
    if (params && params.get('new') === 'true') {
      setCurrentTab('create');
      setViewType('list');
      setConversationTitle(
        moment(new Date().getTime()).format('MMMM Do YYYY, h:mm:ss a'),
      );
    } else {
      setCurrentTab('list');
      setViewType('list');
    }
  }, [window.location.href]);

  useEffect(() => {
    if (playbooks && playbooks.length > 0) {
      if (fetchingPlaybooks) {
        setSelectedPlaybook('loading');
      } else {
        setSelectedPlaybook(
          currentConversation?.playbookHistoryId ||
            playbooks?.find((pb) => pb?.selected)?.id ||
            'select',
        );
      }
    } else {
      if (fetchingPlaybooks) {
        setSelectedPlaybook('loading');
      } else {
        setSelectedPlaybook(currentConversation?.playbookHistoryId || 'select');
      }
    }
  }, [playbooks, currentConversation, fetchingPlaybooks]);

  useEffect(() => {
    if (selectedPlaybook) {
      setPlaybookTitle(
        playbooks?.find((pb) => pb?.id === selectedPlaybook)?.tags?.[0] || '',
      );
    }
  }, [selectedPlaybook]);

  useEffect(() => {
    if (showReAnalyzeDrawer || currentTab === 'create') {
      setFetchingPlaybooks(true);
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          setFetchingPlaybooks(false);
          setShowFetchingMenuItem(false);
          if (data?.selectedPlaybook) {
            setPlaybooks([data?.selectedPlaybook, ...(data?.history || [])]);
          } else {
            setPlaybooks([...(data?.history || [])]);
          }
        }),
      );
    }
  }, [showReAnalyzeDrawer, currentTab]);

  useEffect(() => {
    if (showReAnalyzeDrawer && playbooks?.length > 0) {
      setSelectedPlaybook(
        playbooks?.find(
          (pb) =>
            pb?.id ===
            conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
              ?.playbookHistoryId,
        )?.id ||
          currentConversation?.playbookHistoryId ||
          'select',
      );
    }
  }, [showReAnalyzeDrawer, playbooks, conversations]);

  useEffect(() => {
    if (botId === 'surprise') {
      if ([...(publicBots || []), ...(privateBots || [])]?.length > 1) {
        setSelectedBotId(
          [...(publicBots || []), ...(privateBots || [])][
            Math.floor(
              Math.random() *
                [...(publicBots || []), ...(privateBots || [])].length,
            )
          ]?.id,
        );
      } else {
        setSelectedBotId(
          [...(publicBots || []), ...(privateBots || [])]?.[0]?.id,
        );
      }
    } else {
      setSelectedBotId(botId);
    }
  }, [botId, publicBots, privateBots]);

  useEffect(() => {
    if (vertical === 'surprise') {
      if (orgConfigs?.botVerticals?.length === 1) {
        setVertical(orgConfigs?.botVerticals?.[0]);
      }
    } else {
      setVertical(orgConfigs.botVerticals?.[0] || '');
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (designation === 'surprise') {
      if (orgConfigs?.botDesignations?.length === 1) {
        setDesignation(orgConfigs?.botDesignations?.[0]);
      }
    } else {
      setDesignation(orgConfigs.botDesignations?.[0] || '');
    }
  }, [orgConfigs]);

  useEffect(() => {
    const onSpeechStart = () => {
      setAssistantIsSpeaking(true);
    };
    const onSpeechEnd = () => {
      setAssistantIsSpeaking(false);
    };

    const onCallStartHandler = () => {
      setConnecting(false);
      setConnected(true);
    };

    const onCallEnd = () => {
      setConnecting(false);
      setConnected(false);
    };

    const onVolumeLevel = (level) => {
      setVolumeLevel(level);
    };
    const onVapiError = (error) => {
      console.error(error);
      if (error?.error?.msg === 'Exiting meeting because room was deleted') {
        setCurrentConversation(null);
        setViewType('list');
        dispatch(
          showAlert({
            message: 'Roleplay ended as nothing was said for 30 seconds.',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
      setConnecting(false);
      if (isPublicKeyMissingError({ vapiError: error })) {
        dispatch(
          showAlert({
            message: 'Something went wrong. Please try again!',
            showCross: true,
            title: null,
            type: 'error',
            autoHideDuration: 2000,
            vertical: 'top',
            horizontal: 'center',
          }),
        );
      }
    };
    vapi.on('call-start', onCallStartHandler);
    vapi.on('call-end', onCallEnd);
    vapi.on('speech-start', onSpeechStart);
    vapi.on('speech-end', onSpeechEnd);
    vapi.on('volume-level', onVolumeLevel);
    vapi.on('error', onVapiError);
    return () => {
      vapi.off('speech-start', onSpeechStart);
      vapi.off('speech-end', onSpeechEnd);
      vapi.off('call-start', onCallStartHandler);
      vapi.off('call-end', onCallEnd);
      vapi.off('volume-level', onVolumeLevel);
      vapi.off('error', onVapiError);
    };
  }, []);

  useEffect(() => {
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(
      getUserDetails((data) => {
        if (data?.custom?.plan?.price?.pricingScheme === 'usage_based') {
          dispatch(
            getRemainingCredits((data) => {
              console.log(data);
              dispatch({
                type: 'UPDATE_UI_STATE',
                payload: {
                  key: 'creditsRemaining',
                  value: data,
                },
              });
            }),
          );
        }
      }),
    );
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log('currentConversation', currentConversation);
  // console.log(showTimeRemaining);
  // console.log(timeRemaining);
  // console.log(timeRemainingTimer.current);
  // console.log('currentContextData', currentContextData);
  // console.log('salesReview', salesReview);
  // console.log('showEmptySpeech', showEmptySpeech);
  // console.log(conversationStarted);
  // console.log(currentConversation?.status);
  // console.log('currentConversation', currentConversation);
  console.log('showReportAfterAnalysis', showReportAfterAnalysis);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight: isLoggedIn ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
        marginTop: {
          xs: 1,
          sm: 0,
          md: 0,
        },
      }}
    >
      {/* <Container maxWidth={{ sm: 1, md: 1 }} paddingX={2} paddingY={2}> */}
      {viewType === 'list' ? (
        <></>
      ) : viewType === 'report' ||
        member ||
        isXs ||
        ((process.env.REACT_APP_TARGET_ENV === 'development' ||
          process.env.REACT_APP_TARGET_ENV === 'local') &&
          viewType === 'conversation') ? (
        <Box
          p={{ xs: 1, sm: 1, md: 1 }}
          display={'flex'}
          alignItems={'center'}
          justifyContent={
            viewType === 'report'
              ? 'flex-start'
              : isXs
              ? 'space-between'
              : 'flex-end'
          }
        >
          {isXs ? (
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              onClick={() => {
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'showBackButtonOnTopbar',
                    value: false,
                  },
                });
              }}
              color="primary"
              size="small"
            >
              Go Back
            </Button>
          ) : null}
          {(process.env.REACT_APP_TARGET_ENV === 'development' ||
            process.env.REACT_APP_TARGET_ENV === 'local') &&
          viewType === 'conversation' ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'flex-end'}
              gap={2}
            >
              <Typography color={'text.secondary'}>Auto mute after</Typography>
              <Select
                labelId="pauseTimeLimit-label"
                id="pauseTimeLimit"
                name={'pauseTimeLimit'}
                // multiple
                value={pauseTimeLimit}
                onChange={(e) => setPauseTimeLimit(e.target.value)}
                renderValue={(selected) => `${selected / 1000}s`}
                MenuProps={MenuProps}
                size="small"
                disabled={recording}
              >
                <MenuItem value={500}>0.5s</MenuItem>
                <MenuItem value={750}>0.75s</MenuItem>
                <MenuItem value={1000}>1s</MenuItem>
                <MenuItem value={1250}>1.25s</MenuItem>
                <MenuItem value={1500}>1.50s</MenuItem>
                <MenuItem value={2000}>2s</MenuItem>
                <MenuItem value={2500}>2.50s</MenuItem>
                <MenuItem value={3000}>3s</MenuItem>
                <MenuItem value={4000}>4s</MenuItem>
                <MenuItem value={5000}>5s</MenuItem>
              </Select>
            </Box>
          ) : null}
        </Box>
      ) : null}
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
            sm: 'start',
            md:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
          }}
          justifyContent={{
            xs:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'start',
            sm: 'start',
            md:
              (currentTab === 'create' || fetching) &&
              viewType !== 'conversation'
                ? 'center'
                : 'flex-start',
          }}
          height={1}
          gap={1}
          sx={{
            borderRight: '1px solid #f1f1f1',
            minHeight: 'calc(100vh - 130px)',
            padding: uId ? 0 : 2,
            paddingTop: uId ? 0 : viewType === 'report' ? 0 : 2,
          }}
          position={'relative'}
        >
          {viewType === 'list' && currentTab === 'create' ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                gap: 2,
              }}
            >
              {userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
              userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
              userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
                <Button
                  startIcon={<ArrowBack />}
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    handleTrackEvent(e, `go_to_previous_conversations`);
                    setViewType('list');
                    setCurrentTab('list');
                    setCurrentConversation(null);
                    setShowEmptySpeech(false);
                    history.push('conversation-ai');
                  }}
                >
                  Previous Records
                </Button>
              ) : null}
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: 2,
                  minWidth: '50%',
                  minHeight: '60vh',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                  },
                }}
              >
                <Typography variant="subtitle2" color={'text.secondary'}>
                  Title *
                </Typography>
                <TextField
                  autoFocus
                  placeholder="Title*"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="title"
                  value={conversationTitle}
                  onChange={(e) => setConversationTitle(e.target.value)}
                  fullWidth
                />
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    AI Persona
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={botId}
                    onChange={(e) => {
                      handleTrackEvent(e, `selected_bot_change`);
                      setBotId(e.target.value);
                    }}
                  >
                    {[...(publicBots || []), ...(privateBots || [])].length >
                    1 ? (
                      <FormControlLabel
                        value={'surprise'}
                        control={<Radio />}
                        label={
                          <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            sx={{
                              border: '1px solid #d3d3d3',
                              borderRadius: 1,
                              padding: 1,
                              minWidth: 160,
                              maxWidth: 160,
                              minHeight: 80,
                            }}
                            p={1}
                          >
                            <Typography variant="body2">Surprise Me</Typography>
                            <Typography
                              variant="caption"
                              color={'text.secondary'}
                              sx={{}}
                            >
                              Picked automatically from available bots
                            </Typography>
                          </Box>
                        }
                      />
                    ) : null}
                    {publicBots && publicBots?.length > 0
                      ? publicBots?.map((pb) => (
                          <FormControlLabel
                            key={pb?.id}
                            value={pb?.id}
                            control={<Radio />}
                            label={
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                sx={{
                                  border: '1px solid #d3d3d3',
                                  borderRadius: 1,
                                  padding: 1,
                                  minWidth: 160,
                                  maxWidth: 160,
                                  minHeight: 80,
                                }}
                                p={1}
                              >
                                <Typography variant="body2">
                                  {pb?.title}
                                </Typography>
                                {pb?.description ? (
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    sx={{}}
                                  >
                                    {pb?.description}
                                  </Typography>
                                ) : null}
                              </Box>
                            }
                          />
                        ))
                      : null}
                    {privateBots && privateBots?.length > 0
                      ? privateBots?.map((pb) => (
                          <FormControlLabel
                            key={pb?.id}
                            value={pb?.id}
                            control={<Radio />}
                            label={
                              <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                sx={{
                                  border: '1px solid #d3d3d3',
                                  borderRadius: 1,
                                  padding: 1,
                                  minWidth: 160,
                                  maxWidth: 160,
                                  minHeight: 80,
                                }}
                                p={1}
                              >
                                <Typography variant="body2">
                                  {pb?.title}
                                </Typography>
                                {pb?.description ? (
                                  <Typography
                                    variant="caption"
                                    color={'text.secondary'}
                                    sx={{}}
                                  >
                                    {pb?.description}
                                  </Typography>
                                ) : null}
                              </Box>
                            }
                          />
                        ))
                      : null}
                  </RadioGroup>
                </FormControl>
                {teamId ? (
                  loggedInUserTeamDetails?.botVerticals &&
                  loggedInUserTeamDetails?.botVerticals?.length > 0 ? (
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Pick a vertical
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={vertical}
                        onChange={(e) => {
                          handleTrackEvent(e, `selected_vertical_change`);
                          setVertical(e.target.value);
                        }}
                      >
                        {loggedInUserTeamDetails?.botVerticals &&
                        loggedInUserTeamDetails?.botVerticals?.length > 1 ? (
                          <FormControlLabel
                            value={'surprise'}
                            control={<Radio />}
                            label={'Surprise Me'}
                          />
                        ) : null}
                        {loggedInUserTeamDetails?.botVerticals &&
                        loggedInUserTeamDetails?.botVerticals?.length > 0
                          ? loggedInUserTeamDetails?.botVerticals?.map(
                              (vertical, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={vertical}
                                  control={<Radio />}
                                  label={vertical}
                                />
                              ),
                            )
                          : null}
                      </RadioGroup>
                    </FormControl>
                  ) : null
                ) : orgConfigs?.botVerticals &&
                  orgConfigs?.botVerticals?.length > 0 ? (
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Pick a vertical
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={vertical}
                      onChange={(e) => {
                        handleTrackEvent(e, `selected_vertical_change`);
                        setVertical(e.target.value);
                      }}
                    >
                      {orgConfigs?.botVerticals &&
                      orgConfigs?.botVerticals?.length > 1 ? (
                        <FormControlLabel
                          value={'surprise'}
                          control={<Radio />}
                          label={'Surprise Me'}
                        />
                      ) : null}
                      {orgConfigs?.botVerticals &&
                      orgConfigs?.botVerticals?.length > 0
                        ? orgConfigs?.botVerticals?.map((vertical, index) => (
                            <FormControlLabel
                              key={index}
                              value={vertical}
                              control={<Radio />}
                              label={vertical}
                            />
                          ))
                        : null}
                    </RadioGroup>
                  </FormControl>
                ) : null}
                {teamId ? (
                  loggedInUserTeamDetails?.botDesignations &&
                  loggedInUserTeamDetails?.botDesignations?.length > 0 ? (
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Pick a designation
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={designation}
                        onChange={(e) => {
                          handleTrackEvent(e, `selected_designation_change`);
                          setDesignation(e.target.value);
                        }}
                      >
                        {loggedInUserTeamDetails?.botDesignations &&
                        loggedInUserTeamDetails?.botDesignations?.length > 1 ? (
                          <FormControlLabel
                            value={'surprise'}
                            control={<Radio />}
                            label={'Surprise Me'}
                          />
                        ) : null}

                        {loggedInUserTeamDetails?.botDesignations &&
                        loggedInUserTeamDetails?.botDesignations?.length > 0
                          ? loggedInUserTeamDetails?.botDesignations?.map(
                              (designation, index) => (
                                <FormControlLabel
                                  key={index}
                                  value={designation}
                                  control={<Radio />}
                                  label={designation}
                                />
                              ),
                            )
                          : null}
                      </RadioGroup>
                    </FormControl>
                  ) : null
                ) : orgConfigs?.botDesignations &&
                  orgConfigs?.botDesignations?.length > 0 ? (
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Pick a designation
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={designation}
                      onChange={(e) => {
                        handleTrackEvent(e, `selected_designation_change`);
                        setDesignation(e.target.value);
                      }}
                    >
                      {orgConfigs?.botDesignations &&
                      orgConfigs?.botDesignations?.length > 1 ? (
                        <FormControlLabel
                          value={'surprise'}
                          control={<Radio />}
                          label={'Surprise Me'}
                        />
                      ) : null}

                      {orgConfigs?.botDesignations &&
                      orgConfigs?.botDesignations?.length > 0
                        ? orgConfigs?.botDesignations?.map(
                            (designation, index) => (
                              <FormControlLabel
                                key={index}
                                value={designation}
                                control={<Radio />}
                                label={designation}
                              />
                            ),
                          )
                        : null}
                    </RadioGroup>
                  </FormControl>
                ) : null}
                {fieldsRequired && fieldsRequired?.length > 0 ? (
                  <Box>
                    <Typography
                      variant="body1"
                      style={{ color: theme.palette.error.main }}
                    >
                      This bot requires additional prospect details :{' '}
                      {fieldsRequired?.join(', ')}
                    </Typography>
                    <Button
                      onClick={() => history.push('/org/settings?t=playbook')}
                    >
                      Go to playbook page
                    </Button>
                  </Box>
                ) : null}
                {orgConfigs?.showPlaybookToManagersOnly ? null : (
                  <FormControl fullWidth>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <Box display={'flex'} alignItems={'center'} gap={2}>
                        <Typography color={'text.secondary'}>
                          Pick a playbook
                        </Typography>
                        {fetchingPlaybooks ? (
                          <CircularProgress size={16} />
                        ) : null}
                      </Box>
                    </FormLabel>
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Select
                        size="small"
                        labelId="playbook-select-label"
                        id="playbook-select"
                        name="selectedPlaybook"
                        disabled={fetchingPlaybooks}
                        MenuProps={{ disableScrollLock: true }}
                        onChange={(e) => setSelectedPlaybook(e.target.value)}
                        value={selectedPlaybook}
                        sx={{
                          mt: 1,
                        }}
                      >
                        {showFetchingMenuItem ? (
                          <MenuItem value={'loading'}>
                            <Typography>Fetching Playbooks ... </Typography>
                          </MenuItem>
                        ) : null}
                        <MenuItem value={'select'}>
                          <Typography>
                            Select from available playbooks
                          </Typography>
                        </MenuItem>
                        {playbooks && playbooks?.length > 0
                          ? playbooks?.map((pb) => (
                              <MenuItem key={pb?.id} value={pb?.id}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    alignItems: 'center',
                                  }}
                                >
                                  <Typography>
                                    {pb?.tags?.join(', ')}{' '}
                                  </Typography>
                                  <Typography>
                                    {moment(pb?.createdOn).format(
                                      'DD MMM YYYY',
                                    )}
                                    {pb?.selected ? '(Default)' : ''}
                                  </Typography>
                                </Box>
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      <Tooltip title="show playbook" placement="top" arrow>
                        <Article
                          sx={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                            marginRight: 2,
                          }}
                          onClick={(e) => {
                            if (!fetchingPlaybooks) {
                              e.stopPropagation();
                              handleTrackEvent(e, 'open_playbook_dialog');
                              setShowPlaybook((prev) => !prev);
                            }
                          }}
                        />
                      </Tooltip>
                    </Box>
                    <Drawer
                      open={showPlaybook}
                      anchor="right"
                      onClose={() => setShowPlaybook(false)}
                    >
                      <Box
                        p={1}
                        sx={{
                          borderBottom: '1px solid #d3d3d3',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton onClick={() => setShowPlaybook(false)}>
                          <ChevronRight sx={{}} />
                        </IconButton>
                        <Typography variant="body2" color={'text.secondary'}>
                          Playbook
                        </Typography>
                      </Box>
                      <Box
                        flex={1}
                        padding={2}
                        sx={{
                          width: '40vw',
                          // border: '1px solid #d3d3d3',
                          borderRadius: 2,
                          minHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                          maxHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          gap: { xs: 1, sm: 2, md: 2 },
                          position: 'relative',
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: 'pre-wrap',
                            flex: 1,
                          }}
                        >
                          {parse(
                            playbooks?.find((p) => p?.id === selectedPlaybook)
                              ?.playbook || '',
                          )}
                        </Typography>
                      </Box>
                    </Drawer>
                  </FormControl>
                )}
                {userDetails?.custom?.orgType === 'demo_org' ? (
                  <Typography
                    variant="caption"
                    color={'error.main'}
                    sx={{ alignSelf: 'end' }}
                  >
                    {Object.values(
                      Object.values(
                        userDetails?.custom?.plan?.features || {},
                      )?.find((obj) => obj?.feature === 'practice_call')
                        ?.restrictions || {},
                    )?.find(
                      (obj) => obj?.restriction === 'number_of_practice_call',
                    )?.limit -
                      (userDetails?.custom?.userUsage?.currentUsage
                        ?.number_of_practice_call || 0)}{' '}
                    Practice call
                    {Object.values(
                      Object.values(
                        userDetails?.custom?.plan?.features || {},
                      )?.find((obj) => obj?.feature === 'practice_call')
                        ?.restrictions || {},
                    )?.find(
                      (obj) => obj?.restriction === 'number_of_practice_call',
                    )?.limit -
                      (userDetails?.custom?.userUsage?.currentUsage
                        ?.number_of_practice_call || 0) ===
                    1
                      ? ''
                      : 's'}{' '}
                    left
                  </Typography>
                ) : null}
                {userDetails?.custom?.plan?.price?.pricingScheme ===
                'usage_based' ? (
                  creditsRemaining <=
                  (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) /
                    100 ? (
                    <Alert severity="error">
                      {userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                      userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
                        ? `Your account is running low on credits with less than ${(userDetails
                            ?.custom?.plan?.price?.seatsOrCredits *
                            5) /
                            100} remaining. You can buy more credits from the Plans page for uninterrupted functionality.`
                        : `Your account is running low on credits with less than ${(userDetails
                            ?.custom?.plan?.price?.seatsOrCredits *
                            5) /
                            100} remaining. You can alert your account admin to add more credits for uninterrupted functionality.`}
                    </Alert>
                  ) : null
                ) : null}
                <Button
                  variant="contained"
                  size="small"
                  sx={{ alignSelf: 'end' }}
                  disabled={
                    connecting ||
                    !conversationTitle?.trim() ||
                    creatingConversation ||
                    fetchingPlaybooks
                  }
                  onClick={async (e) => {
                    if (
                      userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                      userDetails?.plan?.currentPaymentStatus === 'paid'
                    ) {
                      if (
                        userDetails?.custom?.plan?.price?.pricingScheme ===
                        'seat_based'
                      ) {
                        if (
                          userDetails?.custom?.userUsage?.currentUsage
                            ?.number_of_practice_call >=
                          Object.values(
                            Object.values(
                              userDetails?.custom?.plan?.features || {},
                            )?.find((obj) => obj?.feature === 'practice_call')
                              ?.restrictions || {},
                          )?.find(
                            (obj) =>
                              obj?.restriction === 'number_of_practice_call',
                          )?.limit
                        ) {
                          setShowLimitExhausted('conversation_exhausted');
                        } else {
                          handleTrackEvent(e, `create_conversation`);
                          const selectedBot = [
                            ...(publicBots || []),
                            ...(privateBots || []),
                          ]?.find((bot) => bot?.id === selectedBotId);
                          if (
                            selectedBot?.mandatoryFields &&
                            Array.isArray(selectedBot?.mandatoryFields) &&
                            selectedBot?.mandatoryFields?.length > 0 &&
                            ((selectedBot?.mandatoryFields?.find(
                              (field) => field === '<%>vertical<%>',
                            ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )) ||
                              (selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>designation<%>',
                              ) &&
                                !(
                                  orgConfigs?.botDesignations &&
                                  orgConfigs?.botDesignations?.length > 0
                                )) ||
                              (selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>competitors<%>',
                              ) &&
                                !(
                                  orgConfigs?.botCompetitors &&
                                  orgConfigs?.botCompetitors?.length > 0
                                )))
                          ) {
                            const requiredFields = [];
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>vertical<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('vertical');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'vertical',
                              );
                            }
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>designation<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('designation');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'designation',
                              );
                            }
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>competitors<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('competitors');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'competitors',
                              );
                            }
                            setFieldsRequired(requiredFields);
                          } else {
                            console.log(selectedBot);
                            if (selectedBot?.vapiEnabled) {
                              // console.log(selectedBot);
                              setViewType('conversation');
                              dispatch(getUserDetails(() => {}));
                              setCurrentConversation({
                                title: conversationTitle?.trim(),
                                status: null,
                                botId: selectedBot?.id,
                                customFields: {
                                  botTitle: selectedBot?.title,
                                },
                                vapiBotId: selectedBot?.vapiBotId,
                                vapiCallId: true,
                                playbookHistoryId: selectedPlaybook,
                                configPlaceHolders: Object.keys(
                                  selectedBot?.configPlaceHolders || {},
                                )
                                  ?.map((configKey) => {
                                    return {
                                      key: configKey,
                                      value:
                                        configKey === '<%>designation<%>'
                                          ? designation === 'surprise'
                                            ? orgConfigs?.botDesignations
                                                ?.length > 1
                                              ? orgConfigs?.botDesignations?.[
                                                  Math.floor(
                                                    Math.random() *
                                                      [
                                                        ...(orgConfigs?.botDesignations ||
                                                          []),
                                                      ].length,
                                                  )
                                                ]
                                              : orgConfigs?.botDesignations?.[0]
                                            : designation
                                          : configKey === '<%>vertical<%>'
                                          ? vertical === 'surprise'
                                            ? orgConfigs?.botVerticals?.length >
                                              1
                                              ? orgConfigs?.botVerticals?.[
                                                  Math.floor(
                                                    Math.random() *
                                                      [
                                                        ...(orgConfigs?.botVerticals ||
                                                          []),
                                                      ].length,
                                                  )
                                                ]
                                              : orgConfigs?.botVerticals?.[0]
                                            : vertical
                                          : null,
                                    };
                                  })
                                  ?.reduce((acc, curr) => {
                                    acc[curr.key] = curr.value;
                                    return acc;
                                  }, {}),
                              });
                            } else {
                              setCreatingConversation(true);
                              dispatch(
                                createNewConversation(
                                  {
                                    teamId: teamId || null,
                                    description: '',
                                    title: conversationTitle?.trim(),
                                    botId: selectedBotId,
                                    playbookHistoryId: selectedPlaybook,
                                    configPlaceHolders: Object.keys(
                                      selectedBot?.configPlaceHolders || {},
                                    )
                                      ?.map((configKey) => {
                                        return {
                                          key: configKey,
                                          value:
                                            configKey === '<%>designation<%>'
                                              ? designation === 'surprise'
                                                ? orgConfigs?.botDesignations
                                                    ?.length > 1
                                                  ? orgConfigs
                                                      ?.botDesignations?.[
                                                      Math.floor(
                                                        Math.random() *
                                                          [
                                                            ...(orgConfigs?.botDesignations ||
                                                              []),
                                                          ].length,
                                                      )
                                                    ]
                                                  : orgConfigs
                                                      ?.botDesignations?.[0]
                                                : designation
                                              : configKey === '<%>vertical<%>'
                                              ? vertical === 'surprise'
                                                ? orgConfigs?.botVerticals
                                                    ?.length > 1
                                                  ? orgConfigs?.botVerticals?.[
                                                      Math.floor(
                                                        Math.random() *
                                                          [
                                                            ...(orgConfigs?.botVerticals ||
                                                              []),
                                                          ].length,
                                                      )
                                                    ]
                                                  : orgConfigs
                                                      ?.botVerticals?.[0]
                                                : vertical
                                              : configKey ===
                                                '<%>competitors<%>'
                                              ? (playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                    ?.competingProducts) ||
                                                ''
                                              : configKey ===
                                                '<%>questions_list<%>'
                                              ? playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )?.playbookStructure
                                                ? playbooks
                                                    ?.find(
                                                      (pb) =>
                                                        pb?.id ===
                                                        selectedPlaybook,
                                                    )
                                                    ?.playbookStructure?.customerQuestions?.map(
                                                      (q, index) =>
                                                        `${index + 1}.${
                                                          q.question
                                                        }`,
                                                    )
                                                    ?.join('\n') || null
                                                : null
                                              : configKey ===
                                                '<%>customer_challenges<%>'
                                              ? process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'development' ||
                                                process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'local'
                                                ? playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                  ? playbooks
                                                      ?.find(
                                                        (pb) =>
                                                          pb?.id ===
                                                          selectedPlaybook,
                                                      )
                                                      ?.playbookStructure?.challengesForCustomer?.map(
                                                        (q, index) =>
                                                          `${index + 1}.${
                                                            q.question
                                                          }`,
                                                      )
                                                      ?.join('\n') || null
                                                  : null
                                                : null
                                              : null,
                                        };
                                      })
                                      ?.reduce((acc, curr) => {
                                        acc[curr.key] = curr.value;
                                        return acc;
                                      }, {}),
                                    configs: null,
                                  },
                                  (data, error) => {
                                    if (error) {
                                      setCreatingConversation(false);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    } else {
                                      dispatch(
                                        getConversationById(
                                          data,
                                          null,
                                          (data) => {
                                            setCurrentConversation(data);
                                          },
                                        ),
                                      );
                                      setCreatingConversation(false);
                                      setConversationTitle('');
                                      setGender('MALE');
                                      setViewType('conversation');
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setConversationStarted(false);
                                      dispatch(getUserDetails(() => {}));
                                    }
                                  },
                                ),
                              );
                            }
                          }
                        }
                      } else {
                        if (creditsRemaining > 0) {
                          handleTrackEvent(e, `create_conversation`);
                          const selectedBot = [
                            ...(publicBots || []),
                            ...(privateBots || []),
                          ]?.find((bot) => bot?.id === selectedBotId);
                          if (
                            selectedBot?.mandatoryFields &&
                            Array.isArray(selectedBot?.mandatoryFields) &&
                            selectedBot?.mandatoryFields?.length > 0 &&
                            ((selectedBot?.mandatoryFields?.find(
                              (field) => field === '<%>vertical<%>',
                            ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )) ||
                              (selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>designation<%>',
                              ) &&
                                !(
                                  orgConfigs?.botDesignations &&
                                  orgConfigs?.botDesignations?.length > 0
                                )) ||
                              (selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>competitors<%>',
                              ) &&
                                !(
                                  orgConfigs?.botCompetitors &&
                                  orgConfigs?.botCompetitors?.length > 0
                                )))
                          ) {
                            const requiredFields = [];
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>vertical<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('vertical');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'vertical',
                              );
                            }
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>designation<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('designation');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'designation',
                              );
                            }
                            if (
                              selectedBot?.mandatoryFields?.find(
                                (field) => field === '<%>competitors<%>',
                              ) &&
                              !(
                                orgConfigs?.botVerticals &&
                                orgConfigs?.botVerticals?.length > 0
                              )
                            ) {
                              requiredFields.push('competitors');
                            } else {
                              requiredFields.filter(
                                (field) => field === 'competitors',
                              );
                            }
                            setFieldsRequired(requiredFields);
                          } else {
                            console.log(selectedBot);
                            if (selectedBot?.vapiEnabled) {
                              // console.log(selectedBot);
                              setViewType('conversation');
                              dispatch(getUserDetails(() => {}));
                              setCurrentConversation({
                                title: conversationTitle?.trim(),
                                status: null,
                                botId: selectedBot?.id,
                                customFields: {
                                  botTitle: selectedBot?.title,
                                },
                                vapiBotId: selectedBot?.vapiBotId,
                                vapiCallId: true,
                                playbookHistoryId: selectedPlaybook,
                                configPlaceHolders: Object.keys(
                                  selectedBot?.configPlaceHolders || {},
                                )
                                  ?.map((configKey) => {
                                    return {
                                      key: configKey,
                                      value:
                                        configKey === '<%>designation<%>'
                                          ? designation === 'surprise'
                                            ? orgConfigs?.botDesignations
                                                ?.length > 1
                                              ? orgConfigs?.botDesignations?.[
                                                  Math.floor(
                                                    Math.random() *
                                                      [
                                                        ...(orgConfigs?.botDesignations ||
                                                          []),
                                                      ].length,
                                                  )
                                                ]
                                              : orgConfigs?.botDesignations?.[0]
                                            : designation
                                          : configKey === '<%>vertical<%>'
                                          ? vertical === 'surprise'
                                            ? orgConfigs?.botVerticals?.length >
                                              1
                                              ? orgConfigs?.botVerticals?.[
                                                  Math.floor(
                                                    Math.random() *
                                                      [
                                                        ...(orgConfigs?.botVerticals ||
                                                          []),
                                                      ].length,
                                                  )
                                                ]
                                              : orgConfigs?.botVerticals?.[0]
                                            : vertical
                                          : null,
                                    };
                                  })
                                  ?.reduce((acc, curr) => {
                                    acc[curr.key] = curr.value;
                                    return acc;
                                  }, {}),
                              });
                            } else {
                              setCreatingConversation(true);
                              dispatch(
                                createNewConversation(
                                  {
                                    teamId: teamId || null,
                                    description: '',
                                    title: conversationTitle?.trim(),
                                    botId: selectedBotId,
                                    playbookHistoryId: selectedPlaybook,
                                    configPlaceHolders: Object.keys(
                                      selectedBot?.configPlaceHolders || {},
                                    )
                                      ?.map((configKey) => {
                                        return {
                                          key: configKey,
                                          value:
                                            configKey === '<%>designation<%>'
                                              ? designation === 'surprise'
                                                ? orgConfigs?.botDesignations
                                                    ?.length > 1
                                                  ? orgConfigs
                                                      ?.botDesignations?.[
                                                      Math.floor(
                                                        Math.random() *
                                                          [
                                                            ...(orgConfigs?.botDesignations ||
                                                              []),
                                                          ].length,
                                                      )
                                                    ]
                                                  : orgConfigs
                                                      ?.botDesignations?.[0]
                                                : designation
                                              : configKey === '<%>vertical<%>'
                                              ? vertical === 'surprise'
                                                ? orgConfigs?.botVerticals
                                                    ?.length > 1
                                                  ? orgConfigs?.botVerticals?.[
                                                      Math.floor(
                                                        Math.random() *
                                                          [
                                                            ...(orgConfigs?.botVerticals ||
                                                              []),
                                                          ].length,
                                                      )
                                                    ]
                                                  : orgConfigs
                                                      ?.botVerticals?.[0]
                                                : vertical
                                              : configKey ===
                                                '<%>competitors<%>'
                                              ? (playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                    ?.competingProducts) ||
                                                ''
                                              : configKey ===
                                                '<%>questions_list<%>'
                                              ? playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )?.playbookStructure
                                                ? playbooks
                                                    ?.find(
                                                      (pb) =>
                                                        pb?.id ===
                                                        selectedPlaybook,
                                                    )
                                                    ?.playbookStructure?.customerQuestions?.map(
                                                      (q, index) =>
                                                        `${index + 1}.${
                                                          q.question
                                                        }`,
                                                    )
                                                    ?.join('\n') || null
                                                : null
                                              : configKey ===
                                                '<%>customer_challenges<%>'
                                              ? process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'development' ||
                                                process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'local'
                                                ? playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                  ? playbooks
                                                      ?.find(
                                                        (pb) =>
                                                          pb?.id ===
                                                          selectedPlaybook,
                                                      )
                                                      ?.playbookStructure?.challengesForCustomer?.map(
                                                        (q, index) =>
                                                          `${index + 1}.${
                                                            q.question
                                                          }`,
                                                      )
                                                      ?.join('\n') || null
                                                  : null
                                                : null
                                              : null,
                                        };
                                      })
                                      ?.reduce((acc, curr) => {
                                        acc[curr.key] = curr.value;
                                        return acc;
                                      }, {}),
                                    configs: null,
                                  },
                                  (data, error) => {
                                    if (error) {
                                      setCreatingConversation(false);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    } else {
                                      dispatch(
                                        getConversationById(
                                          data,
                                          null,
                                          (data) => {
                                            setCurrentConversation(data);
                                          },
                                        ),
                                      );
                                      setCreatingConversation(false);
                                      setConversationTitle('');
                                      setGender('MALE');
                                      setViewType('conversation');
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setConversationStarted(false);
                                      dispatch(getUserDetails(() => {}));
                                    }
                                  },
                                ),
                              );
                            }
                          }
                        } else {
                          setShowInsufficientCredits(true);
                        }
                      }
                    } else {
                      dispatch({
                        type: 'UPDATE_UI_STATE',
                        payload: {
                          key: 'showPlanExpired',
                          value: true,
                        },
                      });
                    }
                  }}
                >
                  Create
                </Button>
              </Card>
            </Box>
          ) : viewType === 'list' && currentTab === 'list' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1} width={1}>
              {fetching ? (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                >
                  <CircularProgress size={20} />
                  <Typography variant="body1" color={'text.primary'} mt={4}>
                    Loading ...
                  </Typography>
                </Box>
              ) : conversations && conversations?.length > 0 ? (
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                  {isXs ||
                  userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                  userDetails?.teamRoles?.[0] === 'STANDARD' ||
                  userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                  userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ marginLeft: 'auto' }}
                      onClick={() => history.push(`conversation-ai?new=true`)}
                    >
                      Create New
                    </Button>
                  ) : null}
                  <TableContainer
                    component={Paper}
                    sx={{
                      minHeight: uId
                        ? 'calc(100vh - 110px)'
                        : isXs ||
                          userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                          userDetails?.teamRoles?.[0] === 'STANDARD' ||
                          userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                          userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
                        ? 'calc(100vh - 140px)'
                        : 'calc(100vh - 110px)',
                      maxHeight: uId
                        ? 'calc(100vh - 110px)'
                        : isXs ||
                          userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                          userDetails?.teamRoles?.[0] === 'STANDARD' ||
                          userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                          userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN'
                        ? 'calc(100vh - 140px)'
                        : 'calc(100vh - 110px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                    }}
                    onScroll={handleListScroll}
                  >
                    <Table
                      sx={{ minWidth: 750 }}
                      stickyHeader
                      aria-label="simple table"
                    >
                      <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                          <TableCell>
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Title
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Date
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Media
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              AI Persona
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Designation
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                            >
                              Vertical
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Playbook
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography
                              variant={'caption'}
                              fontWeight={700}
                              sx={{ textTransform: 'uppercase' }}
                              align="center"
                            >
                              Actions
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {conversations.map((cs, i) => (
                          <TableRow
                            key={i}
                            // sx={{
                            //   '&:last-child td, &:last-child th': {
                            //     border: 0,
                            //   },
                            // }}
                          >
                            <TableCell component="th" scope="row">
                              <Typography
                                variant={'subtitle2'}
                                fontWeight={700}
                              >
                                {cs?.title}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                // fontWeight={700}
                                // sx={{
                                //   color: theme.palette.success.main,
                                // }}
                              >
                                {moment(cs?.createdOn).format('DD MMMM YYYY')}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              {cs?.customFields?.audioLink &&
                              cs?.mergedAudioStatus === 'completed' ? (
                                <Box
                                  display={'flex'}
                                  alignItems={'center'}
                                  justifyContent={'center'}
                                  gap={1}
                                  sx={{
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => setShowPreviewSalesCall(cs)}
                                >
                                  <Typography variant={'subtitle2'}>
                                    {cs?.duration
                                      ? formatSeconds(
                                          Math.round(cs?.duration / 1000000),
                                        )
                                      : '-'}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      background: '#d3d3d3',
                                      padding: '2px',
                                    }}
                                    onClick={() => setShowPreviewSalesCall(cs)}
                                  >
                                    <PlayArrow
                                      sx={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              ) : cs?.status === 'completed' ? (
                                <Typography>-</Typography>
                              ) : !uId ? (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  title={'Continue'}
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      handleTrackEvent(
                                        e,
                                        `start_conversation_from_list`,
                                      );
                                      setConversationStarted(false);
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setCurrentConversation(cs);
                                      setViewType('conversation');
                                    }}
                                  >
                                    <RecordVoiceOverOutlined />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Typography>-</Typography>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                // color={'text.secondary'}
                                variant={'subtitle2'}
                              >
                                {cs?.customFields?.botTitle || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                // color={'text.secondary'}
                                variant={'subtitle2'}
                              >
                                {cs?.configs?.DESIGNATION?.[
                                  '<%>designation<%>'
                                ] ||
                                  cs?.configPlaceHolders?.[
                                    '<%>designation<%>'
                                  ] ||
                                  '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                // color={'text.secondary'}
                                variant={'subtitle2'}
                              >
                                {cs?.configs?.VERTICAL?.['<%>vertical<%>'] ||
                                  cs?.configPlaceHolders?.['<%>vertical<%>'] ||
                                  '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography
                                variant={'caption'}
                                // fontWeight={700}
                                // sx={{
                                //   color: theme.palette.success.main,
                                // }}
                              >
                                {playbooks
                                  ?.find(
                                    (pb) => pb?.id === cs?.playbookHistoryId,
                                  )
                                  ?.tags?.join(',') ||
                                  cs?.playbookTitle ||
                                  '-'}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                gap={1}
                              >
                                {!uId ? (
                                  <Tooltip
                                    title={
                                      cs?.analysisStatus === 'in_process' ||
                                      analysisInProgress.includes(cs?.id)
                                        ? 'Analysis is in progress'
                                        : cs?.analysisStatus === 'completed'
                                        ? 'Analyze Again'
                                        : cs?.analysisStatus === 'failed'
                                        ? `Failed to analyse call.${
                                            uId ? '' : ' Please analyse again!'
                                          }`
                                        : cs?.analysisStatus === null
                                        ? `Analyze`
                                        : '-'
                                    }
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        handleTrackEvent(
                                          e,
                                          `open_analyze_conversation_dialog`,
                                        );
                                        setShowReAnalyzeDrawer(cs?.id);
                                      }}
                                    >
                                      {analysisInProgress.includes(cs?.id) ||
                                      cs?.analysisStatus === 'in_process' ? (
                                        <Cached
                                          sx={{
                                            color: theme.palette.secondary.main,
                                          }}
                                        />
                                      ) : cs?.analysisStatus === 'failed' ? (
                                        <WarningAmber
                                          sx={{
                                            color: theme.palette.secondary.main,
                                          }}
                                        />
                                      ) : (
                                        <Troubleshoot color="primary" />
                                      )}
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                                <Tooltip
                                  title={
                                    cs?.analysis
                                      ? 'View Report'
                                      : 'Report is not available'
                                  }
                                  placement="top"
                                  arrow
                                >
                                  <IconButton
                                    onClick={(e) => {
                                      if (cs?.analysis) {
                                        handleTrackEvent(
                                          e,
                                          `open_analysis_report`,
                                        );
                                        console.log('view report');
                                        setViewType('report');
                                        setSalesReview(cs);
                                        if (uId) {
                                          dispatch({
                                            type: 'UPDATE_UI_STATE',
                                            payload: {
                                              key: 'currentNavigationLevel',
                                              value: currentNavigationLevel + 1,
                                            },
                                          });
                                        }
                                      } else {
                                        return;
                                      }
                                    }}
                                  >
                                    <Article
                                      color={
                                        cs?.analysis ? 'primary' : 'default'
                                      }
                                    />
                                  </IconButton>
                                </Tooltip>
                                {!uId ? (
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={'Delete Conversation'}
                                  >
                                    <IconButton
                                      onClick={(e) => {
                                        handleTrackEvent(
                                          e,
                                          `open_confirm_delete_conversation_dialog`,
                                        );

                                        setShowConfirmDeleteDialog(cs?.id);
                                      }}
                                    >
                                      <Delete
                                        sx={{
                                          color: '#a3a3a3',
                                          fontSize: 20,
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : null}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TableCell
                            variant="footer"
                            colSpan={8}
                            sx={{
                              padding: 0,
                            }}
                          >
                            {fetchingPagination ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  color="primary"
                                  onClick={handleListScroll}
                                  // className="searchSettings"
                                  disabled
                                  // fullWidth
                                >
                                  <Typography color="textPrimary">
                                    Loading more ...{' '}
                                  </Typography>
                                  <CircularProgress size={20} />
                                </Button>
                              </Box>
                            ) : emptyList === false &&
                              conversations.length >= 10 ? (
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  padding: 1,
                                  textAlign: 'center',
                                }}
                              >
                                <Button
                                  size="small"
                                  variant="contained"
                                  // type="submit"
                                  // fullWidth
                                  color="primary"
                                  onClick={(e) => handleListScroll(e, true)}
                                  // className="searchSettings"
                                >
                                  Load More
                                </Button>
                              </Box>
                            ) : null}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                  {/* <Card
                    key={ar?.id}
                    sx={{ width: 1, height: 'auto', padding: 2 }}
                  ></Card>
                  {conversations.map((ar) => (
                    <Card
                      key={ar?.id}
                      sx={{ width: 1, height: 'auto', padding: 2 }}
                    >
                      <Typography>{ar.title}</Typography>
                    </Card>
                  ))} */}
                </Box>
              ) : (
                <Box
                  width={1}
                  height={1}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  flexDirection={'column'}
                  gap={2}
                >
                  <Typography variant="body1" color={'text.secondary'} mt={4}>
                    No records to show here yet.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Add />}
                    // sx={{ marginLeft: 'auto' }}
                    onClick={() => history.push(`conversation-ai?new=true`)}
                  >
                    Create New
                  </Button>
                </Box>
              )}
            </Box>
          ) : viewType === 'conversation' ? (
            <Box
              p={1}
              width={1}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
              position={'relative'}
            >
              <Box display={'flex'} justifyContent={'center'} p={1}>
                <Typography variant="body1" fontWeight={'bold'}>
                  {currentConversation?.title}
                </Typography>
              </Box>
              {userDetails?.custom?.plan?.price?.pricingScheme ===
              'usage_based' ? (
                ((vapiEnabled && !connected) ||
                  (!vapiEnabled && !conversationStarted)) &&
                currentConversation?.status !== 'completed' &&
                creditsRemaining <=
                  (userDetails?.custom?.plan?.price?.seatsOrCredits * 5) /
                    100 ? (
                  <Alert severity="info">
                    You have only {creditsRemaining} credits remaining. You will
                    be allowed to have a conversation for{' '}
                    {Math.floor(creditsRemaining * 2)} minutes only. Consider
                    buying more credits or upgrade your plan if you are
                    anticipating it to be more than{' '}
                    {Math.floor(creditsRemaining * 2)} minutes.
                  </Alert>
                ) : ((vapiEnabled && connected) ||
                    (!vapiEnabled && conversationStarted)) &&
                  showTimeRemaining ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="caption"
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      Remaining Time
                    </Typography>
                    <Typography
                      variant="caption"
                      color={'error'}
                      fontWeight={'bold'}
                    >
                      {convertToTimerFormat(timeRemaining)}
                    </Typography>
                  </Box>
                ) : null
              ) : null}
              {showEmptySpeech &&
              currentConversation?.status !== 'completed' ? (
                <Alert severity="info">
                  Please speak something to get the response
                </Alert>
              ) : null}
              {(vapiEnabled &&
                !connected &&
                currentConversation?.status !== 'completed') ||
              (!vapiEnabled &&
                !conversationStarted &&
                currentConversation?.status === null) ? (
                <Alert severity="info">Click start to get started</Alert>
              ) : null}
              {(orgConfigs?.showPlaybookToManagersOnly &&
                (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                  userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
              !orgConfigs?.showPlaybookToManagersOnly ? (
                <Box display={'flex'} justifyContent={'flex-end'} gap={1}>
                  {playbooks
                    ?.find((p) => p?.id === selectedPlaybook)
                    ?.tags?.join(',') ? (
                    <Typography
                      variant="caption"
                      color={'text.secondary'}
                      fontWeight={'bold'}
                    >
                      {playbooks
                        ?.find((p) => p?.id === selectedPlaybook)
                        .tags?.join(',')}
                    </Typography>
                  ) : null}
                  <Tooltip title="show playbook" placement="top" arrow>
                    <Article
                      sx={{
                        cursor: 'pointer',
                        color: theme.palette.primary.main,
                        marginRight: 2,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTrackEvent(e, 'open_playbook_dialog');
                        setShowPlaybook((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                </Box>
              ) : null}
              <Box
                display={'flex'}
                gap={2}
                position={'relative'}
                flexDirection={{ xs: 'column', sm: 'row', md: 'row' }}
              >
                <Box
                  flex={1}
                  sx={{
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    minHeight: isXs ? 'auto' : 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: { xs: 1, sm: 2, md: 2 },
                    position: 'relative',
                  }}
                >
                  <Typography
                    color={'text.secondary'}
                    variant="subtitle2"
                    sx={{
                      visibility: vapiEnabled
                        ? connected && !assistantIsSpeaking && !vapi.isMuted()
                          ? 'visible'
                          : 'hidden'
                        : recording
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    You are speaking
                    {Array.from({ length: numberOfDots }, (_, id) => id).map(
                      (item) => (
                        <span key={item}>.</span>
                      ),
                    )}
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant="subtitle2"
                    sx={{
                      visibility: vapiEnabled
                        ? assistantIsSpeaking
                          ? 'visible'
                          : 'hidden'
                        : playingResponse
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    You are listening
                    {Array.from({ length: numberOfAltDots }, (_, id) => id).map(
                      (item) => (
                        <span key={item}>.</span>
                      ),
                    )}
                  </Typography>
                  <Avatar
                    alt={userInfo?.firstName?.toUpperCase()}
                    src={userDetails?.profilePicture?.url}
                    sx={{
                      width: { xs: 60, sm: 70, md: 90 },
                      height: { xs: 60, sm: 70, md: 90 },
                      border: vapiEnabled
                        ? connected && !assistantIsSpeaking
                          ? `4px solid ${theme.palette.primary.main}`
                          : 'none'
                        : recording
                        ? `4px solid ${theme.palette.primary.main}`
                        : 'none',
                    }}
                  >
                    <Typography color={'#fff'} sx={{ fontSize: 40 }}>
                      {userInfo?.firstName?.[0]?.toUpperCase()}
                    </Typography>
                  </Avatar>
                  <Typography
                    variant="body1"
                    color={'text.secondary'}
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: 15,
                      left: 20,
                    }}
                  >
                    You
                  </Typography>
                  <IconButton
                    size="small"
                    disabled={playingResponse || gettingResponse}
                    onClick={() => {
                      if (!(currentConversation?.status === 'completed')) {
                        if (vapiEnabled) {
                          if (connected) {
                            if (vapi.isMuted()) {
                              vapi.setMuted(false);
                            } else {
                              vapi.setMuted(true);
                            }
                          }
                        } else {
                          if (recording) {
                            handleRecordStopClick();
                          } else {
                            handleRecordClick();
                          }
                        }
                      }
                    }}
                  >
                    {recording || (connected && !vapi.isMuted()) ? (
                      <Tooltip title="Mute" arrow placement="top">
                        <Mic
                          fontSize="medium"
                          color={
                            playingResponse || gettingResponse
                              ? 'default'
                              : 'primary'
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={
                          currentConversation?.status === 'completed'
                            ? 'Conversation has ended'
                            : 'Unmute'
                        }
                        arrow
                        placement="top"
                      >
                        <MicOff
                          fontSize="medium"
                          color={
                            playingResponse ||
                            gettingResponse ||
                            currentConversation?.status === 'completed'
                              ? 'default'
                              : 'primary'
                          }
                        />
                      </Tooltip>
                    )}
                  </IconButton>
                  {(recording || connected) && (
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 10,
                        right: 15,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Circle sx={{ fontSize: 10 }} color="error" />
                      <Typography color={'text.secondary'} fontWeight={'bold'}>
                        {`0${timerMs} `}:
                        {timerSs > 9
                          ? timerSs < 0
                            ? '00'
                            : ` ${timerSs}`
                          : ` 0${timerSs}`}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Box
                  flex={1}
                  sx={{
                    border: '1px solid #d3d3d3',
                    borderRadius: 2,
                    minHeight: isXs ? 'auto' : 300,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: { xs: 1, sm: 2, md: 2 },
                    position: 'relative',
                  }}
                >
                  <Typography
                    color={'text.secondary'}
                    variant="subtitle2"
                    sx={{
                      visibility: vapiEnabled
                        ? connected && !assistantIsSpeaking && !vapi.isMuted()
                          ? 'visible'
                          : 'hidden'
                        : recording
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    Listening
                    {Array.from({ length: numberOfDots }, (_, id) => id).map(
                      (item) => (
                        <span key={item}>.</span>
                      ),
                    )}
                  </Typography>
                  <Typography
                    color={'text.secondary'}
                    variant="subtitle2"
                    sx={{
                      visibility: vapiEnabled
                        ? connected && assistantIsSpeaking
                          ? 'visible'
                          : 'hidden'
                        : playingResponse
                        ? 'visible'
                        : 'hidden',
                    }}
                  >
                    Speaking
                    {Array.from({ length: numberOfAltDots }, (_, id) => id).map(
                      (item) => (
                        <span key={item}>.</span>
                      ),
                    )}
                  </Typography>
                  <Box position={'relative'}>
                    <SupportAgent
                      sx={{
                        background: '#bdbdbd',
                        borderRadius: '50%',
                        fontSize: { xs: 60, sm: 70, md: 90 },
                        padding: 2,
                        color: '#fff',
                        border: vapiEnabled
                          ? connected && assistantIsSpeaking
                            ? `4px solid ${theme.palette.primary.main}`
                            : 'none'
                          : playingResponse
                          ? `4px solid ${theme.palette.primary.main}`
                          : 'none',
                      }}
                    />
                    {vapiEnabled ? null : gettingResponse ? (
                      <CircularProgress
                        size={isXs ? 60 : 90}
                        sx={{
                          background: 'transparent',
                          position: 'absolute',
                          top: 0,
                          left: 0,
                        }}
                      />
                    ) : null}
                  </Box>
                  {responseAudioUrl ? (
                    <audio
                      // controls
                      autoPlay={autoPlay && playingResponse}
                      preload={'true'}
                      ref={responseAudioRef}
                      src={responseAudioUrl}
                      style={{
                        maxWidth: '220px',
                        padding: '10px 20px',
                      }}
                      onLoadedMetadata={() => responseAudioRef.current.play()}
                      onEnded={() => {
                        // console.log('audio played');
                        if (autoStart && autoRecord && conversationStarted) {
                          handleRecordClick();
                        }
                        setPlayingResponse(false);
                        setResponseAudioUrl('');
                      }}
                      onError={(e) => {
                        console.log(e);
                        setPlayingResponse(false);
                        dispatch(
                          logError({
                            message: e?.message,
                            data: {
                              formatted: {
                                errorText: e?.message,
                                severity: 'error',
                              },
                              raw: e,
                            },
                          }),
                        );
                      }}
                    />
                  ) : null}
                  <Typography
                    variant="body1"
                    color={'text.secondary'}
                    sx={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      position: 'absolute',
                      top: { xs: 7, sm: 15, md: 15 },
                      left: { xs: 7, sm: 20, md: 20 },
                    }}
                  >
                    AI Persona{' '}
                    <span style={{ fontWeight: 500, fontSize: 14 }}>
                      ({currentConversation?.customFields?.botTitle || ''}
                    </span>
                    {currentConversation?.configPlaceHolders ? (
                      <span style={{ fontWeight: 500, fontSize: 14 }}>
                        {currentConversation?.configPlaceHolders?.[
                          '<%>designation<%>'
                        ]
                          ? ` - works as 
                        ${currentConversation?.configPlaceHolders?.['<%>designation<%>']}`
                          : null}
                        {currentConversation?.configPlaceHolders?.[
                          '<%>vertical<%>'
                        ]
                          ? ` in 
                        ${currentConversation?.configPlaceHolders?.['<%>vertical<%>']}
                        vertical`
                          : null}
                      </span>
                    ) : null}
                    <span style={{ fontWeight: 500, fontSize: 14 }}>)</span>
                  </Typography>
                  <IconButton
                    size="small"
                    disabled={recording || gettingResponse}
                    sx={{ visibility: 'hidden' }}
                    onClick={() => {
                      if (playingResponse) {
                        // handlePlayResponse();
                        responseAudioRef?.current?.pause();
                        setPlayingResponse(false);
                      } else {
                        // handleStopResponsePlayback();
                        if (responseAudioUrl && responseAudioRef?.current) {
                          responseAudioRef?.current?.play();
                          setPlayingResponse(true);
                        }
                      }
                    }}
                  >
                    {playingResponse ? (
                      <Tooltip title="Pause Playback" arrow placement="top">
                        <VolumeUp fontSize="medium" color="primary" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Play Response" arrow placement="top">
                        <PlayArrow
                          fontSize="medium"
                          color={responseAudioUrl ? 'primary' : 'default'}
                        />
                      </Tooltip>
                    )}
                  </IconButton>
                </Box>
                <Drawer
                  open={showPlaybook}
                  anchor="right"
                  onClose={() => setShowPlaybook(false)}
                >
                  <Box
                    p={1}
                    sx={{
                      borderBottom: '1px solid #d3d3d3',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IconButton onClick={() => setShowPlaybook(false)}>
                      <ChevronRight sx={{}} />
                    </IconButton>
                    <Typography variant="body2" color={'text.secondary'}>
                      Playbook
                    </Typography>
                  </Box>
                  <Box
                    flex={1}
                    padding={2}
                    sx={{
                      width: '40vw',
                      // border: '1px solid #d3d3d3',
                      borderRadius: 2,
                      minHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                      maxHeight: isXs ? 'auto' : 'calc(100vh - 70px)',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                        // outline: '1px solid slategrey',
                      },
                      gap: { xs: 1, sm: 2, md: 2 },
                      position: 'relative',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        flex: 1,
                      }}
                    >
                      {parse(
                        playbooks?.find((p) => p?.id === selectedPlaybook)
                          ?.playbook || '',
                      )}
                    </Typography>
                  </Box>
                </Drawer>
              </Box>
              <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                {currentConversation?.status !== 'completed' ? (
                  <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={1}
                  >
                    <Button
                      startIcon={
                        vapiEnabled ? (
                          connecting ? (
                            <CircularProgress size={20} />
                          ) : connected ? (
                            <CallEnd />
                          ) : (
                            <Call />
                          )
                        ) : conversationStarted ? (
                          <CallEnd />
                        ) : (
                          <Call />
                        )
                      }
                      variant="contained"
                      size="small"
                      color={
                        vapiEnabled
                          ? connected
                            ? 'error'
                            : 'primary'
                          : conversationStarted
                          ? 'error'
                          : 'primary'
                      }
                      disabled={
                        vapiEnabled
                          ? connecting
                            ? true
                            : false
                          : gettingResponse
                      }
                      onClick={async (e) => {
                        if (vapiEnabled) {
                          if (connected) {
                            await vapi.stop();
                            handleRecordStopClick(true);
                          } else {
                            setConnecting(true);
                            const vapiRes = await vapi.start(
                              currentConversation?.vapiBotId,
                              {
                                variableValues:
                                  (orgConfigs?.botVerticals &&
                                    orgConfigs?.botVerticals?.length > 0) ||
                                  (orgConfigs?.botDesignations &&
                                    orgConfigs?.botDesignations?.length > 0) ||
                                  (playbooks?.find(
                                    (pb) => pb?.id === selectedPlaybook,
                                  ) &&
                                    playbooks?.find(
                                      (pb) => pb?.id === selectedPlaybook,
                                    )?.playbookStructure &&
                                    playbooks?.find(
                                      (pb) => pb?.id === selectedPlaybook,
                                    )?.playbookStructure?.competingProducts)
                                    ? {
                                        gpt_name:
                                          currentConversation?.customFields
                                            ?.botTitle,
                                        vertical:
                                          currentConversation
                                            ?.configPlaceHolders?.[
                                            '<%>vertical<%>'
                                          ],
                                        designation:
                                          currentConversation
                                            ?.configPlaceHolders?.[
                                            '<%>designation<%>'
                                          ],
                                        competitors:
                                          (playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          ) &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure
                                              ?.competingProducts) ||
                                          '',
                                        questions_list:
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          ) &&
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          )?.playbookStructure
                                            ? playbooks
                                                ?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )
                                                ?.playbookStructure?.customerQuestions?.map(
                                                  (q, index) =>
                                                    `${index + 1}.${
                                                      q.question
                                                    }`,
                                                )
                                                ?.join('\n') || ''
                                            : '',
                                        customer_challenges:
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          ) &&
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          )?.playbookStructure
                                            ? playbooks
                                                ?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )
                                                ?.playbookStructure?.challengesForCustomer?.map(
                                                  (q, index) =>
                                                    `${index + 1}.${
                                                      q.question
                                                    }`,
                                                )
                                                ?.join('\n') || ''
                                            : '',
                                      }
                                    : {
                                        gpt_name:
                                          currentConversation?.customFields
                                            ?.botTitle,
                                        questions_list:
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          ) &&
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          )?.playbookStructure
                                            ? playbooks
                                                ?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )
                                                ?.playbookStructure?.customerQuestions?.map(
                                                  (q, index) =>
                                                    `${index + 1}.${
                                                      q.question
                                                    }`,
                                                )
                                                ?.join('\n') || ''
                                            : '',
                                        customer_challenges:
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          ) &&
                                          playbooks?.find(
                                            (pb) => pb?.id === selectedPlaybook,
                                          )?.playbookStructure
                                            ? playbooks
                                                ?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )
                                                ?.playbookStructure?.challengesForCustomer?.map(
                                                  (q, index) =>
                                                    `${index + 1}.${
                                                      q.question
                                                    }`,
                                                )
                                                ?.join('\n') || ''
                                            : '',
                                      },
                              },
                            );
                            if (vapiRes?.id && vapiRes?.webCallUrl) {
                              setCreatingConversation(true);
                              dispatch(
                                createNewConversation(
                                  {
                                    teamId: teamId || null,
                                    description: '',
                                    title: conversationTitle?.trim(),
                                    botId: currentConversation?.botId,
                                    vapiCallId: vapiRes?.id,
                                    webCallUrl: vapiRes?.webCallUrl,
                                    playbookHistoryId: selectedPlaybook,
                                    configPlaceHolders: Object.keys(
                                      currentConversation?.configPlaceHolders ||
                                        {},
                                    )
                                      ?.map((configKey) => {
                                        return {
                                          key: configKey,
                                          value:
                                            configKey === '<%>designation<%>'
                                              ? currentConversation
                                                  ?.configPlaceHolders?.[
                                                  '<%>designation<%>'
                                                ]
                                              : configKey === '<%>vertical<%>'
                                              ? currentConversation
                                                  ?.configPlaceHolders?.[
                                                  '<%>vertical<%>'
                                                ]
                                              : configKey ===
                                                '<%>competitors<%>'
                                              ? (playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                    ?.competingProducts) ||
                                                ''
                                              : configKey ===
                                                '<%>questions_list<%>'
                                              ? playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )?.playbookStructure
                                                ? playbooks
                                                    ?.find(
                                                      (pb) =>
                                                        pb?.id ===
                                                        selectedPlaybook,
                                                    )
                                                    ?.playbookStructure?.customerQuestions?.map(
                                                      (q, index) =>
                                                        `${index + 1}.${
                                                          q.question
                                                        }`,
                                                    )
                                                    ?.join('\n') || null
                                                : null
                                              : configKey ===
                                                '<%>customer_challenges<%>'
                                              ? process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'development' ||
                                                process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'local'
                                                ? playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                  ? playbooks
                                                      ?.find(
                                                        (pb) =>
                                                          pb?.id ===
                                                          selectedPlaybook,
                                                      )
                                                      ?.playbookStructure?.challengesForCustomer?.map(
                                                        (q, index) =>
                                                          `${index + 1}.${
                                                            q.question
                                                          }`,
                                                      )
                                                      ?.join('\n') || null
                                                  : null
                                                : null
                                              : configKey === '<%>gpt_name<%>'
                                              ? currentConversation
                                                  ?.customFields?.botTitle
                                              : null,
                                        };
                                      })
                                      ?.reduce((acc, curr) => {
                                        acc[curr.key] = curr.value;
                                        return acc;
                                      }, {}),
                                    configs: null,
                                  },
                                  (data, error) => {
                                    if (error) {
                                      setCreatingConversation(false);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    } else {
                                      dispatch(
                                        getConversationById(
                                          data,
                                          null,
                                          (data) => {
                                            setCurrentConversation(data);
                                          },
                                        ),
                                      );
                                      setCreatingConversation(false);
                                      setConversationTitle('');
                                      setGender('MALE');
                                      setViewType('conversation');
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setConversationStarted(false);
                                      dispatch(getUserDetails(() => {}));
                                    }
                                  },
                                ),
                              );
                            } else {
                              setConnecting(true);
                              const vapiRes = await vapi.start(
                                currentConversation?.vapiBotId,
                                {
                                  variableValues:
                                    (orgConfigs?.botVerticals &&
                                      orgConfigs?.botVerticals?.length > 0) ||
                                    (orgConfigs?.botDesignations &&
                                      orgConfigs?.botDesignations?.length >
                                        0) ||
                                    (playbooks?.find(
                                      (pb) => pb?.id === selectedPlaybook,
                                    ) &&
                                      playbooks?.find(
                                        (pb) => pb?.id === selectedPlaybook,
                                      )?.playbookStructure &&
                                      playbooks?.find(
                                        (pb) => pb?.id === selectedPlaybook,
                                      )?.playbookStructure?.competingProducts)
                                      ? {
                                          gpt_name:
                                            currentConversation?.customFields
                                              ?.botTitle,
                                          vertical:
                                            currentConversation
                                              ?.configPlaceHolders?.[
                                              '<%>vertical<%>'
                                            ],
                                          designation:
                                            currentConversation
                                              ?.configPlaceHolders?.[
                                              '<%>designation<%>'
                                            ],
                                          competitors:
                                            (playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            ) &&
                                              playbooks?.find(
                                                (pb) =>
                                                  pb?.id === selectedPlaybook,
                                              )?.playbookStructure &&
                                              playbooks?.find(
                                                (pb) =>
                                                  pb?.id === selectedPlaybook,
                                              )?.playbookStructure
                                                ?.competingProducts) ||
                                            '',
                                          questions_list:
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            ) &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure
                                              ? playbooks
                                                  ?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )
                                                  ?.playbookStructure?.customerQuestions?.map(
                                                    (q, index) =>
                                                      `${index + 1}.${
                                                        q.question
                                                      }`,
                                                  )
                                                  ?.join('\n') || ''
                                              : '',
                                          customer_challenges:
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            ) &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure
                                              ? playbooks
                                                  ?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )
                                                  ?.playbookStructure?.challengesForCustomer?.map(
                                                    (q, index) =>
                                                      `${index + 1}.${
                                                        q.question
                                                      }`,
                                                  )
                                                  ?.join('\n') || ''
                                              : '',
                                        }
                                      : {
                                          gpt_name:
                                            currentConversation?.customFields
                                              ?.botTitle,
                                          questions_list:
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            ) &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure
                                              ? playbooks
                                                  ?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )
                                                  ?.playbookStructure?.customerQuestions?.map(
                                                    (q, index) =>
                                                      `${index + 1}.${
                                                        q.question
                                                      }`,
                                                  )
                                                  ?.join('\n') || ''
                                              : '',
                                          customer_challenges:
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            ) &&
                                            playbooks?.find(
                                              (pb) =>
                                                pb?.id === selectedPlaybook,
                                            )?.playbookStructure
                                              ? playbooks
                                                  ?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )
                                                  ?.playbookStructure?.challengesForCustomer?.map(
                                                    (q, index) =>
                                                      `${index + 1}.${
                                                        q.question
                                                      }`,
                                                  )
                                                  ?.join('\n') || ''
                                              : '',
                                        },
                                },
                              );
                              setCreatingConversation(true);
                              dispatch(
                                createNewConversation(
                                  {
                                    teamId: teamId || null,
                                    description: '',
                                    title: conversationTitle?.trim(),
                                    botId: currentConversation?.botId,
                                    vapiCallId: vapiRes?.id,
                                    webCallUrl: vapiRes?.webCallUrl,
                                    playbookHistoryId: selectedPlaybook,
                                    configPlaceHolders: Object.keys(
                                      currentConversation?.configPlaceHolders ||
                                        {},
                                    )
                                      ?.map((configKey) => {
                                        return {
                                          key: configKey,
                                          value:
                                            configKey === '<%>designation<%>'
                                              ? currentConversation
                                                  ?.configPlaceHolders?.[
                                                  '<%>designation<%>'
                                                ]
                                              : configKey === '<%>vertical<%>'
                                              ? currentConversation
                                                  ?.configPlaceHolders?.[
                                                  '<%>vertical<%>'
                                                ]
                                              : configKey ===
                                                '<%>competitors<%>'
                                              ? (playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                    ?.competingProducts) ||
                                                ''
                                              : configKey ===
                                                '<%>questions_list<%>'
                                              ? playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                ) &&
                                                playbooks?.find(
                                                  (pb) =>
                                                    pb?.id === selectedPlaybook,
                                                )?.playbookStructure
                                                ? playbooks
                                                    ?.find(
                                                      (pb) =>
                                                        pb?.id ===
                                                        selectedPlaybook,
                                                    )
                                                    ?.playbookStructure?.customerQuestions?.map(
                                                      (q, index) =>
                                                        `${index + 1}.${
                                                          q.question
                                                        }`,
                                                    )
                                                    ?.join('\n') || null
                                                : null
                                              : configKey ===
                                                '<%>customer_challenges<%>'
                                              ? process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'development' ||
                                                process.env
                                                  .REACT_APP_TARGET_ENV ===
                                                  'local'
                                                ? playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  ) &&
                                                  playbooks?.find(
                                                    (pb) =>
                                                      pb?.id ===
                                                      selectedPlaybook,
                                                  )?.playbookStructure
                                                  ? playbooks
                                                      ?.find(
                                                        (pb) =>
                                                          pb?.id ===
                                                          selectedPlaybook,
                                                      )
                                                      ?.playbookStructure?.challengesForCustomer?.map(
                                                        (q, index) =>
                                                          `${index + 1}.${
                                                            q.question
                                                          }`,
                                                      )
                                                      ?.join('\n') || null
                                                  : null
                                                : null
                                              : configKey === '<%>gpt_name<%>'
                                              ? currentConversation
                                                  ?.customFields?.botTitle
                                              : null,
                                        };
                                      })
                                      ?.reduce((acc, curr) => {
                                        acc[curr.key] = curr.value;
                                        return acc;
                                      }, {}),
                                    configs: null,
                                  },
                                  (data, error) => {
                                    if (error) {
                                      setCreatingConversation(false);
                                      dispatch(
                                        showAlert({
                                          message:
                                            'Something went wrong. Please try again!',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    } else {
                                      dispatch(
                                        getConversationById(
                                          data,
                                          null,
                                          (data) => {
                                            setCurrentConversation(data);
                                          },
                                        ),
                                      );
                                      setCreatingConversation(false);
                                      setConversationTitle('');
                                      setGender('MALE');
                                      setViewType('conversation');
                                      setResponseAudioUrl('');
                                      setPlayingResponse(false);
                                      setConversationStarted(false);
                                      dispatch(getUserDetails(() => {}));
                                    }
                                  },
                                ),
                              );
                            }
                          }
                        } else {
                          if (conversationStarted) {
                            handleTrackEvent(e, `end_conversation`);
                            if (playingResponse) {
                              setPlayingResponse(false);
                              responseAudioRef?.current?.pause();
                            }
                            setConversationStarted(false);
                            handleRecordStopClick(true);
                          } else {
                            if (currentConversation?.status === 'in_process') {
                              handleTrackEvent(e, `continue_conversation`);
                            } else {
                              handleTrackEvent(e, `start_conversation`);
                            }
                            handleRecordClick();
                          }
                        }
                      }}
                    >
                      {vapiEnabled
                        ? connected
                          ? 'End Call'
                          : 'Start'
                        : conversationStarted
                        ? 'End Call'
                        : currentConversation?.status === 'in_process'
                        ? 'Continue'
                        : 'Start'}
                    </Button>
                    {currentConversation?.status === 'in_process' &&
                    !conversationStarted ? (
                      <Button
                        startIcon={<CallEnd />}
                        variant="contained"
                        size="small"
                        color={'error'}
                        // disabled={gettingResponse}
                        onClick={(e) => {
                          handleTrackEvent(e, `end_conversation`);
                          dispatch(
                            updateConversationStatus(
                              currentConversation?.id,
                              'completed',
                              () => {
                                setCurrentConversation((prev) => {
                                  return {
                                    ...prev,
                                    status: 'completed',
                                  };
                                });
                              },
                            ),
                          );
                        }}
                      >
                        End Call
                      </Button>
                    ) : null}
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Typography variant="body1" color={'text.secondary'}>
                      Conversation has ended
                    </Typography>
                    <Typography variant="body1" color={'text.secondary'}>
                      Do you want to analyze this call?
                    </Typography>
                    {userDetails?.custom?.orgType !== 'demo_org' ? (
                      <Box display={'flex'} alignItems={'center'} gap={1}>
                        <Button
                          // data-trackid={'CTA_sign_in'}
                          size={'small'}
                          variant="contained"
                          // fullWidth
                          startIcon={<Troubleshoot />}
                          onClick={(e) => {
                            // handleTrackEvent(e, 'CTA_sign_in');
                            setShowReAnalyzeDrawer(currentConversation?.id);
                          }}
                        >
                          Analyze
                        </Button>
                      </Box>
                    ) : null}
                    <Button
                      startIcon={<Undo />}
                      variant="outlined"
                      size="small"
                      color="primary"
                      onClick={(e) => {
                        handleTrackEvent(e, `go_to_previous_conversations`);
                        setViewType('list');
                        setCurrentTab('list');
                        setCurrentConversation(null);
                        setShowEmptySpeech(false);
                        history.push('conversation-ai');
                      }}
                    >
                      Previous Records
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          ) : viewType === 'report' ? (
            <Box width={1}>
              <ReportPage
                type="practiceCall"
                report={salesReview}
                setSalesReview={setSalesReview}
              />
            </Box>
          ) : null}
          {/* {viewType === 'conversation' ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 5,
                right: 10,
              }}
              display={'flex'}
              alignItems={'center'}
            >
              <Typography variant="caption" color={'text.secondary'}>
                Powered by
              </Typography>
              <img src="/eleven-labs.png" height={20} />
            </Box>
          ) : null} */}
        </Box>
      </Box>
      <Drawer
        open={Boolean(showReAnalyzeDrawer)}
        anchor="right"
        onClose={() => {
          setShowReAnalyzeDrawer(null);
          setShowReportAfterAnalysis(false);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              setShowReAnalyzeDrawer(null);
              setShowReportAfterAnalysis(false);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)?.title}
          </Typography>
        </Box>
        <Box
          sx={{
            width: '40vw',
            minHeight:
              analysisInProgress.includes(showReAnalyzeDrawer) ||
              conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
                ?.analysisStatus === 'in_process'
                ? 'calc(100vh - 180px)'
                : 'calc(100vh - 130px)',
            maxHeight:
              analysisInProgress.includes(showReAnalyzeDrawer) ||
              conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
                ?.analysisStatus === 'in_process'
                ? 'calc(100vh - 180px)'
                : 'calc(100vh - 130px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            justifyContent: 'space-between',
          }}
        >
          <Box p={2} display={'flex'} flexDirection={'column'} gap={1}>
            {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
              ?.transcript ? (
              <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box
                  sx={{
                    background: '#e3e3e3',
                    padding: 2,
                    borderTopLeftRadius: 3,
                    borderTopRightRadius: 3,
                  }}
                >
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Transcript
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    minHeight: '240px',
                    maxHeight: '240px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                  }}
                >
                  {conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
                    ?.transcript || 'Transcript is not available yet'}
                </Typography>
              </Box>
            ) : null}
            {userInfo?.orgRoles?.includes('ORG_ADMIN') ? (
              <FormControl
                component="fieldset"
                variant="standard"
                // sx={{ marginTop: 2 }}
              >
                <FormLabel component="legend" sx={{ fontSize: '14px' }}>
                  Methodologies
                </FormLabel>
                <FormGroup row>
                  {orgConfigs?.selectedMethodologies?.map((mthd) => (
                    <FormControlLabel
                      key={mthd}
                      checked={methods?.includes(mthd)}
                      control={
                        <Checkbox size="small" value={mthd} name="methods" />
                      }
                      label={
                        mthd === 'meddpic' ? 'MEDDICC' : mthd.toUpperCase()
                      }
                      onChange={(e) => handleChange(e)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            ) : (
              <>
                <Typography
                  variant={'body1'}
                  sx={{ marginTop: 1 }}
                  color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  Methodologies
                </Typography>
                <Typography
                  variant={'body1'}
                  // sx={{ marginBottom: 1 }}
                  // color={'text.secondary'}
                  // fontWeight={'bold'}
                >
                  {methods
                    ?.map((mthd) =>
                      mthd === 'meddpic' ? 'MEDDICC' : capitalizeText(mthd),
                    )
                    ?.join(', ')}
                </Typography>
              </>
            )}
            {(orgConfigs?.showPlaybookToManagersOnly &&
              (userInfo?.orgRoles?.includes('ORG_ADMIN') ||
                userInfo?.orgRoles?.includes('WL_ORG_ADMIN'))) ||
            !orgConfigs?.showPlaybookToManagersOnly ? (
              <>
                <Box>
                  <Typography variant="subtitle2" color={'text.secondary'}>
                    Select from available playbooks
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      size="small"
                      labelId="playbook-select-label"
                      id="playbook-select"
                      name="selectedPlaybook"
                      MenuProps={{ disableScrollLock: true }}
                      onChange={(e) => setSelectedPlaybook(e.target.value)}
                      value={selectedPlaybook}
                    >
                      <MenuItem value={'select'}>
                        <Typography>Select from available playbooks</Typography>
                      </MenuItem>
                      {playbooks && playbooks?.length > 0
                        ? playbooks?.map((pb) => (
                            <MenuItem key={pb?.id} value={pb?.id}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 2,
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <Typography>{pb?.tags?.join(', ')} </Typography>
                                <Typography>
                                  {moment(pb?.createdOn).format('DD MMM YYYY')}
                                  {pb?.selected ? '(Default)' : ''}
                                </Typography>
                              </Box>
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    borderRadius: 3,
                    border: '1px solid #e3e3e3',
                  }}
                >
                  <Box
                    sx={{
                      background: '#e3e3e3',
                      padding: 2,
                      borderTopLeftRadius: 3,
                      borderTopRightRadius: 3,
                    }}
                  >
                    <Typography color={'text.secondary'} fontWeight={'bold'}>
                      Playbook
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      // minHeight: '260px',
                      maxHeight: '260px',
                      overflowY: 'scroll',
                      '&::-webkit-scrollbar': {
                        width: '5px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary.main,
                        borderRadius: 2,
                      },
                      whiteSpace: 'pre-wrap',
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: 'pre-wrap',
                        flex: 1,
                        // borderRight: '1px solid #d3d3d3',
                        p: 2,
                      }}
                    >
                      {parse(playbook)}
                    </Typography>
                  </Box>
                </Box>
              </>
            ) : null}
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'column'} gap={2}>
          <Box
            display={'flex'}
            justifyContent={
              analysisInProgress.includes(showReAnalyzeDrawer) ||
              conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
                ?.analysisStatus === 'in_process'
                ? 'space-between'
                : 'flex-end'
            }
            alignItems={'center'}
            p={2}
            width={1}
            borderTop={'1px solid #f1f1f1'}
          >
            {analysisInProgress.includes(showReAnalyzeDrawer) ||
            conversations?.find((cs) => cs?.id === showReAnalyzeDrawer)
              ?.analysisStatus === 'in_process' ? (
              <Alert severity="warning" sx={{ width: 'calc(40vw - 130px)' }}>
                Analysis is in progress. You may continue your work and access
                the report from{' '}
                {orgConfigs?.navBarTitles?.['Private Roleplays']?.title ||
                  'Private Roleplays'}{' '}
                page where it will be available shortly.
              </Alert>
            ) : null}
            <Button
              variant="contained"
              size="small"
              disabled={
                analysisInProgress.includes(showReAnalyzeDrawer) ? true : false
              }
              startIcon={
                analysisInProgress.includes(showReAnalyzeDrawer) ? (
                  <CircularProgress size={20} />
                ) : null
              }
              onClick={(e) => {
                handleTrackEvent(e, `analyze_conversation`);
                if (
                  userDetails?.plan?.trailEndsOn > new Date().getTime() ||
                  userDetails?.plan?.currentPaymentStatus === 'paid'
                ) {
                  handleAnalyze(showReAnalyzeDrawer, 'list');
                } else {
                  dispatch({
                    type: 'UPDATE_UI_STATE',
                    payload: {
                      key: 'showPlanExpired',
                      value: true,
                    },
                  });
                }
              }}
            >
              Analyze
            </Button>
          </Box>
        </Box>
      </Drawer>
      <Dialog
        open={showPermissionDeniedError}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Camera and microphone are blocked
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Box flex={1}>
              <img src="/camerahelp.png" width={'100%'} height={'100%'} />
            </Box>
            <DialogContentText id="alert-dialog-description" flex={1}>
              To record your video we need permission to use your camera and
              microphone. Click the <Lock /> Lock Icon (usually in your
              browser&apos;s address bar) to allow permissions and start
              recording.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `cta_permission_denied_dialog_close`);
              setShowPermissionDeniedError(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Dismiss
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showLimitExhausted)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sorry, your plan&apos;s limit has been exhausted.
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              {userInfo?.custom?.orgType === 'demo_org'
                ? 'You have used up all the available credits. Would you like some more?'
                : showLimitExhausted === 'quota exceeded'
                ? 'Your current plan has reached its usage limit for this feature. To continue, consider upgrading your plan or wait until the limit resets at the beginning of the next cycle. If you have any questions or need assistance, please contact our support team.'
                : 'Your current plan has reached its usage limit for this feature. To continue, consider upgrading your plan or wait until the limit resets at the beginning of the next cycle. If you have any questions or need assistance, please contact our support team.'}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            gap={2}
            justifyContent={
              userInfo?.custom?.orgType === 'demo_org'
                ? 'flex-end'
                : 'space-between'
            }
          >
            {userInfo?.custom?.orgType === 'demo_org' ? null : (
              <Button
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  // handleTrackEvent(e, `cta_permission_denied_dialog_close`);

                  history.push('/pricing');
                  // if (testMode) setShowTestDeviceDialog(false);
                }}
                color="primary"
                // autoFocus
              >
                Go to Pricing Page
              </Button>
            )}
            <Button
              variant="outlined"
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                if (userInfo?.custom?.orgType === 'demo_org') {
                  setShowLimitExhausted(false);
                } else {
                  if (showLimitExhausted === 'quota exceeded') {
                    setShowLimitExhausted(false);
                    dispatch(
                      updateConversationStatus(
                        currentConversation?.id,
                        'completed',
                        () => {
                          setCurrentConversation((prev) => {
                            return {
                              ...prev,
                              status: 'completed',
                            };
                          });
                        },
                      ),
                    );
                  } else {
                    setShowLimitExhausted(false);
                  }
                }
              }}
              color="primary"
              // autoFocus
            >
              {userInfo?.custom?.orgType === 'demo_org'
                ? 'No'
                : showLimitExhausted === 'quota exceeded'
                ? 'End'
                : 'Dismiss'}
            </Button>
            {userInfo?.custom?.orgType === 'demo_org' ? (
              <Button
                variant="contained"
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  if (userInfo?.custom?.orgType === 'demo_org') {
                    setSendingDemoRequest(true);
                    dispatch(
                      sendDemoRequest(
                        {
                          credit: 0,
                          featureName: 'call_analysis',
                        },
                        (error) => {
                          if (error) {
                            setSendingDemoRequest(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Failed to request credits. Please try again!',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            setShowLimitExhausted(false);
                            setSendingDemoRequest(false);
                            setShowDemoRequestSuccessDialog(true);
                            dispatch(
                              showAlert({
                                message: 'Request has been sent successfully.',
                                showCross: true,
                                title: null,
                                type: 'success',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          }
                        },
                      ),
                    );
                  } else {
                    if (showLimitExhausted === 'quota exceeded') {
                      setShowLimitExhausted(false);
                      dispatch(
                        updateConversationStatus(
                          currentConversation?.id,
                          'completed',
                          () => {
                            setCurrentConversation((prev) => {
                              return {
                                ...prev,
                                status: 'completed',
                              };
                            });
                          },
                        ),
                      );
                    } else {
                      setShowLimitExhausted(false);
                    }
                  }
                }}
                color="primary"
                // autoFocus
              >
                Yes
              </Button>
            ) : null}
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showDemoRequestSuccessDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Thanks, we have let the admin know. They&apos;ll be reaching out
              to you soon.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            gap={2}
            justifyContent={'flex-end'}
          >
            <Button
              variant="outlined"
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowDemoRequestSuccessDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              OK
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showInsufficientCredits)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sorry, you don&apos;t have enough credits.
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              This operation requires atleast 1 credit. Please buy more credits
              or wait until the limit resets at the beginning of the next cycle.
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                // handleTrackEvent(e, `cta_permission_denied_dialog_close`);

                history.push('/pricing');
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Go to Pricing Page
            </Button>
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowInsufficientCredits(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this conversation?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `confirm_delete_dialog_close`);
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `delete_conversation`);
              dispatch(
                deleteConversation(showConfirmDeleteDialog, () => {
                  dispatch({
                    type: 'UPDATE_ALL_CONVERSATIONS_AFTER_DELETE',
                    payload: {
                      id: showConfirmDeleteDialog,
                    },
                  });
                  setShowConfirmDeleteDialog(null);
                }),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={endConversationAlert}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              This will end up the conversation. Do you want to continue?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `end_conversation_dialog_close`);
              setEndConversationAlert(false);
              // if (testMode) setShowTestDeviceDialog(false);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleTrackEvent(e, `end_conversation`);
              dispatch(
                updateConversationStatus(
                  currentConversation?.id,
                  'completed',
                  () => {
                    setConversationStarted(false);
                    setEndConversationAlert(false);
                    setCurrentConversation((prev) => {
                      return {
                        ...prev,
                        status: 'completed',
                      };
                    });
                    if (recording) {
                      handleRecordStopClick(true);
                    }
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={Boolean(showPreviewSalesCall)}
        onClose={(e, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
            setShowPreviewSalesCall(null);
          else return;
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            // maxHeight: '30vh',
            maxWidth: '50vw',
            minWidth: '300px',
            outline: 'none',
            borderRadius: 1,
            // padding: 3,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: 1,
              justifyContent: 'space-between',
              padding: '4px 16px',
            }}
          >
            <Typography variant="body2">Media</Typography>
            <IconButton
              data-trackid={'close_preview_sales_call'}
              onClick={(e) => {
                handleTrackEvent(e, 'close_preview_sales_call');
                setShowPreviewSalesCall(null);
              }}
              aria-label="close"
            >
              <Close style={{ fontSize: 20 }} />
            </IconButton>
          </Box>
          <Divider sx={{ width: 1 }} />
          <Box p={1}>
            <MediaPreview
              styles={{
                flex: 1,
              }}
              url={showPreviewSalesCall?.customFields?.audioLink}
            />
          </Box>
        </Box>
      </Modal>
      <Dialog
        open={showSyncHubspotDialog}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Sync the recording and call summary to HubSpot
        </DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Please provide the hubspot contact details you wanted this
              recording to be uploaded on.
            </DialogContentText>
          </Box>
          <Box mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Meeting Title*
                </Typography>
                <TextField
                  placeholder="Meeting Title*"
                  variant="outlined"
                  name={'meetingTitle'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={meetingTitle}
                  onChange={(e) => setMeetingTitle(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Email(HubSpot)*
                </Typography>
                <TextField
                  placeholder="Contact Email*"
                  variant="outlined"
                  name={'contactEmail'}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  type="email"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact First Name
                </Typography>
                <TextField
                  placeholder="Contact First Name"
                  variant="outlined"
                  name={'contactFirstName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactFirstName}
                  onChange={(e) => setContactFirstName(e.target.value)}
                  type="text"
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color={'text.secondary'}>
                  Contact Last Name
                </Typography>
                <TextField
                  placeholder="Contact Last Name"
                  variant="outlined"
                  name={'contactLastName'}
                  // fullWidth
                  // multiline
                  // rows={3}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  value={contactLastName}
                  onChange={(e) => setContactLastName(e.target.value)}
                  type="text"
                />
              </Grid>
              {/*                         
                        {error && (
                          <Grid item xs={12}>
                            <Alert severity="error">{error}</Alert>
                          </Grid>
                        )} */}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width={1}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Button
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setShowSyncHubspotDialog(false);
              }}
              color="primary"
              // autoFocus
            >
              Dismiss
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={syncingWithHubspot}
              // data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setSyncingWithHubspot(true);
                dispatch(
                  syncDataWithHubspot(
                    salesReview?.id,
                    {
                      email: contactEmail,
                      fname: contactFirstName,
                      hubSpot: true,
                      lname: contactLastName,
                      meetingTitle: meetingTitle,
                      startTime: 1704274078176,
                    },
                    () => {
                      setShowSyncHubspotDialog(false);
                      setSyncingWithHubspot(false);
                      dispatch(
                        showAlert({
                          message:
                            'Summary is successfully exported to HubSpot.',
                          showCross: true,
                          title: null,
                          type: 'success',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                    () => {
                      setSyncingWithHubspot(false);
                      // setShowSyncHubspotDialog(false)
                      dispatch(
                        showAlert({
                          message:
                            'Failed to export summary. Please try again.',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              // autoFocus
            >
              Sync
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

ConversationAIMain.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default ConversationAIMain;
