import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import firebase from '../../firebase';
import useScrollBlock from 'utils/disableScrolling';
import { Close } from '@mui/icons-material';
import setOnboarding from 'redux/actions/Common/setOnboarding';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
  },
  inner: {
    maxWidth: '1200px',
    width: '100%',
    margin: '0 auto',
    // padding: theme.spacing(6, 2),
    // [theme.breakpoints.up('sm')]: {
    //   padding: theme.spacing(12, 2),
    // },
  },
  innerNarrowed: {
    maxWidth: 800,
  },
}));

/**
 * Component to display the Tour Tooltip content
 *
 * @param {Object} props
 */
const TourStepContent = (props) => {
  const { children, innerNarrowed, className, ...rest } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const tourState = useSelector((state) => state.tourState);
  const onboardingSettings = useSelector((state) => state.onboardingSettings);
  const [blockScroll, allowScroll] = useScrollBlock();

  function enableScroll() {
    setTimeout(() => {
      document.body.style.overflow = null;
    }, 500);
  }

  const stopTour = () => {
    enableScroll();
    allowScroll();
    if (
      onboardingSettings.onBoarding &&
      onboardingSettings.onBoardingFor === 'orgAdmin'
    ) {
      dispatch({ type: 'STOP_TOUR' });
      dispatch(
        setOnboarding({
          onBoarding: false,
          stopAt: null,
        }),
      );
      history.push('/deals');
    }
  };

  const classes = useStyles();

  console.log(tourState);
  console.log(onboardingSettings);

  return (
    <div>
      <IconButton
        aria-label="close"
        color="inherit"
        className={classes.close}
        style={{ position: 'absolute', top: 5, right: 5 }}
        onClick={() => {
          if (onboardingSettings.onBoardingStep === 'start') {
            stopTour();
          } else {
            setOpenConfirmationDialog(true);
          }
        }}
      >
        <Close style={{ fontSize: 14, color: '#343a40' }} />
      </IconButton>
      {children}
      <Dialog
        open={openConfirmationDialog}
        // onClose={()=>{}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ zIndex: 100003 }}
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to close the guided tour?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            color="primary"
          >
            No
          </Button>
          <Button onClick={() => stopTour()} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

TourStepContent.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * Children to placed inside the section
   */
  children: PropTypes.node,
  innerNarrowed: PropTypes.bool,
};

export default TourStepContent;
