import { Box, useTheme, useMediaQuery, Typography, Chip } from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';

import { PropTypes } from 'prop-types';
import getSellingStyles from 'redux/actions/Common/getSellingStyles';
import { HelpOutlined } from '@mui/icons-material';
import DragNDropSortableList from 'components/DragNDropSortableList';
import getDealDiscoveryQuestionsList from 'redux/actions/Common/getDealDiscoveryQuestionsList';
import getDiscoveryTreeTopics from 'redux/actions/Common/getDiscoveryTreeTopics';

const DealDiscovery = ({ uId, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const {
    dashboardSidebarExpanded,
    questionFrameworkSteps,
    activeQuestionFrameworkStep,
    pinnedTeams,
    navExpandedStatus,
  } = useSelector((state) => state.uiStates);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const { qList, fetching, fetchingPagination } = useSelector(
    (state) => state.discoveryQList,
  );

  const [fetchingSellingStyles, setFetchingSellingStyles] = useState(false);
  const [sellingStyles, setSellingStyles] = useState([]);
  const [fetchingDiscoveryQList, setFetchingDiscoveryQList] = useState(false);

  const [questionTopics, setQuestionTopics] = useState([]);
  const [questionSubTopics, setQuestionSubTopics] = useState([]);
  const [expandedSubtopics, setExpandedSubtopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState('');

  useEffect(() => {
    if (orgConfigs?.discoveryTreeId) {
      dispatch(
        getDiscoveryTreeTopics(orgConfigs?.discoveryTreeId, (data) => {
          console.log(data);
          // if (data?.risk && data?.risk?.length > 0) {
          setQuestionTopics(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            }) || [],
          );
          setQuestionSubTopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setExpandedSubtopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setSelectedTopic(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            })?.[0]?.id || '',
          );
          // } else {
          //   setQuestionTopics(data?.map((aq) => aq?.topic) || []);
          //   setQuestionSubTopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setExpandedSubtopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setSelectedTopic(data?.map((aq) => aq?.topic)?.[0] || '');
          // }
        }),
      );
      setFetchingDiscoveryQList(true);
      dispatch(
        getDealDiscoveryQuestionsList(
          orgConfigs?.discoveryTreeId,
          null,
          null,
          (data) => {
            console.log(data);
            setFetchingDiscoveryQList(false);
          },
        ),
      );
    }
  }, [orgConfigs]);

  useEffect(() => {
    setFetchingSellingStyles(true);
    dispatch(
      getSellingStyles((data) => {
        setSellingStyles(data || []);
        // setTimeout(() => {
        setFetchingSellingStyles(false);
        // }, 3000);
      }),
    );
  }, []);

  // console.log(qList);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
        padding={2}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: 1,
          }}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            gap={2}
            justifyContent={'space-between'}
            sx={{
              borderBottom: '1px solid #d3d3d3',
              width: 1,
              paddingBottom: 2,
            }}
          >
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <Typography fontWeight={'bold'}>
                Discovery Template for all deals
              </Typography>
              <Chip
                color="primary"
                variant="outlined"
                size="small"
                label={
                  sellingStyles?.find(
                    (ss) => ss?.sellingStyle === orgConfigs?.sellingStyle,
                  )?.title
                }
              />
            </Box>
            <Box display={'flex'} alignItems={'center'} gap={1}>
              {/* <HelpOutlined
                sx={{
                  fontSize: 20,
                  color: theme.palette.text.secondary,
                }}
              /> */}
              <Typography variant="body2" color={'text.secondary'}>
                Drag and drop to reorder
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: 1,
              maxHeight: 'calc(100vh - 150px)',
              minHeight: 'calc(100vh - 150px)',
              overflowY: 'scroll',
              '&::-webkit-scrollbar': {
                width: '5px',
              },
              '&::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.primary.main,
                borderRadius: 2,
                // outline: '1px solid slategrey',
              },
              paddingX: 1,
            }}
          >
            {qList && qList?.length > 0 ? (
              <>
                <DragNDropSortableList
                  qList={qList}
                  questionTopics={questionTopics}
                  questionSubTopics={questionSubTopics}
                />
              </>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minHeight: 300,
                  gap: 2,
                }}
              >
                <Typography>No discovery questions found.</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

DealDiscovery.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default DealDiscovery;
