import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Add, Check, Sync } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import QuestionNode from 'components/QuestionNode';
import update from 'immutability-helper';
import DealStageNode from 'components/DealStageNode';

const DragNDropSortableDealStages = memo(function DragNDropSortableDealStages({
  list,
  setDealStages,
  readOnly,
}) {
  const dispatch = useDispatch();
  const findCard = useCallback(
    (id) => {
      const stage = list.filter((stage) => `${stage?.id}` === id)[0];
      return {
        stage,
        index: list.indexOf(stage),
      };
    },
    [list],
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { stage, index } = findCard(id);
      setDealStages((prev) => {
        return update(list, {
          $splice: [
            [index, 1],
            [atIndex, 0, stage],
          ],
        });
      });
    },
    [findCard, list],
  );

  const [, drop] = useDrop(() => ({ accept: 'stage' }));

  return (
    <Box
      ref={drop}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
      }}
    >
      {list?.map((stage, index) => (
        <DealStageNode
          key={stage?.id}
          id={stage?.id}
          stage={stage}
          index={index}
          moveCard={moveCard}
          findCard={findCard}
          setDealStages={setDealStages}
          readOnly={readOnly}
        />
      ))}
    </Box>
  );
});

DragNDropSortableDealStages.propTypes = {
  list: PropTypes.object,
  setDealStages: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default DragNDropSortableDealStages;
