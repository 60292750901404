import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import {
  Add,
  Check,
  CheckCircle,
  Sync,
  Troubleshoot,
  Warning,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import {
  artifactProcessingStatuses,
  artifactStatusMap,
  artifactTypesWithoutMedia,
} from 'data';
import { capitalizeText } from 'utils';
import processDealArtifact from 'redux/actions/Common/processDealArtifact';

const ArtifactStatus = ({
  dealId,
  artifact,
  setDealArtifacts,
  setActiveArtifact,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;

  const { userDetails } = useSelector((state) => state.userDetails);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [processingArtifact, setProcessingArtifact] = useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        background: '#f1f1f1',
        // padding: 1,
        borderRadius: 20,
        cursor: artifactProcessingStatuses.includes(
          artifact?.postProcessingStatus,
        )
          ? 'default'
          : 'pointer',
      }}
      onClick={(e) => {
        // if (anchorEl) {
        //   setAnchorEl(null);
        // } else {
        if (
          !artifactProcessingStatuses.includes(artifact?.postProcessingStatus)
        ) {
          setAnchorEl(e.currentTarget);
        }
        // }
      }}
    >
      {/* <Typography fontSize={12} sx={{ paddingX: 1 }}>
        {artifactTypesWithoutMedia?.includes(artifact?.interactionType) ||
        !artifact?.mediaFileBasedInteraction
          ? capitalizeText(
              artifact?.postProcessingStatus?.split('_')?.join(' ') ||
                'pending',
            )
          : artifact?.customField?.salesReviewFile?.transcript
          ? capitalizeText(
              artifact?.postProcessingStatus?.split('_')?.join(' ') ||
                'pending',
            )
          : artifact?.customField?.salesReviewFile?.status === 'failed'
          ? 'Failed to generate transcript. Please try again.'
          : artifact?.customField?.salesReviewFile?.status === 'pending' ||
            artifact?.customField?.salesReviewFile?.status === 'in_process'
          ? 'Transcription in progress.'
          : 'Pending'}
      </Typography> */}
      {artifact?.postProcessingStatus !== 'completed' &&
      artifact?.postProcessingStatus !== 'interaction_nba_completed' ? (
        <Tooltip
          title={artifactStatusMap[artifact?.postProcessingStatus] || ''}
          arrow
          placement="top"
        >
          <Box
            sx={{
              width: 25,
              height: 25,
              borderRadius: 13,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '4px',
              background: theme.palette.warning.main,
            }}
          >
            {artifactProcessingStatuses.includes(
              artifact?.postProcessingStatus,
            ) ? (
              <CircularProgress size={14} sx={{ color: '#fff' }} />
            ) : artifact?.postProcessingStatus === 'transcription_completed' ? (
              <Troubleshoot sx={{ color: '#fff' }} fontSize="14" />
            ) : (
              <Warning sx={{ color: '#fff' }} fontSize="14" />
            )}
          </Box>
        </Tooltip>
      ) : null}

      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={() => {
          setAnchorEl(null);
        }}
      >
        <Popper
          open={openMenu}
          anchorEl={anchorEl}
          sx={{
            zIndex: 1300,
          }}
          placement={'bottom-end'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 1,
              gap: 2,
              border: '1px solid #d3d3d3',
              borderRadius: 1,
              padding: 1,
              background: '#fff',
              minWidth: 200,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {!artifact?.mediaFileBasedInteraction ? (
              <Button
                disabled={processingArtifact}
                size="small"
                startIcon={
                  processingArtifact ? <CircularProgress size={20} /> : null
                }
                onClick={() => {
                  setProcessingArtifact(true);
                  dispatch(
                    processDealArtifact(
                      dealId,
                      artifact?.id,
                      {
                        speaker:
                          artifact?.customField?.salesReviewFile?.speaker || '',
                        speakerConfigMap:
                          artifact?.customField?.salesReviewFile
                            ?.speakerConfigMap || {},
                      },
                      () => {
                        setProcessingArtifact(false);
                        setAnchorEl(null);
                        setDealArtifacts((prev) =>
                          prev?.map((da) => {
                            if (da?.id === artifact?.id) {
                              return {
                                ...da,
                                postProcessingStatus:
                                  'interaction_analysis_in_process',
                              };
                            } else {
                              return da;
                            }
                          }),
                        );
                      },
                    ),
                  );
                }}
              >
                Analyze Artifact
              </Button>
            ) : artifact?.customField?.salesReviewFile?.transcript ? (
              <Button
                disabled={processingArtifact}
                size="small"
                startIcon={
                  processingArtifact ? <CircularProgress size={20} /> : null
                }
                onClick={() => {
                  setProcessingArtifact(true);
                  dispatch(
                    processDealArtifact(
                      dealId,
                      artifact?.id,
                      {
                        speaker:
                          artifact?.customField?.salesReviewFile?.speaker || '',
                        speakerConfigMap:
                          artifact?.customField?.salesReviewFile
                            ?.speakerConfigMap || {},
                      },
                      () => {
                        setProcessingArtifact(false);
                        setAnchorEl(null);
                        setDealArtifacts((prev) =>
                          prev?.map((da) => {
                            if (da?.id === artifact?.id) {
                              return {
                                ...da,
                                postProcessingStatus:
                                  'interaction_analysis_in_process',
                              };
                            } else {
                              return da;
                            }
                          }),
                        );
                        setActiveArtifact((prev) => {
                          return {
                            ...prev,
                            postProcessingStatus:
                              'interaction_analysis_in_process',
                          };
                        });
                      },
                    ),
                  );
                }}
              >
                Analyze Artifact
              </Button>
            ) : (
              <Button>Trigger Re-Transcription</Button>
            )}
          </Box>
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};

ArtifactStatus.propTypes = {
  artifact: PropTypes.object,
  dealId: PropTypes.string,
  setDealArtifacts: PropTypes.func,
  setActiveArtifact: PropTypes.func,
};

export default ArtifactStatus;
