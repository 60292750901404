const initialstate = {
  validating: null,
  done: null,
  failed: null,
  error: null,
  errorText: null,
};

const validateInvitationTokenReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'VALIDATING_TOKEN':
      return Object.assign({}, state, {
        validating: true,
      });
    case 'VALIDATING_TOKEN_DONE':
      return Object.assign({}, state, {
        validating: false,
        done: true,
        failed: false,
        errorText: '',
        error: false,
        ...action.payload,
      });
    case 'VALIDATING_TOKEN_FAILED':
      return Object.assign({}, state, {
        validating: false,
        failed: true,
        done: false,
        errorText: action.payload,
        error: true,
      });
    default:
      return state;
  }
};

export default validateInvitationTokenReducer;
