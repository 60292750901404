import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import {
  Add,
  Check,
  CheckCircle,
  CloudUpload,
  Sync,
  Troubleshoot,
  Warning,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import {
  artifactProcessingStatuses,
  artifactStatusMap,
  artifactTypesWithoutMedia,
} from 'data';
import { capitalizeText } from 'utils';
import processDealArtifact from 'redux/actions/Common/processDealArtifact';
import DealSelect from 'components/DealSelect';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import addDealArtifact from 'redux/actions/Common/addDealArtifact';
import showAlert from 'redux/actions/Common/showAlert';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import updateDealArtifact from 'redux/actions/Common/updateDealArtifact';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import ArtifactRisksSelectionList from 'components/ArtifactRisksSelectionList';
import getArtifactRisks from 'redux/actions/Common/getArtifactRisks';

const ArtifactUpload = ({
  fullWidth,
  setCreateNewDealDialog,
  setCreationMode,
  setShowUploadArtifact,
  refreshList,
  setRefreshList,
  dealId,
  setViewType,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;

  const { userDetails } = useSelector((state) => state.userDetails);
  const checkProgressTimer = useRef(null);
  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.addDealArtifactState,
  );

  const [activeArtifact, setActiveArtifact] = useState(null);
  const [selectedCrmDeal, setSelectedCrmDeal] = useState('');
  const [uploadingArtifact, setUploadingArtifact] = useState(false);
  const [artifactType, setArtifactType] = useState('email-inbound');
  const [artifactTitle, setArtifactTitle] = useState('');
  const [artifactTime, setArtifactTime] = useState(new Date().getTime());
  const [speaker, setSpeaker] = useState('Speaker 1');
  const [speakers, setSpeakers] = useState({});
  const [buyerParticipants, setBuyerParticipants] = useState('');
  const [sellerParticipants, setSellerParticipants] = useState('');
  const [artifactMedia, setArtifactMedia] = useState('true');
  const [artifactContent, setArtifactContent] = useState('');

  const [activeStep, setActiveStep] = useState(0);
  const inputImage = useRef();
  const [invalidFile, setInvalidFile] = useState(false);
  const [selectedAudio, setSelectedAudio] = useState(null);

  const [progressValue, setProgressValue] = useState(0);
  const [transcriptReady, setTranscriptReady] = useState(false);
  const [transcriptionFailed, setTranscriptionFailed] = useState(false);
  const [uploadingAudioId, setUploadingAudioId] = useState(null);
  const [salesReviewId, setSalesReviewId] = useState('');

  const [processingArtifact, setProcessingArtifact] = useState(false);
  const [showArtifactMediaRadio, setShowArtifactMediaRadio] = useState(false);
  const [syncingCrmDeals, setSyncingCrmDeals] = useState(false);

  const [risks, setRisks] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);

  const validateFile = (event) => {
    if (activeStep === 0) {
      setInvalidFile(false);
      // setActiveStep(1);
    }
  };

  const onFileChange = (event) => {
    // console.log(event.target.files[0]?.size > 200 * 1024 * 1024);
    // handleTrackEvent(event, 'recording_file_selected_or_changed');
    if (
      event.target.files[0] &&
      (event.target.files[0].type.includes('audio') ||
        event.target.files[0].type.includes('video'))
      //   &&
      // (!(
      //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
      //   !(
      //     process.env.REACT_APP_TARGET_ENV === 'local' ||
      //     process.env.REACT_APP_TARGET_ENV === 'development'
      //   )
      // ) ||
      //   userInfo?.id === 'p1NmkUlUCabB0OJIs2UnEapBEJy2')
    ) {
      setSelectedAudio(event.target.files[0]);
      // setActiveStep(1);
      validateFile(event);
    } else {
      // if (
      //   event.target.files[0]?.size > 200 * 1024 * 1024 &&
      //   !(
      //     process.env.REACT_APP_TARGET_ENV === 'local' ||
      //     process.env.REACT_APP_TARGET_ENV === 'development'
      //   )
      // ) {
      //   dispatch(
      //     logError({
      //       message: `User with userId ${userInfo?.id} tried to upload more than 200 MB file`,
      //       data: {
      //         formatted: {
      //           errorText: 'User tried to upload more than 200 MB file',
      //           severity: 'error',
      //         },
      //         raw: {
      //           errorText: 'User tried to upload more than 200 MB file',
      //           severity: 'error',
      //         },
      //       },
      //     }),
      //   );
      //   setInvalidFile(
      //     'The video you are trying to analyze is larger than the limit supported in this plan. Please get in touch with your Qualification AI success manager or write to support@qualification.ai to get it analyzed. ',
      //   );
      // } else {
      inputImage.current.value = null;
      setInvalidFile(true);
      // }
    }
  };

  useEffect(() => {
    if (
      uploadingFor &&
      Object.keys(uploadingFor).length > 0 &&
      uploadingFor?.[uploadingAudioId]
    ) {
      setProgressValue(uploadPercentage?.[uploadingAudioId]);
    }
  }, [uploadingFor, uploadPercentage, uploadingAudioId]);

  useEffect(() => {
    if (activeStep === 1 && salesReviewId) {
      dispatch(
        checkSalesCallTranscriptStatus(salesReviewId, (data) => {
          // console.log(data);
          if (data?.status === 'failed') {
            setTranscriptReady(false);
            setTranscriptionFailed(true);
            dispatch(
              showAlert({
                message: 'Something went wrong. Please try again!',
                showCross: true,
                title: null,
                type: 'error',
                autoHideDuration: 2000,
                vertical: 'top',
                horizontal: 'center',
              }),
            );
          } else if (data?.status !== 'failed' && data?.status !== 'success') {
            checkProgressTimer.current = setInterval(() => {
              dispatch(
                checkSalesCallTranscriptStatus(salesReviewId, (data) => {
                  if (data?.status === 'success') {
                    clearInterval(checkProgressTimer.current);
                    setTranscriptionFailed(false);
                    setTranscriptReady(true);
                    setActiveArtifact((prev) => {
                      return {
                        ...prev,
                        customField: {
                          ...(prev?.customField || {}),
                          salesReviewFile: data?.saleReview,
                        },
                      };
                    });
                  } else if (data?.status === 'failed') {
                    setTranscriptReady(false);
                    setTranscriptionFailed(true);
                    clearInterval(checkProgressTimer.current);
                    dispatch(
                      showAlert({
                        message: 'Something went wrong. Please try again!',
                        showCross: true,
                        title: null,
                        type: 'error',
                        autoHideDuration: 2000,
                        vertical: 'top',
                        horizontal: 'center',
                      }),
                    );
                  }
                }),
              );
            }, 5000);
          }
        }),
      );
    } else if (activeStep !== 1 && salesReviewId) {
      setTranscriptReady(false);
      clearInterval(checkProgressTimer.current);
      setSalesReviewId('');
    }
    // if (activeStep === 1) {
    //   setConversationTitle(
    //     selectedAudio?.name
    //       ?.split('.')
    //       .slice(0, selectedAudio?.name.split('.')?.length - 1)
    //       ?.join('.') || '',
    //   );
    // }
  }, [activeStep, salesReviewId]);

  useEffect(() => {
    if (
      artifactType === 'call' ||
      artifactType === 'call-internal' ||
      artifactType === 'meeting' ||
      artifactType === 'meeting-internal'
    ) {
      setShowArtifactMediaRadio(true);
      setArtifactMedia('true');
    } else {
      setShowArtifactMediaRadio(false);
      setArtifactMedia('false');
    }
  }, [artifactType]);

  useEffect(() => {
    if (activeArtifact?.customField?.salesReviewFile) {
      setBuyerParticipants(
        Object.values(
          activeArtifact?.customField?.salesReviewFile?.speakerConfigMap || {},
        )
          ?.filter((p) => p?.speakerRole === 'customer')
          ?.map((p) => p?.name)
          ?.join(', ') || '',
      );
      setSellerParticipants(
        Object.values(
          activeArtifact?.customField?.salesReviewFile?.speakerConfigMap || {},
        )
          ?.filter((p) => p?.speakerRole === 'seller')
          ?.map((p) => p?.name)
          ?.join(', ') || '',
      );
      setSpeakers(activeArtifact?.customField?.salesReviewFile?.speakers);
      setSpeaker(
        activeArtifact?.customField?.salesReviewFile?.speaker || 'Speaker 1',
      );
    }
  }, [activeArtifact]);

  useEffect(() => {
    if (activeStep === 2 && activeArtifact) {
      dispatch(
        getArtifactRisks(activeArtifact?.id, (data) => {
          console.log(data);
          setRisks(data?.recommendedRisks || []);
        }),
      );
    }
  }, [activeStep, activeArtifact]);

  // console.log(activeStep);
  // console.log(activeArtifact);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: fullWidth ? '100%' : activeStep === 2 ? '70%' : '50%',
        margin: '0 auto',
      }}
    >
      {uploadingArtifact ? (
        <Box
          sx={{
            padding: 2,
            width: 1,
            height: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box position={'relative'}>
            <CircularProgress
              variant="determinate"
              size={200}
              value={progressValue}
              thickness={4}
            />
            <Typography
              variant="body1"
              color={theme.palette.text.primary}
              textAlign={'center'}
              sx={{
                fontSize: 26,
                fontWeight: 'bold',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {progressValue} %
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color={theme.palette.text.primary}
            textAlign={'center'}
            sx={{ fontSize: 20, marginTop: 10 }}
          >
            Uploading. Please wait ...
          </Typography>
        </Box>
      ) : activeStep === 0 ? (
        <>
          <Box
            className="uploadArtifactForm"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            {!dealId ? (
              <DealSelect
                selectedCrmDeal={selectedCrmDeal}
                setSelectedCrmDeal={(value) => {
                  setSelectedCrmDeal(value);
                }}
                // crmDeals={crmDeals}
                refreshList={refreshList}
                setRefreshList={setRefreshList}
                crmSyncing={syncingCrmDeals}
                setShowDealCreationDialog={setCreateNewDealDialog}
                setCreationMode={setCreationMode}
                fullWidth={true}
              />
            ) : null}
            <TextField
              label="Artifact Title"
              variant="outlined"
              name={'artifactTitle'}
              size="small"
              fullWidth
              value={artifactTitle}
              onChange={(e) => setArtifactTitle(e.target.value)}
            />
            <Select
              // ref={promptSelectRef}
              labelId="artifact-type-select-label"
              // label="Select Prompt"
              value={artifactType || ''}
              onChange={(e) => {
                setArtifactType(e.target.value);
              }}
              size="small"
            >
              <MenuItem value={'email-inbound'}>Email Inbound</MenuItem>
              <MenuItem value={'email-outbound'}>Email Outbound</MenuItem>
              <MenuItem value={'email-internal'}>Email (Internal)</MenuItem>
              <MenuItem value={'call'}>Call Recording</MenuItem>
              <MenuItem value={'call-internal'}>
                Call Recording (Internal)
              </MenuItem>
              <MenuItem value={'meeting'}>Meeting Recording</MenuItem>
              <MenuItem value={'meeting-internal'}>
                Meeting Recording (Internal)
              </MenuItem>
              <MenuItem value={'note'}>Note(Internal)</MenuItem>
            </Select>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Artifact Time"
                size="small"
                value={dayjs(artifactTime)}
                onChange={(newValue) => {
                  console.log(new Date(newValue).getTime());
                  setArtifactTime(new Date(newValue).getTime());
                }}
              />
            </LocalizationProvider>
            {artifactMedia === 'true' ||
            artifactType === 'email-internal' ||
            artifactType === 'call-internal' ||
            artifactType === 'meeting-internal' ||
            artifactType === 'note' ? null : (
              <>
                <TextField
                  label="Buyer's Participants(Comma Seperated)"
                  variant="outlined"
                  name={'buyerParticipants'}
                  size="small"
                  fullWidth
                  value={buyerParticipants}
                  onChange={(e) => setBuyerParticipants(e.target.value)}
                />
                <TextField
                  label="Seller's Participants(Comma Seperated)"
                  variant="outlined"
                  name={'sellerParticipants'}
                  size="small"
                  fullWidth
                  value={sellerParticipants}
                  onChange={(e) => setSellerParticipants(e.target.value)}
                />
              </>
            )}
            {showArtifactMediaRadio ? (
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={artifactMedia}
                  onChange={(e) => setArtifactMedia(e.target.value)}
                >
                  <FormControlLabel
                    value={'true'}
                    control={<Radio size="small" />}
                    label={'Media'}
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio size="small" />}
                    label={'Text'}
                  />
                </RadioGroup>
              </FormControl>
            ) : null}

            {artifactMedia === 'false' ? (
              <TextField
                label="Content"
                variant="outlined"
                name={'artifactContent'}
                size="small"
                fullWidth
                multiline
                rows={8}
                value={artifactContent}
                onChange={(e) => setArtifactContent(e.target.value)}
              />
            ) : (
              <InputLabel
                htmlFor="audioCall"
                sx={{ cursor: 'pointer', width: 1 }}
              >
                <input
                  style={{ display: 'none' }}
                  id="audioCall"
                  ref={inputImage}
                  type="file"
                  accept="audio/*,video/*"
                  onChange={(e) => {
                    onFileChange(e);
                  }}
                />
                <Box
                  sx={{
                    border: '1px dashed #d3d3d3',
                    borderRadius: 4,
                    width: 1,
                    minHeight: 120,
                    p: 2,
                    textAlign: 'center',
                  }}
                  display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={2}
                >
                  <CloudUpload
                    sx={{
                      fontSize: 40,
                      color: theme.palette.primary.main,
                    }}
                  />
                  {selectedAudio ? (
                    <Typography color={'#677788'} sx={{ whiteSpace: 'wrap' }}>
                      {selectedAudio?.name}
                    </Typography>
                  ) : null}
                  <Box
                    sx={{
                      background: theme.palette.primary.main,
                      borderRadius: 1,
                      padding: '8px 16px',
                    }}
                  >
                    <Typography color={'#fff'}>
                      {selectedAudio
                        ? 'Change Media File'
                        : 'Choose Media File'}
                    </Typography>
                  </Box>
                  <Box display={'flex'} flexDirection={'column'} gap={1}>
                    {invalidFile ? (
                      <Alert severity="error">
                        <Typography
                          variant="body1"
                          sx={{
                            whiteSpace: 'pre-wrap',
                            color: theme.palette.error.main,
                          }}
                        >
                          {invalidFile}
                        </Typography>
                      </Alert>
                    ) : null}
                    <Typography
                      variant="caption"
                      sx={{
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      (Common audio/video formats supported)
                    </Typography>
                    {/* <Typography
                            variant="caption"
                            sx={{
                              whiteSpace: 'pre-wrap',
                            }}
                          >
                            Upload upto{' '}
                            {Math.round(
                              Object.values(
                                Object.values(
                                  userDetails?.custom?.plan?.features || {},
                                )?.find(
                                  (obj) => obj?.feature === 'call_analysis',
                                )?.restrictions || {},
                              )?.find(
                                (obj) =>
                                  obj?.restriction ===
                                  'duration_of_sale_call_recording_analysis',
                              )?.limit /
                                (1000 * 60),
                            )}{' '}
                            minutes duration of recording
                          </Typography> */}
                  </Box>
                </Box>
              </InputLabel>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              className="uploadArtifactBtn"
              variant="contained"
              // disabled={
              //   generatingValueProp ||
              //   creatingDiscoverytree
              // }
              // startIcon={
              //   creatingDiscoverytree ? (
              //     <CircularProgress size={20} />
              //   ) : null
              // }
              onClick={(e) => {
                setUploadingArtifact(true);
                dispatch(
                  addDealArtifact(
                    dealId || selectedCrmDeal,
                    {
                      buyers: buyerParticipants?.split(',') || [],
                      sellers: sellerParticipants?.split(',') || [],
                      interactionType: artifactType,
                      interactionTime: artifactTime,
                      title: artifactTitle,
                      mediaFileBasedInteraction: artifactMedia === 'true',
                      contentType:
                        artifactMedia === 'true'
                          ? selectedAudio?.type?.split(';')?.[0]
                          : '',
                      fileSize:
                        artifactMedia === 'true' ? selectedAudio?.size : 0,
                      mediaType: 'audio',
                      content: artifactMedia === 'true' ? '' : artifactContent,
                    },
                    selectedAudio,
                    (data) => {
                      try {
                        console.log(data);
                        setArtifactType('email-inbound');
                        setArtifactTitle('');
                        setArtifactContent('');
                        setBuyerParticipants('');
                        setSellerParticipants('');
                        setUploadingArtifact(false);
                        setActiveArtifact(data);
                        if (artifactMedia === 'true') {
                          setActiveStep(1);
                          setSalesReviewId(data?.saleReviewId);
                        } else {
                          setActiveStep(2);
                        }
                        // else {
                        // if (
                        //   setShowUploadArtifact &&
                        //   typeof setShowUploadArtifact === 'function'
                        // ) {
                        //   setShowUploadArtifact(false);
                        // }
                        // if (dealId) {
                        //   setViewType('timeline');
                        // }
                        // }
                        dispatch(getUserDetails((data) => {}));
                        dispatch(
                          showAlert({
                            message: 'Artifact is uploaded successfully.',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 5000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      } catch (err) {
                        console.log(err);
                      }
                    },
                    (audioId) => {
                      setUploadingAudioId(audioId);
                    },
                    () => {
                      setUploadingArtifact(false);
                      dispatch(
                        showAlert({
                          message:
                            'Oops something went wrong. Please try again later!',
                          showCross: true,
                          title: null,
                          type: 'error',
                          autoHideDuration: 5000,
                          vertical: 'top',
                          horizontal: 'center',
                        }),
                      );
                    },
                  ),
                );
              }}
              color="primary"
              autoFocus
            >
              Upload
            </Button>
          </Box>
        </>
      ) : activeStep === 1 && activeArtifact?.mediaFileBasedInteraction ? (
        <>
          {transcriptReady && activeArtifact ? (
            <Box display={'flex'} flexDirection={'column'} gap={2}>
              <Box
                sx={{
                  borderRadius: 3,
                  border: '1px solid #e3e3e3',
                }}
              >
                <Box sx={{ background: '#e3e3e3', padding: 2 }}>
                  <Typography color={'text.secondary'} fontWeight={'bold'}>
                    Transcript
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    minHeight: '260px',
                    maxHeight: '260px',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                    whiteSpace: 'pre-wrap',
                    padding: 2,
                  }}
                >
                  {activeArtifact?.customField?.salesReviewFile?.transcript}
                </Typography>
              </Box>
              {!activeArtifact?.mediaFileBasedInteraction ? null : (
                <>
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Who is the salesperson?
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={speaker}
                      onChange={(e) => setSpeaker(e.target.value)}
                    >
                      {speakers &&
                        Object.keys(speakers)
                          ?.sort()
                          ?.map((spk) => (
                            <FormControlLabel
                              key={spk}
                              value={spk}
                              control={<Radio />}
                              label={speakers?.[spk]}
                            />
                          ))}
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    label="Buyer's Participants(Comma Seperated)"
                    variant="outlined"
                    name={'buyerParticipants'}
                    size="small"
                    fullWidth
                    value={buyerParticipants}
                    onChange={(e) => setBuyerParticipants(e.target.value)}
                  />
                  <TextField
                    label="Seller's Participants(Comma Seperated)"
                    variant="outlined"
                    name={'sellerParticipants'}
                    size="small"
                    fullWidth
                    value={sellerParticipants}
                    onChange={(e) => setSellerParticipants(e.target.value)}
                  />
                </>
              )}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  className="processArtifactBtn"
                  variant="contained"
                  disabled={processingArtifact}
                  startIcon={
                    processingArtifact ? <CircularProgress size={20} /> : null
                  }
                  onClick={(e) => {
                    setProcessingArtifact(true);
                    dispatch(
                      updateDealArtifact(
                        dealId || selectedCrmDeal,
                        activeArtifact?.id,
                        {
                          buyers: buyerParticipants?.split(',') || [],
                          sellers: sellerParticipants?.split(',') || [],
                        },
                        (data) => {
                          console.log(data);
                          dispatch(
                            processDealArtifact(
                              dealId || selectedCrmDeal,
                              activeArtifact?.id,
                              {
                                speaker: speaker || 'Speaker 1',
                                speakerConfigMap:
                                  activeArtifact?.customField?.salesReviewFile
                                    ?.speakerConfigMap || {},
                              },
                              () => {
                                setProcessingArtifact(false);
                                setActiveStep(2);
                                setBuyerParticipants('');
                                setSellerParticipants('');
                                // if (
                                //   setShowUploadArtifact &&
                                //   typeof setShowUploadArtifact === 'function'
                                // ) {
                                //   setShowUploadArtifact(false);
                                // }
                                // if (dealId) {
                                //   setViewType('timeline');
                                //   setActiveArtifact(data);
                                // }
                              },
                            ),
                          );
                        },
                        () => {
                          setProcessingArtifact(false);
                          dispatch(
                            showAlert({
                              message:
                                'Oops something went wrong. Please try again later!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 5000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        },
                      ),
                    );
                  }}
                  color="primary"
                  autoFocus
                >
                  Process Artifact
                </Button>
              </Box>
            </Box>
          ) : transcriptionFailed ? (
            <Box
              sx={{
                border: '1px solid #f1f1f1',
                borderRadius: 2,
                display: 'flex',
                padding: '32px 16px',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="body1">
                Failed to generate transcript. Please try uploading the
                recording again.
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                border: '1px solid #f1f1f1',
                borderRadius: 2,
                display: 'flex',
                padding: '32px 16px',
                flexDirection: 'column',
                gap: 2,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress
                size={30}
                sx={{ color: theme.palette.primary.main }}
              />
              <Typography variant="body1">
                Please wait while we generate the transcript ...
              </Typography>
            </Box>
          )}
        </>
      ) : activeStep === 2 ? (
        <>
          <ArtifactRisksSelectionList
            viewType={'uploadArtifact'}
            activeArtifact={activeArtifact}
            successCallback={(risks, data) => {
              if (
                setShowUploadArtifact &&
                typeof setShowUploadArtifact === 'function'
              ) {
                setShowUploadArtifact(false);
                history.push(
                  `/deals/${selectedCrmDeal}?activeArtifact=${activeArtifact?.id}`,
                );
              }
              if (dealId) {
                setViewType('timeline');
                setActiveArtifact((prev) => {
                  return {
                    ...prev,
                    selectedRisks: risks,
                    nextBestActionsMap: data,
                  };
                });
              }
            }}
          />
        </>
      ) : null}
    </Box>
  );
};

ArtifactUpload.propTypes = {
  fullWidth: PropTypes.bool,
  dealId: PropTypes.string,
  refreshList: PropTypes.bool,
  setRefreshList: PropTypes.func,
  setCreateNewDealDialog: PropTypes.func,
  setCreationMode: PropTypes.func,
  setShowUploadArtifact: PropTypes.func,
  setViewType: PropTypes.func,
};

export default ArtifactUpload;
