import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'utils/axios';
import { v4 as uuidv4 } from 'uuid';

const getDealDiscoveryQuestionsList = (treeId, lastSeen, topicId, callback) => {
  return async (dispatch) => {
    if (lastSeen) {
      dispatch({ type: 'FETCHING_DISCOVERY_QUESTION_LIST_WITH_PAGINATION' });
    } else {
      dispatch({ type: 'FETCHING_DISCOVERY_QUESTION_LIST' });
    }
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.get(
            `/us/dt/discovery-tree/${treeId}/questions-list`,
            {
              params: {
                requestId: reqId,
                lastSeen,
                limit: lastSeen ? 1 : null,
                topicId,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            },
          );
          //   console.log(response.data);
          if (response.status === 200) {
            if (lastSeen) {
              await dispatch({
                type:
                  'ADD_MORE_DISCOVERY_QUESTIONS_TO_ALL_DISCOVERY_QUESTIONS_LIST',
                payload: {
                  qList: response?.data,
                },
              });
            } else {
              await dispatch({
                type: 'FETCHING_DISCOVERY_QUESTION_LIST_DONE',
                payload: {
                  qList: response?.data,
                },
              });
            }
            if (typeof callback === 'function') {
              callback(response?.data);
            }
          } else {
            dispatch({
              type: 'FETCHING_DISCOVERY_QUESTION_LIST_FAILED',
              payload: `Failed to fetch discovery question list`,
            });
          }
        } catch (e) {
          dispatch({
            type: 'FETCHING_DISCOVERY_QUESTION_LIST_FAILED',
            payload: `Failed to fetch discovery question list`,
          });
        }
        unsubscribe();
      });
    } catch (e) {
      console.log(e);
      dispatch({
        type: 'FETCHING_DISCOVERY_QUESTION_LIST_FAILED',
        payload: `Failed to fetch discovery question list`,
      });
    }
  };
};

export default getDealDiscoveryQuestionsList;
