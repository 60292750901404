import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import JoyRide, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useHistory } from 'react-router-dom';
import firebase from '../../firebase';
import { useTheme } from '@mui/material';
import setOnboarding from 'redux/actions/Common/setOnboarding';
import PropTypes from 'prop-types';

const Tour = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const tourState = useSelector((state) => state.tourState);
  const onboardingSettings = useSelector((state) => state.onboardingSettings);
  const [showEnd, setShowEnd] = useState(false);
  const { steps } = props;

  // handleJoyrideCallback = data => {
  //   // const { sidebarOpen } = this.state;
  //   const { action, index, type, status } = data;

  //   // if (String([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
  //   //   // Need to set our running state to false, so we can restart if we click start again.
  //   //   this.setState({ run: false, stepIndex: 0 });
  //   // } else if (
  //   //   String([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND]).includes(type)
  //   // ) {
  //   const stepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

  //   //   if (sidebarOpen && index === 0) {
  //   //     setTimeout(() => {
  //   //       this.setState({ run: true });
  //   //     }, 400);
  //   //   } else if (sidebarOpen && index === 1) {
  //   //     this.setState(
  //   //       {
  //   //         run: false,
  //   //         sidebarOpen: false,
  //   //         stepIndex,
  //   //       },
  //   //       () => {
  //   //         setTimeout(() => {
  //   //           this.setState({ run: true });
  //   //         }, 400);
  //   //       },
  //   //     );
  //   //   } else if (index === 2 && action === ACTIONS.PREV) {
  //   //     this.setState(
  //   //       {
  //   //         run: false,
  //   //         sidebarOpen: true,
  //   //         stepIndex,
  //   //       },
  //   //       () => {
  //   //         setTimeout(() => {
  //   //           this.setState({ run: true });
  //   //         }, 400);
  //   //       },
  //   //     );
  //   //   } else {
  //   //     // Update state to advance the tour
  //   //     this.setState({
  //   //       sidebarOpen: false,
  //   //       stepIndex,
  //   //     });
  //   //   }
  //   // }

  //   // console.groupCollapsed(
  //   //   type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type,
  //   // );
  //   // this.setState({
  //   //   run: true,
  //   //   stepIndex,
  //   // });
  //   console.log(data);
  //   // console.groupEnd();
  // };

  const callback = (data) => {
    const { action, index, type, status } = data;

    console.log(action, index, type, status);
    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tourState.run) ||
      status === STATUS.FINISHED
    ) {
      // setTimeout(() => {
      //   dispatch({ type: 'STOP_TOUR' });
      // }, 2000);
      if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'applicant' &&
        onboardingSettings.onBoardingStep === 'addVideoPage'
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'applicant',
            onBoardingStep: 'textOverlayApplicant',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'applicant' &&
        (onboardingSettings.onBoardingStep === 'accountSettings' ||
          (onboardingSettings.onBoardingStep === 'employeeHomePage' &&
            onboardingSettings.stopAt === 'employeeHomePage') ||
          (onboardingSettings.onBoardingStep === 'applicationsTable' &&
            onboardingSettings.stopAt === 'applicationsTable') ||
          (onboardingSettings.onBoardingStep === 'bookmarkedJobs' &&
            onboardingSettings.stopAt === 'bookmarkedJobs'))
      ) {
        dispatch({ type: 'STOP_TOUR' });
        dispatch(
          setOnboarding({
            onBoarding: false,
            stopAt: null,
          }),
        );
        history.push('/deals');
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'applicant' &&
        onboardingSettings.onBoardingStep === 'textOverlayApplicant' &&
        tourState.stepIndex === 2
      ) {
        setTimeout(() => {
          dispatch(
            setOnboarding({
              onBoardingFor: 'applicant',
              onBoardingStep: 'publishVideoApplicant',
            }),
          );
        }, 500);
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'applicant' &&
        onboardingSettings.onBoardingStep === 'videoReviewApplicant' &&
        tourState.stepIndex === 1
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'applicant',
            onBoardingStep: 'finishRecordVideoApplicant',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'applicant' &&
        onboardingSettings.onBoardingStep === 'bookmarkedJobs' &&
        tourState.stepIndex === 3
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'applicant',
            onBoardingStep: 'accountSettings',
          }),
        );
      }
      if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'addVideoPageJob'
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'employer',
            onBoardingStep: 'textOverlayJob',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        (onboardingSettings.onBoardingStep === 'accountSettings' ||
          onboardingSettings.onBoardingStep === 'shortlistPage' ||
          (onboardingSettings.onBoardingStep === 'allJobs' &&
            onboardingSettings.stopAt === 'allJobs') ||
          (onboardingSettings.onBoardingStep === 'employerHomePage' &&
            onboardingSettings.stopAt === 'employerHomePage') ||
          (onboardingSettings.onBoardingStep === 'applicantsDetailed' &&
            onboardingSettings.stopAt === 'applicantsDetailed' &&
            tourState.stepIndex === 7) ||
          (onboardingSettings.onBoardingStep === 'starred' &&
            onboardingSettings.stopAt === 'starred'))
      ) {
        dispatch({ type: 'DRAWER_STATE', payload: false });
        firebase.analytics().logEvent('onboarding_end', {
          onBoardingfor: onboardingSettings.onBoardingStep,
        });
        dispatch({ type: 'STOP_TOUR' });
        dispatch(
          setOnboarding({
            onBoarding: false,
            stopAt: null,
          }),
        );
        if (localStorage.getItem('recordedVideoThumbnail')) {
          localStorage.removeItem('recordedVideoThumbnail');
        }
        dispatch({
          type: 'FETCHING_JOB_LIKED_BY_DONE',
          payload: [],
        });
        dispatch({ type: 'DRAWER_STATE', payload: false });
        history.push('/main');
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'textOverlayJob' &&
        tourState.stepIndex === 2
      ) {
        setTimeout(() => {
          dispatch(
            setOnboarding({
              onBoardingFor: 'employer',
              onBoardingStep: 'publishVideoJob',
            }),
          );
        }, 500);
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'videoReviewJob' &&
        tourState.stepIndex === 1
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'employer',
            onBoardingStep: 'finishRecordVideoJob',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'applicants' &&
        tourState.stepIndex === 2
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'employer',
            onBoardingStep: 'applicantsDetailed',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'starred' &&
        tourState.stepIndex === 2
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'employer',
            onBoardingStep: 'accountSettings',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'acceptedPage' &&
        tourState.stepIndex === 2
      ) {
        setTimeout(() => {
          dispatch(
            setOnboarding({
              onBoardingFor: 'employer',
              onBoardingStep: 'starred',
            }),
          );
        }, 500);
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'employer' &&
        onboardingSettings.onBoardingStep === 'activateJob'
      ) {
        dispatch({ type: 'MAKE_DEMO_JOB_ACTIVE', payload: true });
        setTimeout(() => {
          dispatch(
            setOnboarding({
              onBoardingFor: 'employer',
              onBoardingStep: 'applicants',
            }),
          );
        }, 500);
      }
      if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'agency' &&
        (onboardingSettings.onBoardingStep === 'accountSettings' ||
          (onboardingSettings.onBoardingStep === 'connectedApplicantsBtn' &&
            onboardingSettings.stopAt === 'connectedApplicantsBtn') ||
          (onboardingSettings.onBoardingStep === 'candidatesPage' &&
            onboardingSettings.stopAt === 'candidatesPage') ||
          (onboardingSettings.onBoardingStep === 'jobsPage' &&
            onboardingSettings.stopAt === 'jobsPage') ||
          (onboardingSettings.onBoardingStep === 'applicationsInProcess' &&
            onboardingSettings.stopAt === 'applicationsInProcess') ||
          (onboardingSettings.onBoardingStep === 'agencyHome' &&
            onboardingSettings.stopAt === 'agencyHome'))
      ) {
        firebase.analytics().logEvent('onboarding_end', {
          onBoardingfor: onboardingSettings.onBoardingStep,
        });
        dispatch({ type: 'STOP_TOUR' });
        dispatch(
          setOnboarding({
            onBoarding: false,
            stopAt: null,
          }),
        );
        dispatch({ type: 'DRAWER_STATE', payload: false });
        history.push('/main');
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'agency' &&
        onboardingSettings.onBoardingStep === 'agencyHome'
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'agency',
            onBoardingStep: 'accountSettings',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'agency' &&
        onboardingSettings.onBoardingStep === 'inviteCandidatePage'
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'agency',
            onBoardingStep: 'connectedApplicantsBtn',
          }),
        );
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'agency' &&
        onboardingSettings.onBoardingStep === 'candidatesPage'
      ) {
        setTimeout(() => {}, 2000);
        setTimeout(() => {
          dispatch(
            setOnboarding({
              onBoardingFor: 'agency',
              onBoardingStep: 'jobsPageBtn',
            }),
          );
        }, 2500);
      } else if (
        onboardingSettings.onBoarding &&
        onboardingSettings.onBoardingFor === 'agency' &&
        onboardingSettings.onBoardingStep === 'applicationsInProcess'
      ) {
        dispatch(
          setOnboarding({
            onBoardingFor: 'agency',
            onBoardingStep: 'agencyHomeBtn',
          }),
        );
      }
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch({
        type: 'NEXT_OR_PREV',
        payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      });
      // if (
      //   onboardingSettings.onBoarding &&
      //   onboardingSettings.onBoardingFor === 'applicant' &&
      //   onboardingSettings.onBoardingStep === 'inviteForAFakeJob' &&
      //   tourState.stepIndex === 0
      // ) {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      //   });
      // } else {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      //   });
      // }
      // if (
      //   onboardingSettings.onBoarding &&
      //   onboardingSettings.onBoardingFor === 'employer' &&
      //   onboardingSettings.onBoardingStep === 'inviteAFakeEmployee' &&
      //   tourState.stepIndex === 0
      // ) {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      //   });
      // } else {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) },
      //   });
      // }
      // if (
      //   onboardingSettings.onBoarding &&
      //   onboardingSettings.onBoardingFor === 'applicant' &&
      //   onboardingSettings.onBoardingStep === 'addVideoPage'
      // ) {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + 2 },
      //   });
      // }
      // if (
      //   onboardingSettings.onBoarding &&
      //   onboardingSettings.onBoardingFor === 'employer' &&
      //   onboardingSettings.onBoardingStep === 'addVideoPageJob'
      // ) {
      //   dispatch({
      //     type: 'NEXT_OR_PREV',
      //     payload: { stepIndex: index + 2 },
      //   });
      // }
    }
    // if (
    //   onboardingSettings.onBoarding &&
    //   onboardingSettings.onBoardingFor === 'employer' &&
    //   onboardingSettings.onBoardingStep === 'finishRecordVideoJob' &&
    //   tourState.stepIndex === 0
    // ) {
    //   window.scrollTo({
    //     top: 700,
    //     left: 0,
    //     behavior: 'smooth',
    //   });
    // }
    // if (
    //   onboardingSettings.onBoarding &&
    //   onboardingSettings.onBoardingFor === 'applicant' &&
    //   onboardingSettings.onBoardingStep === 'finishRecordVideoApplicant' &&
    //   tourState.stepIndex === 0
    // ) {
    //   window.scrollTo({
    //     top: 700,
    //     left: 0,
    //     behavior: 'smooth',
    //   });
    // }
    // console.log(data);
  };

  useEffect(() => {
    if (
      onboardingSettings.onBoarding &&
      onboardingSettings.onBoardingFor === 'orgAdmin' &&
      onboardingSettings.onBoardingStep === 'start' &&
      tourState.stepIndex === 9
    ) {
      setShowEnd(true);
    } else {
      setShowEnd(false);
    }
  }, [onboardingSettings]);

  // console.log(tourState);

  return (
    <JoyRide
      {...tourState}
      steps={steps}
      callback={callback}
      // hideBackButton={true}
      disableScrolling={true}
      showSkipButton={true}
      floaterProps={{}}
      styles={{
        tooltipContainer: {
          textAlign: 'left',
        },
        buttonNext: {
          backgroundColor: theme.palette.primary.main,
        },
        buttonBack: {
          marginRight: 10,
          color: theme.palette.primary.main,
        },
        tooltip: {
          display: 'block',
        },
      }}
      locale={{
        next: tourState.stepIndex === 0 ? 'Start' : 'Next',
        last: showEnd ? 'End' : 'Next',
      }}
    />
  );
};

Tour.propTypes = {
  steps: PropTypes.array,
};

export default Tour;
