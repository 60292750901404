const initialstate = {
  onBoarding: false,
  onBoardingStep: null,
  onBoardingFor: null,
  stopAt: null,
};

const setOnboardingReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'SET_ONBOARDING':
      return Object.assign({}, state, {
        onBoarding:
          action.payload.onBoarding === undefined
            ? true
            : action.payload.onBoarding,
        onBoardingStep: action.payload.onBoardingStep,
        onBoardingFor: action.payload.onBoardingFor,
        stopAt:
          action.payload.stopAt || action.payload.stopAt === null
            ? action.payload.stopAt
            : state.stopAt,
      });
    default:
      return state;
  }
};

export default setOnboardingReducer;
