const initialstate = {
  fetching: null,
  fetchingPagination: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  qList: null,
  emptyList: null,
  lastSeen: null,
};

const getDiscoveryQuestionsListReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_DISCOVERY_QUESTION_LIST':
      return Object.assign({}, state, {
        fetching: true,
        fetchingPagination: false,
      });
    case 'FETCHING_DISCOVERY_QUESTION_LIST_WITH_PAGINATION':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: true,
      });
    case 'FETCHING_DISCOVERY_QUESTION_LIST_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        qList: action.payload.qList,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        emptyList: false,
      });
    case 'ADD_MORE_DISCOVERY_QUESTIONS_TO_ALL_DISCOVERY_QUESTIONS_LIST':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingPagination: false,
        fetchingFailed: false,
        qList: Array.isArray(action.payload.qList)
          ? state.qList
            ? state.qList.concat(action.payload.qList)
            : action.payload.qList
          : state.qList,
        emptyList:
          Array.isArray(action.payload.qList) &&
          (action.payload.qList.length === 0 ||
            action.payload.qList.length % 10 > 0)
            ? true
            : false,
        lastSeen: action.payload.lastSeen
          ? action.payload.lastSeen
          : state.lastSeen,
        error: false,
        errorText: '',
      });
    case 'UPDATE_DISCOVERY_QUESTION': {
      const updatedQList = state.qList.map((qs) => {
        if (qs?.id === action.payload.id) {
          return action.payload.data;
        } else {
          return qs;
        }
      });
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        qList: updatedQList,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK': {
      return Object.assign({}, state, {
        fetching: false,
        fetchingPagination: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        qList: action.payload.data,
        lastSeen: state.lastSeen,
        emptyList: state.emptyList,
        error: state.error,
        errorText: state.errorText,
      });
    }
    case 'FETCHING_DISCOVERY_QUESTION_LIST_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: false,
        fetchingPagination: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        qList: [],
        emptyList: false,
        lastSeen: '',
      });

    case 'FETCHING_DISCOVERY_QUESTION_LIST_PAGINATION_FAILED':
      return Object.assign({}, state, {
        fetchingPagination: false,
      });
    default:
      return state;
  }
};

export default getDiscoveryQuestionsListReducer;
