import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import { Add, Check, Sync } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';
import QuestionNode from 'components/QuestionNode';
import update from 'immutability-helper';

const DragNDropSortableList = memo(function DragNDropSortableList({
  qList,
  questionTopics,
  questionSubTopics,
}) {
  const dispatch = useDispatch();
  const findCard = useCallback(
    (id) => {
      const question = qList.filter((qs) => `${qs.id}` === id)[0];
      return {
        question,
        index: qList.indexOf(question),
      };
    },
    [qList],
  );

  const moveCard = useCallback(
    (id, atIndex) => {
      const { question, index } = findCard(id);
      dispatch({
        type: 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
        payload: {
          data: update(qList, {
            $splice: [
              [index, 1],
              [atIndex, 0, question],
            ],
          }),
        },
      });
    },
    [findCard, qList],
  );

  const [, drop] = useDrop(() => ({ accept: 'questions' }));

  return (
    <Box
      ref={drop}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      {qList
        ?.filter((qs) => !qs?.deleted && qs?.pinned)
        ?.map((dq, index) => (
          <QuestionNode
            key={dq?.id}
            id={dq?.id}
            question={dq}
            index={index}
            moveCard={moveCard}
            findCard={findCard}
            questionTopics={questionTopics}
            questionSubTopics={questionSubTopics}
          />
        ))}
    </Box>
  );
});

DragNDropSortableList.propTypes = {
  qList: PropTypes.object,
  questionTopics: PropTypes.object,
  questionSubTopics: PropTypes.object,
};

export default DragNDropSortableList;
