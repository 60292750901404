import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { alpha, useTheme } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { detect } from 'detect-browser';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import ProfileCompletion from 'components/ProfileCompletion/ProfileCompletion';
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  PushPin,
} from '@mui/icons-material';
import { regex1, regex2, regex3, uuidRegex } from 'utils';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';

const NavItem = ({
  id,
  title,
  items,
  href,
  startIcon,
  action,
  highlighted,
  teamMode,
  forPinnedTeam,
  team,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;
  const { competitionIdOrSlug, participationId } = useParams();
  const {
    dashboardSidebarExpanded,
    currentContextData,
    loggedInUserTeamDetails,
    pinnedTeams,
    currentTeamToManage,
    navExpandedStatus,
  } = useSelector((state) => state.uiStates);
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const browser = detect();
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const { userDetails } = useSelector((state) => state.userDetails);

  const { percentCompleted } = useSelector(
    (state) => state.profileCompletionState,
  );

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const pathRegex = new RegExp(`^${href}$`, 'i');

  return (
    <Box id={id} className={id}>
      <Tooltip
        title={dashboardSidebarExpanded ? '' : title}
        arrow
        placement="right"
      >
        <Box
          data-trackid={`open_${title}`}
          sx={{
            padding: '12px 24px',
            textAlign: 'center',
            background:
              pathRegex.test(pathname) || highlighted
                ? theme.palette.background.level1
                : 'transparent',
            borderRight:
              pathRegex.test(pathname) || highlighted
                ? `2px solid ${theme.palette.primary.light}`
                : 'none',
            // marginBottom: 1,
            cursor: 'pointer',
            ':hover': {
              background: theme.palette.background.level1,
            },
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={(e) => {
            handleTrackEvent(e, `open_${title}`);
            // if (team) {
            //   dispatch(
            //     updateUserDetails(
            //       {
            //         userSetting: {
            //           navbarTeamCollapsState: {
            //             ...navExpandedStatus,
            //             [team?.id]: navExpandedStatus?.[team?.id]
            //               ? false
            //               : true,
            //           },
            //         },
            //       },
            //       () => {
            //         dispatch({
            //           type: 'UPDATE_UI_STATE',
            //           payload: {
            //             key: 'navExpandedStatus',
            //             value: {
            //               ...navExpandedStatus,
            //               [team?.id]: navExpandedStatus?.[team?.id]
            //                 ? false
            //                 : true,
            //             },
            //           },
            //         });
            //       },
            //     ),
            //   );
            // } else {
            //   dispatch(
            //     updateUserDetails(
            //       {
            //         userSetting: {
            //           navbarTeamCollapsState: {
            //             ...navExpandedStatus,
            //             [title?.trim()]: navExpandedStatus?.[title?.trim()]
            //               ? false
            //               : true,
            //           },
            //         },
            //       },
            //       () => {
            //         dispatch({
            //           type: 'UPDATE_UI_STATE',
            //           payload: {
            //             key: 'navExpandedStatus',
            //             value: {
            //               ...navExpandedStatus,
            //               [title?.trim()]: navExpandedStatus?.[title?.trim()]
            //                 ? false
            //                 : true,
            //             },
            //           },
            //         });
            //       },
            //     ),
            //   );
            // }
            // if (forPinnedTeam) {
            //   dispatch({
            //     type: 'UPDATE_UI_STATE',
            //     payload: {
            //       key: 'currentTeamToManage',
            //       value: null,
            //     },
            //   });
            // }
            // if (
            //   (team && !navExpandedStatus?.[team?.id]) ||
            //   (!team && !navExpandedStatus?.[title?.trim()])
            // ) {
            if (href) {
              dispatch({
                type: 'UPDATE_UI_STATE',
                payload: {
                  key: 'currentContextData',
                  value: null,
                },
              });
              if (
                href !== window.location.href.split(window.location.origin)?.[1]
              ) {
                history.push(href);
              }
            } else {
              action();
            }
            // }
          }}
        >
          {/* {
            <Box
              sx={{
                visibility: items?.length > 0 ? 'visible' : 'hidden',
              }}
            > */}
          {/* {userInfo?.orgRoles?.[0] === 'ORG_ADMIN' ||
          userInfo?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
            team ? (
              navExpandedStatus?.[team?.id] ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowRight />
              )
            ) : navExpandedStatus?.[title?.trim()] ? (
              <KeyboardArrowDown />
            ) : (
              <KeyboardArrowRight />
            )
          ) : null} */}
          {/* </Box>
          } */}

          {typeof startIcon === 'string' ? (
            <img
              src={startIcon}
              alt=""
              style={{
                filter: pathRegex.test(pathname) ? 'none' : 'invert(0.5)',
                width: 20,
                height: 20,
              }}
            />
          ) : (
            startIcon
          )}
          {dashboardSidebarExpanded ? (
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              width={1}
              gap={1}
            >
              <Tooltip
                arrow
                placement="right"
                title={
                  title?.replace(
                    Object.keys(
                      orgConfigs?.navBarTitles || {},
                    )?.find((feature) => title?.includes(feature)),
                    orgConfigs?.navBarTitles?.[
                      Object.keys(
                        orgConfigs?.navBarTitles || {},
                      )?.find((feature) => title?.includes(feature))
                    ]?.title || title,
                  )?.length > 10
                    ? title?.replace(
                        Object.keys(
                          orgConfigs?.navBarTitles || {},
                        )?.find((feature) => title?.includes(feature)),
                        orgConfigs?.navBarTitles?.[
                          Object.keys(
                            orgConfigs?.navBarTitles || {},
                          )?.find((feature) => title?.includes(feature))
                        ]?.title || title,
                      )
                    : ''
                }
              >
                <Typography
                  sx={{
                    color:
                      pathRegex.test(pathname) || highlighted
                        ? theme.palette.text.primary
                        : theme.palette.text.secondary,
                    fontWeight:
                      pathRegex.test(pathname) || highlighted ? 600 : 500,
                    marginLeft: href === '/posted-jobs' ? 1.5 : 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: 150,
                    textAlign: 'left',
                  }}
                >
                  {title?.replace(
                    Object.keys(
                      orgConfigs?.navBarTitles || {},
                    )?.find((feature) => title?.includes(feature)),
                    orgConfigs?.navBarTitles?.[
                      Object.keys(
                        orgConfigs?.navBarTitles || {},
                      )?.find((feature) => title?.includes(feature))
                    ]?.title || title,
                  )}
                </Typography>
              </Tooltip>
              {title === 'Profile' && percentCompleted < 100 ? (
                <ProfileCompletion
                  percentCompleted={percentCompleted}
                  circleSize={30}
                  circleTextSize={8}
                  fontSize={14}
                  thickness={4}
                  dontShowExtraText={true}
                />
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Tooltip>
      {(pathname === `/practice/interview` ||
        pathname === `/practice/pitch` ||
        pathname === `/practice/script` ||
        pathname === `/sales-call-analysis` ||
        pathname.startsWith('/teams') ||
        pathname.startsWith('/deals') ||
        // regex1.test(pathname) ||
        // regex2.test(pathname) ||
        // regex3.test(pathname) ||
        pathname === `/teams/${currentTeamToManage?.id}/sales-call-analysis` ||
        pathname === `/teams/${currentTeamToManage?.id}/conversation-ai` ||
        pathname === `/teams/${currentTeamToManage?.id}/team-settings` ||
        pathname === `/cro-interviews` ||
        pathname === `/accounts` ||
        pathname === `/compass-framework` ||
        pathname === `/compass-helps` ||
        pathname === `/tasks` ||
        pathname === `/digital-twin` ||
        pathname === `/ask-expert` ||
        pathname === `/conversation-ai` ||
        pathname === `/settings` ||
        pathname === `/profile-settings` ||
        pathname === `/customer/settings` ||
        pathname === `/performance` ||
        // pathname === `/org-settings` ||
        pathname === `/team-settings` ||
        pathname === `/org-settings` ||
        pathname === `/teams` ||
        pathname === `/all-teams` ||
        pathname === `/pricing` ||
        pathname === `/playbook` ||
        pathname === `/integrations` ||
        pathname === `/practice-calls-vapi` ||
        pathname === `/profile` ||
        pathname === `/discovery-template` ||
        pathname === `/deal-discovery` ||
        pathname === `/deals` ||
        pathname === `/practice/contests`) &&
      (title === 'Practice' ||
        title.trim() === 'CRO Interviews' ||
        title.trim() === 'Accounts' ||
        title.trim() === 'Compass Framework' ||
        title.trim() === 'Compass Helps' ||
        title.trim() === 'Sales Call Analysis' ||
        title.trim() === 'Tasks' ||
        title.trim() === 'Ask Expert' ||
        title.trim() === 'John Barrows' ||
        pinnedTeams?.find((team) => team?.name === title.trim()) ||
        title.trim() === `${currentTeamToManage?.name}` ||
        title.trim() ===
          `Ask ${userDetails?.custom?.expertBot?.title || 'Ron'}` ||
        title.trim() === 'Brynne Tillman' ||
        title.trim() === 'John Stopper' ||
        title.trim() === 'Private Roleplays' ||
        title.trim() === 'My Performance' ||
        title.trim() === 'Organization' ||
        title.trim() === 'Tools' ||
        title.trim() === 'My Teams' ||
        title.trim() === 'My Team' ||
        // title.trim() === 'My ORG' ||
        title.trim() === 'Clients Dashboard' ||
        title.trim() === 'Practice Call v2(vapi)' ||
        title.trim() === 'My Organization' ||
        title.trim() === 'My Profile' ||
        title.trim() === 'Playbook' ||
        title.trim() === 'Integrations' ||
        title.trim() === 'Setup' ||
        title.trim() === 'Deals' ||
        title.trim() === loggedInUserTeamDetails?.name ||
        title.trim() === 'Settings') &&
      // ((team && navExpandedStatus?.[team?.id]) ||
      //   (!team && navExpandedStatus?.[title?.trim()])) &&
      dashboardSidebarExpanded
        ? items?.map((item) => {
            // console.log(item);
            return (
              <Box
                id={item?.id || ''}
                key={item?.name}
                sx={{
                  display: item?.disabled ? 'none' : 'block',
                }}
              >
                <Tooltip
                  key={item?.name}
                  title={dashboardSidebarExpanded ? '' : item?.name}
                  arrow
                  placement="right"
                >
                  <Box
                    sx={{
                      padding: '8px 32px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 1,
                      gap: 1,
                    }}
                    data-trackid={`open_${item?.name}`}
                    onClick={(e) => {
                      handleTrackEvent(e, `open_${item?.name}`);
                      if (item?.href) {
                        history.push(item?.href);
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'currentContextData',
                            value: null,
                          },
                        });
                      } else {
                        action();
                      }
                    }}
                  >
                    {typeof item?.startIcon === 'string' ? (
                      item?.startIcon?.trim() ? (
                        <img
                          src={item?.startIcon}
                          alt=""
                          style={{
                            filter: pathRegex.test(pathname)
                              ? 'none'
                              : 'invert(0.5)',
                            width: 20,
                            height: 20,
                          }}
                        />
                      ) : (
                        <>&nbsp;</>
                      )
                    ) : (
                      item?.startIcon
                    )}
                    {dashboardSidebarExpanded ? (
                      <Tooltip
                        title={
                          item?.name?.split('')?.length > 20 ? item?.name : ''
                        }
                        arrow
                        placement="bottom"
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` === item?.href ||
                              item?.highlighted
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` === item?.href ||
                              item?.highlighted
                                ? 600
                                : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            '&::after': {
                              content:
                                item?.name?.split('')?.length > 24
                                  ? '"..."'
                                  : '""',
                              position: 'absolute',
                              right: 0,
                              top: 0,
                              padding: '0 5px',
                              background:
                                `${pathname}${search}` === item?.href ||
                                item?.highlighted
                                  ? theme.palette.background.level1
                                  : 'transparent',
                            },
                          }}
                        >
                          {item?.name
                            ?.replace(
                              Object.keys(
                                orgConfigs?.navBarTitles?.[
                                  Object.keys(
                                    orgConfigs?.navBarTitles || {},
                                  )?.find((feature) => title?.includes(feature))
                                ]?.subTitles || {},
                              )?.find((subFeature) =>
                                item?.name?.includes(subFeature),
                              ),
                              orgConfigs?.navBarTitles?.[
                                Object.keys(
                                  orgConfigs?.navBarTitles || {},
                                )?.find((feature) => title?.includes(feature))
                              ]?.subTitles?.[
                                Object.keys(
                                  orgConfigs?.navBarTitles?.[
                                    Object.keys(
                                      orgConfigs?.navBarTitles || {},
                                    )?.find((feature) =>
                                      title?.includes(feature),
                                    )
                                  ]?.subTitles || {},
                                )?.find((subFeature) =>
                                  item?.name?.includes(subFeature),
                                )
                              ]?.title || item?.name,
                            )
                            ?.replace(
                              Object.keys(
                                orgConfigs?.navBarTitles || {},
                              )?.find((feature) =>
                                item?.name?.includes(feature),
                              ),
                              orgConfigs?.navBarTitles?.[
                                Object.keys(
                                  orgConfigs?.navBarTitles || {},
                                )?.find((feature) =>
                                  item?.name?.includes(feature),
                                )
                              ]?.title || item?.name,
                            )}
                        </Typography>
                      </Tooltip>
                    ) : null}
                  </Box>
                </Tooltip>
                {pathname === `/accounts` &&
                item?.name?.trim() === 'All Accounts' &&
                dashboardSidebarExpanded ? (
                  currentContextData?.title ? (
                    <>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded
                            ? ''
                            : currentContextData?.title
                        }
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 4,
                            gap: 1,
                          }}
                          data-trackid={`open_${currentContextData?.title}`}
                          onClick={(e) => {
                            handleTrackEvent(
                              e,
                              `open_${currentContextData?.title}`,
                            );
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'view',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color: currentContextData?.title
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                              fontWeight: currentContextData?.title ? 600 : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              '&::after': {
                                content:
                                  currentContextData?.title?.split('')?.length >
                                  20
                                    ? '"..."'
                                    : '""',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                padding: '0 5px',
                                background: currentContextData?.title
                                  ? theme.palette.background.level1
                                  : 'transparent',
                              },
                            }}
                          >
                            {currentContextData?.title}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Overview'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_overview`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_overview`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'view',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'view'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'view' ? 600 : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            Overview
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Compass'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_compass`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_compass`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'manage',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'manage'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'manage'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            Compass
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded ? '' : 'Organization Chart'
                        }
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 6,
                            gap: 1,
                          }}
                          data-trackid={`open_org_chart`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_org_chart`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  title: currentContextData?.title,
                                  tab: 'orgchart',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.tab === 'orgchart'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.tab === 'orgchart'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            Org Chart
                          </Typography>
                        </Box>
                      </Tooltip>
                    </>
                  ) : null
                ) : null}
                {/* {pathname === `/teams` &&
                item?.name?.trim() === 'All Teams' &&
                dashboardSidebarExpanded ? (
                  currentContextData?.currentTeam ? (
                    <>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded
                            ? ''
                            : currentContextData?.currentTeam
                        }
                        arrow
                        placement="top"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 3,
                            gap: 1,
                          }}
                          data-trackid={`open_${currentContextData?.currentTeam}`}
                          onClick={(e) => {
                            handleTrackEvent(
                              e,
                              `open_${currentContextData?.currentTeam}`,
                            );
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  currentTeam: currentContextData?.currentTeam,
                                  currentTeamTab: 'manageusers',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color: currentContextData?.currentTeam
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                              fontWeight: currentContextData?.currentTeam
                                ? 600
                                : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              '&::after': {
                                content:
                                  currentContextData?.currentTeam?.split('')
                                    ?.length > 20
                                    ? '"..."'
                                    : '""',
                                position: 'absolute',
                                right: 0,
                                top: 0,
                                padding: '0 5px',
                                background: currentContextData?.currentTeam
                                  ? '#fff'
                                  : 'transparent',
                              },
                            }}
                          >
                            {currentContextData?.currentTeam}
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={
                          dashboardSidebarExpanded ? '' : 'Score Dashboard'
                        }
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 5,
                            gap: 1,
                          }}
                          data-trackid={`open_score_dashboard`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_score_dashboard`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  currentTeam: currentContextData?.currentTeam,
                                  currentTeamTab: 'scoresboard',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.currentTeamTab ===
                                'scoresboard'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.currentTeamTab ===
                                'scoresboard'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            Score Dashboard
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Team Dashboard'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 5,
                            gap: 1,
                          }}
                          data-trackid={`open_manage_members`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_manage_members`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  currentTeam: currentContextData?.currentTeam,
                                  currentTeamTab: 'manageusers',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.currentTeamTab ===
                                'manageusers'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.currentTeamTab ===
                                'manageusers'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                            }}
                          >
                            Team Dashboard
                          </Typography>
                        </Box>
                      </Tooltip>
                      <Tooltip
                        title={dashboardSidebarExpanded ? '' : 'Team Settings'}
                        arrow
                        placement="right"
                      >
                        <Box
                          sx={{
                            padding: '8px 32px',
                            textAlign: 'center',
                            // background:
                            //   `${pathname}${search}` === item?.href
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // borderRight:
                            //   `${pathname}${search}` === item?.href
                            //     ? `2px solid ${theme.palette.primary.light}`
                            //     : 'none',
                            // marginBottom: 1,
                            cursor: 'pointer',
                            // ':hover': {
                            //   background: theme.palette.background.level1,
                            // },
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 5,
                            gap: 1,
                          }}
                          data-trackid={`open_team_settings`}
                          onClick={(e) => {
                            handleTrackEvent(e, `open_team_settings`);
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'currentContextData',
                                value: {
                                  currentTeam: currentContextData?.currentTeam,
                                  currentTeamTab: 'teamsettings',
                                },
                              },
                            });
                          }}
                        >
                          <Typography
                            sx={{
                              color:
                                currentContextData?.currentTeamTab ===
                                'teamsettings'
                                  ? theme.palette.primary.main
                                  : theme.palette.text.secondary,
                              fontWeight:
                                currentContextData?.currentTeamTab ===
                                'teamsettings'
                                  ? 600
                                  : 500,
                              fontSize: 14,
                              display: 'flex',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              width: '100%',
                              position: 'relative',
                              // '&::after': {
                              //   content:
                              //     currentContextData?.currentTeam?.split('')?.length >
                              //     20
                              //       ? '"..."'
                              //       : '""',
                              //   position: 'absolute',
                              //   right: 0,
                              //   top: 0,
                              //   padding: '0 5px',
                              //   background: currentContextData?.currentTeam
                              //     ? theme.palette.background.level1
                              //     : 'transparent',
                              // },
                            }}
                          >
                            Team Settings
                          </Typography>
                        </Box>
                      </Tooltip>
                    </>
                  ) : null
                ) : null} */}
                {item?.name?.trim() === 'Organization Settings' &&
                (pathname === '/settings' || pathname === '/pricing') &&
                dashboardSidebarExpanded ? (
                  <>
                    {/* <Tooltip
                      title={dashboardSidebarExpanded ? '' : 'Playbooks'}
                      arrow
                      placement="right"
                    >
                      <Box
                        sx={{
                          padding: '8px 32px',
                          textAlign: 'center',
                          // background:
                          //   `${pathname}${search}` === item?.href
                          //     ? theme.palette.background.level1
                          //     : 'transparent',
                          // borderRight:
                          //   `${pathname}${search}` === item?.href
                          //     ? `2px solid ${theme.palette.primary.light}`
                          //     : 'none',
                          // marginBottom: 1,
                          cursor: 'pointer',
                          // ':hover': {
                          //   background: theme.palette.background.level1,
                          // },
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 5,
                          gap: 1,
                        }}
                        data-trackid={`open_org_playbook_settings`}
                        onClick={(e) => {
                          handleTrackEvent(e, `open_org_playbook_settings`);
                          history.push('/settings?t=playbook');
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` === '/settings?t=playbook'
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` === '/settings?t=playbook'
                                ? 600
                                : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            // '&::after': {
                            //   content:
                            //     currentContextData?.currentTeam?.split('')?.length >
                            //     20
                            //       ? '"..."'
                            //       : '""',
                            //   position: 'absolute',
                            //   right: 0,
                            //   top: 0,
                            //   padding: '0 5px',
                            //   background: currentContextData?.currentTeam
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // },
                          }}
                        >
                          Playbooks
                        </Typography>
                      </Box>
                    </Tooltip> */}
                    <Tooltip
                      title={dashboardSidebarExpanded ? '' : 'Integrations'}
                      arrow
                      placement="right"
                    >
                      <Box
                        sx={{
                          padding: '8px 32px',
                          textAlign: 'center',
                          // background:
                          //   `${pathname}${search}` === item?.href
                          //     ? theme.palette.background.level1
                          //     : 'transparent',
                          // borderRight:
                          //   `${pathname}${search}` === item?.href
                          //     ? `2px solid ${theme.palette.primary.light}`
                          //     : 'none',
                          // marginBottom: 1,
                          cursor: 'pointer',
                          // ':hover': {
                          //   background: theme.palette.background.level1,
                          // },
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 5,
                          gap: 1,
                        }}
                        data-trackid={`open_org_bots_listing`}
                        onClick={(e) => {
                          handleTrackEvent(e, `open_org_bots_listing`);
                          history.push(`/settings?t=integrations`);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` ===
                              '/settings?t=integrations'
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` ===
                              '/settings?t=integrations'
                                ? 600
                                : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            // '&::after': {
                            //   content:
                            //     currentContextData?.currentTeam?.split('')?.length >
                            //     20
                            //       ? '"..."'
                            //       : '""',
                            //   position: 'absolute',
                            //   right: 0,
                            //   top: 0,
                            //   padding: '0 5px',
                            //   background: currentContextData?.currentTeam
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // },
                          }}
                        >
                          Integrations
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={dashboardSidebarExpanded ? '' : 'Ask Donna'}
                      arrow
                      placement="right"
                    >
                      <Box
                        sx={{
                          padding: '8px 32px',
                          textAlign: 'center',
                          // background:
                          //   `${pathname}${search}` === item?.href
                          //     ? theme.palette.background.level1
                          //     : 'transparent',
                          // borderRight:
                          //   `${pathname}${search}` === item?.href
                          //     ? `2px solid ${theme.palette.primary.light}`
                          //     : 'none',
                          // marginBottom: 1,
                          cursor: 'pointer',
                          // ':hover': {
                          //   background: theme.palette.background.level1,
                          // },
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 5,
                          gap: 1,
                        }}
                        data-trackid={`open_org_ask_donna`}
                        onClick={(e) => {
                          handleTrackEvent(e, `open_org_ask_donna`);
                          history.push(`/settings?t=donna-presets`);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` ===
                              '/settings?t=donna-presets'
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` ===
                              '/settings?t=donna-presets'
                                ? 600
                                : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            // '&::after': {
                            //   content:
                            //     currentContextData?.currentTeam?.split('')?.length >
                            //     20
                            //       ? '"..."'
                            //       : '""',
                            //   position: 'absolute',
                            //   right: 0,
                            //   top: 0,
                            //   padding: '0 5px',
                            //   background: currentContextData?.currentTeam
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // },
                          }}
                        >
                          Ask Donna
                        </Typography>
                      </Box>
                    </Tooltip>
                    <Tooltip
                      title={dashboardSidebarExpanded ? '' : 'Plans'}
                      arrow
                      placement="right"
                    >
                      <Box
                        sx={{
                          padding: '8px 32px',
                          textAlign: 'center',
                          // background:
                          //   `${pathname}${search}` === item?.href
                          //     ? theme.palette.background.level1
                          //     : 'transparent',
                          // borderRight:
                          //   `${pathname}${search}` === item?.href
                          //     ? `2px solid ${theme.palette.primary.light}`
                          //     : 'none',
                          // marginBottom: 1,
                          cursor: 'pointer',
                          // ':hover': {
                          //   background: theme.palette.background.level1,
                          // },
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 5,
                          gap: 1,
                        }}
                        data-trackid={`open_org_ask_donna_settings`}
                        onClick={(e) => {
                          handleTrackEvent(e, `open_org_ask_donna_settings`);
                          history.push(`/pricing`);
                        }}
                      >
                        <Typography
                          sx={{
                            color:
                              `${pathname}${search}` === '/pricing'
                                ? theme.palette.primary.main
                                : theme.palette.text.secondary,
                            fontWeight:
                              `${pathname}${search}` === '/pricing' ? 600 : 500,
                            fontSize: 14,
                            display: 'flex',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            width: '100%',
                            position: 'relative',
                            // '&::after': {
                            //   content:
                            //     currentContextData?.currentTeam?.split('')?.length >
                            //     20
                            //       ? '"..."'
                            //       : '""',
                            //   position: 'absolute',
                            //   right: 0,
                            //   top: 0,
                            //   padding: '0 5px',
                            //   background: currentContextData?.currentTeam
                            //     ? theme.palette.background.level1
                            //     : 'transparent',
                            // },
                          }}
                        >
                          Plans
                        </Typography>
                      </Box>
                    </Tooltip>
                  </>
                ) : null}
              </Box>
            );
          })
        : null}
    </Box>
  );
};

NavItem.propTypes = {
  id: PropTypes.string,
  items: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  startIcon: PropTypes.any,
  onClose: PropTypes.func,
  action: PropTypes.func,
  showSecondaryItems: PropTypes.bool,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  teamMode: PropTypes.string,
  forPinnedTeam: PropTypes.bool,
  team: PropTypes.object,
};

export default NavItem;
