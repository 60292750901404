import {
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  useTheme,
  Modal,
  Divider,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tabs,
  Tab,
  Chip,
  useMediaQuery,
  DialogActions,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Tooltip,
  InputAdornment,
  FormLabel,
  FormControlLabel,
  Checkbox,
  FormGroup,
  RadioGroup,
  Radio,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  Drawer,
  Alert,
  Autocomplete,
  Badge,
  Popper,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import Container from 'components/Container';
import { useDispatch, useSelector } from 'react-redux';
import {
  Add,
  Archive,
  ArrowBack,
  Article,
  Assessment,
  Assistant,
  AudioFile,
  Audiotrack,
  AutoAwesome,
  BookmarkAdd,
  Cached,
  Chat,
  Check,
  ChevronRight,
  Close,
  CloudUpload,
  Delete,
  Edit,
  ErrorOutline,
  FilterList,
  FindReplace,
  Info,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardArrowUp,
  Lock,
  Loop,
  Mic,
  PlayArrow,
  PostAdd,
  Redo,
  Refresh,
  Replay,
  Search,
  Send,
  StopCircle,
  Subject,
  Sync,
  TaskAlt,
  Timeline,
  Troubleshoot,
  Unarchive,
  Warning,
  WarningAmber,
} from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useHistory } from 'react-router-dom';
//actions
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { detect } from 'detect-browser';
import moment from 'moment';
import showAlert from 'redux/actions/Common/showAlert';

import { PropTypes } from 'prop-types';
import { Bar, Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip as TooltipChart,
  Legend,
  ArcElement,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import parse from 'html-react-parser';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import trackEvent from 'redux/actions/Common/trackEvent';
import logError from 'redux/actions/Common/logError';
import { capitalizeText, formatSeconds, validateEmail } from 'utils';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import syncDataWithHubspot from 'redux/actions/Common/syncDataWithHubspot';
import MediaPreview from 'components/@2024/MediaPreview';
import ReportPage from 'pages/ReportPage';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import getMessageResponse from 'redux/actions/Candidate/getMessageResponse';
import ProfileCompletion from 'components/ProfileCompletion';
import updateSalesReviewData from 'redux/actions/Common/updateSalesReviewData';
import reAnalyzeSalesCallv2 from 'redux/actions/Common/reAnalyzeSalesCallv2';
import getRemainingCredits from 'redux/actions/Common/getRemainingCredits';
import AutoCompleteMUI from 'components/@2024/AutoCompleteMUI';
import { v4 as uuidv4 } from 'uuid';
import updateTeam from 'redux/actions/Common/updateTeam';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import getDeals from 'redux/actions/Common/getDeals';
import createNewDeal from 'redux/actions/Candidate/createNewDeal';
import getAllTeamMembers from 'redux/actions/Common/getAllTeamMembers';
import deleteDeal from 'redux/actions/Candidate/deleteDeal';
import updateDeal from 'redux/actions/Candidate/updateDeal';
import crmHubspotSync from 'redux/actions/Common/crmHubspotSync';
import generateDeal from 'redux/actions/Candidate/generateDeal';
import getConversationById from 'redux/actions/Candidate/getConversationById';
import createNewConversation from 'redux/actions/Candidate/createNewConversation';
import updateConversationStatus from 'redux/actions/Candidate/updateConversationStatus';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import getAllArtifacts from 'redux/actions/Common/getAllArtifacts';
import checkSalesCallTranscriptStatus from 'redux/actions/Common/checkSalesCallTranscriptStatus';
import addDealArtifact from 'redux/actions/Common/addDealArtifact';
import DealSelect from 'components/DealSelect';
import updateDealArtifact from 'redux/actions/Common/updateDealArtifact';
import processDealArtifact from 'redux/actions/Common/processDealArtifact';
import { artifactTypesWithoutMedia } from 'data';
import updateArtifactDealAssciation from 'redux/actions/Common/updateArtifactDealAssciation';
import getAllDealNames from 'redux/actions/Common/getAllDealNames';
import ArtifactUpload from 'components/ArtifactUpload';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  TooltipChart,
  Legend,
  ChartDataLabels,
);

const VideoPreview = memo(
  function VideoPreview({ selectedAudio, onDurationChange }) {
    return (
      <Box
        // flex={1}
        display={'flex'}
        flexDirection={'column'}
        sx={{ alignItems: 'center', justifyContent: 'flex-start' }}
      >
        {selectedAudio ? (
          selectedAudio?.type?.split('/')?.[0] === 'audio' ? (
            <Box display={'flex'} flexDirection={'column'} gap={1}>
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <audio
                preload
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                onDurationChange={onDurationChange}
              />
            </Box>
          ) : (
            <Box
              width={'90%'}
              display={'flex'}
              flexDirection={'column'}
              gap={1}
            >
              <Typography
                variant="body2"
                color={'text.secondary'}
                textAlign={'center'}
              >
                Selected File
              </Typography>
              <video
                style={{ width: '100%' }}
                preload={false}
                src={window.URL.createObjectURL(selectedAudio)}
                controls
                controlsList="nodownload"
                disablePictureInPicture
                onDurationChange={onDurationChange}
              />
            </Box>
          )
        ) : null}
      </Box>
    );
  },
  (pre, post) => {
    return pre?.selectedAudio === post?.selectedAudio;
  },
);

const activeUUID = uuidv4();

const activeUUIDDeal = uuidv4();
const activeUUIDDeal1 = uuidv4();

const Deals = ({ uId, member, parentComponent }) => {
  const theme = useTheme();
  const history = useHistory();
  const browser = detect();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const pathParams = useParams();
  const params = new URLSearchParams(window.location.search);
  const { isLoggedIn } = useSelector((state) => state.firebaseSession);
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const organization = JSON.parse(localStorage.getItem('organization') || '{}');
  const { dashboardSidebarExpanded } = useSelector((state) => state.uiStates);
  const teamId = localStorage.getItem('teamId') || pathParams?.teamId;
  const chatsScrollview = useRef(null);
  const checkProgressTimer = useRef(null);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const {
    deals,
    fetching,
    fetchingPagination,
    lastSeen,
    lastTime,
    emptyList,
  } = useSelector((state) => state.deals);
  const { userDetails } = useSelector((state) => state.userDetails);
  const { members } = useSelector((state) => state.allTeamMembers);
  const {
    showCompetitionDetailsOnTopbar,
    currentContextData,
    showBackButtonOnTopbar,
    currentNavigationLevel,
    creditsRemaining,
    loggedInUserTeamDetails,
    pinnedTeams,
  } = useSelector((state) => state.uiStates);

  const conversationDetailsById = useSelector(
    (state) => state.conversationDetailsById,
  );

  const { uploadingFor, uploadPercentage } = useSelector(
    (state) => state.addDealArtifactState,
  );
  const { conversationDetails } = conversationDetailsById;

  const [loading, setLoading] = useState(false);
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });
  const videoRef = useRef(null);

  const [currentTab, setCurrentTab] = useState('active');

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);
  const [activeAccount, setActiveAccount] = useState(activeUUID);

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const [anchorElAddAccount, setAnchorElAddAccount] = useState(null);
  const openAddAccountMenu = Boolean(anchorElAddAccount);

  const [viewType, setViewType] = useState('list');

  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(null);
  const [createNewDealDialog, setCreateNewDealDialog] = useState(false);
  const [creationMode, setCreationMode] = useState('normal');

  const [accountName, setAccountName] = useState('');
  const [dealName, setDealName] = useState('');
  const [dealSize, setDealSize] = useState(0);
  const [dealSizeUnit, setDealSizeUnit] = useState('usd');
  const [dealContact, setDealContact] = useState('');
  const [dealDescription, setDealDescription] = useState('');
  const [creatingDeal, setCreatingDeal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [goLiveOn, setGoLiveOn] = useState(new Date().getTime());
  const [dealClosed, setDealClosed] = useState(false);
  const [dealCloseDate, setDealCloseDate] = useState(new Date().getTime());
  const [dealStage, setDealStage] = useState('');

  const [activeDeal, setActiveDeal] = useState(activeUUIDDeal);
  const [activeDealIndex, setActiveDealIndex] = useState(0);

  const [generatingDealSummary, setGeneratingDealSummary] = useState(false);

  const [showAskDonna, setShowAskDonna] = useState(null);
  const [askAIQuestion, setAskAIQuestion] = useState('');
  const [generatingResponse, setGeneratingResponse] = useState(false);
  const [fetchingConversationById, setFetchingConversationById] = useState(
    false,
  );
  const [wordsLimit, setWordsLimit] = useState(50);
  const [creatingConversation, setCreatingConversation] = useState(false);

  const [currentDealsTab, setCurrentDealsTab] = useState('deals');
  const [showUploadArtifact, setShowUploadArtifact] = useState(false);
  const [loadingArtifacts, setLoadingArtifacts] = useState(false);
  const [artifacts, setArtifacts] = useState([]);
  const [filteredArtifacts, setFilteredArtifacts] = useState([]);

  const [selectedCrmDeal, setSelectedCrmDeal] = useState('');

  const [syncingCrmDeals, setSyncingCrmDeals] = useState(false);
  const [refreshDealList, setRefreshDealList] = useState(false);

  const [dealEditable, setDealEditable] = useState(false);
  const [anchorElAddDeal, setAnchorElAddDeal] = useState(null);
  const openAddDealMenu = Boolean(anchorElAddDeal);
  const [addDealFor, setAddDealFor] = useState(null);

  const [showArtifactDetails, setShowArtifactDetails] = useState(null);
  const [currentArtifactDetailsTab, setCurrentArtifactDetailsTab] = useState(
    'transcript',
  );

  const [crmDeals, setCrmDeals] = useState([]);
  const [
    showConfirmDealUpdateDialog,
    setShowConfirmDealUpdateDialog,
  ] = useState(null);

  const [updatingDealAssociation, setUpdatingDealAssociation] = useState(false);

  const handleTrackEvent = (e, type) => {
    try {
      dispatch(
        trackEvent({
          context: 'click_event',
          eventType: type,
          userId: isLoggedIn ? userInfo?.id : null,
          time: new Date().getTime(),
          os: browser?.os,
          sessionId: isLoggedIn
            ? userInfo?.id
            : sessionStorage.getItem('trackingSessionId'),
          customData: {
            page_path: pathname,
            browser: browser?.name,
            browser_version: browser?.version,
          },
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        logError({
          message: e?.message,
          data: {
            formatted: {
              errorText: e?.message,
              severity: 'error',
            },
            raw: e,
          },
        }),
      );
    }
  };

  const handleListScroll = (e, paginate) => {
    // console.log(e.target.scrollTop + e.target.clientHeight);
    // console.log(e.target.clientHeight);
    // console.log(e.target.scrollHeight);
    // console.log(
    //   e.target.scrollTop + e.target.clientHeight + 1 >= e.target.scrollHeight,
    // );
    if (
      (e.target.scrollTop + e.target.clientHeight + 1 >=
        e.target.scrollHeight &&
        // viewType === 'list' &&
        !fetching &&
        !fetchingPagination &&
        deals &&
        deals.length % 10 === 0 &&
        emptyList === false) ||
      paginate
    ) {
      if (uId) {
        dispatch(
          getDeals(
            {
              archived: currentTab === 'archived' ? true : false,
              size: 10,
              lastSeen: lastSeen,
              lastTime: lastTime,
            },
            // {
            //   accountTagId: activeAccount !== activeUUID ? activeAccount : null,
            //   userId: uId,
            //   // keywords: searchKeywords?.join(','),
            //   lastSeen: lastSeen,
            //   lastTime: lastTime,
            //   size: 10,
            //   teamId: teamId || null,
            // },
            () => {},
          ),
        );
      } else {
        dispatch(
          getDeals(
            {
              archived: currentTab === 'archived' ? true : false,
              size: 10,
              lastSeen: lastSeen,
              lastTime: lastTime,
            },
            () => {},
          ),
        );
      }
    }
  };

  const handleDealUpdate = () => {
    if (selectedCrmDeal !== 'select') {
      setUpdatingDealAssociation(true);
      dispatch(
        updateArtifactDealAssciation(
          addDealFor?.customField?.deal?.id,
          selectedCrmDeal,
          addDealFor?.id,
          (data) => {
            setUpdatingDealAssociation(false);
            console.log(data);
            setAnchorElAddDeal(null);
            setAddDealFor(null);
            setSelectedCrmDeal('select');
            setDealEditable(false);
            setShowConfirmDealUpdateDialog(false);
            setFilteredArtifacts((prev) => {
              return prev.map((artifact) => {
                if (artifact.id === addDealFor.id) {
                  return {
                    ...artifact,
                    customField: {
                      ...(artifact.customField || {}),
                      deal: crmDeals?.find(
                        (deal) => deal.id === selectedCrmDeal,
                      ),
                    },
                  };
                }
                return artifact;
              });
            });
          },
          () => {
            setUpdatingDealAssociation(false);
            setAnchorElAddDeal(null);
            setShowConfirmDealUpdateDialog(false);
            setAddDealFor(null);
          },
        ),
      );
    } else {
      setAnchorElAddDeal(null);
      setAddDealFor(null);
    }
  };

  const handleDealFilter = () => {
    setFilteredArtifacts(
      artifacts?.filter((artifact) => {
        return artifact?.dealId === activeDeal;
      }),
    );
  };

  const resetSearch = () => {
    setFilteredArtifacts(artifacts);
    setActiveDeal('select');
  };

  useEffect(() => {
    // if (currentTab === 'create') {
    if (teamId) {
      // console.log('coming here');
      // console.log(teamId);
      // console.log(pinnedTeams);
      if (pinnedTeams && pinnedTeams?.length > 0) {
        setAccounts(
          Object.keys(
            pinnedTeams?.find((team) => team?.id === teamId)?.accountTagMap ||
              {},
          )?.map((key) => {
            return {
              id: key,
              name: pinnedTeams?.find((team) => team?.id === teamId)
                ?.accountTagMap?.[key],
            };
          }) || [],
        );
      } else {
        setAccounts(
          Object.keys(loggedInUserTeamDetails?.accountTagMap || {})?.map(
            (key) => {
              return {
                id: key,
                name: loggedInUserTeamDetails?.accountTagMap?.[key],
              };
            },
          ) || [],
        );
      }
    } else {
      if (orgConfigs) {
        setAccounts(
          Object.keys(orgConfigs?.accountTagMap || {})?.map((key) => {
            return {
              id: key,
              name: orgConfigs?.accountTagMap?.[key],
            };
          }) || [],
        );
      }
    }
    // }
  }, [orgConfigs, teamId, loggedInUserTeamDetails, pinnedTeams]);

  useEffect(() => {
    if (createNewDealDialog && typeof createNewDealDialog === 'object') {
      setDealName(createNewDealDialog?.name);
      setDealDescription(createNewDealDialog?.description);
      setGoLiveOn(createNewDealDialog?.goLiveOn || new Date().getTime());
      setDealCloseDate(createNewDealDialog?.closeOn || new Date().getTime());
      setDealStage(createNewDealDialog?.stage);
      setDealContact(createNewDealDialog?.contact?.email);
      setDealSize(createNewDealDialog?.amount?.amount);
      setDealSizeUnit(createNewDealDialog?.amount?.currency);
      setAccountName(
        accounts?.find((ac) => ac?.id === createNewDealDialog?.accountId)?.name,
      );
      setDealClosed(createNewDealDialog?.closeOn || false);
      setDealCloseDate(createNewDealDialog?.closeOn || new Date().getTime());
    }
  }, [createNewDealDialog]);

  useEffect(() => {
    if (currentDealsTab === 'deals') {
      if (uId) {
        dispatch(
          getDeals(
            { archived: currentTab === 'archived' ? true : false, size: 10 },
            // {
            //   accountTagId: activeAccount !== activeUUID ? activeAccount : null,
            //   userId: uId,
            //   // keywords: searchKeywords?.join(','),
            //   lastTime: lastTime,
            //   size: 10,
            //   teamId: teamId || null,
            // },
            () => {},
          ),
        );
      } else {
        dispatch(
          getDeals(
            { archived: currentTab === 'archived' ? true : false, size: 10 },
            () => {},
          ),
        );
      }
    } else if (currentDealsTab === 'artifacts') {
      setLoadingArtifacts(true);
      dispatch(
        getAllArtifacts((data) => {
          setLoadingArtifacts(false);
          console.log(data);
          setArtifacts(
            data,
            // ?.sort((a, b) => b?.interactionTime - a?.interactionTime),
          );
        }),
      );
      dispatch(
        getAllDealNames((dealNames) => {
          setCrmDeals(dealNames);
        }),
      );
    }
  }, [currentDealsTab, currentTab, viewType, teamId]);

  useEffect(() => {
    if (dealEditable && crmDeals && crmDeals?.length > 0) {
      setSelectedCrmDeal(
        crmDeals?.find((ac) => ac?.id === dealEditable?.customField?.deal?.id)
          ?.id || 'select',
        crmDeals?.find(
          (ac) => ac?.id === dealEditable?.customField?.deal?.id,
        ) || null,
      );
    }
  }, [dealEditable, crmDeals]);

  useEffect(() => {
    if (!showBackButtonOnTopbar) {
      setViewType('list');
      setCurrentTab('active');
      setActiveDealIndex(0);
    }
  }, [showBackButtonOnTopbar]);

  useEffect(() => {
    setFilteredArtifacts(artifacts);
  }, [artifacts]);

  useEffect(() => {
    if (viewType === 'report') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: true,
        },
      });
      // dispatch({
      //   type: 'UPDATE_UI_STATE',
      //   payload: {
      //     key: 'currentContextData',
      //     value: {
      //       ...currentContextData,
      //       title: salesReview?.title || '',
      //     },
      //   },
      // });
    } else {
      if (!(params && params.get('new') === 'true') && !uId) {
        // history.push('/sales-call-analysis');
      }
      if (!uId) {
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'showBackButtonOnTopbar',
            value: false,
          },
        });
        dispatch({
          type: 'UPDATE_UI_STATE',
          payload: {
            key: 'currentContextData',
            value: {
              ...currentContextData,
              title: '',
            },
          },
        });
      }
    }
  }, [viewType]);

  useEffect(() => {
    if (member && uId) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: {
            ...currentContextData,
            name: member?.fullName,
            email: member?.email,
          },
        },
      });
    }
  }, [member, uId]);

  useEffect(() => {
    if (
      (parentComponent === 'teamSettings' && currentNavigationLevel > 2) ||
      (parentComponent === 'customerSettings' && currentNavigationLevel > 3)
    ) {
      setViewType('report');
    } else {
      setViewType('list');
    }
  }, [currentNavigationLevel]);

  // useEffect(() => {
  //   setActiveDeal(deals?.[activeDealIndex]);
  // }, [activeDealIndex, deals]);

  useEffect(() => {
    if (showAskDonna && userDetails) {
      if (userDetails?.currentAcrossDealConversationId) {
        setFetchingConversationById(true);
        dispatch(
          getConversationById(
            userDetails?.currentAcrossDealConversationId,
            null,
            (data) => {
              setFetchingConversationById(false);
            },
          ),
        );
      }
    }
  }, [showAskDonna, userDetails]);

  useEffect(() => {
    if (params.get('new') === 'true') {
      setCreateNewDealDialog(true);
    }
    if (uId) {
      console.log('uId', uId);
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
      dispatch(
        getAllTeamMembers(
          teamId || null,
          null,
          true,
          0,
          25,
          null,
          (data) => {},
        ),
      );
      dispatch(getOrganizationConfigs((data) => {}));
      dispatch(
        getUserDetails((data) => {
          if (data?.custom?.plan?.price?.pricingScheme === 'usage_based') {
            dispatch(
              getRemainingCredits((data) => {
                // console.log(data);
                dispatch({
                  type: 'UPDATE_UI_STATE',
                  payload: {
                    key: 'creditsRemaining',
                    value: data,
                  },
                });
              }),
            );
          }
        }),
      );
    }
    return () => {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showBackButtonOnTopbar',
          value: false,
        },
      });
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'currentContextData',
          value: null,
        },
      });
    };
  }, []);

  // console.log(teamId);
  // console.log(selectedDeal);
  // console.log(hubspotDeals);
  // console.log(orgConfigs?.hubspotDealProperties);
  // console.log(propertiesChanged);
  // console.log(propertiesUnchaged);

  // console.log(dealContacts);
  // console.log(propertiesChanged);
  // console.log(teamId);
  // console.log(lastSeen);
  // console.log(lastTime);
  console.log(creationMode);

  return (
    <Box
      sx={{
        width: {
          xs: '100%',
          sm: isLoggedIn ? 'calc(100% - 80px)' : 1,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? 'calc(100% - 240px)'
                : 'calc(100% - 80px)'
              : 1,
        },
        marginLeft: {
          xs: '0px',
          sm: isLoggedIn ? '80px' : 0,
          md:
            isLoggedIn && !uId
              ? dashboardSidebarExpanded
                ? '240px'
                : '80px'
              : 0,
        },
        height: 1,
        minHeight:
          isLoggedIn && !uId ? 'calc(100vh - 60px)' : 'calc(100vh - 146px)',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={{ xs: 'column', sm: 'column', md: 'row' }}
        height={1}
      >
        <Box
          flex={{ xs: 'auto', sm: 'auto', md: 1 }}
          display={'flex'}
          flexDirection={{ xs: 'row', sm: 'row', md: 'column' }}
          alignItems={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'start',
          }}
          justifyContent={{
            xs: 'start',
            sm: 'start',
            md: currentTab === 'create' ? 'center' : 'flex-start',
          }}
          height={1}
          gap={2}
          sx={{
            minHeight: isXs
              ? 'auto'
              : viewType === 'report'
              ? 'calc(100vh - 130px)'
              : 'calc(100vh - 60px)',
            padding: 2,
            paddingTop: 1,
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            width={1}
            border={'1px solid #d3d3d3'}
            borderRadius={2}
          >
            {viewType === 'list' ? (
              <>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'flex-end'}
                  gap={1}
                  // padding={1}
                >
                  <Tabs
                    value={currentDealsTab}
                    onChange={(e, newValue) => setCurrentDealsTab(newValue)}
                    aria-label="Deals Tabs"
                    orientation={'horizontal'}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    sx={{
                      flex: 1,
                    }}
                  >
                    <Tab
                      value={'deals'}
                      label={<Typography variant="body1">Deals</Typography>}
                      id={`simple-tab-${0}`}
                      aria-controls={`simple-tabpanel-${0}`}
                      sx={{}}
                    />
                    <Tab
                      value={'artifacts'}
                      label={<Typography variant="body1">Artifacts</Typography>}
                      id={`simple-tab-${5}`}
                      aria-controls={`simple-tabpanel-${5}`}
                      sx={{}}
                    />
                  </Tabs>
                  {isXs ||
                  userDetails?.orgRoles?.[0] === 'ORG_ADMIN' ||
                  userDetails?.teamRoles?.[0] === 'TEAM_ADMIN' ||
                  userDetails?.teamRoles?.[0] === 'STANDARD' ||
                  userDetails?.orgRoles?.[0] === 'WL_ORG_ADMIN' ? (
                    currentDealsTab === 'deals' ? (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                        gap={1}
                        marginRight={1}
                      >
                        <Button
                          startIcon={<AutoAwesome />}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setShowAskDonna(true)}
                        >
                          Ask Donna
                        </Button>
                        {userDetails?.custom?.integration?.hubSpot ||
                        userDetails?.custom?.integration?.pipedriveCrm ||
                        userDetails?.custom?.integration?.zohoCrm ? (
                          <Button
                            startIcon={
                              syncingCrmDeals ? (
                                <CircularProgress size={20} />
                              ) : (
                                <Sync />
                              )
                            }
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={syncingCrmDeals}
                            // sx={{ marginLeft: 'auto' }}
                            onClick={() => {
                              setSyncingCrmDeals(true);
                              dispatch(
                                crmHubspotSync(
                                  () => {
                                    setSyncingCrmDeals(false);
                                    dispatch(
                                      showAlert({
                                        message:
                                          'Deals are synced with CRM successfully.',
                                        showCross: true,
                                        title: null,
                                        type: 'success',
                                        autoHideDuration: 2000,
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }),
                                    );
                                    dispatch(
                                      getDeals(
                                        {
                                          archived:
                                            currentTab === 'archived'
                                              ? true
                                              : false,
                                          size: 10,
                                        },
                                        (data) => {},
                                      ),
                                    );
                                  },
                                  () => {
                                    setSyncingCrmDeals(false);
                                    dispatch(
                                      showAlert({
                                        message:
                                          'Failed to sync deals to CRM. Please try again.',
                                        showCross: true,
                                        title: null,
                                        type: 'error',
                                        autoHideDuration: 2000,
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }),
                                    );
                                  },
                                ),
                              );
                            }}
                          >
                            CRM Sync
                          </Button>
                        ) : (
                          <Button
                            startIcon={<Add />}
                            variant="contained"
                            color="primary"
                            size="small"
                            // sx={{ marginLeft: 'auto' }}
                            onClick={() => {
                              setCreateNewDealDialog(true);
                              setCreationMode('normal');
                            }}
                          >
                            New Deal
                          </Button>
                        )}
                      </Box>
                    ) : currentDealsTab === 'artifacts' ? (
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'flex-end'}
                        gap={1}
                        marginRight={1}
                      >
                        <Button
                          startIcon={<PostAdd />}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setShowUploadArtifact(true)}
                        >
                          Upload Artifact
                        </Button>
                      </Box>
                    ) : null
                  ) : null}
                </Box>
                <Box
                  sx={{
                    borderTop: '1px solid #d3d3d3',
                    minHeight: 'calc(100vh - 145px)',
                  }}
                  className={'dealsList'}
                >
                  {currentDealsTab === 'deals' ? (
                    <>
                      {fetching ? (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={4}
                          >
                            Loading ...
                          </Typography>
                        </Box>
                      ) : deals && deals?.length > 0 ? (
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          <TableContainer
                            component={Paper}
                            sx={{
                              minHeight: uId
                                ? 'calc(100vh - 90px)'
                                : (isXs ||
                                    userDetails?.orgRoles?.[0] ===
                                      'ORG_ADMIN' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'TEAM_ADMIN' ||
                                    userDetails?.orgRoles?.[0] ===
                                      'WL_ORG_ADMIN') &&
                                  deals &&
                                  deals?.length > 0
                                ? 'calc(100vh - 145px)'
                                : 'calc(100vh - 90px)',
                              maxHeight: uId
                                ? 'calc(100vh - 90px)'
                                : (isXs ||
                                    userDetails?.orgRoles?.[0] ===
                                      'ORG_ADMIN' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'TEAM_ADMIN' ||
                                    userDetails?.orgRoles?.[0] ===
                                      'WL_ORG_ADMIN') &&
                                  deals &&
                                  deals?.length > 0
                                ? 'calc(100vh - 145px)'
                                : 'calc(100vh - 90px)',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                            }}
                            onScroll={handleListScroll}
                          >
                            <Table
                              sx={{ minWidth: 750 }}
                              stickyHeader
                              aria-label="simple table"
                            >
                              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Deal
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      CRM
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell align="center">
                                <Box
                                  display={'flex'}
                                  justifyContent={'center'}
                                  alignItems={'center'}
                                  gap={1}
                                >
                                  <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{
                                      textTransform: 'uppercase',
                                      // textAlign: 'center',
                                    }}
                                  >
                                    Contact
                                  </Typography>
                                </Box>
                              </TableCell> */}
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Deal Stage
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Deal Size
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                      textAlign={'center'}
                                      // align="center"
                                    >
                                      Confidence (%)
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Close Date
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell align="center">
                                <Typography
                                  variant={'caption'}
                                  fontWeight={700}
                                  sx={{ textTransform: 'uppercase' }}
                                >
                                  Go Live Date
                                </Typography>
                              </TableCell> */}
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Artifacts
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Actions
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {deals
                                  ?.filter((deal) =>
                                    currentTab === 'archived'
                                      ? deal?.archived
                                      : !deal?.archived,
                                  )
                                  .map((deal, i) => {
                                    return (
                                      <TableRow key={i}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ minWidth: 150 }}
                                        >
                                          <Typography
                                            variant={'subtitle2'}
                                            className={`dealDetail${i}`}
                                            fontWeight={700}
                                            sx={{
                                              cursor: 'pointer',
                                              textDecoration: 'underline',
                                            }}
                                            onClick={() => {
                                              history.push(
                                                `/deals/${deal?.id}`,
                                              );
                                            }}
                                          >
                                            {deal?.name}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ minWidth: 150 }}
                                        >
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {}}
                                          >
                                            {deal?.crm === 'zohocrm'
                                              ? 'Zoho'
                                              : deal?.crm === 'pipedrive'
                                              ? 'Pipedrive'
                                              : deal?.crm === 'hubspot'
                                              ? 'HubSpot'
                                              : '--'}
                                          </Typography>
                                        </TableCell>
                                        {/* <TableCell
                                      component="th"
                                      scope="row"
                                      align="center"
                                      // sx={{ maxWidth: 100 }}
                                    >
                                      <Typography
                                        variant={'subtitle2'}
                                        // fontWeight={700}
                                        textAlign={'center'}
                                        sx={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          // setAccountEditable(ar);
                                          setAnchorEl(null);
                                        }}
                                      >
                                        {deal?.contact?.email || '--'}
                                      </Typography>
                                    </TableCell> */}
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          // sx={{ maxWidth: 100 }}
                                        >
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                            textAlign={'center'}
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              // setAccountEditable(ar);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            {orgConfigs?.dealStages?.find(
                                              (stage) =>
                                                stage.id === deal?.stage,
                                            )?.name || '--'}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          // sx={{ maxWidth: 100 }}
                                        >
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                            textAlign={'center'}
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              // setAccountEditable(ar);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            {deal?.amount?.amount ? (
                                              <>
                                                {deal?.amount?.currency ===
                                                'usd'
                                                  ? '$'
                                                  : deal?.amount?.currency ===
                                                    'inr'
                                                  ? '₹'
                                                  : '$'}
                                                {deal?.amount?.amount || '--'}
                                              </>
                                            ) : (
                                              '--'
                                            )}
                                          </Typography>
                                        </TableCell>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          align="center"
                                          // sx={{ maxWidth: 100 }}
                                        >
                                          <Typography
                                            variant={'subtitle2'}
                                            // fontWeight={700}
                                            textAlign={'center'}
                                            sx={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              // setAccountEditable(ar);
                                              setAnchorEl(null);
                                            }}
                                          >
                                            {deal?.confidence || '--'}
                                          </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                          <Typography variant={'caption'}>
                                            {deal?.closeOn > 0
                                              ? moment(deal?.closeOn).format(
                                                  'DD MMMM YYYY',
                                                )
                                              : '--'}
                                          </Typography>
                                        </TableCell>
                                        {/* <TableCell align="center">
                                      <Typography variant={'caption'}>
                                        {deal?.goLiveOn
                                          ? moment(deal?.goLiveOn).format(
                                              'DD MMMM YYYY',
                                            )
                                          : '--'}
                                      </Typography>
                                    </TableCell> */}
                                        <TableCell align="center">
                                          {deal?.counts?.interactions &&
                                          deal?.counts?.interactions > 0 ? (
                                            <Button
                                              size="small"
                                              variant="text"
                                              color="primary"
                                              onClick={() => {
                                                history.push(
                                                  `/deals/${deal?.id}?tab=timeline`,
                                                );
                                              }}
                                            >
                                              <Typography
                                                variant="body2"
                                                fontWeight={'bold'}
                                                sx={{
                                                  textDecoration: 'underline',
                                                }}
                                              >
                                                {deal?.counts?.interactions ||
                                                  0}
                                              </Typography>
                                            </Button>
                                          ) : (
                                            <Typography variant={'caption'}>
                                              {deal?.counts?.interactions
                                                ? deal?.counts?.interactions
                                                : 0}
                                            </Typography>
                                          )}
                                        </TableCell>
                                        <TableCell align="center">
                                          <Box
                                            display={'flex'}
                                            alignItems={'center'}
                                            gap={1}
                                            justifyContent={'center'}
                                          >
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={'Deal Report'}
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  setViewType('report');
                                                  setActiveDealIndex(i);
                                                }}
                                              >
                                                <Assessment
                                                  sx={{
                                                    color:
                                                      theme.palette.primary
                                                        .main,
                                                    fontSize: 20,
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                deal?.archived
                                                  ? 'Deal is in archived state. You can not upload artifact for archived deal'
                                                  : 'Upload Artifact'
                                              }
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  if (!deal?.archived) {
                                                    history.push(
                                                      `/deals/${deal?.id}?tab=upload`,
                                                    );
                                                  }
                                                }}
                                              >
                                                <CloudUpload
                                                  sx={{
                                                    color: deal?.archived
                                                      ? '#a3a3a3'
                                                      : theme.palette.primary
                                                          .main,
                                                    fontSize: 20,
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={
                                                deal?.archived
                                                  ? 'Deal is in archived state. You can not update archived deal'
                                                  : 'Update Deal'
                                              }
                                            >
                                              <IconButton
                                                disabled={deal?.crm === 'crm'}
                                                onClick={(e) => {
                                                  if (!deal?.archived) {
                                                    setCreateNewDealDialog(
                                                      deal,
                                                    );
                                                    setCreationMode('normal');
                                                  }
                                                }}
                                              >
                                                <Edit
                                                  sx={{
                                                    color:
                                                      deal?.archived ||
                                                      deal?.crm
                                                        ? '#a3a3a3'
                                                        : theme.palette.primary
                                                            .main,
                                                    fontSize: 20,
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                            {/* <Tooltip
                                          arrow
                                          placement="top"
                                          title={
                                            deal?.archived
                                              ? 'Unarchive Deal'
                                              : 'Archive Deal'
                                          }
                                        >
                                          <IconButton
                                            onClick={(e) => {
                                              dispatch(
                                                updateDeal(
                                                  deal?.id,
                                                  {
                                                    archived: !deal?.archived,
                                                  },
                                                  (data, error) => {
                                                    if (error) {
                                                      dispatch(
                                                        showAlert({
                                                          message:
                                                            'Oops, Something went wront! Please try again.',
                                                          showCross: true,
                                                          title: null,
                                                          type: 'error',
                                                          autoHideDuration: 2000,
                                                          vertical: 'top',
                                                          horizontal: 'center',
                                                        }),
                                                      );
                                                    } else {
                                                      dispatch({
                                                        type: 'UPDATE_DEALS',
                                                        payload: {
                                                          id: deal?.id,
                                                          data: {
                                                            ...deal,
                                                            archived: !deal?.archived,
                                                          },
                                                        },
                                                      });
                                                    }
                                                  },
                                                ),
                                              );
                                            }}
                                          >
                                            {deal?.archived ? (
                                              <Unarchive
                                                sx={{
                                                  color:
                                                    theme.palette.primary.main,
                                                  fontSize: 20,
                                                }}
                                              />
                                            ) : (
                                              <Archive
                                                sx={{
                                                  color:
                                                    theme.palette.primary.main,
                                                  fontSize: 20,
                                                }}
                                              />
                                            )}
                                          </IconButton>
                                        </Tooltip> */}
                                            <Tooltip
                                              arrow
                                              placement="top"
                                              title={'Delete Deal'}
                                            >
                                              <IconButton
                                                onClick={(e) => {
                                                  setShowConfirmDeleteDialog(
                                                    deal?.id,
                                                  );
                                                }}
                                              >
                                                <Delete
                                                  sx={{
                                                    color: '#a3a3a3',
                                                    fontSize: 20,
                                                  }}
                                                />
                                              </IconButton>
                                            </Tooltip>
                                          </Box>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                              </TableBody>
                              <TableFooter>
                                <TableRow>
                                  <TableCell
                                    variant="footer"
                                    colSpan={'100%'}
                                    sx={{
                                      padding: 0,
                                    }}
                                  >
                                    {fetchingPagination ? (
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: 1,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          // type="submit"
                                          color="primary"
                                          onClick={handleListScroll}
                                          // className="searchSettings"
                                          disabled
                                          // fullWidth
                                        >
                                          <Typography color="textPrimary">
                                            Loading ...{' '}
                                          </Typography>
                                          <CircularProgress size={20} />
                                        </Button>
                                      </Box>
                                    ) : emptyList === false &&
                                      deals.length >= 10 ? (
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: 1,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Button
                                          size="small"
                                          variant="contained"
                                          color="primary"
                                          onClick={(e) =>
                                            handleListScroll(e, true)
                                          }
                                        >
                                          Load More
                                        </Button>
                                      </Box>
                                    ) : null}
                                  </TableCell>
                                </TableRow>
                              </TableFooter>
                            </Table>
                          </TableContainer>
                        </Box>
                      ) : (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                          gap={2}
                        >
                          <Typography
                            variant="body1"
                            color={'text.secondary'}
                            mt={4}
                          >
                            No deals found
                          </Typography>
                        </Box>
                      )}
                    </>
                  ) : currentDealsTab === 'artifacts' ? (
                    <>
                      {loadingArtifacts ? (
                        <Box
                          width={1}
                          height={1}
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          flexDirection={'column'}
                        >
                          <CircularProgress size={20} />
                          <Typography
                            variant="body1"
                            color={'text.primary'}
                            mt={4}
                          >
                            Loading ...
                          </Typography>
                        </Box>
                      ) : (
                        <Box display={'flex'} flexDirection={'column'} gap={1}>
                          <TableContainer
                            component={Paper}
                            sx={{
                              minHeight: uId
                                ? 'calc(100vh - 90px)'
                                : (isXs ||
                                    userDetails?.orgRoles?.[0] ===
                                      'ORG_ADMIN' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'STANDARD' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'TEAM_ADMIN' ||
                                    userDetails?.orgRoles?.[0] ===
                                      'WL_ORG_ADMIN') &&
                                  filteredArtifacts &&
                                  filteredArtifacts?.length > 0
                                ? 'calc(100vh - 130px)'
                                : 'calc(100vh - 90px)',
                              maxHeight: uId
                                ? 'calc(100vh - 90px)'
                                : (isXs ||
                                    userDetails?.orgRoles?.[0] ===
                                      'ORG_ADMIN' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'STANDARD' ||
                                    userDetails?.teamRoles?.[0] ===
                                      'TEAM_ADMIN' ||
                                    userDetails?.orgRoles?.[0] ===
                                      'WL_ORG_ADMIN') &&
                                  filteredArtifacts &&
                                  filteredArtifacts?.length > 0
                                ? 'calc(100vh - 130px)'
                                : 'calc(100vh - 90px)',
                              overflowY: 'scroll',
                              '&::-webkit-scrollbar': {
                                width: '5px',
                              },
                              '&::-webkit-scrollbar-track': {
                                boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                                webkitBoxShadow:
                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                              },
                              '&::-webkit-scrollbar-thumb': {
                                backgroundColor: theme.palette.primary.main,
                                borderRadius: 2,
                              },
                            }}
                            // onScroll={handleListScroll}
                          >
                            <Table
                              sx={{ minWidth: 750 }}
                              stickyHeader
                              aria-label="simple table"
                            >
                              <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Title
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Type
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Box
                                      display={'flex'}
                                      justifyContent={'center'}
                                      alignItems={'center'}
                                      gap={1}
                                    >
                                      <Typography
                                        variant={'caption'}
                                        fontWeight={700}
                                        sx={{
                                          textTransform: 'uppercase',
                                          // textAlign: 'center',
                                        }}
                                      >
                                        Deal
                                      </Typography>
                                      {params.get('dealId') ? null : (
                                        <FilterList
                                          sx={{
                                            fontSize: 18,
                                            color: theme.palette.primary.main,
                                            cursor: 'pointer',
                                          }}
                                          onClick={(e) => {
                                            if (anchorEl) {
                                              setAnchorEl(null);
                                            } else {
                                              setAnchorEl(e.currentTarget);
                                            }
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </TableCell>
                                  {/* {userDetails?.custom?.integration?.hubSpot ||
                                                userDetails?.custom?.integration?.zohoCrm ||
                                                userDetails?.custom?.integration?.pipedriveCrm ? (
                                                  <TableCell>
                                                    <Typography
                                                      variant={'caption'}
                                                      fontWeight={700}
                                                      sx={{ textTransform: 'uppercase' }}
                                                    >
                                                      CRM
                                                    </Typography>
                                                  </TableCell>
                                                ) : null} */}
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Date
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                      // textAlign={'center'}
                                      // align="center"
                                    >
                                      Summary
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="center">
                                    <Typography
                                      variant={'caption'}
                                      fontWeight={700}
                                      sx={{ textTransform: 'uppercase' }}
                                    >
                                      Actions
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {filteredArtifacts &&
                                filteredArtifacts?.length > 0 ? (
                                  <>
                                    {filteredArtifacts.map((ar, i) => {
                                      return (
                                        <TableRow key={i}>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            sx={{ minWidth: 100 }}
                                          >
                                            {/* {callTitleEditable?.id === ar?.id ? (
                                                        <form
                                                          onSubmit={(e) => {
                                                            e.preventDefault();
                                                            setUpdatingSalesReviewTitle(true);
                                                            dispatch(
                                                              updateSalesReviewData(
                                                                callTitleEditable?.id,
                                                                {
                                                                  title: salesCallTitle,
                                                                },
                                                                (data) => {
                                                                  setUpdatingSalesReviewTitle(false);
                                                                  setCallTitleEditable(null);
                                                                  dispatch({
                                                                    type:
                                                                      'UPDATE_SALES_CALL_ANALYSIS',
                                                                    payload: {
                                                                      id: callTitleEditable?.id,
                                                                      data: {
                                                                        ...callTitleEditable,
                                                                        title: salesCallTitle,
                                                                      },
                                                                    },
                                                                  });
                                                                  dispatch(
                                                                    showAlert({
                                                                      message:
                                                                        'Title is updated successfully.',
                                                                      showCross: true,
                                                                      title: null,
                                                                      type: 'success',
                                                                      autoHideDuration: 5000,
                                                                      vertical: 'top',
                                                                      horizontal: 'center',
                                                                    }),
                                                                  );
                                                                },
                                                                () => {
                                                                  setUpdatingSalesReviewTitle(false);
                                                                  dispatch(
                                                                    showAlert({
                                                                      message:
                                                                        'Failed to update title.',
                                                                      showCross: true,
                                                                      title: null,
                                                                      type: 'error',
                                                                      autoHideDuration: 5000,
                                                                      vertical: 'top',
                                                                      horizontal: 'center',
                                                                    }),
                                                                  );
                                                                },
                                                              ),
                                                            );
                                                          }}
                                                        >
                                                          <Box
                                                            sx={{
                                                              display: 'flex',
                                                              alignItems: 'center',
                                                              gap: 1,
                                                            }}
                                                          >
                                                            <TextField
                                                              autoFocus
                                                              placeholder="Sales Call Title"
                                                              variant="outlined"
                                                              color="primary"
                                                              size="small"
                                                              name="salesCallTitle"
                                                              value={salesCallTitle}
                                                              onChange={(e) =>
                                                                setSalesCallTitle(e.target.value)
                                                              }
                                                            />
                                                            <Box
                                                              display={'flex'}
                                                              flexDirection={'column'}
                                                              gap={0.5}
                                                            >
                                                              <Tooltip
                                                                title="Close"
                                                                arrow
                                                                placement="right"
                                                              >
                                                                <IconButton
                                                                  sx={{
                                                                    borderRadius: 1,
                                                                    color: '#fff',
                                                                    width: 16,
                                                                    height: 16,
                                                                    background:
                                                                      theme.palette.text.secondary,
                                                                    ':hover': {
                                                                      backgroundColor:
                                                                        theme.palette.text.secondary,
                                                                    },
                                                                  }}
                                                                  onClick={() => {
                                                                    setCallTitleEditable(null);
                                                                  }}
                                                                >
                                                                  <Close sx={{ fontSize: 15 }} />
                                                                </IconButton>
                                                              </Tooltip>
                                                              <Tooltip
                                                                title="Done"
                                                                arrow
                                                                placement="right"
                                                              >
                                                                {updatingSalesReviewTitle ? (
                                                                  <CircularProgress
                                                                    size={14}
                                                                    sx={{
                                                                      color:
                                                                        theme.palette.primary.main,
                                                                    }}
                                                                  />
                                                                ) : (
                                                                  <IconButton
                                                                    disabled={
                                                                      !salesCallTitle?.trim() ||
                                                                      updatingSalesReviewTitle
                                                                    }
                                                                    sx={{
                                                                      borderRadius: 1,
                                                                      color: '#fff',
                                                                      width: 16,
                                                                      height: 16,
                                                                      background:
                                                                        theme.palette.primary.main,
                                                                      ':hover': {
                                                                        backgroundColor:
                                                                          theme.palette.primary.main,
                                                                      },
                                                                    }}
                                                                    onClick={() => {
                                                                      setUpdatingSalesReviewTitle(
                                                                        true,
                                                                      );
                                                                      dispatch(
                                                                        updateSalesReviewData(
                                                                          callTitleEditable?.id,
                                                                          {
                                                                            title: salesCallTitle,
                                                                          },
                                                                          (data) => {
                                                                            setUpdatingSalesReviewTitle(
                                                                              false,
                                                                            );
                                                                            setCallTitleEditable(
                                                                              null,
                                                                            );
                                                                            dispatch({
                                                                              type:
                                                                                'UPDATE_SALES_CALL_ANALYSIS',
                                                                              payload: {
                                                                                id:
                                                                                  callTitleEditable?.id,
                                                                                data: {
                                                                                  ...callTitleEditable,
                                                                                  title: salesCallTitle,
                                                                                },
                                                                              },
                                                                            });
                                                                            dispatch(
                                                                              showAlert({
                                                                                message:
                                                                                  'Title is updated successfully.',
                                                                                showCross: true,
                                                                                title: null,
                                                                                type: 'success',
                                                                                autoHideDuration: 5000,
                                                                                vertical: 'top',
                                                                                horizontal: 'center',
                                                                              }),
                                                                            );
                                                                          },
                                                                          () => {
                                                                            setUpdatingSalesReviewTitle(
                                                                              false,
                                                                            );
                                                                            dispatch(
                                                                              showAlert({
                                                                                message:
                                                                                  'Failed to update title.',
                                                                                showCross: true,
                                                                                title: null,
                                                                                type: 'error',
                                                                                autoHideDuration: 5000,
                                                                                vertical: 'top',
                                                                                horizontal: 'center',
                                                                              }),
                                                                            );
                                                                          },
                                                                        ),
                                                                      );
                                                                    }}
                                                                  >
                                                                    <Check sx={{ fontSize: 15 }} />
                                                                  </IconButton>
                                                                )}
                                                              </Tooltip>
                                                            </Box>
                                                          </Box>
                                                        </form>
                                                      ) : (
                                                        <Tooltip
                                                          arrow
                                                          placement="top"
                                                          title={'Click to update sales call title'}
                                                        > */}
                                            <Typography
                                              variant={'subtitle2'}
                                              // fontWeight={700}
                                              sx={{
                                                // cursor: 'pointer',
                                                maxWidth: 200,
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                              }}
                                              // onClick={() => {
                                              //   setCallTitleEditable(ar);
                                              //   setSalesCallTitle(ar?.title);
                                              // }}
                                            >
                                              {ar?.title || '--'}
                                            </Typography>
                                            {/* </Tooltip> */}
                                            {/* )} */}
                                          </TableCell>
                                          <TableCell>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                              }}
                                            >
                                              <img
                                                src={
                                                  ar?.interactionType ===
                                                  'transcript'
                                                    ? '/dh-icons/transcript.svg'
                                                    : ar?.interactionType ===
                                                      'email-internal'
                                                    ? '/dh-icons/email.svg'
                                                    : ar?.interactionType ===
                                                      'email-inbound'
                                                    ? '/dh-icons/email-reply.svg'
                                                    : ar?.interactionType ===
                                                      'email-outbound'
                                                    ? '/dh-icons/email-forward.svg'
                                                    : ar?.interactionType ===
                                                      'call'
                                                    ? '/dh-icons/call.svg'
                                                    : ar?.interactionType ===
                                                      'call-internal'
                                                    ? '/dh-icons/call.svg'
                                                    : ar?.interactionType ===
                                                      'note'
                                                    ? '/dh-icons/transcript.svg'
                                                    : ar?.interactionType ===
                                                      'meeting'
                                                    ? '/dh-icons/meet.svg'
                                                    : ar?.interactionType ===
                                                      'meeting-internal'
                                                    ? '/dh-icons/meet.svg'
                                                    : ''
                                                }
                                                width={20}
                                                height={20}
                                              />
                                              <Typography variant={'caption'}>
                                                {capitalizeText(
                                                  ar?.interactionType
                                                    ?.split('-')
                                                    ?.join(' '),
                                                )}
                                              </Typography>
                                            </Box>
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            align="center"
                                            sx={{ width: 320 }}
                                          >
                                            {dealEditable?.id === ar?.id ? (
                                              <Box display={'flex'} gap={1}>
                                                <DealSelect
                                                  selectedCrmDeal={
                                                    selectedCrmDeal
                                                  }
                                                  setSelectedCrmDeal={(
                                                    value,
                                                    deal,
                                                  ) => {
                                                    setSelectedCrmDeal(value);
                                                    setShowConfirmDealUpdateDialog(
                                                      true,
                                                    );
                                                  }}
                                                  // crmDeals={crmDeals}
                                                  refreshList={refreshDealList}
                                                  setRefreshList={
                                                    setRefreshDealList
                                                  }
                                                  crmSyncing={syncingCrmDeals}
                                                  setShowDealCreationDialog={
                                                    setCreateNewDealDialog
                                                  }
                                                  setCreationMode={
                                                    setCreationMode
                                                  }
                                                  setDealEditable={
                                                    setDealEditable
                                                  }
                                                  fullWidth={true}
                                                  dealEditable={dealEditable}
                                                />
                                              </Box>
                                            ) : ar?.customField?.deal ? (
                                              <Typography
                                                variant={'subtitle2'}
                                                fontWeight={700}
                                                textAlign={'center'}
                                                sx={{
                                                  cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                  setDealEditable(ar);
                                                  setAddDealFor(ar);
                                                  setAnchorEl(null);
                                                }}
                                              >
                                                {ar?.customField?.deal?.name ||
                                                  '-'}
                                              </Typography>
                                            ) : (
                                              <Tooltip
                                                arrow
                                                placement="top"
                                                title={'Deal Name'}
                                              >
                                                <BookmarkAdd
                                                  // color="primary"
                                                  sx={{
                                                    cursor: 'pointer',
                                                    color: '#677788',
                                                  }}
                                                  onClick={(e) => {
                                                    setDealEditable(ar);
                                                    setAddDealFor(ar);
                                                    setAnchorEl(null);
                                                  }}
                                                />
                                              </Tooltip>
                                            )}
                                          </TableCell>
                                          {/* {userDetails?.custom?.integration?.hubSpot ||
                                                    userDetails?.custom?.integration?.zohoCrm ||
                                                    userDetails?.custom?.integration?.pipedriveCrm ? (
                                                      <TableCell>
                                                        <Typography
                                                          variant={'caption'}
                                                          // fontWeight={700}
                                                          // sx={{ textTransform: 'uppercase' }}
                                                        >
                                                          {ar?.customData?.qaiDeal?.crm === 'zohocrm'
                                                            ? 'Zoho'
                                                            : ar?.customData?.qaiDeal?.crm ===
                                                              'pipedrive'
                                                            ? 'Pipedrive'
                                                            : ar?.customData?.qaiDeal?.crm ===
                                                              'hubspot'
                                                            ? 'HubSpot'
                                                            : '--'}
                                                        </Typography>
                                                      </TableCell>
                                                    ) : null} */}
                                          <TableCell>
                                            <Typography variant={'caption'}>
                                              {moment(ar?.createdOn).format(
                                                'DD MMMM YYYY',
                                              )}
                                            </Typography>
                                          </TableCell>
                                          <TableCell sx={{ maxWidth: 200 }}>
                                            <Typography
                                              sx={{
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                display: '-webkit-box',
                                                WebkitLineClamp: '1',
                                                WebkitBoxOrient: 'vertical',
                                              }}
                                              // onMouseEnter={(e) => {
                                              //   setAnchorElDetails(e.currentTarget);
                                              //   setPopperDetails(
                                              //     ar?.content ||
                                              //       ar?.customField?.salesReviewFile
                                              //         ?.transcript ||
                                              //       '',
                                              //   );
                                              // }}
                                              // onMouseLeave={() => {
                                              //   setAnchorElDetails(null);
                                              //   setPopperDetails(null);
                                              // }}
                                            >
                                              {ar?.content ||
                                                ar?.customField?.salesReviewFile
                                                  ?.transcript ||
                                                ''}
                                            </Typography>
                                            {/* <Popper
                                          id="mouse-over-popover"
                                          sx={{
                                            pointerEvents: 'none',
                                            zIndex: 1111111,
                                          }}
                                          open={Boolean(anchorElDetails)}
                                          anchorEl={anchorElDetails}
                                          placement="left"
                                          onClose={() => {
                                            setAnchorElDetails(null);
                                          }}
                                          onMouseEnter={(e) => {
                                            e.stopPropagation();
                                          }}
                                          onMouseLeave={(e) => {
                                            e.stopPropagation();
                                          }}
                                          // disableRestoreFocus
                                        >
                                          <Box
                                            sx={{
                                              border: '1px solid #d3d3d3',
                                              borderRadius: 1,
                                              padding: 1,
                                              background: '#fff',
                                              maxWidth: 400,
                                              display: 'flex',
                                              flexDirection: 'column',
                                              gap: 1,
                                              overflowY: 'scroll',
                                              '&::-webkit-scrollbar': {
                                                width: '5px',
                                              },
                                              '&::-webkit-scrollbar-track': {
                                                boxShadow:
                                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                                                webkitBoxShadow:
                                                  'inset 0 0 6px rgba(0,0,0,0.00)',
                                              },
                                              '&::-webkit-scrollbar-thumb': {
                                                backgroundColor:
                                                  theme.palette.primary.main,
                                                borderRadius: 2,
                                              },
                                              maxHeight: 300,
                                              // boxShadow: theme.shadows[1],
                                            }}
                                          >
                                            <Typography
                                              variant={'body1'}
                                              color={'text.secondary'}
                                              sx={{
                                                padding: 1,
                                              }}
                                            >
                                              {popperDetails || ''}
                                            </Typography>
                                          </Box>
                                        </Popper> */}
                                          </TableCell>
                                          <TableCell align="center">
                                            <Box
                                              display={'flex'}
                                              alignItems={'center'}
                                              gap={1}
                                              justifyContent={'center'}
                                            >
                                              <Tooltip
                                                title={'Artifact Content'}
                                                placement="top"
                                                arrow
                                              >
                                                <IconButton
                                                  onClick={(e) => {
                                                    setShowArtifactDetails(ar);
                                                  }}
                                                >
                                                  <Article
                                                    color={
                                                      ar?.analysis
                                                        ? 'primary'
                                                        : 'default'
                                                    }
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <TableRow>
                                    <TableCell
                                      variant="footer"
                                      colSpan={6}
                                      sx={{
                                        padding: 0,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          padding: 1,
                                          textAlign: 'center',
                                        }}
                                      >
                                        <Typography
                                          variant="body1"
                                          color={'text.secondary'}
                                          mt={4}
                                        >
                                          No artifact found.
                                        </Typography>
                                      </Box>
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                              {/* <TableFooter>
                                              <TableRow>
                                                <TableCell
                                                  variant="footer"
                                                  colSpan={6}
                                                  sx={{
                                                    padding: 0,
                                                  }}
                                                >
                                                  {fetchingPagination ? (
                                                    <Box
                                                      sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: 1,
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                      <Button
                                                        size="small"
                                                        variant="contained"
                                                        // type="submit"
                                                        color="primary"
                                                        onClick={handleListScroll}
                                                        // className="searchSettings"
                                                        disabled
                                                        // fullWidth
                                                      >
                                                        <Typography color="textPrimary">
                                                          Loading ...{' '}
                                                        </Typography>
                                                        <CircularProgress size={20} />
                                                      </Button>
                                                    </Box>
                                                  ) : emptyList === false &&
                                                    analysisReviews.length >= 10 ? (
                                                    <Box
                                                      sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        padding: 1,
                                                        textAlign: 'center',
                                                      }}
                                                    >
                                                      <Button
                                                        size="small"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={(e) => handleListScroll(e, true)}
                                                      >
                                                        Load More
                                                      </Button>
                                                    </Box>
                                                  ) : null}
                                                </TableCell>
                                              </TableRow>
                                            </TableFooter> */}
                            </Table>
                          </TableContainer>
                          <Popper
                            open={openMenu}
                            anchorEl={anchorEl}
                            sx={{
                              zIndex: 1300,
                            }}
                            placement={'bottom-end'}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #d3d3d3',
                                borderRadius: 1,
                                padding: 1,
                                background: '#fff',
                                // minWidth: 300,
                              }}
                            >
                              <FormControl size="small" sx={{ minWidth: 120 }}>
                                <InputLabel id="deal-select">Deal</InputLabel>
                                <Select
                                  size="small"
                                  labelId="deal-select-label"
                                  id="deal-select"
                                  name="selectedQAIDeal"
                                  label="Select Deal"
                                  MenuProps={{ disableScrollLock: true }}
                                  onChange={(e) =>
                                    setActiveDeal(e.target.value)
                                  }
                                  value={activeDeal}
                                >
                                  {[
                                    { id: activeUUIDDeal, name: 'all' },
                                    { id: activeUUIDDeal1, name: 'unassigned' },
                                    ...(crmDeals || []),
                                  ]?.map((deal) => (
                                    <MenuItem value={deal?.id} key={deal?.id}>
                                      <Typography variant="caption">
                                        {deal?.name}
                                      </Typography>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              <Box display={'flex'} gap={1}>
                                <Tooltip arrow placement="top" title={'Close'}>
                                  <IconButton
                                    sx={{
                                      borderRadius: 1,
                                      border: '1px solid #d3d3d3',
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                    }}
                                  >
                                    <Close sx={{ fontSize: 18 }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={'Reset'}>
                                  <IconButton
                                    sx={{
                                      borderRadius: 1,
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      width: 40,
                                      height: 40,
                                    }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                      resetSearch(true);
                                    }}
                                  >
                                    <Replay sx={{ fontSize: 18 }} />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={'Filter'}>
                                  <IconButton
                                    sx={{
                                      borderRadius: 1,
                                      color: '#fff',
                                      width: 40,
                                      height: 40,
                                      background: theme.palette.primary.main,
                                      ':hover': {
                                        backgroundColor:
                                          theme.palette.primary.main,
                                      },
                                    }}
                                    onClick={() => {
                                      setAnchorEl(null);
                                      handleDealFilter();
                                    }}
                                  >
                                    <Check sx={{ fontSize: 18 }} />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Popper>
                          {/* <Popper
                                          open={openAddDealMenu}
                                          anchorEl={anchorElAddDeal}
                                          sx={{
                                            zIndex: 1300,
                                          }}
                                          placement={'bottom'}
                                        >
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 1,
                                              border: '1px solid #d3d3d3',
                                              borderRadius: 1,
                                              padding: 1,
                                              background: '#fff',
                                              // minWidth: 300,
                                            }}
                                          >
                                            <DealSelect
                                              selectedCrmDeal={selectedCrmDeal}
                                              setSelectedCrmDeal={setSelectedCrmDeal}
                                              // crmDeals={crmDeals}
                                              refreshList={refreshDealList}
                                              setRefreshList={setRefreshDealList}
                                              crmSyncing={syncingCrmDeals}
                                              setShowDealCreationDialog={setCreateNewDealDialog}
                                              setDealEditable={() => {}}
                                              fullWidth={true}
                                            />
                                            <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                                              <Box
                                                display={'flex'}
                                                gap={0.5}
                                                sx={{ alignItems: 'center' }}
                                              >
                                                <Tooltip title="Close" arrow placement="top">
                                                  <IconButton
                                                    sx={{
                                                      borderRadius: 1,
                                                      color: '#fff',
                                                      width: 16,
                                                      height: 16,
                                                      background: theme.palette.text.secondary,
                                                      ':hover': {
                                                        backgroundColor: theme.palette.text.secondary,
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      setAnchorElAddDeal(null);
                                                      setAddDealFor(null);
                                                    }}
                                                  >
                                                    <Close sx={{ fontSize: 15 }} />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                  title="Sync with CRM"
                                                  arrow
                                                  placement="right"
                                                >
                                                  {syncingCrmDeals ? (
                                                    <CircularProgress size={15} />
                                                  ) : (
                                                    <IconButton
                                                      sx={{
                                                        borderRadius: 1,
                                                        color: '#fff',
                                                        width: 16,
                                                        height: 16,
                                                        background: theme.palette.text.secondary,
                                                        ':hover': {
                                                          backgroundColor:
                                                            theme.palette.text.secondary,
                                                        },
                                                      }}
                                                      onClick={() => {
                                                        setSyncingCrmDeals(true);
                                                        dispatch(
                                                          crmHubspotSync(
                                                            () => {
                                                              setSyncingCrmDeals(false);
                                                              setRefreshDealList(true);
                                                            },
                                                            () => {
                                                              setSyncingCrmDeals(false);
                                                            },
                                                          ),
                                                        );
                                                      }}
                                                    >
                                                      <Sync sx={{ fontSize: 15 }} />
                                                    </IconButton>
                                                  )}
                                                </Tooltip>
                                              </Box>
                      
                                              <Box
                                                display={'flex'}
                                                gap={0.5}
                                                sx={{ alignItems: 'center' }}
                                              >
                                                <Tooltip title="Done" arrow placement="left">
                                                  <IconButton
                                                    sx={{
                                                      borderRadius: 1,
                                                      color: '#fff',
                                                      width: 16,
                                                      height: 16,
                                                      background: theme.palette.primary.main,
                                                      ':hover': {
                                                        backgroundColor: theme.palette.primary.main,
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      handleDealUpdate();
                                                    }}
                                                  >
                                                    <Check sx={{ fontSize: 15 }} />
                                                  </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                  title="Create New Deal"
                                                  arrow
                                                  placement="right"
                                                >
                                                  <IconButton
                                                    sx={{
                                                      borderRadius: 1,
                                                      color: '#fff',
                                                      width: 16,
                                                      height: 16,
                                                      background: theme.palette.primary.main,
                                                      ':hover': {
                                                        backgroundColor: theme.palette.primary.main,
                                                      },
                                                    }}
                                                    onClick={() => {
                                                      if (
                                                        userDetails?.custom?.integration?.hubSpot ||
                                                        userDetails?.custom?.integration
                                                          ?.pipedriveCrm ||
                                                        userDetails?.custom?.integration?.zohoCrm
                                                      ) {
                                                        if (
                                                          userDetails?.custom?.integration?.hubSpot
                                                        ) {
                                                          window.open(
                                                            'https://app.hubspot.com/login',
                                                            '__blank',
                                                          );
                                                        } else if (
                                                          userDetails?.custom?.integration?.zohoCrm
                                                        ) {
                                                          window.open(
                                                            'https://crm.zoho.com',
                                                            '__blank',
                                                          );
                                                        } else if (
                                                          userDetails?.custom?.integration
                                                            ?.pipedriveCrm
                                                        ) {
                                                          window.open(
                                                            'https://app.pipedrive.com/auth/login',
                                                            '__blank',
                                                          );
                                                        }
                                                      } else {
                                                        if (
                                                          userDetails?.teamRoles?.includes(
                                                            'TEAM_ADMIN',
                                                          ) ||
                                                          userDetails?.orgRoles?.includes(
                                                            'ORG_ADMIN',
                                                          ) ||
                                                          userDetails?.orgRoles?.includes(
                                                            'WL_ORG_ADMIN',
                                                          )
                                                        ) {
                                                          history.push(
                                                            `/teams/${teamId}/deals?new=true`,
                                                          );
                                                        } else {
                                                          history.push(`/deals?new=true`);
                                                        }
                                                      }
                                                    }}
                                                  >
                                                    <Add sx={{ fontSize: 15 }} />
                                                  </IconButton>
                                                </Tooltip>
                                              </Box>
                                            </Box>
                                          </Box>
                                        </Popper> */}
                        </Box>
                        // )
                        // : (
                        //   <Box
                        //     width={1}
                        //     height={1}
                        //     display={'flex'}
                        //     justifyContent={'center'}
                        //     alignItems={'center'}
                        //     flexDirection={'column'}
                        //     gap={2}
                        //   >
                        //     <Typography
                        //       variant="body1"
                        //       color={'text.secondary'}
                        //       mt={4}
                        //     >
                        //       No artifact found.
                        //     </Typography>
                        //   </Box>
                      )}
                    </>
                  ) : null}
                </Box>
              </>
            ) : (
              <Box
                display={'flex'}
                flexDirection={'column'}
                gap={2}
                border={'1px solid #d3d3d3'}
                sx={{
                  minHeight: 'calc(100vh - 84px)',
                  maxHeight: 'calc(100vh - 84px)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      padding: '8px 0',
                    }}
                  >
                    <IconButton
                      disabled={activeDealIndex === 0}
                      onClick={() => {
                        setActiveDealIndex(activeDealIndex - 1);
                      }}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <Typography variant="body1" color={'text.secondary'}>
                      {activeDeal?.name}
                    </Typography>
                    <IconButton
                      disabled={activeDealIndex === deals?.length - 1}
                      onClick={() => {
                        setActiveDealIndex(activeDealIndex + 1);
                      }}
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 24px',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 250,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 2 }}>Deal Size</Typography>
                      <Typography sx={{ flex: 1 }}>Confidence</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        color={'text.secondary'}
                        fontWeight={'bold'}
                        sx={{ flex: 2, p: 1 }}
                      >
                        {activeDeal?.amount?.amount ? (
                          <>
                            {activeDeal?.amount?.currency === 'usd'
                              ? '$'
                              : activeDeal?.amount?.currency === 'inr'
                              ? '₹'
                              : '$'}
                            {activeDeal?.amount?.amount || '--'}
                          </>
                        ) : (
                          '--'
                        )}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                      />
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.confidence
                          ? `${activeDeal?.confidence}%`
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 350,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>Close Date</Typography>
                      <Typography sx={{ flex: 1 }}>Go Live Date</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.closeOn > 0
                          ? moment(activeDeal?.closeOn).format('DD MMMM YYYY')
                          : '--'}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        variant="fullWidth"
                        flexItem
                      />
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.goLiveOn
                          ? moment(activeDeal?.goLiveOn).format('DD MMMM YYYY')
                          : '--'}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      minWidth: 250,
                    }}
                  >
                    {/* <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ flex: 1 }}>Hubspot Deal</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'stretch',
                        border: '1px solid #d3d3d3',
                      }}
                    >
                      <Typography
                        sx={{ flex: 1, p: 1 }}
                        color={'text.secondary'}
                        fontWeight={'bold'}
                      >
                        {activeDeal?.crmDeam || '--'}
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    padding: '8px 24px',
                    borderTop: '1px solid #d3d3d3',
                    maxHeight: 'calc(100vh - 240px)',
                    overflowY: 'scroll',
                    '&::-webkit-scrollbar': {
                      width: '5px',
                    },
                    '&::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: 2,
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Troubleshoot />}
                      onClick={() => {
                        history.push(
                          `/teams/${teamId}/sales-call-analysis?dealId=${activeDeal?.id}`,
                        );
                      }}
                    >
                      {activeDeal?.counts?.interactions || 0} Artifacts
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={
                        generatingDealSummary ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Replay />
                        )
                      }
                      disabled={
                        generatingDealSummary ||
                        !activeDeal?.counts?.interactions
                      }
                      onClick={() => {
                        setGeneratingDealSummary(true);
                        dispatch(
                          generateDeal(activeDeal?.id, (data, error) => {
                            console.log(data);
                            setGeneratingDealSummary(false);
                            if (error) {
                              dispatch(
                                showAlert({
                                  message:
                                    'Oops, Something went wront! Please try again.',
                                  showCross: true,
                                  title: null,
                                  type: 'error',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            } else {
                              dispatch({
                                type: 'UPDATE_DEALS',
                                payload: {
                                  id: activeDeal?.id,
                                  data: {
                                    ...activeDeal,
                                    dealSummarySections: data,
                                  },
                                },
                              });
                              dispatch(
                                showAlert({
                                  message:
                                    'Deal Summary is generated successfully.',
                                  showCross: true,
                                  title: null,
                                  type: 'success',
                                  autoHideDuration: 2000,
                                  vertical: 'top',
                                  horizontal: 'center',
                                }),
                              );
                            }
                          }),
                        );
                      }}
                    >
                      Generate Summary
                    </Button>
                  </Box>
                  {activeDeal?.dealSummarySections &&
                  activeDeal?.dealSummarySections?.length > 0 ? (
                    activeDeal?.dealSummarySections?.map((point) => (
                      <Box
                        key={point}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        <Typography color={`text.secondary`}>
                          {capitalizeText(point?.title?.split('_')?.join(' '))}
                        </Typography>
                        <Box
                          sx={{
                            border: '1px solid #d3d3d3',
                            minHeight: 200,
                            p: 2,
                          }}
                        >
                          {point?.valueStr ? (
                            <Typography>{point?.valueStr}</Typography>
                          ) : (
                            <ul
                              style={{
                                listStyle: 'number',
                                margin: 0,
                                marginBlock: 0,
                                padding: 0,
                                paddingLeft: 16,
                              }}
                            >
                              {point?.valueArrStr?.map((item) => (
                                <li key={item}>
                                  <Typography>{item}</Typography>
                                </li>
                              ))}
                            </ul>
                          )}
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          minHeight: 200,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Typography color={'text.secondary'}>
                          No Summary Available
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {/* <Button
                  size="small"
                  variant="outlined"
                  onClick={(e) => {
                    setViewType('list');
                  }}
                  color="primary"
                >
                  Go Back
                </Button> */}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog
        open={Boolean(showConfirmDeleteDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              Are you sure you want to delete this sales call?
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDeleteDialog(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              dispatch(
                deleteDeal(
                  showConfirmDeleteDialog,

                  (data) => {
                    setShowConfirmDeleteDialog(false);
                    dispatch(
                      getDeals(
                        {
                          archived: currentTab === 'archived' ? true : false,
                          size: 10,
                        },
                        (data) => {},
                      ),
                    );
                  },
                  () => {
                    setShowConfirmDeleteDialog(false);
                  },
                ),
              );
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        open={createNewDealDialog}
        onClose={(e, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            return;
          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            // boxShadow: theme.shadows[5],
            outline: 'none',
            maxHeight: '80vh',
            maxWidth: '50vw',
            minWidth: '50vw',
            overflowY: 'auto',
            borderRadius: 1,
            padding: 2,
            position: 'relative',
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              top: 14,
              right: 15,
              padding: '4px',
              background: theme.palette.background.default,
              ':hover': {
                backgroundColor: '#d3d3d3',
              },
            }}
            onClick={(e) => {
              setCreateNewDealDialog(false);
              setCreationMode('normal');
            }}
          >
            <Close sx={{ fontSize: 20 }} />
          </IconButton>
          <Box display={'flex'} flexDirection={'column'} gap={1}>
            <Typography variant={'h6'}>
              {createNewDealDialog && typeof createNewDealDialog === 'object'
                ? 'Update Deal'
                : 'Create new deal'}
            </Typography>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              <TextField
                autoFocus
                label="Deal Name"
                variant="outlined"
                color="primary"
                size="small"
                name="dealName"
                value={dealName}
                onChange={(e) => setDealName(e.target.value)}
                fullWidth
                sx={{ flex: 2 }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deal Go Live Date"
                  format="YYYY-MM-DD"
                  value={dayjs(goLiveOn)}
                  onChange={(newValue) =>
                    setGoLiveOn(new Date(newValue).getTime())
                  }
                  sx={{ flex: 1 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>
            <TextField
              autoFocus
              label="Deal Description"
              multiline
              rows={4}
              variant="outlined"
              color="primary"
              size="small"
              name="dealDescription"
              value={dealDescription}
              onChange={(e) => setDealDescription(e.target.value)}
              fullWidth
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <FormControl size="small" sx={{ flex: 2 }}>
                <InputLabel id="dealSizeUnit">Deal Stage</InputLabel>
                <Select
                  size="small"
                  label="Deal Stage"
                  labelId={'dealStage'}
                  id={'dealStage'}
                  name={'dealStage'}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => {
                    setDealStage(e.target.value);
                  }}
                  value={dealStage}
                >
                  {orgConfigs?.dealStages?.map((stage) => (
                    <MenuItem key={stage?.id} value={stage?.id}>
                      <Typography variant="subtitle2">{stage?.name}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                autoFocus
                label="Deal Amount"
                variant="outlined"
                color="primary"
                size="small"
                name="dealSize"
                type="number"
                value={dealSize}
                onChange={(e) => setDealSize(e.target.value)}
                fullWidth
                sx={{ flex: 1 }}
              />
              <FormControl size="small" sx={{ flex: 1 }}>
                <InputLabel id="dealSizeUnit">Deal Amount Unit</InputLabel>
                <Select
                  size="small"
                  label="Deal Amount Unit"
                  labelId={'dealSizeUnit'}
                  id={'dealSizeUnit'}
                  name={'dealSizeUnit'}
                  MenuProps={{ disableScrollLock: true }}
                  onChange={(e) => {
                    setDealSizeUnit(e.target.value);
                  }}
                  value={dealSizeUnit}
                >
                  <MenuItem value={'usd'}>
                    <Typography variant="subtitle2">USD</Typography>
                  </MenuItem>
                  <MenuItem value={'inr'}>
                    <Typography variant="subtitle2">INR</Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <AutoCompleteMUI
              id="accountName"
              value={accountName}
              onChange={(event, newValue) => {
                setAccountName(newValue);
              }}
              options={accounts?.map((ac) => ac?.name)}
              onTextChange={(e) => setAccountName(e.target.value)}
              onKeyDown={() => {
                return false;
              }}
              placeholder="Account Name"
              minWidth={140}
            />
            <FormControl size="small" sx={{ width: '100%' }}>
              <InputLabel id="dealContact">Deal Contact</InputLabel>
              <Select
                size="small"
                label="Deal Contact"
                labelId={'dealContact'}
                id={'dealContact'}
                name={'dealContact'}
                MenuProps={{ disableScrollLock: true }}
                onChange={(e) => {
                  setDealContact(e.target.value);
                }}
                value={dealContact}
              >
                {members?.map((member) => (
                  <MenuItem value={member?.email} key={member?.email}>
                    <Typography variant="subtitle2">
                      {member?.fullName} -- {member?.email}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {createNewDealDialog && typeof createNewDealDialog === 'object' ? (
              <FormControlLabel
                sx={{ marginTop: -1 }}
                control={
                  <Checkbox
                    size="small"
                    checked={dealClosed}
                    onChange={(e) => {
                      setDealClosed(e.target.checked);
                    }}
                    name="dealClosed"
                  />
                }
                label={
                  <Typography variant="body2" color={'text.secondary'}>
                    Deal Closed
                  </Typography>
                }
              />
            ) : null}
            {dealClosed ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Deal Close Date"
                  format="YYYY-MM-DD"
                  value={dayjs(dealCloseDate)}
                  onChange={(newValue) => setDealCloseDate(newValue)}
                  sx={{ flex: 1 }}
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            ) : null}
            <Box display={'flex'} justifyContent={'flex-end'}>
              <Button
                size="small"
                variant="contained"
                disabled={!dealName?.trim() || creatingDeal}
                onClick={() => {
                  if (
                    createNewDealDialog &&
                    typeof createNewDealDialog === 'object'
                  ) {
                    setCreatingDeal(true);
                    dispatch(
                      updateDeal(
                        createNewDealDialog?.id,
                        {
                          name: dealName,
                          description: dealDescription,
                          accountId: accounts?.find(
                            (ac) => ac?.name === accountName,
                          )?.id,
                          contact: {
                            email: members?.find(
                              (member) => member?.email === dealContact,
                            )?.email,
                            name: members?.find(
                              (member) => member?.email === dealContact,
                            )?.fullName,
                          },
                          amount: {
                            amount: Number(dealSize),
                            currency: dealSizeUnit,
                          },
                          goLiveOn: goLiveOn,
                          stage: dealStage,
                          closeOn: dealClosed ? dealCloseDate : 0,
                        },
                        (data, error) => {
                          if (error) {
                            setCreatingDeal(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Oops, Something went wront! Please try again.',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            dispatch(
                              getDeals(
                                {
                                  archived:
                                    currentTab === 'archived' ? true : false,
                                  size: 10,
                                },
                                (cs) => {
                                  setCreatingDeal(false);
                                  setCreateNewDealDialog(false);
                                  setCreationMode('normal');
                                },
                              ),
                            );
                          }
                        },
                      ),
                    );
                  } else {
                    setCreatingDeal(true);
                    dispatch(
                      createNewDeal(
                        {
                          name: dealName,
                          description: dealDescription,
                          accountId: accounts?.find(
                            (ac) => ac?.name === accountName,
                          )?.id,
                          contact: {
                            email: members?.find(
                              (member) => member?.email === dealContact,
                            )?.email,
                            name: members?.find(
                              (member) => member?.email === dealContact,
                            )?.fullName,
                          },
                          amount: {
                            amount: Number(dealSize),
                            currency: dealSizeUnit,
                          },
                          goLiveOn: goLiveOn,
                          stage: dealStage,
                          closeOn: dealClosed ? dealCloseDate : 0,
                        },
                        (data, error) => {
                          if (error) {
                            setCreatingDeal(false);
                            dispatch(
                              showAlert({
                                message:
                                  'Oops, Something went wront! Please try again.',
                                showCross: true,
                                title: null,
                                type: 'error',
                                autoHideDuration: 2000,
                                vertical: 'top',
                                horizontal: 'center',
                              }),
                            );
                          } else {
                            if (creationMode === 'dropdown') {
                              setRefreshDealList(true);
                              setCreatingDeal(false);
                              setCreateNewDealDialog(false);
                              setSelectedCrmDeal(data || 'select');
                            } else {
                              history.push(`/deals`);
                              dispatch(
                                getDeals(
                                  {
                                    archived:
                                      currentTab === 'archived' ? true : false,
                                    size: 10,
                                  },
                                  (cs) => {
                                    setCreatingDeal(false);
                                    setCreateNewDealDialog(false);
                                    setCreationMode('normal');
                                  },
                                ),
                              );
                            }
                          }
                        },
                      ),
                    );
                  }
                }}
              >
                {createNewDealDialog && typeof createNewDealDialog === 'object'
                  ? 'Update Deal'
                  : 'Create new deal'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Drawer
        open={Boolean(showAskDonna)}
        anchor="right"
        onClose={() => setShowAskDonna(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowAskDonna(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>{'Ask Donna'}</Typography>
        </Box>
        <Box
          sx={{
            width: '45vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <Box display={'flex'} flexDirection={'column'} gap={2}>
            {fetchingConversationById ? (
              <Box
                width={1}
                height={1}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                p={2}
              >
                <CircularProgress size={20} />
                <Typography variant="body1" color={'text.primary'} mt={2}>
                  Loading all chats ...
                </Typography>
              </Box>
            ) : conversationDetails ? (
              <>
                {conversationDetails?.messages &&
                conversationDetails?.messages?.length > 0 ? (
                  <Box
                    flex={1}
                    display={'flex'}
                    flexDirection={'column'}
                    gap={1}
                  >
                    <Box
                      ref={chatsScrollview}
                      sx={{
                        minHeight: 'calc(100vh - 115px)',
                        maxHeight: 'calc(100vh - 115px)',
                        overflowY: 'scroll',
                        '&::-webkit-scrollbar': {
                          width: '5px',
                        },
                        '&::-webkit-scrollbar-track': {
                          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: theme.palette.primary.main,
                          borderRadius: 2,
                        },
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {/* {Object.values(
                              conversationDetails?.presetMessageMap || {},
                            )?.map(({ question, answer }, index) => (
                              <>
                                {question ? (
                                  <Box
                                    key={question?.id}
                                    sx={{
                                      borderTopLeftRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 16
                                          : 0,
                                      borderTopRightRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 0
                                          : 16,
                                      borderBottomLeftRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 8
                                          : 16,
                                      borderBottomRightRadius:
                                        question?.gptMessage?.role === 'user'
                                          ? 16
                                          : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        question?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        question?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      sx={{
                                        padding: '8px 16px',
                                        gap: 4,
                                      }}
                                    >
                                      {question?.gptMessage?.role === 'user' ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              question?.gptMessage?.role === 'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          System
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              question?.gptMessage?.role === 'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          Donna
                                        </Typography>
                                      )}
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color:
                                            question?.gptMessage?.role === 'user'
                                              ? '#f1f1f1'
                                              : theme.palette.text.secondary,
                                        }}
                                      >
                                        {moment(question?.createdOn).format(
                                          'DD MMMM YYYY hh:mm:ss A',
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        whiteSpace: 'pre-wrap',
                                        color:
                                          question?.gptMessage?.role === 'user'
                                            ? '#fff'
                                            : theme.palette.text.primary,
                                        padding: '0px 16px 16px 16px',
                                      }}
                                    >
                                      {question?.gptMessage?.content}
                                    </Typography>
                                  </Box>
                                ) : null}
                                {answer ? (
                                  <Box
                                    key={answer?.id}
                                    sx={{
                                      borderTopLeftRadius:
                                        answer?.gptMessage?.role === 'user' ? 16 : 0,
                                      borderTopRightRadius:
                                        answer?.gptMessage?.role === 'user' ? 0 : 16,
                                      borderBottomLeftRadius:
                                        answer?.gptMessage?.role === 'user' ? 8 : 16,
                                      borderBottomRightRadius:
                                        answer?.gptMessage?.role === 'user' ? 16 : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        answer?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        answer?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <Box
                                      display={'flex'}
                                      alignItems={'center'}
                                      justifyContent={'space-between'}
                                      sx={{
                                        padding: '8px 16px',
                                        gap: 4,
                                      }}
                                    >
                                      {answer?.gptMessage?.role === 'user' ? (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              answer?.gptMessage?.role === 'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          You
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color:
                                              answer?.gptMessage?.role === 'user'
                                                ? '#f1f1f1'
                                                : theme.palette.text.secondary,
                                          }}
                                        >
                                          Donna
                                        </Typography>
                                      )}
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color:
                                            answer?.gptMessage?.role === 'user'
                                              ? '#f1f1f1'
                                              : theme.palette.text.secondary,
                                        }}
                                      >
                                        {moment(answer?.createdOn).format(
                                          'DD MMMM YYYY hh:mm:ss A',
                                        )}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        whiteSpace: 'pre-wrap',
                                        color:
                                          answer?.gptMessage?.role === 'user'
                                            ? '#fff'
                                            : theme.palette.text.primary,
                                        padding: '0px 16px 16px 16px',
                                      }}
                                    >
                                      {answer?.gptMessage?.content}
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Box
                                    width={1}
                                    height={1}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    p={2}
                                    sx={{
                                      borderTopLeftRadius:
                                        answer?.gptMessage?.role === 'user' ? 16 : 0,
                                      borderTopRightRadius:
                                        answer?.gptMessage?.role === 'user' ? 0 : 16,
                                      borderBottomLeftRadius:
                                        answer?.gptMessage?.role === 'user' ? 8 : 16,
                                      borderBottomRightRadius:
                                        answer?.gptMessage?.role === 'user' ? 16 : 8,
                                      maxWidth: '80%',
                                      alignSelf:
                                        answer?.gptMessage?.role === 'user'
                                          ? 'end'
                                          : 'start',
                                      display: 'flex',
                                      flexDirection: 'column',
                                      // gap: 1,
                                      boxShadow: theme.shadows[8],
                                      background:
                                        answer?.gptMessage?.role === 'user'
                                          ? theme.palette.primary.main
                                          : '#fff',
                                    }}
                                  >
                                    <CircularProgress size={20} />
                                    <Typography
                                      variant="body1"
                                      color={'text.primary'}
                                      mt={4}
                                    >
                                      This will take a few seconds. Please check back
                                      after a short while.
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            ))} */}
                      {conversationDetails?.messages?.map((msg, index) => (
                        <>
                          {msg ? (
                            <Box
                              key={msg?.id}
                              sx={{
                                borderTopLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 0,
                                borderTopRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 0 : 16,
                                borderBottomLeftRadius:
                                  msg?.gptMessage?.role === 'user' ? 8 : 16,
                                borderBottomRightRadius:
                                  msg?.gptMessage?.role === 'user' ? 16 : 8,
                                maxWidth: '80%',
                                alignSelf:
                                  msg?.gptMessage?.role === 'user'
                                    ? 'end'
                                    : 'start',
                                display: 'flex',
                                flexDirection: 'column',
                                // gap: 1,
                                boxShadow: theme.shadows[8],
                                background:
                                  msg?.gptMessage?.role === 'user'
                                    ? theme.palette.primary.main
                                    : '#fff',
                              }}
                            >
                              <Box
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                sx={{
                                  padding: '8px 16px',
                                  gap: 4,
                                }}
                              >
                                {msg?.gptMessage?.role === 'user' ? (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    {'You'}
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="caption"
                                    sx={{
                                      color:
                                        msg?.gptMessage?.role === 'user'
                                          ? '#f1f1f1'
                                          : theme.palette.text.secondary,
                                    }}
                                  >
                                    Donna
                                  </Typography>
                                )}
                                <Typography
                                  variant="caption"
                                  sx={{
                                    color:
                                      msg?.gptMessage?.role === 'user'
                                        ? '#f1f1f1'
                                        : theme.palette.text.secondary,
                                  }}
                                >
                                  {moment(msg?.createdOn).format(
                                    'DD MMMM YYYY hh:mm:ss A',
                                  )}
                                </Typography>
                              </Box>
                              <Typography
                                sx={{
                                  whiteSpace: 'pre-wrap',
                                  color:
                                    msg?.gptMessage?.role === 'user'
                                      ? '#fff'
                                      : theme.palette.text.primary,
                                  padding: '0px 16px 16px 16px',
                                }}
                              >
                                {msg?.gptMessage?.content}
                              </Typography>
                            </Box>
                          ) : null}
                        </>
                      ))}
                    </Box>
                  </Box>
                ) : !conversationDetails?.messages ||
                  (conversationDetails?.messages &&
                    conversationDetails?.messages?.length === 0) ? (
                  <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={2}
                    flexDirection={'column'}
                  >
                    <Typography variant="body1" color={'text.secondary'} mt={4}>
                      No conversation done yet.
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    width={1}
                    height={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    p={2}
                    flexDirection={'column'}
                  >
                    <CircularProgress size={20} />
                    <Typography variant="body1" color={'text.primary'} mt={4}>
                      This will take a few seconds. Please check back after a
                      short while.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box
                flex={3}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
                gap={2}
              >
                <Typography variant="body1" color={'text.secondary'} mt={4}>
                  No conversation done yet.
                </Typography>
              </Box>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (userDetails?.currentAcrossDealConversationId) {
                  setGeneratingResponse(true);
                  dispatch(
                    getMessageResponse(
                      userDetails?.currentAcrossDealConversationId,
                      askAIQuestion?.trim(),
                      '',
                      null,
                      null,
                      'v2',
                      wordsLimit,
                      null,
                      async (data) => {
                        // console.log(data);
                        setGeneratingResponse(false);
                        setAskAIQuestion('');
                        dispatch({
                          type: 'UPDATE_CONVERSATION_MESSAGES',
                          payload: [
                            {
                              gptMessage: {
                                role: 'user',
                                content: askAIQuestion?.trim(),
                              },
                              id: uuidv4(),
                              createdOn: Date.now(),
                              audioId: uuidv4(),
                              userId: null,
                              customFields: {},
                            },
                            ...data,
                          ],
                        });
                        if (chatsScrollview?.current) {
                          chatsScrollview?.current?.scroll({
                            top: chatsScrollview?.current?.scrollHeight,
                            behaviour: 'smooth',
                          });
                        }
                      },
                      (errorCode) => {
                        setGeneratingResponse(false);
                        if (errorCode === 'quota exceeded') {
                          // setShowLimitExhausted(errorCode);
                        }
                      },
                    ),
                  );
                } else {
                  setCreatingConversation(true);
                  dispatch(
                    createNewConversation(
                      {
                        // description: '',
                        title: askAIQuestion?.trim(),
                        botId: orgConfigs?.dealsExpertBot?.id,
                        conversationMod: 'chat',
                      },
                      (conId) => {
                        setGeneratingResponse(true);
                        dispatch(
                          getConversationById(conId, null, (data) => {
                            dispatch(
                              updateUserDetails(
                                {
                                  currentAcrossDealConversationId: conId,
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                            dispatch(
                              updateConversationStatus(
                                conId,
                                'in_process',
                                () => {
                                  dispatch(
                                    getMessageResponse(
                                      conId,
                                      askAIQuestion?.trim(),
                                      '',
                                      null,
                                      null,
                                      'v2',
                                      wordsLimit,
                                      null,
                                      async (data) => {
                                        // console.log(data);
                                        setGeneratingResponse(false);
                                        setAskAIQuestion('');
                                        dispatch({
                                          type: 'UPDATE_CONVERSATION_MESSAGES',
                                          payload: [
                                            {
                                              gptMessage: {
                                                role: 'user',
                                                content: askAIQuestion?.trim(),
                                              },
                                              id: uuidv4(),
                                              createdOn: Date.now(),
                                              audioId: uuidv4(),
                                              userId: null,
                                              customFields: {},
                                            },
                                            ...data,
                                          ],
                                        });
                                        if (chatsScrollview?.current) {
                                          chatsScrollview?.current?.scroll({
                                            top:
                                              chatsScrollview?.current
                                                ?.scrollHeight,
                                            behaviour: 'smooth',
                                          });
                                        }
                                      },
                                      (errorCode) => {
                                        setGeneratingResponse(false);
                                        if (errorCode === 'quota exceeded') {
                                          // setShowLimitExhausted(errorCode);
                                        }
                                      },
                                    ),
                                  );
                                },
                              ),
                            );
                          }),
                        );
                        setCreatingConversation(false);
                      },
                    ),
                  );
                }
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
                borderTop={'1px solid #d3d3d3'}
                p={1}
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  zIndex: 11,
                  width: 1,
                  background: '#fff',
                }}
              >
                <TextField
                  autoFocus
                  placeholder="Ask any question"
                  variant="outlined"
                  color="primary"
                  size="small"
                  name="askAIQuestion"
                  value={askAIQuestion}
                  onChange={(e) => setAskAIQuestion(e.target.value)}
                  sx={{ flex: 4 }}
                  autoComplete="off"
                />
                {/* <FormControl size="small" sx={{ minWidth: 150 }}>
                  <InputLabel id="wordsLimit">Words Limit</InputLabel>
                  <Select
                    variant="outlined"
                    size="small"
                    labelId={'wordsLimit'}
                    label={'Words Limit'}
                    id={'wordsLimit'}
                    name={'wordsLimit'}
                    MenuProps={{ disableScrollLock: true }}
                    onChange={(e) => {
                      setWordsLimit(e.target.value);
                    }}
                    value={wordsLimit}
                  >
                    <MenuItem value={50}>
                      <Typography variant="subtitle2">50</Typography>
                    </MenuItem>
                    <MenuItem value={100}>
                      <Typography variant="subtitle2">100</Typography>
                    </MenuItem>
                    <MenuItem value={150}>
                      <Typography variant="subtitle2">150</Typography>
                    </MenuItem>
                    <MenuItem value={200}>
                      <Typography variant="subtitle2">200</Typography>
                    </MenuItem>
                    {process.env.REACT_APP_TARGET_ENV === 'local' ||
                    process.env.REACT_APP_TARGET_ENV === 'development' ? (
                      <MenuItem value={500}>
                        <Typography variant="subtitle2">500</Typography>
                      </MenuItem>
                    ) : null}
                  </Select>
                </FormControl> */}
                <Button
                  sx={{ flex: 1 }}
                  variant="contained"
                  size="small"
                  color="primary"
                  disabled={
                    generatingResponse || !askAIQuestion.trim()
                    // conversationDetails?.presetQuestionStatus !== 'completed'
                  }
                  startIcon={
                    generatingResponse ? (
                      <CircularProgress size={20} />
                    ) : (
                      <AutoAwesome />
                    )
                  }
                  type="submit"
                >
                  Ask Donna
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showUploadArtifact)}
        anchor="right"
        onClose={() => setShowUploadArtifact(null)}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => setShowUploadArtifact(null)}>
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>Upload Artifact</Typography>
        </Box>
        <Box
          p={2}
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 58px)',
            maxHeight: 'calc(100vh - 58px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          <ArtifactUpload
            fullWidth
            refreshList={refreshDealList}
            setRefreshList={setRefreshDealList}
            setCreateNewDealDialog={setCreateNewDealDialog}
            setCreationMode={setCreationMode}
            setShowUploadArtifact={setShowUploadArtifact}
          />
        </Box>
      </Drawer>
      <Drawer
        open={Boolean(showArtifactDetails)}
        anchor="right"
        onClose={() => {
          setCurrentArtifactDetailsTab('transcript');
          setShowArtifactDetails(null);
        }}
      >
        <Box
          p={1}
          sx={{
            borderBottom: '1px solid #d3d3d3',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <IconButton
            onClick={() => {
              setCurrentArtifactDetailsTab('transcript');
              setShowArtifactDetails(null);
            }}
          >
            <ChevronRight sx={{}} />
          </IconButton>
          <Typography color={'text.secondary'}>
            {showArtifactDetails?.title}
          </Typography>
        </Box>
        {artifactTypesWithoutMedia?.includes(
          showArtifactDetails?.interactionType,
        ) || !showArtifactDetails?.mediaFileBasedInteraction ? null : (
          <Tabs
            value={currentArtifactDetailsTab}
            onChange={(e, newValue) => setCurrentArtifactDetailsTab(newValue)}
            aria-label="Artifact Detail Tabs"
            orientation={'horizontal'}
            variant="scrollable"
            allowScrollButtonsMobile={false}
            scrollButtons={false}
            sx={{
              flex: 1,
              borderBottom: '1px solid #d3d3d3',
            }}
          >
            <Tab
              value={'transcript'}
              label={<Typography variant="body1">Transcript</Typography>}
              id={`simple-tab-${0}`}
              aria-controls={`simple-tabpanel-${0}`}
              sx={{}}
            />
            <Tab
              value={'media'}
              label={<Typography variant="body1">Media</Typography>}
              id={`simple-tab-${5}`}
              aria-controls={`simple-tabpanel-${5}`}
              sx={{}}
            />
          </Tabs>
        )}
        <Box
          // p={2}
          sx={{
            width: '40vw',
            minHeight: 'calc(100vh - 110px)',
            maxHeight: 'calc(100vh - 110px)',
            overflowY: 'scroll',
            '&::-webkit-scrollbar': {
              width: '5px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: theme.palette.primary.main,
              borderRadius: 2,
              // outline: '1px solid slategrey',
            },
            // background: '#343a40',
            flex: 1,
          }}
        >
          {currentArtifactDetailsTab === 'transcript' ? (
            <Typography
              sx={{
                whiteSpace: 'pre-wrap',
                padding: 2,
                flex: 1,
              }}
            >
              {showArtifactDetails?.content ||
                showArtifactDetails?.customField?.salesReviewFile?.transcript}
            </Typography>
          ) : (
            <MediaPreview
              videoRef={videoRef}
              styles={{
                flex: 1,
                borderLeft: '1px solid #d3d3d3',
                padding: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
              url={
                showArtifactDetails?.customField?.salesReviewFile?.customData
                  ?.url || ''
              }
              mediaType={
                showArtifactDetails?.customField?.salesReviewFile
                  ?.saleReviewMediaContentType || ''
              }
              handleVideoSeekTo
            />
          )}
        </Box>
      </Drawer>
      <Dialog
        open={Boolean(showConfirmDealUpdateDialog)}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{
          zIndex: 10002,
          '& .MuiPaper-root-MuiDialog-paper': {
            maxWidth: '70vw',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">Confirm</DialogTitle>
        <DialogContent>
          <Box display={'flex'} alignItems={'center'} gap={2}>
            <Typography color={'text.secondary'}>
              {crmDeals?.find((deal) => deal?.id === selectedCrmDeal)?.name}
            </Typography>
            <Typography color={'text.secondary'}>{'<-->'}</Typography>
            <Typography color={'text.secondary'}>
              {addDealFor?.title}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'} gap={2} mt={1}>
            <DialogContentText id="alert-dialog-description" flex={1}>
              You are about to associate the artifact with the selected deal.
            </DialogContentText>
          </Box>
          {/* <Typography color={'text.secondary'} mt={1}>
                  This will trigger autosync of the properties and summary from the
                  call to the deal.
                </Typography> */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              setShowConfirmDealUpdateDialog(null);
              setSelectedCrmDeal('select');
              setDealEditable(false);
              setAddDealFor(null);
              // if (testMode) setShowTestDeviceDialog(null);
            }}
            color="primary"
            // autoFocus
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            startIcon={updatingDealAssociation ? <CircularProgress /> : null}
            disabled={updatingDealAssociation}
            // data-trackid={'cta_permission_denied_dialog_close'}
            onClick={(e) => {
              handleDealUpdate();
            }}
            color="primary"
            autoFocus
          >
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

VideoPreview.propTypes = {
  selectedAudio: PropTypes.object,
  onDurationChange: PropTypes.func,
};

Deals.propTypes = {
  uId: PropTypes.string,
  member: PropTypes.object,
  parentComponent: PropTypes.string,
};

export default Deals;
