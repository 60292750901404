import axios from 'utils/axios';
import { auth } from '../../../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { v4 as uuidv4 } from 'uuid';

const createNewDiscoveryQuestion = (treeId, topicId, payload, callback) => {
  return async (dispatch) => {
    dispatch({ type: 'CREATING_NEW_DISCOVERY_QUESTION_FOR_TOPIC' });
    try {
      let unsubscribe = onAuthStateChanged(auth, async (user) => {
        const token = await user?.getIdToken();
        const userInfo = localStorage.getItem('userInfo');
        const userId = JSON.parse(userInfo)?.id;
        const reqId = uuidv4();
        try {
          const response = await axios.post(
            `/us/dt/discovery-tree/${treeId}/topics/${topicId}/questions`,
            payload,
            {
              params: {
                requestId: reqId,
              },
              headers: token
                ? {
                    Authorization: `Bearer ${token}`,
                  }
                : {},
            },
          );
          if (response.status === 200) {
            dispatch({
              type: 'CREATING_NEW_DISCOVERY_QUESTION_FOR_TOPIC_DONE',
              payload: response?.data,
            });
            if (typeof callback === 'function') {
              callback(response?.data, false);
            }
          } else {
            dispatch({
              type: 'CREATING_NEW_DISCOVERY_QUESTION_FOR_TOPIC_FAILED',
              payload: response.data.text,
            });
            if (typeof callback === 'function') {
              callback(null, response.data.text);
            }
          }
        } catch (e) {
          if (
            e?.response?.status === 400 &&
            e?.response?.data?.status === 400
          ) {
            if (typeof callback === 'function') {
              callback(e?.response?.data?.text);
            }
          } else {
            if (typeof callback === 'function') {
              callback(null, true);
            }
          }
          dispatch({
            type: 'CREATING_NEW_DISCOVERY_QUESTION_FOR_TOPIC_FAILED',
            payload:
              e.response && e.response.data.text
                ? e.response.data.text
                : 'Failed to create new discovery question',
          });
        }
        unsubscribe();
      });
    } catch (e) {
      dispatch({
        type: 'CREATING_NEW_DISCOVERY_QUESTION_FOR_TOPIC_FAILED',
        payload: `Failed to create new discovery question`,
      });
      if (typeof callback === 'function') {
        callback(null, true);
      }
    }
  };
};

export default createNewDiscoveryQuestion;
