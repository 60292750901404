import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import Container from 'components/Container';
import {
  Topbar,
  Sidebar,
  Footer,
  BottomNavbar,
  // ThemeModeToggler
} from './components';

import AlertPopup from 'components/AlertPopup';
import { useDispatch, useSelector } from 'react-redux';
import hideAlert from 'redux/actions/Common/hiderAlert';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  Modal,
  Popper,
  Radio,
  RadioGroup,
  Select,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Switch,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import getUserDetails from 'redux/actions/Common/getUserDetails';
import { messaging } from '../../firebase';
import { getToken, onMessage } from 'firebase/messaging';
import {
  Add,
  AddBoxOutlined,
  AddCircleOutline,
  ArrowLeft,
  ArrowRight,
  Cached,
  Check,
  CheckCircle,
  CheckCircleOutline,
  CheckOutlined,
  Clear,
  Close,
  Delete,
  DeleteOutline,
  DoneAll,
  Edit,
  EditOutlined,
  EditRounded,
  ExitToApp,
  HelpOutline,
  HelpOutlined,
  History,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Lock,
  Loop,
  Person,
  Pin,
  PushPin,
  PushPinOutlined,
  Redo,
  Replay,
  Save,
  Search,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import updateNotificationPreferences from 'redux/actions/Common/updateNotificationPreferences.js';
import getSalesCallReviews from 'redux/actions/Common/getDeals';
import { AccountSettings } from 'views';
import ProfileCompletion from 'components/ProfileCompletion';
import getPlaybookHistory from 'redux/actions/Common/getPlaybookHistory';
import getOrganizationConfigs from 'redux/actions/Common/getOrganizationConfigs';
import getRemainingCredits from 'redux/actions/Common/getRemainingCredits';
import { useHistory } from 'react-router-dom';
import getTeams from 'redux/actions/Common/getTeams';
import updateUserDetails from 'redux/actions/Common/updateUserDetails';
import { capitalizeText, initializePendo } from 'utils';
import RichTextEditor from 'components/RichTextEditor';
import generateValueProp from 'redux/actions/Common/generateValueProp';
import updateGlobalOrganizationConfig from 'redux/actions/Common/updateGlobalOrganizationConfig';
import createDiscoveryTree from 'redux/actions/Common/createDiscoveryTree';
import getDiscoveryTreeStatus from 'redux/actions/Common/getDiscoveryTreeStatus';
import getDiscoveryQuestionList from 'redux/actions/Common/getDiscoveryQuestionList';
import getAllCrmDeals from 'redux/actions/Common/getAllCrmDeals';
import showAlert from 'redux/actions/Common/showAlert';
import getDealDiscoveryQuestionsList from 'redux/actions/Common/getDealDiscoveryQuestionsList';
import updateDeal from 'redux/actions/Candidate/updateDeal';
import updateDiscoveryQuestion from 'redux/actions/Compass/updateDiscoveryQuestion';
import ReactTyped from 'react-typed';
import createNewDiscoveryQuestion from 'redux/actions/Common/createNewDiscoveryQuestion';
import QuestionNode from 'components/QuestionNode';
import { DndProvider, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import updateDiscoveryQuestionBulk from 'redux/actions/Compass/updateDiscoveryQuestionBulk';
import parse from 'html-react-parser';
import getSelectedDiscoveryQuestions from 'redux/actions/Common/getSelectedDiscoveryQuestions';
import getDiscoveryTreeTopics from 'redux/actions/Common/getDiscoveryTreeTopics';
import DragNDropSortableList from 'components/DragNDropSortableList';
import integrateHubspot from 'redux/actions/Common/integrateHubspot';
import getPipedriveOAuthUrl from 'redux/actions/Common/getPipedriveOAuthUrl';
import getZohoCrmOAuthUrl from 'redux/actions/Common/getZohoCrmOAuthUrl';
import getGoogleOAuthUrl from 'redux/actions/Common/getGoogleOAuthUrl';
import getDealDiscoveryRisks from 'redux/actions/Common/getDealDiscoveryRisks';
import createNewDeal from 'redux/actions/Candidate/createNewDeal';
import { v4 as uuidv4 } from 'uuid';
import getDeals from 'redux/actions/Common/getDeals';
import getSellingStyles from 'redux/actions/Common/getSellingStyles';
import DragNDropSortableDealStages from 'components/DragNDropSortableDealStages';
import Joyride from 'react-joyride';
import setOnboarding from 'redux/actions/Common/setOnboarding';
import TourStepContent from 'components/TourStepContent';
import Tour from 'components/Tour';
import firebaseLogout from 'redux/actions/Auth/firebaseLogout';
import firebaseCheckSession from 'redux/actions/Auth/firebaseCheckSession';

const crms = [
  { crm: 'hubspot', name: 'Hubspot', icon: '/hubspot.svg', iconWidth: '120px' },
  {
    crm: 'pipedrive',
    name: 'Pipedrive',
    icon: '/pipedrive-logo.png',
    iconWidth: '140px',
  },
  { crm: 'zoho', name: 'Zoho', icon: '/zoho-crm.svg', iconWidth: '100px' },
];

const tools = [
  {
    tool: 'gmail',
    name: 'Gmail',
    icon: '/dh-icons/gmail.svg',
    iconWidth: '120px',
  },
  {
    tool: 'google-calendar',
    name: 'Google Calendar',
    icon: '/google_calendar.png',
    iconWidth: '160px',
  },
];

const onboardingStages = [
  'company_info',
  'value_prop',
  'value_prop_generated',
  'selling_style',
  'selling_style_picked',
  'deal_risk_selection',
  'discovery_tree_generation_in_progress',
  'crm_integration',
  'deal_stages',
  'tools_integrations',
  'discovery_tree_generation_in_progress_after',
  'discovery_questions',
  'discovery_questions_reordering',
  'deal_creation',
];

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} color="primary" />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const RenderValuePropJSON = ({ valueProp, key }) => {
  if (typeof valueProp === 'object' && !Array.isArray(valueProp)) {
    return (
      <Box key={key}>
        {Object.keys(valueProp || {})?.map((key) => {
          return (
            <Box key={key}>
              <Typography>
                {capitalizeText(key?.split('_')?.join(' ') || '')}
              </Typography>
              <Box sx={{ marginLeft: 2 }}>
                <RenderValuePropJSON valueProp={valueProp?.[key]} />
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  } else if (typeof valueProp === 'object' && Array.isArray(valueProp)) {
    return valueProp?.map((item) => (
      <RenderValuePropJSON key={item} valueProp={item} />
    ));
  }
  return (
    <Box
      key={key}
      sx={{
        display: 'flex',
        gap: 1,
      }}
    >
      <Typography>-</Typography>
      <Typography>{valueProp}</Typography>
    </Box>
  );
};

const Dashboard = ({
  children,
  colorInvert = false,
  bgcolor = 'transparent',
  noTopbar = false,
  noFooter = true,
  leftSidebar = true,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: false,
  });
  const uiStates = useSelector((state) => state.uiStates);
  const {
    dashboardSidebarExpanded,
    showOnboardingDialog,
    onBoardingStep,
    onBoardingCompletionPercentage,
    navExpandedStatus,
    showOnboardingDialogV2,
    onboardingStepV2,
    completedStepsV2,
    onboardingStage,
  } = uiStates;
  const teamId = localStorage.getItem('teamId');
  const userInfo = JSON.parse(localStorage.getItem('userInfo') || '{}');
  const { userDetails } = useSelector((state) => state.userDetails);
  const { orgConfigs } = useSelector((state) => state.orgConfigs);
  const onboardingSettings = useSelector((state) => state.onboardingSettings);

  const [openSidebar, setOpenSidebar] = useState(false);
  const [showBottomNav, setShowBottomNav] = useState(false);
  const alertState = useSelector((state) => state.alertState);
  const [completedStep, setCompletedStep] = useState({});
  const [methodologiesAvailable, setMethodologiesAvailable] = useState([]);
  const [selectedPlaybook, setSelectedPlaybook] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showNewCustomerDialog, setShowNewCustomerDialog] = useState(true);
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [aboutCompany, setAboutCompany] = useState('');
  const [valueProposition, setValueProposition] = useState('');
  const [valuePropositionJson, setValuePropositionJson] = useState(null);
  const [valuePropHtml, setValuePropHtml] = useState('');
  const [valuePropJsonError, setValuePropJsonError] = useState(false);
  const [sellingStyle, setSellingStyle] = useState(
    'persuasive_or_traditional_selling',
  );
  const [companyName, setCompanyName] = useState('');
  const [expandedStyles, setExpandedStyles] = useState([
    'persuasive_or_traditional_selling',
    'consultative_or_solution_selling',
    'relationship_or_trust_based_selling',
    'value_based_selling',
    'insight_or_challenger_selling',
    'spin_or_question_based_selling',
    'collaborative_or_partnering_selling',
    'inbound_educational_selling',
    'social_selling',
  ]);
  const [metaData, setMetaData] = useState('');

  const [updatingOrg, setUpdatingOrg] = useState(false);
  const [generatingValueProp, setGeneratingValueProp] = useState(false);
  const checkProgressTimer = useRef(null);
  const [treeGenerationInProgress, setTreeGenerationInProgress] = useState(
    true,
  );
  const [treeId, setTreeId] = useState('');
  const { qList, fetching, fetchingPagination } = useSelector(
    (state) => state.discoveryQList,
  );

  const [questionTopics, setQuestionTopics] = useState([]);
  const [questionSubTopics, setQuestionSubTopics] = useState([]);
  const [discoveryQList, setDiscoveryQList] = useState([]);
  const [fetchingDiscoveryQList, setFetchingDiscoveryQList] = useState(false);
  const [selectedDiscoveryQList, setSelectedDiscoveryQList] = useState([]);

  const [selectedQuestionsList, setSelectedQuestionsList] = useState([]);
  const [questionEditMode, setQuestionEditMode] = useState(false);
  const [editedQuestionText, setEditedQuestionText] = useState('');

  const [anchorElDetails, setAnchorElDetails] = useState(null);
  const [popperDetails, setPopperDetails] = useState(null);

  const [fetchingCrmDeals, setFetchingCrmDeals] = useState(false);
  const [missingRequiredCrmScopes, setMissingRequiredCrmScopes] = useState([]);
  const [crmDeals, setCrmDeals] = useState([]);

  const [questionGenerationProgress, setQuestionGenerationProgress] = useState(
    0,
  );

  const [updatingDeal, setUpdatingDeal] = useState(false);
  const [onboardingDeal, setOnboardingDeal] = useState(null);
  const [updatingQuestion, setUpdatingQuestion] = useState(false);
  const [valuePropMode, setValuePropMode] = useState('html');
  const [selectedTopic, setSelectedTopic] = useState(false);
  const [expandedSubtopics, setExpandedSubtopics] = useState([]);
  const [showDeleted, setShowDeleted] = useState(false);

  const [showNewQuestionDialog, setShowNewQuestionDialog] = useState(false);
  const [subtopicIdForNewQuestion, setSubtopicIdForNewQuestion] = useState('');
  const [creatingNewQuestion, setCreatingNewQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const [newQuestionSellingStyle, setNewQuestionSellingStyle] = useState(
    'persuasive_or_traditional_selling',
  );

  const [toolSearch, setToolSearch] = useState('');
  const [crmSearch, setCrmSearch] = useState('');
  const [selectedCrm, setSelectedCrm] = useState('');

  const [crmConnected, setCrmConnected] = useState('');
  const [toolsConnected, setToolsConnected] = useState(false);

  // hubspot integration

  const [hubspotIntergration, setHubspotIntegration] = useState(false);
  const [showHubspotKeyDialog, setShowHubspotKeyDialog] = useState(false);
  const [hubspotIntegrationError, setHubspotIntegrationError] = useState('');
  const [requiredScopes, setRequiredScopes] = useState([]);
  const [hubspotKey, setHubspotKey] = useState('');
  const [keyVisible, setKeyVisible] = useState(false);
  const [integratingHubspot, setIntegratingHubspot] = useState(false);

  // zoho Integration
  const [zohoCrmIntegration, setZohoCrmIntegration] = useState(false);
  const [showZohoKeyDialog, setShowZohoKeyDialog] = useState(false);
  const [zohoIntegrationError, setZohoIntegrationError] = useState('');
  const [zohoClientId, setZohoClientId] = useState('');
  const [zohoClientSecret, setZohoClientSecret] = useState('');
  const [clientIdVisible, setClientIdVisible] = useState(false);
  const [clientSecretVisible, setCleintSecretVisible] = useState(false);
  const [integratingZoho, setIntegratingZoho] = useState(false);

  // pipedrive integration

  const [pipedriveCrmIntergration, setPipedriveCrmIntergration] = useState(
    false,
  );

  // google calendar integration
  const [googleIntegration, setGoogleIntegration] = useState(false);
  const [showDisclosure, setShowDisclosure] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // gmail integration
  const [showGmailIntegrationDialog, setShowGmailIntegrationDialog] = useState(
    false,
  );
  const [gmailIntegration, setGmailIntegration] = useState(false);
  const [gmailAppPassword, setGmailAppPassword] = useState('');
  const [appPasswordVisible, setAppPasswordVisible] = useState(false);
  const [integratingGmail, setIntegratingGmail] = useState(false);

  const [dealCreationInProgress, setDealCreationInProgress] = useState(false);
  const [dealTitle, setDealTitle] = useState('');
  const [dealCreated, setDealCreated] = useState(false);

  const [dealRisks, setDealRisks] = useState(null);
  const [selectedDealRisks, setSelectedDealRisks] = useState([]);
  const [creatingRisk, setCreatingRisk] = useState(false);
  const [showNewRiskDialog, setShowNewRiskDialog] = useState(false);
  const [newRisk, setNewRisk] = useState('');
  const [riskExplanation, setRiskExplanation] = useState('');

  const [fetchingSellingStyles, setFetchingSellingStyles] = useState(false);
  const [sellingStyles, setSellingStyles] = useState([]);

  const [dealStages, setDealStages] = useState([]);
  const [newStage, setNewStage] = useState('');
  const [stageCreationMode, setStageCreationMode] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuClick = (event) => {
    if (anchorEl) setAnchorEl(null);
    else setAnchorEl(event.target);
  };

  const handleLogoutClick = () => {
    if (localStorage.getItem('notificationToken')) {
      dispatch(
        updateNotificationPreferences(
          {
            pushnotification: false,
          },
          () => {
            localStorage.removeItem('notificationToken');
            dispatch(
              firebaseLogout(() => {
                dispatch(firebaseCheckSession());
                history.go();
              }),
            );
          },
        ),
      );
    } else {
      dispatch(
        firebaseLogout(() => {
          dispatch(firebaseCheckSession());
          history.go();
        }),
      );
    }
  };

  const [
    regeneratingQuestionInSpecificStyle,
    setRegeneratingQuestionInSpecificStyle,
  ] = useState(false);

  const [
    fetchingMoreDiscoveryQuestions,
    setFetchingMoreDiscoveryQuestions,
  ] = useState(false);

  const [
    notificationPermissionStatus,
    setNotificationPermissionStatus,
  ] = useState('ask');

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 38,
  });

  const checkNotificationPromise = async () => {
    try {
      if (!('Notification' in window)) {
        throw 'This browser does not support desktop notification';
      }
      const permission = await Notification.requestPermission();
      setNotificationPermissionStatus(permission);
      if (permission === 'granted') {
        const notToken = await getToken(messaging, {
          vapidKey:
            process.env.REACT_APP_TARGET_ENV === 'local' ||
            process.env.REACT_APP_TARGET_ENV === 'development'
              ? 'BJniguQbz234pBc8uVFNUDKA9PiABl85gqftUdZ5IOMNnOvYJplR1OgCbS_KJXBEV5sTiE4LvIqUbNCw9A6itU8'
              : 'BOG23-8imwPCCqu7H4GBkAoMVBe1KZ8dqBRIsOSLKnnB3FcT7OC6wB37AHvg3foTlVlb0GRXS3O9n_Vwq-8q24Q',
        });
        localStorage.setItem('notificationToken', notToken);
        dispatch(
          updateNotificationPreferences(
            {
              pushnotification: true,
              pushnotificationToken: notToken,
            },
            () => {},
          ),
        );
        onMessage(messaging, (payload) => {
          console.log('coming here');
          console.log(payload);
          if (
            payload?.notification?.title === 'Sales Call Analysis Completed!'
          ) {
            dispatch(
              getSalesCallReviews(
                {
                  keywords: '',
                  lastSeen: null,
                  lastTime: null,
                  size: 10,
                  teamId: teamId,
                },
                (data) => {
                  // console.log(data);
                },
              ),
            );
          }
          //   {
          //     "from": "1090205382087",
          //     "messageId": "2bf13188-cfbf-4ede-89e3-025682ed0078",
          //     "notification": {
          //         "title": "Sales Call Analysis Completed!",
          //         "body": "Your sales call media file 'prod_test_1' has been successfully analyzed. Check out the detailed report now"
          //     }
          // }
        });
      } else {
        console.log('permission not granted');
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isXs) {
      setShowBottomNav(true);
    } else {
      setShowBottomNav(false);
    }
  }, [isXs]);

  useEffect(() => {
    if (isMd) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: true,
        },
      });
    } else {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'dashboardSidebarExpanded',
          value: false,
        },
      });
    }
  }, [isMd]);

  useEffect(() => {
    if (showOnboardingDialog) {
      dispatch(getOrganizationConfigs((data) => {}));
    }
  }, [showOnboardingDialog]);

  useEffect(() => {
    if (showOnboardingDialog && onBoardingStep === 1) {
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          setSelectedPlaybook(data?.selectedPlaybook);
        }),
      );
    }
  }, [showOnboardingDialog, onBoardingStep]);

  useEffect(() => {
    if (
      orgConfigs &&
      ((orgConfigs?.methodologies && orgConfigs?.methodologies?.length > 0) ||
        (orgConfigs?.customMethodologies &&
          orgConfigs?.customMethodologies?.length > 0))
    ) {
      setMethodologiesAvailable(true);
    } else {
      setMethodologiesAvailable(false);
    }
  }, [orgConfigs]);

  // useEffect(() => {
  // if (showOnboardingDialog) {
  //   if (userDetails?.custom?.orgType === 'demo_org') {
  //     dispatch({
  //       type: 'UPDATE_UI_STATE',
  //       payload: {
  //         key: 'onBoardingStep',
  //         value: 1,
  //       },
  //     });
  //   } else {
  //     console.log('coming here');
  //     dispatch({
  //       type: 'UPDATE_UI_STATE',
  //       payload: {
  //         key: 'onBoardingStep',
  //         value: 0,
  //       },
  //     });
  //   }
  // }
  // }, [showOnboardingDialog, userDetails]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
      payload: {
        key: 1,
        value: Math.floor(
          (orgConfigs?.botVerticals &&
          Array.isArray(orgConfigs?.botVerticals) &&
          orgConfigs?.botVerticals.length > 0
            ? 12.5
            : 0) +
            (orgConfigs?.botDesignations &&
            Array.isArray(orgConfigs?.botDesignations) &&
            orgConfigs?.botDesignations.length > 0
              ? 12.5
              : 0),
        ),
      },
    });
    if (
      orgConfigs?.botVerticals &&
      Array.isArray(orgConfigs?.botVerticals) &&
      orgConfigs?.botVerticals.length > 0 &&
      orgConfigs?.botDesignations &&
      Array.isArray(orgConfigs?.botDesignations) &&
      orgConfigs?.botDesignations.length > 0
    ) {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [1]: true,
        };
      });
    } else {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [1]: false,
        };
      });
    }
    if (userDetails?.custom?.orgType !== 'demo_org') {
      if (methodologiesAvailable) {
        if (orgConfigs?.isGeneralAutoAnalysis !== null) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [3]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 3,
              value: 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [3]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 3,
              value: 0,
            },
          });
        }
      } else {
        if (orgConfigs?.isGeneralAutoAnalysis !== null) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [2]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 2,
              value: 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [2]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 2,
              value: 0,
            },
          });
        }
      }
    } else {
      setCompletedStep((prev) => {
        return {
          ...prev,
          [3]: true,
        };
      });
      dispatch({
        type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
        payload: {
          key: 3,
          value: 0,
        },
      });
    }
    if (methodologiesAvailable) {
      if (
        orgConfigs?.selectedMethodologies &&
        Array.isArray(orgConfigs?.selectedMethodologies) &&
        orgConfigs?.selectedMethodologies?.length > 0
      ) {
        setCompletedStep((prev) => {
          return {
            ...prev,
            [2]: true,
          };
        });
        dispatch({
          type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
          payload: {
            key: 2,
            value: 25,
          },
        });
      } else {
        setCompletedStep((prev) => {
          return {
            ...prev,
            [2]: false,
          };
        });
        dispatch({
          type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
          payload: {
            key: 2,
            value: 0,
          },
        });
      }
    }
  }, [orgConfigs]);

  useEffect(() => {
    if (onBoardingStep === 1 || onBoardingStep === 2) {
      dispatch(
        getPlaybookHistory(teamId || null, (data) => {
          if (data?.selectedPlaybook) {
            setCompletedStep((prev) => {
              return {
                ...prev,
                [0]: true,
              };
            });
            dispatch({
              type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
              payload: {
                key: 0,
                value: userDetails?.custom?.orgType === 'demo_org' ? 50 : 25,
              },
            });
          } else {
            setCompletedStep((prev) => {
              return {
                ...prev,
                [0]: false,
              };
            });
            dispatch({
              type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
              payload: {
                key: 0,
                value: 0,
              },
            });
          }
        }),
      );
    }
  }, [onBoardingStep]);

  // useEffect(() => {
  //   if (
  //     (userDetails?.orgRoles?.includes('ORG_ADMIN') ||
  //       userDetails?.orgRoles?.includes('WL_ORG_ADMIN')) &&
  //     orgConfigs
  //   ) {
  //     dispatch(
  //       getTeams(10, null, (teams) => {
  //         // console.log(teams);
  //         // setLoading(false);
  //         dispatch({
  //           type: 'UPDATE_UI_STATE',
  //           payload: {
  //             key: 'pinnedTeams',
  //             value: teams || [],
  //           },
  //         });
  //         // dispatch({
  //         //   type: 'UPDATE_UI_STATE',
  //         //   payload: {
  //         //     key: 'navExpandedStatus',
  //         //     value: {
  //         //       ...navExpandedStatus,
  //         //       [teams?.find((team) => team?.defaultTeam)?.id]:
  //         //         userDetails?.userSetting?.navbarTeamCollapsState?.[
  //         //           teams?.find((team) => team?.defaultTeam)?.id
  //         //         ] !== undefined
  //         //           ? userDetails?.userSetting?.navbarTeamCollapsState?.[
  //         //               teams?.find((team) => team?.defaultTeam)?.id
  //         //             ]
  //         //           : true,
  //         //     },
  //         //   },
  //         // });
  //       }),
  //     );
  //   }
  // }, [userDetails, orgConfigs]);

  useEffect(() => {
    if (orgConfigs) {
      setCompanyName(orgConfigs?.organizationName || '');
      setCompanyWebsite(orgConfigs?.companyWebsite || '');
      setAboutCompany(orgConfigs?.aboutCompany || '');
      // setValueProposition(orgConfigs?.valueProp || '');
      setSellingStyle(
        orgConfigs?.sellingStyle || 'persuasive_or_traditional_selling',
      );
      setTreeId(orgConfigs?.discoveryTreeId);
      // setTreeGenerationInProgress(orgConfigs?.discoveryTreeId ? false : true);
      // setSelectedDealRisks(orgConfigs?.selectedDiscoveryRisk || []);
      setDealStages(orgConfigs?.dealStages || []);
    }
  }, [orgConfigs]);

  useEffect(() => {
    setQuestionEditMode(null);
    setEditedQuestionText('');
  }, [treeId, onboardingStepV2]);

  useEffect(() => {
    if (userDetails) {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'onboardingStage',
          value: userDetails?.custom?.onboardingStage,
        },
      });
    }
  }, [userDetails]);

  // useEffect(() => {
  //   if (
  //     userDetails &&
  //     (process.env.REACT_APP_TARGET_ENV === 'local' ||
  //       process.env.REACT_APP_TARGET_ENV === 'development')
  //   ) {
  //     initializePendo(userDetails);
  //   }
  // }, [userDetails]);

  // useEffect(() => {
  //   dispatch(
  //     updateUserDetails(
  //       {
  //         userSetting: {
  //           navbarTeamCollapsState: {
  //             ...navExpandedStatus,
  //           },
  //         },
  //       },
  //       () => {},
  //     ),
  //   );
  // }, [navExpandedStatus]);

  useEffect(() => {
    if (
      onboardingStepV2 === 'selling_style' ||
      onboardingStepV2 === 'selling_style_picked' ||
      onboardingStepV2 === 'discovery_questions' ||
      onboardingStepV2 === 'discovery_questions_reordering'
    ) {
      setFetchingSellingStyles(true);
      dispatch(
        getSellingStyles((data) => {
          setSellingStyles(data || []);
          // setTimeout(() => {
          setFetchingSellingStyles(false);
          // }, 3000);
        }),
      );
    }
    if (onboardingStepV2 === 'deal_risk_selection' && !dealRisks) {
      dispatch(
        getDealDiscoveryRisks((data) => {
          setDealRisks(data || []);
          setSelectedDealRisks(
            orgConfigs?.selectedDiscoveryRisk &&
              orgConfigs?.selectedDiscoveryRisk?.length > 0
              ? orgConfigs?.selectedDiscoveryRisk
              : data?.map((risk) => risk?.id) || [],
          );
        }),
      );
    }
  }, [onboardingStepV2, orgConfigs]);

  useEffect(() => {
    // if (sellingStyle) {
    if (!expandedStyles?.includes(Number(sellingStyle))) {
      setExpandedStyles((prev) => {
        return [...prev, Number(sellingStyle)];
      });
    }
    // }
  }, [sellingStyle]);

  useEffect(() => {
    if (
      (onboardingStepV2 === 'discovery_questions' ||
        onboardingStepV2 === 'discovery_questions_reordering') &&
      treeId
    ) {
      dispatch(
        getDiscoveryTreeTopics(treeId, (data) => {
          console.log(data);
          // if (data?.risk && data?.risk?.length > 0) {
          setQuestionTopics(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            }) || [],
          );
          setQuestionSubTopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setExpandedSubtopics(
            [
              ...(data?.map((risk) => [
                ...(risk?.dealStages?.map((stage) => {
                  return {
                    id: stage?.id,
                    riskTitle: stage?.stage,
                    path: stage?.path,
                  };
                }) || []),
              ]) || []),
            ]?.flat(),
          );
          setSelectedTopic(
            data?.map((risk) => {
              return { id: risk?.id, riskTitle: risk?.riskTitle };
            })?.[0]?.id || '',
          );
          // } else {
          //   setQuestionTopics(data?.map((aq) => aq?.topic) || []);
          //   setQuestionSubTopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setExpandedSubtopics([
          //     ...(data?.map((aq) => [
          //       ...(aq?.subTopics?.map((subTopic) => subTopic?.topic) || []),
          //     ])?.[0] || []),
          //   ]);
          //   setSelectedTopic(data?.map((aq) => aq?.topic)?.[0] || '');
          // }
        }),
      );
    }
  }, [onboardingStepV2, treeId]);

  useEffect(() => {
    if (
      (onboardingStepV2 === 'discovery_questions' ||
        onboardingStepV2 === 'discovery_questions_reordering') &&
      treeId &&
      !qList
    ) {
      setFetchingDiscoveryQList(true);
      dispatch(
        getDealDiscoveryQuestionsList(treeId, null, null, (data) => {
          console.log(data);
          setFetchingDiscoveryQList(false);
        }),
      );
    }
  }, [onboardingStepV2, treeId]);

  useEffect(() => {
    if (
      (onboardingStepV2 === 'discovery_tree_generation_in_progress' ||
        onboardingStepV2 === 'discovery_tree_generation_in_progress_after' ||
        onboardingStepV2 === 'crm_integration' ||
        onboardingStepV2 === 'deal_stages' ||
        onboardingStepV2 === 'tools_integrations') &&
      treeId
    ) {
      if (!checkProgressTimer.current) {
        checkProgressTimer.current = setInterval(() => {
          dispatch(
            getDiscoveryTreeStatus(treeId, (data) => {
              console.log(data);
              if (data?.status === 'completed') {
                clearInterval(checkProgressTimer.current);
                setTreeGenerationInProgress(false);
              } else if (data?.status === 'failed') {
                clearInterval(checkProgressTimer.current);
              } else {
                // dispatch(
                //   updateUserDetails(
                //     {
                //       onboardingStage:
                //         'discovery_tree_generation_in_progress',
                //     },
                //     () => {
                //       dispatch(getUserDetails((data) => {}));
                //     },
                //   ),
                // );
                setQuestionGenerationProgress(
                  (Object.keys(data?.progress || {}).length /
                    data?.stagesCount) *
                    100,
                );
              }
            }),
          );
        }, 5000);
      }
    } else {
      clearInterval(checkProgressTimer.current);
    }
  }, [onboardingStepV2, treeId]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_UI_STATE',
      payload: {
        key: 'onboardingStepV2',
        value:
          onboardingStage === 'signup_completed'
            ? 'company_info'
            : onboardingStage,
      },
    });
    if (onboardingStage === 'onboarding_completed') {
      dispatch({
        type: 'UPDATE_UI_STATE',
        payload: {
          key: 'showOnboardingDialogV2',
          value: false,
        },
      });
    }
  }, [onboardingStage]);

  useEffect(() => {
    if (valueProposition) {
      try {
        setValuePropositionJson(JSON.parse(valueProposition));
        setValuePropJsonError(false);
      } catch (e) {
        setValuePropJsonError(true);
        console.log(e);
      }
    }
  }, [valueProposition]);

  useEffect(() => {
    if (userDetails) {
      setCrmConnected(
        userDetails?.custom?.integration?.hubSpot
          ? 'hubspot'
          : userDetails?.custom?.integration?.zohoCrm
          ? 'zoho'
          : userDetails?.custom?.integration?.pipedriveCrm
          ? 'pipedrive'
          : '',
      );
      setToolsConnected(
        userDetails?.custom?.userIntegration?.calendarIntegration,
      );
      setGoogleIntegration(
        userDetails?.custom?.userIntegration?.calendarIntegration,
      );
      setGmailIntegration(userDetails?.custom?.userIntegration?.gmail);
    }
  }, [userDetails]);

  useEffect(() => {
    dispatch(getOrganizationConfigs((data) => {}));
    dispatch(
      getDeals(
        {
          archived: false,
          size: 5,
          lastSeen: null,
          lastTime: null,
        },
        () => {},
      ),
    );
    dispatch(
      getPlaybookHistory(teamId || null, (data) => {
        if (data?.selectedPlaybook) {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [0]: true,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 0,
              value: userDetails?.custom?.orgType === 'demo_org' ? 50 : 25,
            },
          });
        } else {
          setCompletedStep((prev) => {
            return {
              ...prev,
              [0]: false,
            };
          });
          dispatch({
            type: 'UPDATE_ONBOARDING_STEP_COMPLETION_PERCENTAGE',
            payload: {
              key: 0,
              value: 0,
            },
          });
        }
      }),
    );
    checkNotificationPromise();
    // dispatch(
    //   getUserDetails((data) => {
    //     dispatch(
    //       updateUserDetails(
    //         {
    //           userSetting: {
    //             navbarTeamCollapsState: {
    //               ...navExpandedStatus,
    //               [data?.organizationId]:
    //                 data?.userSetting?.navbarTeamCollapsState?.[
    //                   data?.organizationId
    //                 ] !== undefined
    //                   ? data?.userSetting?.navbarTeamCollapsState?.[
    //                       data?.organizationId
    //                     ]
    //                   : true,
    //               ['My Organization']:
    //                 data?.userSetting?.navbarTeamCollapsState?.[
    //                   'My Organization'
    //                 ] !== undefined
    //                   ? data?.userSetting?.navbarTeamCollapsState?.[
    //                       'My Organization'
    //                     ]
    //                   : true,
    //               ['Clients Dashboard']:
    //                 data?.userSetting?.navbarTeamCollapsState?.[
    //                   'Clients Dashboard'
    //                 ] !== undefined
    //                   ? data?.userSetting?.navbarTeamCollapsState?.[
    //                       'Clients Dashboard'
    //                     ]
    //                   : true,
    //               ['Tools']:
    //                 data?.userSetting?.navbarTeamCollapsState?.['Tools'] !==
    //                 undefined
    //                   ? data?.userSetting?.navbarTeamCollapsState?.['Tools']
    //                   : true,
    //             },
    //           },
    //         },
    //         () => {
    //           dispatch({
    //             type: 'UPDATE_UI_STATE',
    //             payload: {
    //               key: 'navExpandedStatus',
    //               value: {
    //                 ...(data?.userSetting?.navbarTeamCollapsState || {}),
    //                 [data?.organizationId]:
    //                   data?.userSetting?.navbarTeamCollapsState?.[
    //                     data?.organizationId
    //                   ] !== undefined
    //                     ? data?.userSetting?.navbarTeamCollapsState?.[
    //                         data?.organizationId
    //                       ]
    //                     : true,
    //                 ['My Organization']:
    //                   data?.userSetting?.navbarTeamCollapsState?.[
    //                     'My Organization'
    //                   ] !== undefined
    //                     ? data?.userSetting?.navbarTeamCollapsState?.[
    //                         'My Organization'
    //                       ]
    //                     : true,
    //                 ['Clients Dashboard']:
    //                   data?.userSetting?.navbarTeamCollapsState?.[
    //                     'Clients Dashboard'
    //                   ] !== undefined
    //                     ? data?.userSetting?.navbarTeamCollapsState?.[
    //                         'Clients Dashboard'
    //                       ]
    //                     : true,
    //                 ['Tools']:
    //                   data?.userSetting?.navbarTeamCollapsState?.['Tools'] !==
    //                   undefined
    //                     ? data?.userSetting?.navbarTeamCollapsState?.['Tools']
    //                     : true,
    //               },
    //             },
    //           });
    //         },
    //       ),
    //     );
    //     if (data?.custom?.plan?.price?.pricingScheme === 'usage_based') {
    //       dispatch(
    //         getRemainingCredits((data) => {
    //           console.log(data);
    //           dispatch({
    //             type: 'UPDATE_UI_STATE',
    //             payload: {
    //               key: 'creditsRemaining',
    //               value: data,
    //             },
    //           });
    //         }),
    //       );
    //     }
    //     if (
    //       (data?.teamRoles?.includes('TEAM_ADMIN') ||
    //         data?.teamRoles?.includes('STANDARD')) &&
    //       !data?.orgRole?.includes('ORG_ADMIN')
    //     ) {
    //       setLoading(true);
    //       dispatch(
    //         getTeams(10, null, (teams) => {
    //           // console.log(teams);
    //           setLoading(false);
    //           dispatch({
    //             type: 'UPDATE_UI_STATE',
    //             payload: {
    //               key: 'loggedInUserTeamDetails',
    //               value:
    //                 teams?.length > 1
    //                   ? teams?.filter(
    //                       (team) => team?.id !== data?.organizationId,
    //                     )?.[0]
    //                   : teams?.[0],
    //             },
    //           });
    //         }),
    //       );
    //     } else {
    //       dispatch({
    //         type: 'UPDATE_UI_STATE',
    //         payload: {
    //           key: 'loggedInUserTeamDetails',
    //           value: null,
    //         },
    //       });
    //     }
    //   }),
    // );
  }, []);

  // console.log(showOnboardingDialog);
  // console.log(onBoardingStep);
  // console.log(userDetails);
  // console.log(expandedStyles);
  // console.log(crmDeals);
  // console.log(questionTopics);
  // console.log(questionSubTopics);
  // console.log(questionTopicSubtopicTree);
  // console.log(valuePropJsonError);
  // console.log(valuePropMode);
  // console.log(valuePropositionJson);
  // console.log(valueProposition);
  // console.log(discoveryQList);
  // console.log(questionTopics);
  // console.log(onboardingStage);
  // console.log(valuePropHtml);
  // console.log(questionTopics);
  // console.log(questionSubTopics);
  // console.log(onboardingStepV2);
  // console.log(questionTopics);
  // console.log(selectedTopic);
  // console.log(questionSubTopics);
  // console.log(qList);
  // console.log(questionSubTopics);

  return (
    <ErrorBoundary>
      <Box>
        {/* <Box>
            <ThemeModeToggler />
          </Box> */}
        {!noTopbar && !showBottomNav ? (
          <AppBar
            position={'sticky'}
            sx={{
              top: 0,
              backgroundColor: trigger
                ? theme.palette.background.paper
                : bgcolor,
              background: '#fff',
              borderBottom: `1px solid ${theme.palette.background.level1}`,
            }}
            elevation={trigger ? 1 : 0}
          >
            <Container
              paddingY={0}
              paddingX={isMd ? 4 : 1}
              maxWidth={{ sm: 1, md: 1 }}
            >
              <Topbar
                onSidebarOpen={handleSidebarOpen}
                colorInvert={trigger ? false : colorInvert}
              />
            </Container>
          </AppBar>
        ) : null}
        {leftSidebar && !showBottomNav ? (
          <Sidebar
            onClose={() => {}}
            open={true}
            variant="permanent"
            expanded={dashboardSidebarExpanded}
          />
        ) : null}
        {showBottomNav ? (
          <BottomNavbar
            onClose={() => {}}
            open={true}
            variant="permanent"
            expanded={dashboardSidebarExpanded}
          />
        ) : null}
        <main>
          <AlertPopup
            // classes={{ root: classes.browserInfoSnackbar }}
            open={alertState.open}
            autoHideDuration={6000}
            handleClose={() => dispatch(hideAlert())}
            message={alertState.message}
            type={alertState.type}
            verticalLocation={alertState.vertical}
            horizontalLocation={alertState.horizontal}
          ></AlertPopup>
          {loading ? null : (
            <Box paddingTop={showBottomNav ? 6 : 0}>{children}</Box>
          )}
          {!noFooter ? <Divider /> : null}
        </main>
        {!noFooter ? (
          <Container paddingY={1} maxWidth={{ sm: 1, md: 1236 }}>
            <Footer />
          </Container>
        ) : null}
        <Dialog
          open={Boolean(
            notificationPermissionStatus === 'ask' ||
              notificationPermissionStatus === 'denied',
          )}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            zIndex: 10002,
            '& .MuiPaper-root-MuiDialog-paper': {
              maxWidth: '70vw',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {notificationPermissionStatus === 'denied'
              ? 'Notification permissions are blocked'
              : 'Notification permissions are required'}
          </DialogTitle>
          <DialogContent>
            <Box display={'flex'} alignItems={'center'} gap={2}>
              {/* <Box flex={1}>
                <img src="/microphonehelp.png" width={'100%'} height={'100%'} />
              </Box> */}
              <DialogContentText id="alert-dialog-description" flex={1}>
                We need browser notifications enabled to show you updated
                information. You may click the Lock <Lock /> Icon (usually in
                your browser&apos;s address bar) and allow browser
                notifications. You will still be able to customize and turn off
                notifications.
              </DialogContentText>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              data-trackid={'cta_permission_denied_dialog_close'}
              onClick={(e) => {
                setNotificationPermissionStatus('dontshowagain');
                // if (testMode) setShowTestDeviceDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Dismiss
            </Button>
          </DialogActions>
        </Dialog>
        {process.env.REACT_APP_TARGET_ENV === 'local' ||
        process.env.REACT_APP_TARGET_ENV === 'development' ||
        userInfo?.custom?.orgType === 'demo_org' ? (
          <Modal
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            open={Boolean(showOnboardingDialog)}
            onClose={(e, reason) => {
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                return;
              }
            }}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <Box
              sx={{
                backgroundColor: theme.palette.background.paper,
                width: '60vw',
                borderRadius: 1,
                position: 'relative',
              }}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'center'}
                p={2}
                borderBottom={'1px solid #d3d3d3'}
              >
                <Typography variant="body1" textAlign={'center'}>
                  {userDetails?.custom?.orgType === 'demo_org'
                    ? 'Welcome'
                    : 'Qualification AI Onboarding'}
                </Typography>
              </Box>
              <Box
                // p={2}
                sx={{
                  maxHeight: '75vh',
                  overflowY: 'scroll',
                  '&::-webkit-scrollbar': {
                    width: '5px',
                  },
                  '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 2,
                    // outline: '1px solid slategrey',
                  },
                }}
              >
                {onBoardingStep === 0 ? (
                  <Box p={2}>
                    <Typography variant="body1" textAlign={'center'}>
                      Welcome to Qualification AI user onboarding. Please
                      complete few steps to get started. <br /> Follow these
                      steps and complete all the steps to make your application
                      experience smooth.
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingTop={2}
                  >
                    <Stepper
                      nonLinear
                      activeStep={onBoardingStep - 1}
                      alternativeLabel
                    >
                      {[
                        'Generate Playbook',
                        "Prospect's Target & Vertical",
                        'Sales Methodologies',
                        'Auto Analysis',
                      ]
                        ?.filter(
                          (step) =>
                            (orgConfigs?.methodologies &&
                              orgConfigs?.methodologies?.length > 0) ||
                            (orgConfigs?.customMethodologies &&
                              orgConfigs?.customMethodologies?.length > 0 &&
                              step === 'Sales Methodologies') ||
                            step !== 'Sales Methodologies',
                        )
                        ?.filter(
                          (step) =>
                            (userDetails?.custom?.orgType === 'demo_org' &&
                              step !== 'Auto Analysis') ||
                            userDetails?.custom?.orgType !== 'demo_org',
                        )
                        .map((label, index) => (
                          <Step key={label} completed={completedStep[index]}>
                            <StepButton
                              color="inherit"
                              // onClick={() => {
                              //   dispatch({
                              //     type: 'UPDATE_UI_STATE',
                              //     payload: {
                              //       key: 'onBoardingStep',
                              //       value: index + 1,
                              //     },
                              //   });
                              // }}
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                    </Stepper>
                    <AccountSettings
                      defaultTab="playbook"
                      onBoardingStep={onBoardingStep}
                      setCompletedStep={setCompletedStep}
                    />
                  </Box>
                )}
              </Box>
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                p={2}
                borderTop={'1px solid #d3d3d3'}
              >
                {userDetails?.custom?.orgType === 'demo_org' ? (
                  <></>
                ) : (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      dispatch({
                        type: 'UPDATE_UI_STATE',
                        payload: {
                          key: 'showOnboardingDialog',
                          value: false,
                        },
                      });
                      dispatch({
                        type: 'UPDATE_UI_STATE',
                        payload: {
                          key: 'onBoardingStep',
                          value: 0,
                        },
                      });
                    }}
                  >
                    Skip and Continue Manually
                  </Button>
                )}
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={1}
                >
                  <ProfileCompletion
                    percentCompleted={Object.values(
                      onBoardingCompletionPercentage || {},
                    )?.reduce((ac, cr) => {
                      ac += cr;
                      return ac;
                    }, 0)}
                    circleSize={50}
                    circleTextSize={12}
                    fontSize={12}
                    thickness={5}
                    dontShowExtraText={true}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Onboarding Completed
                  </Typography>
                </Box>
                {Object.values(onBoardingCompletionPercentage || {})?.reduce(
                  (ac, cr) => {
                    ac += cr;
                    return ac;
                  },
                  0,
                ) === 100 &&
                ((userDetails?.custom?.orgType === 'demo_org' &&
                  onBoardingStep === 3) ||
                  (userDetails?.custom?.orgType !== 'demo_org' &&
                    onBoardingStep === 4)) ? (
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    {userDetails?.custom?.orgType === 'demo_org' ? (
                      <Typography variant="body1">
                        You are all set! Let&apos;s begin your first roleplay.
                      </Typography>
                    ) : null}
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'showOnboardingDialog',
                            value: false,
                          },
                        });
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'onBoardingStep',
                            value: 0,
                          },
                        });
                        if (userDetails?.custom?.orgType === 'demo_org') {
                          history.push('/conversation-ai?new=true');
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                ) : (
                  <Box display={'flex'} alignItems={'center'} gap={2}>
                    {onBoardingStep === 0 ? null : (
                      <Button
                        variant="contained"
                        size="small"
                        disabled={
                          userDetails?.custom?.orgType === 'demo_org' &&
                          onBoardingStep === 1
                        }
                        onClick={() => {
                          dispatch({
                            type: 'UPDATE_UI_STATE',
                            payload: {
                              key: 'onBoardingStep',
                              value: onBoardingStep - 1,
                            },
                          });
                        }}
                      >
                        Previous
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      size="small"
                      disabled={
                        onBoardingStep === 4 ||
                        (onBoardingStep === 1 && !selectedPlaybook) ||
                        (onBoardingStep === 2 &&
                          (!orgConfigs?.botVerticals ||
                            orgConfigs?.botVerticals?.length === 0 ||
                            !orgConfigs?.botDesignations ||
                            orgConfigs?.botDesignations?.length === 0))
                      }
                      onClick={() => {
                        // if(onBoardingStep === 1){
                        //   if(selecte)
                        // }
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'onBoardingStep',
                            value: onBoardingStep + 1,
                          },
                        });
                      }}
                    >
                      {onBoardingStep === 0
                        ? 'Continue with onboarding'
                        : 'Next'}
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Modal>
        ) : null}
        {/* {process.env.REACT_APP_TARGET_ENV === 'local' ||
        process.env.REACT_APP_TARGET_ENV === 'development' ? ( */}
        <Dialog
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          fullScreen
          open={Boolean(showOnboardingDialogV2)}
          onClose={(e, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              return;
            }
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              width: '100vw',
              borderRadius: 1,
              position: 'relative',
            }}
          >
            <Box
              // p={2}
              sx={{
                maxHeight: 'calc(100vh - 60px)',
                minHeight: 'calc(100vh - 60px)',
                overflowY: 'scroll',
                '&::-webkit-scrollbar': {
                  width: '5px',
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                  webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.primary.main,
                  borderRadius: 2,
                  // outline: '1px solid slategrey',
                },
                position: 'relative',
              }}
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                >
                  <Button
                    data-trackid={'open_profile_menu'}
                    aria-controls="profile-menu"
                    aria-haspopup="true"
                    onClick={(e) => {
                      handleProfileMenuClick(e);
                    }}
                  >
                    <Avatar
                      alt={userInfo?.custom?.organization?.toUpperCase()}
                      src={userDetails?.profilePicture?.url}
                      sx={{
                        width: 40,
                        height: 40,
                      }}
                    >
                      {userInfo?.custom?.organization?.[0]?.toUpperCase()}
                    </Avatar>
                  </Button>
                  <Menu
                    id="profile-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                    transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    style={{
                      marginTop: '15px',
                    }}
                  >
                    <MenuItem
                      data-trackid={'profile_menu_logout_option'}
                      style={{}}
                      onClick={(e) => {
                        handleLogoutClick();
                      }}
                    >
                      <ExitToApp style={{ marginRight: '10px' }} />
                      Logout
                    </MenuItem>
                    <MenuItem
                      style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        pointerEvents: 'none',
                      }}
                    >
                      {userInfo?.email}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  gap: 2,
                }}
              >
                {onboardingStepV2 === 'company_info' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      paddingTop: 10,
                      gap: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: 20,
                      }}
                    >
                      Welcome. Let&apos;s prepare you some awesome discovery
                      questions!
                    </Typography>
                    <Box
                      sx={{
                        border: '1px solid #d3d3d3',
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Typography color={'text.secondary'}>
                        Your Company*
                      </Typography>
                      <TextField
                        autoFocus
                        placeholder="Enter company name*"
                        variant="outlined"
                        color="primary"
                        size="small"
                        name="companyName"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                        fullWidth
                      />
                      <Typography color={'text.secondary'}>
                        Company Website*
                      </Typography>
                      <Typography color={'text.secondary'} variant="caption">
                        (Provide your company website which will be used to
                        generate value proposition)
                      </Typography>
                      <TextField
                        autoFocus
                        placeholder="Enter company website*"
                        variant="outlined"
                        color="primary"
                        size="small"
                        name="companyWebsite"
                        value={companyWebsite}
                        onChange={(e) => setCompanyWebsite(e.target.value)}
                        fullWidth
                      />
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'value_prop' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      gap: 1,
                      paddingTop: 10,
                    }}
                  >
                    <Typography sx={{ fontSize: 20 }}>
                      Let&apos;s prepare a value prop that will be used
                      throughout this product.
                    </Typography>
                    <Typography color={'text.secondary'} variant="subtitle2">
                      Please provide some information about your solution.
                      <br />
                      It should include the top problems your company solves for
                      your buyers and key differentiators in your solution.
                    </Typography>
                    <Box
                      sx={{
                        // border: '1px solid #d3d3d3',
                        // padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <TextField
                        autoFocus
                        placeholder="Value Proposition*"
                        variant="outlined"
                        color="primary"
                        size="small"
                        multiline
                        rows={16}
                        name="aboutCompany"
                        value={aboutCompany}
                        onChange={(e) => setAboutCompany(e.target.value)}
                        fullWidth
                      />
                      {/* <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Button variant="contained" color="primary">
                              Generate
                            </Button>
                          </Box> */}
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'value_prop_generated' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      gap: 1,
                      paddingTop: 10,
                    }}
                  >
                    <Typography sx={{ fontSize: 20 }}>
                      Here&apos;s your value proposition. Please review it
                      carefully and alter it, if need be, before proceeding.
                    </Typography>
                    <Typography variant="caption" color={'text.secondary'}>
                      Go back to previous step if you want to regenerate.
                    </Typography>
                    <Box
                      sx={{
                        // border: '1px solid #d3d3d3',
                        // padding: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      {/* <Typography color={'text.secondary'}>
                          Your Value Proposition*
                        </Typography>
                        <Typography color={'text.secondary'} variant="caption">
                          You can provide a few points here such as the problem
                          you solve for your buyers and your key
                          differentiators. Then hit the Generate button to
                          create a polished version with AI.
                        </Typography> */}
                      {/* <Box
                          sx={{
                            height: 470,
                          }}
                        > */}
                      {/* <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={valuePropMode}
                            onChange={(e) => setValuePropMode(e.target.value)}
                          >
                            <FormControlLabel
                              value={'html'}
                              control={<Radio size="small" />}
                              label={'RICH TEXT'}
                            />
                            <FormControlLabel
                              value={'ui'}
                              control={<Radio size="small" />}
                              label={'UI'}
                            />
                            <FormControlLabel
                              value={'json'}
                              control={<Radio size="small" />}
                              label={'JSON'}
                            />
                          </RadioGroup>
                        </FormControl> */}
                      {valuePropMode === 'html' ? (
                        <Box
                          sx={
                            {
                              // maxHeight: 'calc(100vh - 300px)',
                              // overflowY: 'scroll',
                              // '&::-webkit-scrollbar': {
                              //   width: '5px',
                              // },
                              // '&::-webkit-scrollbar-track': {
                              //   boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              //   webkitBoxShadow:
                              //     'inset 0 0 6px rgba(0,0,0,0.00)',
                              // },
                              // '&::-webkit-scrollbar-thumb': {
                              //   backgroundColor: theme.palette.primary.main,
                              //   borderRadius: 2,
                              //   // outline: '1px solid slategrey',
                              // },
                              // // border: '1px solid #d3d3d3',
                              // padding: 2,
                            }
                          }
                        >
                          <RichTextEditor
                            value={valuePropHtml || ''}
                            onChange={setValuePropHtml}
                            placeholder="Enter Value Proposition"
                            height="calc(100vh - 320px)"
                          />
                        </Box>
                      ) : valuePropMode === 'ui' ? (
                        <Box
                          sx={{
                            maxHeight: 'calc(100vh - 300px)',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                              width: '5px',
                            },
                            '&::-webkit-scrollbar-track': {
                              boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                              webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: theme.palette.primary.main,
                              borderRadius: 2,
                              // outline: '1px solid slategrey',
                            },
                            border: '1px solid #d3d3d3',
                            padding: 2,
                          }}
                        >
                          <RenderValuePropJSON
                            valueProp={valuePropositionJson}
                          />
                        </Box>
                      ) : valuePropMode === 'json' ? (
                        <Box>
                          <TextField
                            value={valueProposition}
                            multiline
                            rows={18}
                            fullWidth
                            onChange={(e) =>
                              setValueProposition(e.target.value)
                            }
                          />
                        </Box>
                      ) : null}
                      {valuePropJsonError ? (
                        <Typography color={theme.palette.error.main}>
                          Invalid JSON
                        </Typography>
                      ) : null}
                      {/* <RichTextEditor
                          value={valueProposition}
                          onChange={setValueProposition}
                          placeholder="Enter Value Proposition"
                          height="400px"
                        /> */}
                      {/* </Box> */}
                      {/* <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Replay />}
                            onClick={() => {

                            }}
                          >
                            Regenerate
                          </Button>
                        </Box> */}
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'selling_style' ? (
                  <Box
                    sx={{
                      padding: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: 2,
                      paddingTop: 4,
                    }}
                  >
                    <Typography sx={{ fontSize: 20, marginLeft: 9 }}>
                      Select the row that best aligns with your style of
                      selling?
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        // gap: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          border: '1px solid #C2DBFF',
                          background: '#C2DBFF',
                          padding: 1,
                          borderRadius: 1,
                          marginLeft: 8,
                          paddingLeft: 4,
                          marginRight: '6px',
                        }}
                      >
                        {/* <Box sx={{ flex: 1 }}>
                            <Typography fontWeight={'bold'}>Select</Typography>
                          </Box> */}
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>
                            Selling Style
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>Approach</Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>Goal</Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>
                            Sample Question 1
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>
                            Sample Question 2
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography fontWeight={'bold'}>
                            Sample Question 3
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          // width: '100%',
                          // border: '1px solid #d3d3d3',
                          // padding: 1,
                          gap: 1,
                          maxHeight: 'calc(100vh - 200px)',
                          minHeight: 'calc(100vh - 200px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          overflowX: 'visible',
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          // paddingTop: 3,
                          marginLeft: 4,
                          paddingLeft: 4,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // gap: 1,
                          }}
                        >
                          {fetchingSellingStyles ? (
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                minHeight: 300,
                              }}
                            >
                              <CircularProgress size={20} />
                              <Typography
                                sx={{ fontSize: 14, textAlign: 'center' }}
                              >
                                Fetching Selling Styles
                              </Typography>
                            </Box>
                          ) : (
                            <>
                              {sellingStyles?.map((style) => (
                                <Box
                                  key={style?.sellingStyle}
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    // border: '1px solid #d3d3d3',
                                    // paddingX: 1,
                                    paddingY: 1,
                                    alignItems: 'start',
                                    cursor: 'pointer',
                                    position: 'relative',
                                  }}
                                  onClick={() => {
                                    setSellingStyle(style?.sellingStyle);
                                    if (sellingStyle === style?.sellingStyle) {
                                      if (
                                        !expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                      ) {
                                        setExpandedStyles((prev) => {
                                          return [...prev, style?.sellingStyle];
                                        });
                                      } else {
                                        setExpandedStyles((prev) =>
                                          prev?.filter(
                                            (es) => es !== style?.sellingStyle,
                                          ),
                                        );
                                      }
                                    } else {
                                      setExpandedStyles((prev) => {
                                        return [...prev, style?.sellingStyle];
                                      });
                                    }
                                  }}
                                >
                                  {sellingStyle === style?.sellingStyle ? (
                                    <FormControlLabel
                                      control={
                                        <Radio
                                          size="small"
                                          checked={
                                            sellingStyle === style?.sellingStyle
                                          }
                                          value={sellingStyle}
                                          name="sellingStyle"
                                        />
                                      }
                                      // label={
                                      //   <Typography variant="subtitle2">
                                      //     {style?.style}
                                      //   </Typography>
                                      // }
                                      onChange={() =>
                                        setSellingStyle(style?.sellingStyle)
                                      }
                                      sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: -25,
                                        transform: 'translate(0, -50%)',
                                        zIndex: 11111,
                                      }}
                                    />
                                  ) : null}
                                  <Box
                                    sx={{
                                      flex: 1,
                                      display: 'flex',
                                      background:
                                        sellingStyle === style?.sellingStyle
                                          ? '#C2DBFF'
                                          : '#F2F6FC',
                                      padding: 1,
                                      paddingY: 2,
                                      borderRadius: 2,
                                      gap: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        flex: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 1
                                          : 12,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'start',
                                          // marginLeft: -6,
                                        }}
                                      >
                                        {expandedStyles?.includes(
                                          style?.sellingStyle,
                                        ) ? (
                                          <KeyboardArrowDown
                                            sx={{
                                              fontSize: 40,
                                              marginTop: -1,
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setExpandedStyles((prev) =>
                                                prev.filter(
                                                  (es) =>
                                                    es !== style?.sellingStyle,
                                                ),
                                              );
                                            }}
                                          />
                                        ) : (
                                          <KeyboardArrowRight
                                            sx={{
                                              fontSize: 40,
                                              marginTop: -1,
                                            }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setExpandedStyles((prev) => {
                                                return [
                                                  ...prev,
                                                  style?.sellingStyle,
                                                ];
                                              });
                                            }}
                                          />
                                        )}
                                        <Box>
                                          <Typography
                                            color={'text.primary'}
                                            fontWeight={'bold'}
                                          >
                                            {style?.title}
                                          </Typography>
                                          <Typography
                                            color={'text.secondary'}
                                            sx={{
                                              display: expandedStyles?.includes(
                                                style?.sellingStyle,
                                              )
                                                ? 'flex'
                                                : 'none',
                                            }}
                                          >
                                            {style?.focus}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 'flex'
                                          : 'none',
                                      }}
                                    >
                                      <Typography>{style?.approach}</Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 'flex'
                                          : 'none',
                                      }}
                                    >
                                      <Typography>{style?.goal}</Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 'flex'
                                          : 'none',
                                      }}
                                    >
                                      <Typography>
                                        {style?.questions?.[0]}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 'flex'
                                          : 'none',
                                      }}
                                    >
                                      <Typography>
                                        {style?.questions?.[1]}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        flex: 1,
                                        display: expandedStyles?.includes(
                                          style?.sellingStyle,
                                        )
                                          ? 'flex'
                                          : 'none',
                                      }}
                                    >
                                      <Typography>
                                        {style?.questions?.[2]}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Box>
                                // <FormControlLabel
                                //   key={style?.sellingStyle}
                                //   value={style?.sellingStyle}
                                //   control={<Radio />}
                                //   sx={{
                                //     '& .MuiFormControl-root': {
                                //       border: '1px solid #d3d3d3',
                                //       padding: 1,
                                //     },
                                //   }}
                                //   label={
                                //     <Box
                                //       sx={{
                                //         // border: '1px solid #d3d3d3',
                                //         width: '100%',
                                //       }}
                                //     >
                                //       <Accordion
                                //         expanded={expandedStyles?.includes(
                                //           style?.sellingStyle,
                                //         )}
                                //         onChange={(e, newExpandedStatus) => {
                                //           e.stopPropagation();
                                //           if (expandedStyles?.includes(style?.sellingStyle)) {
                                //             setExpandedStyles((prev) =>
                                //               prev.filter((es) => es !== style?.sellingStyle),
                                //             );
                                //           } else {
                                //             setExpandedStyles((prev) => [
                                //               ...prev,
                                //               style?.sellingStyle,
                                //             ]);
                                //           }
                                //           // console.log(newExpandedStatus);
                                //         }}
                                //         sx={{
                                //           boxShadow: 'none',
                                //           width: '100%',
                                //           // borderBottom: '1px solid #d3d3d3',
                                //           // borderTop: '1px solid #d3d3d3',
                                //         }}
                                //       >
                                //         <AccordionSummary
                                //           aria-controls="panel1d-content"
                                //           id="panel1d-header"
                                //           sx={{
                                //             width: '100%',
                                //             '& .MuiButtonBase-root .MuiAccordionSummary-root': {
                                //               paddingY: 0,
                                //             },
                                //             // marginLeft: -4,
                                //           }}
                                //         >
                                //           {expandedStyles?.includes(style?.sellingStyle) ? (
                                //             <KeyboardArrowDown
                                //               sx={{
                                //                 fontSize: 40,
                                //                 marginTop: -1,
                                //               }}
                                //             />
                                //           ) : (
                                //             <KeyboardArrowRight
                                //               sx={{ fontSize: 40 }}
                                //             />
                                //           )}
                                //           <Typography component="span">
                                //             {style?.style}
                                //           </Typography>
                                //         </AccordionSummary>
                                //         {/* <AccordionDetails>
                                //               <Box
                                //                 sx={{
                                //                   display: 'flex',
                                //                   gap: 2,
                                //                 }}
                                //               >
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography
                                //                     color={'text.secondary'}
                                //                   >
                                //                     {style?.focus}
                                //                   </Typography>
                                //                 </Box>
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography>
                                //                     {style?.approach}
                                //                   </Typography>
                                //                 </Box>
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography>
                                //                     {style?.goal}
                                //                   </Typography>
                                //                 </Box>
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography>
                                //                     {style?.sampleQuestion1}
                                //                   </Typography>
                                //                 </Box>
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography>
                                //                     {style?.sampleQuestion2}
                                //                   </Typography>
                                //                 </Box>
                                //                 <Box sx={{ flex: 1 }}>
                                //                   <Typography>
                                //                     {style?.sampleQuestion3}
                                //                   </Typography>
                                //                 </Box>
                                //               </Box>
                                //             </AccordionDetails> */}
                                //       </Accordion>
                                //     </Box>
                                //   }
                                // />
                              ))}
                            </>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'selling_style_picked' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    <Typography>
                      {
                        sellingStyles?.find(
                          (ss) => ss?.sellingStyle === sellingStyle,
                        )?.title
                      }{' '}
                      ... great choice!
                    </Typography>
                    Let&apos;s now set the stage for de-risking your deals.
                  </Box>
                ) : onboardingStepV2 === 'deal_risk_selection' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                      padding: 4,
                    }}
                  >
                    <Box sx={{ width: '70%' }}>
                      <Typography sx={{ fontSize: 20 }}>
                        Let&apos;s set the stage for de-risking your deals.
                        We&apos;ve identified the risks to target for your first
                        discovery call. Feel free to alter the selection or add
                        your own.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                        width: 1,
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={1}
                        justifyContent={'space-between'}
                        sx={{
                          borderBottom: '1px solid #d3d3d3',
                          paddingBottom: 1,
                          width: '70%',
                          margin: '0 auto',
                        }}
                      >
                        <Box
                          display={'flex'}
                          flexDirection={'column'}
                          // gap={1}
                        >
                          <Typography fontWeight={'bold'} fontSize={20}>
                            Risks to mitigate - Discovery First Call
                          </Typography>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            You can alter the selection below or add another
                            risk.
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          maxHeight: 'calc(100vh - 260px)',
                          overflowY: 'scroll',
                          '&::-webkit-scrollbar': {
                            width: '5px',
                          },
                          '&::-webkit-scrollbar-track': {
                            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: theme.palette.primary.main,
                            borderRadius: 2,
                            // outline: '1px solid slategrey',
                          },
                          // border: '1px solid #d3d3d3',
                          // padding: 2,
                          paddingRight: 2,
                          width: '70%',
                          margin: '0 auto',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            width: 1,
                            // marginLeft: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              gap: 2,
                              width: 1,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                // marginLeft: 2,
                                // gap: 1,
                                cursor: 'pointer',
                              }}
                            ></Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                // marginRight: 8,
                              }}
                            >
                              <Tooltip arrow placement="top" title="Add more">
                                <AddBoxOutlined
                                  sx={{
                                    color: '#747775',
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => {
                                    setShowNewRiskDialog(true);
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                          {dealRisks?.map((dr, index) => (
                            <Box
                              key={index + 1}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                borderRadius: 2,
                                width: 1,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (selectedDealRisks?.includes(dr?.id)) {
                                  setSelectedDealRisks(
                                    selectedDealRisks?.filter(
                                      (id) => id !== dr?.id,
                                    ),
                                  );
                                } else {
                                  setSelectedDealRisks([
                                    ...selectedDealRisks,
                                    dr?.id,
                                  ]);
                                }
                              }}
                            >
                              <Box
                                sx={{
                                  background: selectedDealRisks?.includes(
                                    dr?.id,
                                  )
                                    ? '#C2DBFF'
                                    : '#F2F6FC',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  gap: 2,
                                  flex: 1,
                                  borderRadius: 2,
                                  minHeight: 80,
                                  // border: dq?.pinned
                                  //   ? '1px solid #beb9e7'
                                  //   : 'none',
                                  position: 'relative',
                                }}
                              >
                                {dr?.custom ? (
                                  <Close
                                    sx={{
                                      color: '#000',
                                      cursor: 'pointer',
                                      fontSize: 18,
                                      position: 'absolute',
                                      top: -2,
                                      right: -2,
                                      background: '#F2F6FC',
                                      borderRadius: '50%',
                                      border: '1px solid #C2DBFF',
                                      padding: '2px',
                                      zIndex: 1000,
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setDealRisks((prev) =>
                                        prev?.filter(
                                          (risk) => risk?.id !== dr?.id,
                                        ),
                                      );
                                    }}
                                  />
                                ) : null}

                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    flex: 1,
                                    borderRadius: 2,
                                    paddingLeft: 2,
                                  }}
                                >
                                  {/* <Typography
                                                    sx={{
                                                      // background: '#d3e2fe',
                                                      padding: 2,
                                                      borderTopLeftRadius: 8,
                                                      borderBottomLeftRadius: 8,
                                                      minHeight: 80,
                                                      minWidth: 80,
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      setAnchorElDetails(
                                                        e.currentTarget,
                                                      );
                                                      setPopperDetails(
                                                        'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                      );
                                                    }}
                                                    onMouseLeave={() => {
                                                      setAnchorElDetails(null);
                                                      setPopperDetails(null);
                                                    }}
                                                  >
                                                    {index < 9 ? 0 : ''}
                                                    {index + 1}
                                                  </Typography>
                                                  <Popper
                                                    id="mouse-over-popover"
                                                    sx={{
                                                      pointerEvents: 'none',
                                                      zIndex: 1111111,
                                                    }}
                                                    open={Boolean(
                                                      anchorElDetails,
                                                    )}
                                                    anchorEl={anchorElDetails}
                                                    placement="top"
                                                    onClose={() => {
                                                      setAnchorElDetails(null);
                                                    }}
                                                    // disableRestoreFocus
                                                  >
                                                    <Box
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 1,
                                                        padding: 1,
                                                        background: '#fff',
                                                        maxWidth: 400,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 1,
                                                        // boxShadow: theme.shadows[1],
                                                      }}
                                                    >
                                                      <Typography
                                                        variant={'body1'}
                                                        color={'text.secondary'}
                                                        sx={{
                                                          padding: 1,
                                                        }}
                                                      >
                                                        {popperDetails || ''}
                                                      </Typography>
                                                    </Box>
                                                  </Popper> */}
                                  <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    sx={{
                                      width: 1,
                                      padding: 1,
                                      justifyContent: 'space-between',
                                    }}
                                  >
                                    <Typography>{dr?.riskTitle}</Typography>
                                    <Typography
                                      color={'text.secondary'}
                                      variant="subtitle2"
                                    >
                                      {dr?.explanation}
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    // background: '#d3e2fe',
                                    padding: 2,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1,
                                    borderTopRightRadius: 8,
                                    borderBottomRightRadius: 8,
                                    minHeight: 80,
                                    position: 'relative',
                                  }}
                                >
                                  {/* <HelpOutline
                                                        sx={{
                                                          cursor: 'pointer',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                          setPopperDetails(
                                                            null,
                                                          );
                                                        }}
                                                      />
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={
                                                          anchorElDetails
                                                        }
                                                        placement="left"
                                                        onClose={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection:
                                                              'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={
                                                              'text.secondary'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails ||
                                                              ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper> */}
                                  {/* <Tooltip arrow placement="top" title="Edit">
                                      <img
                                        src={'/dh-icons/edit.svg'}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      />
                                    </Tooltip> */}
                                  <Tooltip
                                    arrow
                                    placement="top"
                                    title={
                                      selectedDealRisks?.includes(dr?.id)
                                        ? 'Unselect'
                                        : 'Select'
                                    }
                                  >
                                    {selectedDealRisks?.includes(dr?.id) ? (
                                      <img
                                        src={'/dh-icons/pinned.svg'}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {}}
                                      />
                                    ) : (
                                      <img
                                        src={'/dh-icons/unpinned.svg'}
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={(e) => {}}
                                      />
                                    )}
                                  </Tooltip>
                                </Box>
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : onboardingStepV2 ===
                  'discovery_tree_generation_in_progress' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {/* <Typography>
                      {
                        sellingStyles?.find((ss) => ss?.id === sellingStyle)
                          ?.style
                      }{' '}
                      ... great choice!
                    </Typography> */}
                    {treeGenerationInProgress ? (
                      <>
                        <CircularProgress />
                        <LinearProgressWithLabel
                          value={questionGenerationProgress}
                        />
                      </>
                    ) : null}
                    {treeGenerationInProgress ? (
                      <>
                        {/* <ReactTyped
                          strings={['You are all set.']}
                          typeSpeed={40}
                          style={{ fontSize: 20, textAlign: 'center' }}
                          showCursor={false}
                        />
                        <ReactTyped
                          strings={['Discovery questions are generated.']}
                          typeSpeed={40}
                          style={{ fontSize: 20, textAlign: 'center' }}
                          startDelay={1000}
                          showCursor={false}
                        />
                        <ReactTyped
                          strings={[
                            'You can now move further and tell us more about your selling style.',
                          ]}
                          typeSpeed={40}
                          style={{ fontSize: 20, textAlign: 'center' }}
                          startDelay={3000}
                          showCursor={false}
                        /> */}
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          We are preparing your discovery questions. They will
                          be available in a moment.
                        </Typography>
                        <Typography
                          sx={{ fontSize: 20, textAlign: 'center' }}
                          color={'text.secondary'}
                        >
                          In the meantime, you can click Next and set up your
                          integrations...
                        </Typography>
                      </>
                    ) : (
                      <>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          You are all set.
                        </Typography>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Discovery questions are generated. You can now move
                          further and start working on the discovery.
                        </Typography>
                      </>
                    )}
                  </Box>
                ) : onboardingStepV2 ===
                  'discovery_tree_generation_in_progress_after' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {/* <Typography>
                    {
                      sellingStyles?.find((ss) => ss?.id === sellingStyle)
                        ?.style
                    }{' '}
                    ... great choice!
                  </Typography> */}
                    {treeGenerationInProgress ? (
                      <>
                        <CircularProgress />
                        <LinearProgressWithLabel
                          value={questionGenerationProgress}
                        />
                      </>
                    ) : null}
                    {treeGenerationInProgress ? (
                      <>
                        {/* <ReactTyped
                        strings={['You are all set.']}
                        typeSpeed={40}
                        style={{ fontSize: 20, textAlign: 'center' }}
                        showCursor={false}
                      />
                      <ReactTyped
                        strings={['Discovery questions are generated.']}
                        typeSpeed={40}
                        style={{ fontSize: 20, textAlign: 'center' }}
                        startDelay={1000}
                        showCursor={false}
                      />
                      <ReactTyped
                        strings={[
                          'You can now move further and tell us more about your selling style.',
                        ]}
                        typeSpeed={40}
                        style={{ fontSize: 20, textAlign: 'center' }}
                        startDelay={3000}
                        showCursor={false}
                      /> */}
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          We are preparing your discovery questions. They will
                          be available in a moment.
                        </Typography>
                        {/* <Typography color={'text.secondary'}>
                          In the meantime, let&apos;s set up your
                          integrations...
                        </Typography> */}
                      </>
                    ) : (
                      <>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          You are all set.
                        </Typography>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Discovery questions are generated. You can now move
                          further and start working on the discovery.
                        </Typography>
                      </>
                    )}
                  </Box>
                ) : onboardingStepV2 === 'crm_integration' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {treeGenerationInProgress ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <CircularProgress size={20} />
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Crafting Questions
                        </Typography>
                        <LinearProgressWithLabel
                          value={questionGenerationProgress}
                        />
                      </Box>
                    ) : null}
                    <Box>
                      <Typography sx={{ fontSize: 20, mb: 2 }}>
                        Let&apos;s get your CRM connected. It just takes a
                        couple of minutes.
                      </Typography>
                      {crmConnected ? null : (
                        <>
                          <Typography
                            sx={{
                              fontSize: 20,
                            }}
                          >
                            Pick your CRM
                          </Typography>
                          <Typography
                            variant="caption"
                            color={'text.secondary'}
                          >
                            {selectedCrm
                              ? 'Click to unselect'
                              : 'Click to select'}
                          </Typography>
                        </>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}
                      >
                        {crms?.map((crm) => (
                          <Box
                            key={crm?.crm}
                            sx={{
                              p: 2,
                              background:
                                selectedCrm === crm?.crm ||
                                crmConnected === crm?.crm
                                  ? '#C2DBFF'
                                  : '#F2F6FC',
                              borderRadius: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minHeight: 60,
                              cursor: !crmConnected ? 'pointer' : 'default',
                              gap: 6,
                              position: 'relative',
                            }}
                            onClick={() => {
                              if (!crmConnected) {
                                setSelectedCrm((prev) =>
                                  prev === crm?.crm ? null : crm?.crm,
                                );
                              }
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: crmConnected
                                  ? 'center'
                                  : 'center',
                                gap: 2,
                                width: '50%',
                              }}
                            >
                              <img
                                src={crm?.icon}
                                alt={crm?.name}
                                width={crm?.iconWidth || '100px'}
                              />
                              {/* {crmConnected === crm?.crm ? ( */}
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  visibility:
                                    crmConnected === crm?.crm
                                      ? 'visible'
                                      : 'hidden',
                                  position: 'absolute',
                                  top: 20,
                                  right: 20,
                                }}
                              >
                                <CheckCircle
                                  color="primary"
                                  sx={{ fontSize: 26 }}
                                />
                                <Typography>Integrated</Typography>
                              </Box>
                              {/* ) : null} */}
                            </Box>
                          </Box>
                        ))}
                        <Box sx={{}}>
                          <TextField
                            placeholder="Search for my CRM"
                            variant="outlined"
                            color="primary"
                            size="small"
                            name="crmSearch"
                            value={crmSearch}
                            autoComplete="off"
                            onChange={(e) => {
                              setCrmSearch(e.target.value);
                            }}
                            fullWidth
                            sx={{
                              borderRadius: 2,
                              '& .MuiOutlinedInput-root': {
                                minHeight: 60,
                                background: '#F2F6FC',
                                borderRadius: 2,
                              },
                              '& .MuiOutlinedInput-input': {
                                borderRadius: 2,
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => {}}>
                                    <Search />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {crmConnected ? null : (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={selectedCrm ? false : true}
                              onClick={() => {
                                if (selectedCrm === 'hubspot') {
                                  setShowHubspotKeyDialog(true);
                                } else if (selectedCrm === 'pipedrive') {
                                  dispatch(
                                    getPipedriveOAuthUrl((url) => {
                                      console.log(url);
                                      window.open(url, '_self');
                                    }),
                                  );
                                } else if (selectedCrm === 'zoho') {
                                  setShowZohoKeyDialog(true);
                                }
                                // dispatch(
                                //   updateUserDetails(
                                //     {
                                //       onboardingStage: 'discovery_questions',
                                //     },
                                //     () => {
                                //       dispatch(getUserDetails((data) => {}));
                                //     },
                                //   ),
                                // );
                              }}
                            >
                              Continue
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'deal_stages' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {treeGenerationInProgress ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <CircularProgress size={20} />
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Crafting Questions
                        </Typography>
                        <LinearProgressWithLabel
                          value={questionGenerationProgress}
                        />
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        // alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ fontSize: 16, width: '80%' }}>
                        We recommend the following Deal Stages. You may edit or
                        reorder them as per your sales process.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          maxWidth: '60%',
                        }}
                      >
                        {dealStages && dealStages.length > 0 ? (
                          <>
                            <DragNDropSortableDealStages
                              list={dealStages}
                              setDealStages={setDealStages}
                              readOnly={crmConnected ? true : false}
                            />
                            {crmConnected ? null : (
                              <>
                                {stageCreationMode ? (
                                  <form
                                    onSubmit={() => {
                                      setDealStages((prev) => [
                                        ...prev,
                                        {
                                          name: newStage,
                                          id: uuidv4(),
                                          crm: 'qai',
                                        },
                                      ]);
                                      setStageCreationMode(false);
                                      setNewStage('');
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        borderRadius: 2,
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        minWidth: 300,
                                        flex: 1,
                                        justifyContent: 'space-between',
                                      }}
                                    >
                                      <TextField
                                        placeholder="Stage Name"
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        name="newStage"
                                        value={newStage}
                                        onChange={(e) => {
                                          setNewStage(e.target.value);
                                        }}
                                        fullWidth
                                        sx={{
                                          borderRadius: 2,
                                          '& .MuiOutlinedInput-root': {
                                            background: '#F2F6FC',
                                            borderRadius: 2,
                                          },
                                          '& .MuiOutlinedInput-input': {
                                            borderRadius: 2,
                                          },
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position="end">
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  // gap: 1,
                                                }}
                                              >
                                                <IconButton
                                                  onClick={() => {
                                                    setStageCreationMode(false);
                                                    setNewStage('');
                                                  }}
                                                >
                                                  <Close
                                                    sx={{ fontSize: 16 }}
                                                  />
                                                </IconButton>
                                                <IconButton
                                                  disabled={!newStage?.trim()}
                                                  type="submit"
                                                >
                                                  <Check
                                                    color={
                                                      newStage?.trim()
                                                        ? 'primary'
                                                        : 'disabled'
                                                    }
                                                    sx={{ fontSize: 16 }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </Box>
                                  </form>
                                ) : (
                                  <Box
                                    sx={{
                                      p: 1,
                                      background: '#F2F6FC',
                                      borderRadius: 2,
                                      display: 'flex',
                                      alignItems: 'center',
                                      cursor: 'pointer',
                                      gap: 2,
                                      minWidth: 300,
                                      flex: 1,
                                      justifyContent: 'space-between',
                                    }}
                                    onClick={() => {
                                      setStageCreationMode(true);
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        flex: 1,
                                      }}
                                    >
                                      <AddCircleOutline
                                        sx={{
                                          color: theme.palette.text.secondary,
                                        }}
                                      />
                                      <Typography color={'text.secondary'}>
                                        Add new stage
                                      </Typography>
                                    </Box>
                                  </Box>
                                )}
                              </>
                            )}
                          </>
                        ) : null}
                      </Box>
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'tools_integrations' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '30%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {treeGenerationInProgress ? (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <CircularProgress size={20} />
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Crafting Questions
                        </Typography>
                        <LinearProgressWithLabel
                          value={questionGenerationProgress}
                        />
                      </Box>
                    ) : null}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 1,
                      }}
                    >
                      <Typography sx={{ fontSize: 20 }}>
                        Let&apos;s connect your communication tools now, so we
                        don&apos;t miss anything important.
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 20,
                          mt: 2,
                        }}
                      >
                        Pick your tools
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2,
                        }}
                      >
                        {tools?.map((tool) => (
                          <Box
                            key={tool?.tool}
                            sx={{
                              p: 2,
                              background: '#F2F6FC',
                              borderRadius: 2,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              minHeight: 60,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: 6,
                                width: '70%',
                              }}
                            >
                              <img
                                src={tool?.icon}
                                alt={tool?.name}
                                width={tool?.iconWidth || '100px'}
                              />
                              <Tooltip
                                arrow
                                title={'Integrate ' + tool?.name}
                                placement="bottom"
                              >
                                <FormControlLabel
                                  sx={{}}
                                  control={
                                    <Switch
                                      checked={
                                        tool?.tool === 'gmail'
                                          ? gmailIntegration
                                            ? true
                                            : false
                                          : tool?.tool === 'google-calendar'
                                          ? googleIntegration
                                            ? true
                                            : false
                                          : false
                                      }
                                      disableRipple
                                      // disabled={
                                      //   tool?.tool === 'gmail' ? true : false
                                      // }
                                      size="small"
                                      name="active"
                                      className="integrateTool"
                                      onChange={(e, newValue) => {
                                        if (tool?.tool === 'google-calendar') {
                                          setShowDisclosure(true);
                                        } else {
                                          if (newValue) {
                                            setShowGmailIntegrationDialog(true);
                                          } else {
                                            dispatch(
                                              integrateHubspot(
                                                {
                                                  gmail: false,
                                                },
                                                'user',
                                                () => {
                                                  dispatch(
                                                    getUserDetails(() => {}),
                                                  );
                                                },
                                              ),
                                            );
                                          }
                                        }

                                        // if (
                                        //   userInfo?.orgRoles?.includes(
                                        //     'ORG_ADMIN',
                                        //   )
                                        // ) {
                                        //   if (newValue) {
                                        //     if (
                                        //       zohoCrmIntegration ||
                                        //       pipedriveCrmIntergration
                                        //     ) {
                                        //       setShowIntegrationRestricted(true);
                                        //     } else {
                                        //       if (
                                        //         userDetails?.plan?.trailEndsOn >
                                        //           new Date().getTime() ||
                                        //         userDetails?.plan
                                        //           ?.currentPaymentStatus ===
                                        //           'paid'
                                        //       ) {
                                        //         setShowHubspotKeyDialog(true);
                                        //       } else {
                                        //         dispatch({
                                        //           type: 'UPDATE_UI_STATE',
                                        //           payload: {
                                        //             key: 'showPlanExpired',
                                        //             value: true,
                                        //           },
                                        //         });
                                        //       }
                                        //     }
                                        //   } else {
                                        //     dispatch(
                                        //       integrateHubspot(
                                        //         {
                                        //           hubSpotKey: null,
                                        //           hubspot: false,
                                        //         },
                                        //         'org',
                                        //         () => {
                                        //           setShowHubspotKey(false);
                                        //           setHubspotKey('');
                                        //           dispatch(
                                        //             getUserDetails(() => {}),
                                        //           );
                                        //           history.push(
                                        //             '/profile-settings?t=integrations&it=crm',
                                        //           );
                                        //         },
                                        //       ),
                                        //     );
                                        //   }
                                        // }
                                      }}
                                    />
                                  }
                                  label={
                                    <Typography
                                      variant="caption"
                                      color={'text.secondary'}
                                    >
                                      {tool?.tool === 'gmail'
                                        ? gmailIntegration
                                          ? 'ON'
                                          : 'OFF'
                                        : tool?.tool === 'google-calendar'
                                        ? googleIntegration
                                          ? 'ON'
                                          : 'OFF'
                                        : 'OFF'}
                                    </Typography>
                                  }
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        ))}
                        <Box sx={{}}>
                          <TextField
                            placeholder="Search for my Tool"
                            variant="outlined"
                            color="primary"
                            size="small"
                            name="toolSearch"
                            value={toolSearch}
                            autoComplete="off"
                            onChange={(e) => {
                              setToolSearch(e.target.value);
                            }}
                            fullWidth
                            sx={{
                              borderRadius: 2,
                              '& .MuiOutlinedInput-root': {
                                minHeight: 60,
                                background: '#F2F6FC',
                                borderRadius: 2,
                              },
                              '& .MuiOutlinedInput-input': {
                                borderRadius: 2,
                              },
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={() => {}}>
                                    <Search />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {/* <Button
                              variant="contained"
                              size="small"
                              disabled={selectedCrm ? false : true}
                              onClick={() => {
                                // dispatch(
                                //   updateUserDetails(
                                //     {
                                //       onboardingStage: 'discovery_questions',
                                //     },
                                //     () => {
                                //       dispatch(getUserDetails((data) => {}));
                                //     },
                                //   ),
                                // );
                              }}
                            >
                              Continue
                            </Button> */}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'discovery_questions' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                      padding: 4,
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Typography sx={{ fontSize: 20 }}>
                        Here are your discovery questions for your first call.
                        They are crafted to reduce risk from the start. Please
                        review them carefully, edit if required and select those
                        you like.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: 1,
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                        justifyContent={'space-between'}
                        sx={{
                          borderBottom: '1px solid #d3d3d3',
                          width: 1,
                          paddingBottom: 2,
                        }}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                          <Typography fontWeight={'bold'}>
                            Discovery Template for all deals
                          </Typography>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={
                              sellingStyles?.find(
                                (ss) => ss?.sellingStyle === sellingStyle,
                              )?.title
                            }
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          {/* { ? ( */}
                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={1}
                            sx={{}}
                          >
                            {qList?.filter((qs) => !qs?.deleted && qs?.pinned)
                              ?.length > 0 ? (
                              <>
                                <Typography fontWeight={'bold'}>
                                  {
                                    qList?.filter(
                                      (qs) => !qs?.deleted && qs?.pinned,
                                    )?.length
                                  }{' '}
                                  Selected
                                </Typography>
                                {/* <Button
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  startIcon={<Clear />}
                                  sx={{
                                    padding: '4px 8px',
                                  }}
                                  onClick={() => {
                                    dispatch(
                                      updateDiscoveryQuestionBulk(
                                        treeId,
                                        qList?.map((dq) => {
                                          return {
                                            questionId: dq?.id,
                                            pinned: false,
                                          };
                                        }),
                                        () => {
                                          dispatch({
                                            type:
                                              'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                                            payload: {
                                              data: qList?.map((dq) => {
                                                return {
                                                  ...dq,
                                                  pinned: false,
                                                };
                                              }),
                                            },
                                          });
                                        },
                                        () => {
                                          dispatch(
                                            showAlert({
                                              message:
                                                'Failed to update question',
                                              showCross: true,
                                              title: null,
                                              type: 'error',
                                              autoHideDuration: 2000,
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }),
                                          );
                                        },
                                      ),
                                    );
                                  }}
                                >
                                  Clear All
                                </Button> */}
                              </>
                            ) : null}
                            {/* <Button
                              size="small"
                              color="primary"
                              variant="outlined"
                              startIcon={<DoneAll />}
                              disabled={
                                qList?.filter(
                                  (qs) => !qs?.deleted && qs?.pinned,
                                )?.length === qList?.length
                              }
                              sx={{
                                padding: '4px 8px',
                              }}
                              onClick={() => {
                                dispatch(
                                  updateDiscoveryQuestionBulk(
                                    treeId,
                                    qList?.map((dq) => {
                                      return {
                                        questionId: dq?.id,
                                        pinned: true,
                                      };
                                    }),
                                    () => {
                                      dispatch({
                                        type:
                                          'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                                        payload: {
                                          data: qList?.map((dq) => {
                                            return {
                                              ...dq,
                                              pinned: true,
                                            };
                                          }),
                                        },
                                      });
                                    },
                                    () => {
                                      dispatch(
                                        showAlert({
                                          message: 'Failed to update question',
                                          showCross: true,
                                          title: null,
                                          type: 'error',
                                          autoHideDuration: 2000,
                                          vertical: 'top',
                                          horizontal: 'center',
                                        }),
                                      );
                                    },
                                  ),
                                );
                              }}
                            >
                              Select All
                            </Button> */}
                          </Box>
                          {/* ) : null} */}
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            disabled={
                              expandedSubtopics?.length ===
                              questionSubTopics?.length
                            }
                            onClick={() => {
                              setExpandedSubtopics([...questionSubTopics]);
                            }}
                          >
                            Expand All
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            disabled={expandedSubtopics?.length === 0}
                            onClick={() => {
                              setExpandedSubtopics([]);
                            }}
                          >
                            Collapse All
                          </Button>
                          {/* <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            sx={{
                              padding: '4px 8px',
                            }}
                            disabled={expandedSubtopics?.length === 0}
                            onClick={() => setShowDeleted((prev) => !prev)}
                          >
                            {showDeleted ? 'Hide Deleted' : 'Show Deleted'}
                          </Button> */}
                        </Box>
                      </Box>
                      {questionTopics && questionTopics?.length > 0 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            padding: 1,
                            width: 1,
                          }}
                        >
                          <Box sx={{ display: 'flex', gap: 2, width: 1 }}>
                            <Box
                              sx={{
                                flex: 1,
                                background: '#F2F6FC',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                padding: 2,
                                borderRadius: 2,
                                minHeight: 'calc(100vh - 280px)',
                              }}
                            >
                              {questionTopics?.map((topic) => (
                                <Box
                                  key={topic?.id}
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    background:
                                      selectedTopic === topic?.id
                                        ? '#C2DBFF'
                                        : 'transparent',
                                    padding: 1,
                                    cursor: 'pointer',
                                    borderRadius: 2,
                                    width: 1,
                                  }}
                                  onClick={() => {
                                    setSelectedTopic(topic?.id);
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      width: 1,
                                    }}
                                  >
                                    <Typography
                                      color={'text.primary'}
                                      fontWeight={'bold'}
                                    >
                                      {topic?.riskTitle}
                                    </Typography>
                                  </Box>
                                </Box>
                              ))}
                            </Box>
                            <Box
                              sx={{
                                flex: 4,
                                // background: '#F2F6FC',
                                // padding: 2,
                              }}
                            >
                              {questionSubTopics
                                ?.filter(
                                  (qst) =>
                                    qst?.path?.split('>')?.[1] ===
                                    selectedTopic,
                                )
                                ?.map((subtopic) => (
                                  <Box
                                    key={subtopic?.id}
                                    sx={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      gap: 1,
                                      width: 1,
                                      // marginLeft: 2,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 2,
                                        width: 1,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          // marginLeft: 2,
                                          // gap: 1,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          if (
                                            expandedSubtopics?.find(
                                              (sc) => sc?.id === subtopic?.id,
                                            )
                                          ) {
                                            setExpandedSubtopics((prev) =>
                                              prev.filter(
                                                (st) => st?.id !== subtopic?.id,
                                              ),
                                            );
                                          } else {
                                            setExpandedSubtopics((prev) => [
                                              ...prev,
                                              subtopic,
                                            ]);
                                          }
                                        }}
                                      >
                                        {expandedSubtopics?.find(
                                          (sc) => sc?.id === subtopic?.id,
                                        ) ? (
                                          <KeyboardArrowDown
                                            sx={{
                                              fontSize: 34,
                                              color: '#747775',
                                            }}
                                          />
                                        ) : (
                                          <KeyboardArrowRight
                                            sx={{
                                              fontSize: 34,
                                              color: '#747775',
                                            }}
                                          />
                                        )}
                                        <Typography
                                          color={'text.primary'}
                                          fontWeight={'bold'}
                                        >
                                          {subtopic?.riskTitle}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          gap: 1,
                                          // marginRight: 8,
                                        }}
                                      >
                                        {fetchingMoreDiscoveryQuestions ===
                                        subtopic?.id ? (
                                          <Typography variant="caption">
                                            Generating More Question
                                          </Typography>
                                        ) : null}
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title="Generate more like this"
                                        >
                                          <Loop
                                            sx={{
                                              color: '#747775',
                                              cursor: 'pointer',
                                              animation:
                                                fetchingMoreDiscoveryQuestions ===
                                                subtopic?.id
                                                  ? 'spin 2s linear infinite'
                                                  : 'none',
                                              '@keyframes spin': {
                                                '0%': {
                                                  transform: 'rotate(360deg)',
                                                },
                                                '100%': {
                                                  transform: 'rotate(0deg)',
                                                },
                                              },
                                            }}
                                            onClick={() => {
                                              setFetchingMoreDiscoveryQuestions(
                                                subtopic?.id,
                                              );
                                              dispatch(
                                                getDealDiscoveryQuestionsList(
                                                  treeId,
                                                  qList?.filter(
                                                    (dq) =>
                                                      (!dq?.deleted ||
                                                        showDeleted) &&
                                                      dq?.nodeId ===
                                                        subtopic?.id,
                                                  )?.[
                                                    qList?.filter(
                                                      (dq) =>
                                                        (!dq?.deleted ||
                                                          showDeleted) &&
                                                        dq?.nodeId ===
                                                          subtopic?.id,
                                                    )?.length - 1
                                                  ]?.id,
                                                  qList?.filter(
                                                    (dq) =>
                                                      (!dq?.deleted ||
                                                        showDeleted) &&
                                                      dq?.nodeId ===
                                                        subtopic?.id,
                                                  )?.[
                                                    qList?.filter(
                                                      (dq) =>
                                                        (!dq?.deleted ||
                                                          showDeleted) &&
                                                        dq?.nodeId ===
                                                          subtopic?.id,
                                                    )?.length - 1
                                                  ]?.nodeId,
                                                  (data) => {
                                                    console.log(data);
                                                    setFetchingMoreDiscoveryQuestions(
                                                      false,
                                                    );
                                                  },
                                                ),
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          arrow
                                          placement="top"
                                          title="Add more into this category"
                                        >
                                          <AddBoxOutlined
                                            sx={{
                                              color: '#747775',
                                              cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                              setShowNewQuestionDialog(true);
                                              setSubtopicIdForNewQuestion(
                                                qList?.filter(
                                                  (dq) =>
                                                    (!dq?.deleted ||
                                                      showDeleted) &&
                                                    dq?.nodeId === subtopic?.id,
                                                )?.[
                                                  qList?.filter(
                                                    (dq) =>
                                                      (!dq?.deleted ||
                                                        showDeleted) &&
                                                      dq?.nodeId ===
                                                        subtopic?.id,
                                                  )?.length - 1
                                                ]?.nodeId,
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                    {expandedSubtopics?.find(
                                      (sc) => sc?.id === subtopic?.id,
                                    ) ? (
                                      <>
                                        {qList
                                          ?.filter(
                                            (dq) =>
                                              (!dq?.deleted || showDeleted) &&
                                              dq?.nodeId === subtopic?.id,
                                          )
                                          ?.sort((a, b) => {
                                            if (a.pinned && !b.pinned)
                                              return -1;
                                            if (!a.pinned && b.pinned) return 1;
                                            return 0;
                                          })
                                          ?.map((dq, index) => (
                                            <Box
                                              key={dq?.id}
                                              sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                borderRadius: 2,
                                                width: 1,
                                              }}
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                if (
                                                  !dq?.deleted &&
                                                  !questionEditMode
                                                ) {
                                                  setUpdatingQuestion(true);
                                                  dispatch(
                                                    updateDiscoveryQuestion(
                                                      treeId,
                                                      dq?.id,
                                                      {
                                                        ...dq,
                                                        pinned: !dq?.pinned,
                                                      },
                                                      () => {
                                                        setUpdatingQuestion(
                                                          false,
                                                        );
                                                        dispatch({
                                                          type:
                                                            'UPDATE_DISCOVERY_QUESTION',
                                                          payload: {
                                                            id: dq?.id,
                                                            data: {
                                                              ...dq,
                                                              pinned: !dq?.pinned,
                                                            },
                                                          },
                                                        });
                                                      },
                                                      () => {
                                                        dispatch(
                                                          showAlert({
                                                            message:
                                                              'Failed to update question',
                                                            showCross: true,
                                                            title: null,
                                                            type: 'error',
                                                            autoHideDuration: 2000,
                                                            vertical: 'top',
                                                            horizontal:
                                                              'center',
                                                          }),
                                                        );
                                                      },
                                                    ),
                                                  );
                                                }
                                              }}
                                            >
                                              <Box
                                                sx={{
                                                  background: dq?.pinned
                                                    ? '#C2DBFF'
                                                    : '#F2F6FC',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  justifyContent:
                                                    'space-between',
                                                  gap: 2,
                                                  flex: 1,
                                                  borderRadius: 2,
                                                  minHeight: 80,
                                                  // border: dq?.pinned
                                                  //   ? '1px solid #beb9e7'
                                                  //   : 'none',
                                                }}
                                              >
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 2,
                                                    flex: 1,
                                                    borderRadius: 2,
                                                    paddingLeft: 2,
                                                  }}
                                                >
                                                  {/* <Typography
                                                    sx={{
                                                      // background: '#d3e2fe',
                                                      padding: 2,
                                                      borderTopLeftRadius: 8,
                                                      borderBottomLeftRadius: 8,
                                                      minHeight: 80,
                                                      minWidth: 80,
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center',
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                    }}
                                                    onMouseEnter={(e) => {
                                                      setAnchorElDetails(
                                                        e.currentTarget,
                                                      );
                                                      setPopperDetails(
                                                        'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                      );
                                                    }}
                                                    onMouseLeave={() => {
                                                      setAnchorElDetails(null);
                                                      setPopperDetails(null);
                                                    }}
                                                  >
                                                    {index < 9 ? 0 : ''}
                                                    {index + 1}
                                                  </Typography>
                                                  <Popper
                                                    id="mouse-over-popover"
                                                    sx={{
                                                      pointerEvents: 'none',
                                                      zIndex: 1111111,
                                                    }}
                                                    open={Boolean(
                                                      anchorElDetails,
                                                    )}
                                                    anchorEl={anchorElDetails}
                                                    placement="top"
                                                    onClose={() => {
                                                      setAnchorElDetails(null);
                                                    }}
                                                    // disableRestoreFocus
                                                  >
                                                    <Box
                                                      sx={{
                                                        border:
                                                          '1px solid #d3d3d3',
                                                        borderRadius: 1,
                                                        padding: 1,
                                                        background: '#fff',
                                                        maxWidth: 400,
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: 1,
                                                        // boxShadow: theme.shadows[1],
                                                      }}
                                                    >
                                                      <Typography
                                                        variant={'body1'}
                                                        color={'text.secondary'}
                                                        sx={{
                                                          padding: 1,
                                                        }}
                                                      >
                                                        {popperDetails || ''}
                                                      </Typography>
                                                    </Box>
                                                  </Popper> */}
                                                  {questionEditMode ===
                                                  dq?.id ? (
                                                    <>
                                                      <TextField
                                                        autoFocus
                                                        placeholder="Question"
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        name="question"
                                                        value={
                                                          editedQuestionText
                                                        }
                                                        disabled={
                                                          regeneratingQuestionInSpecificStyle
                                                        }
                                                        onChange={(e) => {
                                                          setEditedQuestionText(
                                                            e.target.value,
                                                          );
                                                        }}
                                                        fullWidth
                                                      />
                                                      <FormControl
                                                        size="small"
                                                        sx={{ minWidth: 150 }}
                                                      >
                                                        <InputLabel id="sellingStyle">
                                                          Selling Style
                                                        </InputLabel>
                                                        <Select
                                                          variant="outlined"
                                                          size="small"
                                                          labelId={
                                                            'sellingStyle'
                                                          }
                                                          label={
                                                            'Selling Style'
                                                          }
                                                          id={'sellingStyle'}
                                                          name={'sellingStyle'}
                                                          MenuProps={{
                                                            disableScrollLock: true,
                                                          }}
                                                          onChange={(e) => {
                                                            e.stopPropagation();
                                                            setRegeneratingQuestionInSpecificStyle(
                                                              true,
                                                            );
                                                            dispatch(
                                                              updateDiscoveryQuestion(
                                                                treeId,
                                                                dq?.id,
                                                                {
                                                                  ...dq,
                                                                  sellingStyle:
                                                                    e.target
                                                                      .value,
                                                                },
                                                                (data) => {
                                                                  console.log(
                                                                    data,
                                                                  );
                                                                  setRegeneratingQuestionInSpecificStyle(
                                                                    false,
                                                                  );
                                                                  setQuestionEditMode(
                                                                    null,
                                                                  );
                                                                  setEditedQuestionText(
                                                                    '',
                                                                  );
                                                                  dispatch({
                                                                    type:
                                                                      'UPDATE_DISCOVERY_QUESTION',
                                                                    payload: {
                                                                      id:
                                                                        dq?.id,
                                                                      data: {
                                                                        ...dq,
                                                                        cq:
                                                                          data?.cq,
                                                                        sellingStyle:
                                                                          e
                                                                            .target
                                                                            .value,
                                                                      },
                                                                    },
                                                                  });
                                                                },
                                                                () => {
                                                                  dispatch(
                                                                    showAlert({
                                                                      message:
                                                                        'Failed to update question',
                                                                      showCross: true,
                                                                      title: null,
                                                                      type:
                                                                        'error',
                                                                      autoHideDuration: 2000,
                                                                      vertical:
                                                                        'top',
                                                                      horizontal:
                                                                        'center',
                                                                    }),
                                                                  );
                                                                },
                                                              ),
                                                            );
                                                          }}
                                                          value={
                                                            dq?.sellingStyle ||
                                                            sellingStyle ||
                                                            'persuasive_or_traditional_selling'
                                                          }
                                                        >
                                                          {sellingStyles?.map(
                                                            (ss) => (
                                                              <MenuItem
                                                                key={
                                                                  ss?.sellingStyle
                                                                }
                                                                value={
                                                                  ss?.sellingStyle
                                                                }
                                                              >
                                                                <Typography variant="subtitle2">
                                                                  {ss?.title}
                                                                </Typography>
                                                              </MenuItem>
                                                            ),
                                                          )}
                                                        </Select>
                                                      </FormControl>
                                                    </>
                                                  ) : (
                                                    <Box
                                                      display={'flex'}
                                                      gap={1}
                                                      sx={{
                                                        width: 1,
                                                        padding: 1,
                                                        textDecoration: dq?.deleted
                                                          ? 'line-through'
                                                          : 'none',
                                                      }}
                                                    >
                                                      <InfoOutlined
                                                        sx={{
                                                          cursor: 'pointer',
                                                          color: '#a8a2a6',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            dq?.exp ||
                                                              'No explanation available',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                          setPopperDetails(
                                                            null,
                                                          );
                                                        }}
                                                        onClick={(e) =>
                                                          e.stopPropagation()
                                                        }
                                                      />
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={
                                                          anchorElDetails
                                                        }
                                                        placement="top"
                                                        onClose={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection:
                                                              'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={
                                                              'text.secondary'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails ||
                                                              ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper>
                                                      <Typography>
                                                        {dq?.cq}
                                                      </Typography>
                                                    </Box>
                                                  )}
                                                </Box>
                                                <Box
                                                  sx={{
                                                    // background: '#d3e2fe',
                                                    padding: 2,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    gap: 1,
                                                    borderTopRightRadius: 8,
                                                    borderBottomRightRadius: 8,
                                                    minHeight: 80,
                                                    position: 'relative',
                                                  }}
                                                >
                                                  {questionEditMode ===
                                                  dq?.id ? (
                                                    updatingQuestion ||
                                                    regeneratingQuestionInSpecificStyle ? (
                                                      <CircularProgress
                                                        size={20}
                                                      />
                                                    ) : (
                                                      <>
                                                        <Tooltip
                                                          title="Cancel"
                                                          arrow
                                                          placement="top"
                                                        >
                                                          <Close
                                                            sx={{
                                                              cursor: 'pointer',
                                                              fontSize: 20,
                                                            }}
                                                            onClick={() => {
                                                              setQuestionEditMode(
                                                                null,
                                                              );
                                                              setEditedQuestionText(
                                                                '',
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                        <Tooltip
                                                          title="Save"
                                                          arrow
                                                          placement="top"
                                                        >
                                                          <Check
                                                            sx={{
                                                              cursor: 'pointer',
                                                              fontSize: 20,
                                                            }}
                                                            onClick={() => {
                                                              setUpdatingQuestion(
                                                                true,
                                                              );
                                                              dispatch(
                                                                updateDiscoveryQuestion(
                                                                  treeId,
                                                                  questionEditMode,
                                                                  {
                                                                    q: editedQuestionText,
                                                                    // sellingStyle: dq?.sellingStyle
                                                                  },
                                                                  () => {
                                                                    setUpdatingQuestion(
                                                                      false,
                                                                    );
                                                                    setQuestionEditMode(
                                                                      null,
                                                                    );
                                                                    dispatch({
                                                                      type:
                                                                        'UPDATE_DISCOVERY_QUESTION',
                                                                      payload: {
                                                                        id: questionEditMode,
                                                                        data: {
                                                                          ...dq,
                                                                          cq: editedQuestionText,
                                                                          // sellingStyle: dq?.sellingStyle
                                                                        },
                                                                      },
                                                                    });
                                                                  },
                                                                  () => {
                                                                    dispatch(
                                                                      showAlert(
                                                                        {
                                                                          message:
                                                                            'Failed to update question',
                                                                          showCross: true,
                                                                          title: null,
                                                                          type:
                                                                            'error',
                                                                          autoHideDuration: 2000,
                                                                          vertical:
                                                                            'top',
                                                                          horizontal:
                                                                            'center',
                                                                        },
                                                                      ),
                                                                    );
                                                                  },
                                                                ),
                                                              );
                                                            }}
                                                          />
                                                        </Tooltip>
                                                      </>
                                                    )
                                                  ) : (
                                                    <>
                                                      {/* <HelpOutline
                                                        sx={{
                                                          cursor: 'pointer',
                                                        }}
                                                        onMouseEnter={(e) => {
                                                          setAnchorElDetails(
                                                            e.currentTarget,
                                                          );
                                                          setPopperDetails(
                                                            'This is a long explanation of why this question makes sense. It can be a multi line explanation. And the help box should have the height and width to handle that.',
                                                          );
                                                        }}
                                                        onMouseLeave={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                          setPopperDetails(
                                                            null,
                                                          );
                                                        }}
                                                      />
                                                      <Popper
                                                        id="mouse-over-popover"
                                                        sx={{
                                                          pointerEvents: 'none',
                                                          zIndex: 1111111,
                                                        }}
                                                        open={Boolean(
                                                          anchorElDetails,
                                                        )}
                                                        anchorEl={
                                                          anchorElDetails
                                                        }
                                                        placement="left"
                                                        onClose={() => {
                                                          setAnchorElDetails(
                                                            null,
                                                          );
                                                        }}
                                                        // disableRestoreFocus
                                                      >
                                                        <Box
                                                          sx={{
                                                            border:
                                                              '1px solid #d3d3d3',
                                                            borderRadius: 1,
                                                            padding: 1,
                                                            background: '#fff',
                                                            maxWidth: 400,
                                                            display: 'flex',
                                                            flexDirection:
                                                              'column',
                                                            gap: 1,
                                                            // boxShadow: theme.shadows[1],
                                                          }}
                                                        >
                                                          <Typography
                                                            variant={'body1'}
                                                            color={
                                                              'text.secondary'
                                                            }
                                                            sx={{
                                                              padding: 1,
                                                            }}
                                                          >
                                                            {popperDetails ||
                                                              ''}
                                                          </Typography>
                                                        </Box>
                                                      </Popper> */}
                                                      <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title="Edit"
                                                      >
                                                        <img
                                                          src={
                                                            '/dh-icons/edit.svg'
                                                          }
                                                          style={{
                                                            cursor: 'pointer',
                                                          }}
                                                          onClick={(e) => {
                                                            if (!dq?.deleted) {
                                                              e.stopPropagation();
                                                              setQuestionEditMode(
                                                                dq?.id,
                                                              );
                                                              setEditedQuestionText(
                                                                dq?.cq,
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      </Tooltip>
                                                      <Tooltip
                                                        arrow
                                                        placement="top"
                                                        title={
                                                          dq?.pinned
                                                            ? 'Unselect'
                                                            : 'Select'
                                                        }
                                                      >
                                                        {dq?.pinned ? (
                                                          <img
                                                            src={
                                                              '/dh-icons/pinned.svg'
                                                            }
                                                            style={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={(e) => {
                                                              e.stopPropagation();
                                                              setUpdatingQuestion(
                                                                true,
                                                              );
                                                              dispatch(
                                                                updateDiscoveryQuestion(
                                                                  treeId,
                                                                  dq?.id,
                                                                  {
                                                                    ...dq,
                                                                    pinned: !dq?.pinned,
                                                                    // sellingStyle: dq?.sellingStyle
                                                                  },
                                                                  () => {
                                                                    setUpdatingQuestion(
                                                                      false,
                                                                    );
                                                                    dispatch({
                                                                      type:
                                                                        'UPDATE_DISCOVERY_QUESTION',
                                                                      payload: {
                                                                        id:
                                                                          dq?.id,
                                                                        data: {
                                                                          ...dq,
                                                                          pinned: !dq?.pinned,
                                                                        },
                                                                      },
                                                                    });
                                                                  },
                                                                  () => {
                                                                    dispatch(
                                                                      showAlert(
                                                                        {
                                                                          message:
                                                                            'Failed to update question',
                                                                          showCross: true,
                                                                          title: null,
                                                                          type:
                                                                            'error',
                                                                          autoHideDuration: 2000,
                                                                          vertical:
                                                                            'top',
                                                                          horizontal:
                                                                            'center',
                                                                        },
                                                                      ),
                                                                    );
                                                                  },
                                                                ),
                                                              );
                                                            }}
                                                          />
                                                        ) : (
                                                          <img
                                                            src={
                                                              '/dh-icons/unpinned.svg'
                                                            }
                                                            style={{
                                                              cursor: 'pointer',
                                                            }}
                                                            onClick={(e) => {
                                                              if (
                                                                !dq?.deleted
                                                              ) {
                                                                e.stopPropagation();
                                                                setUpdatingQuestion(
                                                                  true,
                                                                );
                                                                dispatch(
                                                                  updateDiscoveryQuestion(
                                                                    treeId,
                                                                    dq?.id,
                                                                    {
                                                                      ...dq,
                                                                      pinned: !dq?.pinned,
                                                                      // sellingStyle: dq?.sellingStyle
                                                                    },
                                                                    () => {
                                                                      setUpdatingQuestion(
                                                                        false,
                                                                      );
                                                                      dispatch({
                                                                        type:
                                                                          'UPDATE_DISCOVERY_QUESTION',
                                                                        payload: {
                                                                          id:
                                                                            dq?.id,
                                                                          data: {
                                                                            ...dq,
                                                                            pinned: !dq?.pinned,
                                                                          },
                                                                        },
                                                                      });
                                                                    },
                                                                    () => {
                                                                      dispatch(
                                                                        showAlert(
                                                                          {
                                                                            message:
                                                                              'Failed to update question',
                                                                            showCross: true,
                                                                            title: null,
                                                                            type:
                                                                              'error',
                                                                            autoHideDuration: 2000,
                                                                            vertical:
                                                                              'top',
                                                                            horizontal:
                                                                              'center',
                                                                          },
                                                                        ),
                                                                      );
                                                                    },
                                                                  ),
                                                                );
                                                              }
                                                            }}
                                                          />
                                                        )}
                                                      </Tooltip>
                                                      {dq?.pinned ? null : (
                                                        <Tooltip
                                                          arrow
                                                          placement="top"
                                                          title={
                                                            dq?.deleted
                                                              ? 'Restore'
                                                              : 'Remove'
                                                          }
                                                        >
                                                          {dq?.deleted ? (
                                                            <Redo
                                                              sx={{
                                                                color: '#fff',
                                                                cursor:
                                                                  'pointer',
                                                                fontSize: 16,
                                                                position:
                                                                  'absolute',
                                                                top: -2,
                                                                right: -2,
                                                                background:
                                                                  'rgba(128,105,191,0.5)',
                                                                borderRadius:
                                                                  '50%',
                                                                padding: '2px',
                                                              }}
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                setUpdatingQuestion(
                                                                  true,
                                                                );
                                                                dispatch(
                                                                  updateDiscoveryQuestion(
                                                                    treeId,
                                                                    dq?.id,
                                                                    {
                                                                      ...dq,
                                                                      deleted: !dq?.deleted,
                                                                      // sellingStyle: dq?.sellingStyle
                                                                    },
                                                                    () => {
                                                                      setUpdatingQuestion(
                                                                        false,
                                                                      );
                                                                      dispatch({
                                                                        type:
                                                                          'UPDATE_DISCOVERY_QUESTION',
                                                                        payload: {
                                                                          id:
                                                                            dq?.id,
                                                                          data: {
                                                                            ...dq,
                                                                            deleted: !dq?.deleted,
                                                                          },
                                                                        },
                                                                      });
                                                                    },
                                                                    () => {
                                                                      dispatch(
                                                                        showAlert(
                                                                          {
                                                                            message:
                                                                              'Failed to update question',
                                                                            showCross: true,
                                                                            title: null,
                                                                            type:
                                                                              'error',
                                                                            autoHideDuration: 2000,
                                                                            vertical:
                                                                              'top',
                                                                            horizontal:
                                                                              'center',
                                                                          },
                                                                        ),
                                                                      );
                                                                    },
                                                                  ),
                                                                );
                                                              }}
                                                            />
                                                          ) : (
                                                            <Close
                                                              sx={{
                                                                color: '#000',
                                                                cursor:
                                                                  'pointer',
                                                                fontSize: 18,
                                                                position:
                                                                  'absolute',
                                                                top: -2,
                                                                right: -2,
                                                                background:
                                                                  '#F2F6FC',
                                                                borderRadius:
                                                                  '50%',
                                                                border:
                                                                  '1px solid #C2DBFF',
                                                                padding: '2px',
                                                                zIndex: 1000,
                                                              }}
                                                              onClick={(e) => {
                                                                e.stopPropagation();
                                                                setUpdatingQuestion(
                                                                  true,
                                                                );
                                                                dispatch(
                                                                  updateDiscoveryQuestion(
                                                                    treeId,
                                                                    dq?.id,
                                                                    {
                                                                      ...dq,
                                                                      deleted: !dq?.deleted,
                                                                      // sellingStyle: dq?.sellingStyle
                                                                    },
                                                                    () => {
                                                                      setUpdatingQuestion(
                                                                        false,
                                                                      );
                                                                      dispatch({
                                                                        type:
                                                                          'UPDATE_DISCOVERY_QUESTION',
                                                                        payload: {
                                                                          id:
                                                                            dq?.id,
                                                                          data: {
                                                                            ...dq,
                                                                            deleted: !dq?.deleted,
                                                                          },
                                                                        },
                                                                      });
                                                                    },
                                                                    () => {
                                                                      dispatch(
                                                                        showAlert(
                                                                          {
                                                                            message:
                                                                              'Failed to update question',
                                                                            showCross: true,
                                                                            title: null,
                                                                            type:
                                                                              'error',
                                                                            autoHideDuration: 2000,
                                                                            vertical:
                                                                              'top',
                                                                            horizontal:
                                                                              'center',
                                                                          },
                                                                        ),
                                                                      );
                                                                    },
                                                                  ),
                                                                );
                                                              }}
                                                            />
                                                          )}
                                                        </Tooltip>
                                                      )}
                                                    </>
                                                  )}
                                                </Box>
                                              </Box>
                                            </Box>
                                          ))}
                                      </>
                                    ) : null}
                                  </Box>
                                ))}
                            </Box>
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 300,
                            gap: 2,
                          }}
                        >
                          <Typography>No discovery questions found.</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'discovery_questions_reordering' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                      padding: 4,
                    }}
                  >
                    <Box sx={{ width: '100%' }}>
                      <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        Here are your final discovery questions. Please review
                        and reorder them according to your meeting setup. <br />
                        Tip: Put the questions to be asked first on top of the
                        list.
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignItems={'center'}
                        gap={2}
                        justifyContent={'space-between'}
                        sx={{
                          borderBottom: '1px solid #d3d3d3',
                          width: 1,
                          paddingBottom: 2,
                        }}
                      >
                        <Box display={'flex'} alignItems={'center'} gap={2}>
                          <Typography fontWeight={'bold'}>
                            Discovery Template for all deals
                          </Typography>
                          <Chip
                            color="primary"
                            variant="outlined"
                            size="small"
                            label={
                              sellingStyles?.find(
                                (ss) => ss?.sellingStyle === sellingStyle,
                              )?.title
                            }
                          />
                        </Box>
                        <Box display={'flex'} alignItems={'center'} gap={1}>
                          {/* <HelpOutlined
                            sx={{
                              fontSize: 20,
                              color: theme.palette.text.secondary,
                            }}
                          /> */}
                          <Typography variant="body2" color={'text.secondary'}>
                            Drag and drop to reorder
                          </Typography>
                        </Box>
                      </Box>
                      {qList && qList?.length > 0 ? (
                        <DragNDropSortableList
                          qList={qList}
                          questionTopics={questionTopics}
                          questionSubTopics={questionSubTopics}
                        />
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            minHeight: 300,
                            gap: 2,
                          }}
                        >
                          <Typography>No discovery questions found.</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ) : onboardingStepV2 === 'deal_creation' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50%',
                      height: 'calc(100vh - 260px)',
                      // justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      paddingTop: 10,
                    }}
                  >
                    {/* <Typography>
                  {
                    sellingStyles?.find((ss) => ss?.id === sellingStyle)
                      ?.style
                  }{' '}
                  ... great choice!
                </Typography> */}
                    {dealCreationInProgress ? (
                      <>
                        <CircularProgress />
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          To give you a feel for it, we are creating a sample
                          deal for you to start with. The questions will be
                          available for new deals you create as well. And,
                          don&apos;t worry you can update them later.
                        </Typography>
                      </>
                    ) : dealCreated ? (
                      <Box>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          Your sample deal has been created with the questions
                          to cover in your first discovery call.
                        </Typography>
                        <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                          You are all set for your fist meeting plan!
                        </Typography>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          border: '1px solid #d3d3d3',
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                          minWidth: 400,
                        }}
                      >
                        <Typography color={'text.secondary'}>
                          Deal Name
                        </Typography>
                        <TextField
                          autoFocus
                          placeholder="Enter deal name*"
                          variant="outlined"
                          color="primary"
                          size="small"
                          name="dealTitle"
                          value={dealTitle}
                          onChange={(e) => setDealTitle(e.target.value)}
                          fullWidth
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                          }}
                        >
                          {dealCreated ? null : (
                            <Button
                              variant="contained"
                              size="small"
                              disabled={dealTitle?.trim() ? false : true}
                              onClick={() => {
                                setDealCreationInProgress(true);
                                dispatch(
                                  createNewDeal(
                                    {
                                      name: dealTitle,
                                    },
                                    (data, error) => {
                                      if (error) {
                                        setDealCreationInProgress(false);
                                        dispatch(
                                          showAlert({
                                            message:
                                              'Oops, Something went wront! Please try again.',
                                            showCross: true,
                                            title: null,
                                            type: 'error',
                                            autoHideDuration: 2000,
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }),
                                        );
                                      } else {
                                        setDealCreated(true);
                                        setDealCreationInProgress(false);
                                      }
                                    },
                                  ),
                                );
                              }}
                            >
                              Continue
                            </Button>
                          )}
                        </Box>
                      </Box>
                    )}
                    {/* {treeGenerationInProgress ? (
                        <> */}
                    {/* <ReactTyped
                      strings={['You are all set.']}
                      typeSpeed={40}
                      style={{ fontSize: 20, textAlign: 'center' }}
                      showCursor={false}
                    />
                    <ReactTyped
                      strings={['Discovery questions are generated.']}
                      typeSpeed={40}
                      style={{ fontSize: 20, textAlign: 'center' }}
                      startDelay={1000}
                      showCursor={false}
                    />
                    <ReactTyped
                      strings={[
                        'You can now move further and tell us more about your selling style.',
                      ]}
                      typeSpeed={40}
                      style={{ fontSize: 20, textAlign: 'center' }}
                      startDelay={3000}
                      showCursor={false}
                    /> */}
                    {/* <Typography
                            sx={{ fontSize: 20, textAlign: 'center' }}
                          >
                            Those are some great questions to de-risk your deals
                            from the start!
                          </Typography>
                          <Typography color={'text.secondary'}>
                            To give you a feel for it, we are creating a sample
                            deal for you to start with. The questions will be
                            available for new deals you create as well. And,
                            don&apos;t worry you can update them later.
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{ fontSize: 20, textAlign: 'center' }}
                          >
                            Your sample deal has been created with the questions
                            to cover in your first discovery call.
                          </Typography>
                          <Typography
                            sx={{ fontSize: 20, textAlign: 'center' }}
                          >
                            You are all set for your fist meeting plan!
                          </Typography>
                        </>
                      )} */}
                  </Box>
                ) : null}
              </Box>
            </Box>
            <Box
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              p={'8px 16px'}
              borderTop={'1px solid #d3d3d3'}
            >
              <Typography
                sx={{
                  flexWrap: 'nowrap',
                  textWrap: 'nowrap',
                }}
              >
                Deal Health AI Onboarding
                {/* -{' '} */}
                {/* {capitalizeText(onboardingStepV2?.split('_')?.join(' '))} */}
              </Typography>
              {/* <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    dispatch({
                      type: 'UPDATE_UI_STATE',
                      payload: {
                        key: 'showOnboardingDialogV2',
                        value: false,
                      },
                    });
                    dispatch({
                      type: 'UPDATE_UI_STATE',
                      payload: {
                        key: 'onboardingStepV2',
                        value: 0,
                      },
                    });
                  }}
                >
                  Skip and Continue Manually
                </Button> */}
              {/* <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  gap={1}
                >
                  <ProfileCompletion
                    percentCompleted={Object.values(
                      onBoardingCompletionPercentage || {},
                    )?.reduce((ac, cr) => {
                      ac += cr;
                      return ac;
                    }, 0)}
                    circleSize={50}
                    circleTextSize={12}
                    fontSize={12}
                    thickness={5}
                    dontShowExtraText={true}
                  />
                  <Typography variant="body2" color={'text.secondary'}>
                    Onboarding Completed
                  </Typography>
                </Box> */}
              {/* {Object.values(onBoardingCompletionPercentage || {})?.reduce(
                  (ac, cr) => {
                    ac += cr;
                    return ac;
                  },
                  0,
                ) === 100 &&
                ((userDetails?.custom?.orgType === 'demo_org' &&
                  onBoardingStep === 3) ||
                  (userDetails?.custom?.orgType !== 'demo_org' &&
                    onBoardingStep === 4)) ? (
                  <Box display={'flex'} alignItems={'center'} gap={1}>
                    {userDetails?.custom?.orgType === 'demo_org' ? (
                      <Typography variant="body1">
                        You are all set! Let&apos;s begin your first roleplay.
                      </Typography>
                    ) : null}
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'showOnboardingDialog',
                            value: false,
                          },
                        });
                        dispatch({
                          type: 'UPDATE_UI_STATE',
                          payload: {
                            key: 'onBoardingStep',
                            value: 0,
                          },
                        });
                        if (userDetails?.custom?.orgType === 'demo_org') {
                          history.push('/conversation-ai?new=true');
                        }
                      }}
                    >
                      Continue
                    </Button>
                  </Box>
                ) : ( */}
              <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'flex-end'}
                gap={2}
                width={1}
              >
                {onboardingStepV2 === 0 ? null : (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={
                      onboardingStepV2 === 'company_info' ||
                      onboardingStepV2 ===
                        'discovery_tree_generation_in_progress'
                      // ||
                      // onboardingStep >= 6 ||
                      // treeGenerationInProgress
                    }
                    startIcon={<KeyboardArrowLeft />}
                    onClick={() => {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage:
                              onboardingStages?.[
                                onboardingStages?.indexOf(
                                  onboardingStepV2 || '',
                                ) - 1
                              ],
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                          },
                        ),
                      );
                    }}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  variant="contained"
                  size="small"
                  startIcon={
                    generatingValueProp || updatingOrg ? (
                      <CircularProgress size={20} />
                    ) : null
                  }
                  endIcon={<KeyboardArrowRight />}
                  disabled={
                    updatingOrg ||
                    generatingValueProp ||
                    (onboardingStepV2 === 'company_info' &&
                      (!companyWebsite?.trim() || !companyName?.trim())) ||
                    (onboardingStepV2 === 'value_prop' &&
                      !aboutCompany?.trim()) ||
                    (onboardingStepV2 === 'value_prop_generated' &&
                      (valuePropJsonError || !valuePropositionJson)) ||
                    (onboardingStepV2 === 'discovery_questions' &&
                      qList?.length === 0) ||
                    (onboardingStepV2 ===
                      'discovery_tree_generation_in_progress_after' &&
                      treeGenerationInProgress)
                  }
                  onClick={() => {
                    if (onboardingStepV2 === 'company_info') {
                      setUpdatingOrg(true);
                      dispatch(
                        updateGlobalOrganizationConfig(
                          {
                            organizationName: companyName,
                            companyWebsite: companyWebsite,
                          },
                          () => {
                            dispatch(getOrganizationConfigs(() => {}));
                            dispatch(
                              updateUserDetails(
                                {
                                  onboardingStage: 'value_prop',
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                            setUpdatingOrg(false);
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'value_prop') {
                      setGeneratingValueProp(true);
                      dispatch(
                        generateValueProp(
                          {
                            url: companyWebsite,
                            aboutCompany: aboutCompany,
                          },
                          (data) => {
                            setGeneratingValueProp(false);
                            setValueProposition(data?.valueProp || '');
                            setValuePropHtml(data?.valuePropHtml || '');
                            setMetaData(data?.metaData);
                            setUpdatingOrg(true);
                            dispatch(
                              updateGlobalOrganizationConfig(
                                {
                                  aboutCompany: aboutCompany,
                                },
                                () => {
                                  dispatch(getOrganizationConfigs(() => {}));
                                  setUpdatingOrg(false);
                                  dispatch(
                                    updateUserDetails(
                                      {
                                        onboardingStage: 'value_prop_generated',
                                      },
                                      () => {
                                        dispatch(getUserDetails((data) => {}));
                                      },
                                    ),
                                  );
                                },
                              ),
                            );
                          },
                          () => {
                            setGeneratingValueProp(false);
                            setValueProposition('');
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'value_prop_generated') {
                      setUpdatingOrg(true);
                      dispatch(
                        updateGlobalOrganizationConfig(
                          {
                            valueProp: JSON.stringify(
                              valuePropositionJson,
                              null,
                              2,
                            ),
                          },
                          () => {
                            dispatch(
                              updateUserDetails(
                                {
                                  onboardingStage: 'selling_style',
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                            dispatch(getOrganizationConfigs(() => {}));
                            setUpdatingOrg(false);
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'selling_style') {
                      setUpdatingOrg(true);
                      dispatch(
                        updateGlobalOrganizationConfig(
                          {
                            sellingStyle: sellingStyle,
                          },
                          () => {
                            dispatch(getOrganizationConfigs(() => {}));
                            setUpdatingOrg(false);
                            dispatch(
                              updateUserDetails(
                                {
                                  onboardingStage: 'selling_style_picked',
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'selling_style_picked') {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage: 'deal_risk_selection',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                            dispatch(
                              getDealDiscoveryRisks((data) => {
                                setDealRisks(data || []);
                                setSelectedDealRisks(
                                  data?.map((risk) => risk?.id),
                                );
                              }),
                            );
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'deal_risk_selection') {
                      setUpdatingOrg(true);
                      dispatch(
                        updateGlobalOrganizationConfig(
                          {
                            selectedDiscoveryRisk: selectedDealRisks,
                          },
                          () => {
                            if (!orgConfigs?.discoveryTreeId) {
                              dispatch(
                                createDiscoveryTree(
                                  {
                                    title: companyName,
                                    url: companyWebsite,
                                    sellingStyle: sellingStyle,
                                    aboutCompany: aboutCompany,
                                    valueProp: valuePropHtml,
                                    metaData: metaData,
                                    model: 'gpt-4o-mini-2024-07-18',
                                  },
                                  (data) => {
                                    dispatch(getOrganizationConfigs(() => {}));
                                  },
                                ),
                              );
                            }
                            setUpdatingOrg(false);
                            dispatch(
                              updateUserDetails(
                                {
                                  onboardingStage:
                                    'discovery_tree_generation_in_progress',
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                          },
                        ),
                      );
                    } else if (
                      onboardingStepV2 ===
                      'discovery_tree_generation_in_progress'
                    ) {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage: 'crm_integration',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'crm_integration') {
                      if (crmConnected) {
                        dispatch(getOrganizationConfigs(() => {}));
                        dispatch(
                          updateUserDetails(
                            {
                              onboardingStage: 'deal_stages',
                            },
                            () => {
                              dispatch(getUserDetails((data) => {}));
                            },
                          ),
                        );
                      } else {
                        dispatch(getOrganizationConfigs(() => {}));
                        dispatch(
                          updateUserDetails(
                            {
                              onboardingStage: 'deal_stages',
                            },
                            () => {
                              dispatch(getUserDetails((data) => {}));
                            },
                          ),
                        );
                      }
                    } else if (onboardingStepV2 === 'deal_stages') {
                      if (crmConnected && orgConfigs?.dealStages?.length > 0) {
                        dispatch(
                          updateUserDetails(
                            {
                              onboardingStage: 'tools_integrations',
                            },
                            () => {
                              dispatch(getUserDetails((data) => {}));
                            },
                          ),
                        );
                      } else {
                        setUpdatingOrg(true);
                        dispatch(
                          updateGlobalOrganizationConfig(
                            {
                              crmDealStages: dealStages,
                            },
                            () => {
                              dispatch(getOrganizationConfigs(() => {}));
                              setUpdatingOrg(false);
                              dispatch(
                                updateUserDetails(
                                  {
                                    onboardingStage: 'tools_integrations',
                                  },
                                  () => {
                                    dispatch(getUserDetails((data) => {}));
                                  },
                                ),
                              );
                            },
                          ),
                        );
                      }
                    } else if (onboardingStepV2 === 'tools_integrations') {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage:
                              'discovery_tree_generation_in_progress_after',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                          },
                        ),
                      );
                    } else if (
                      onboardingStepV2 ===
                      'discovery_tree_generation_in_progress_after'
                    ) {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage: 'discovery_questions',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                            setFetchingDiscoveryQList(true);
                            dispatch(
                              getDealDiscoveryQuestionsList(
                                treeId,
                                null,
                                null,
                                (data) => {
                                  console.log(data);
                                  setFetchingDiscoveryQList(false);
                                },
                              ),
                            );
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'discovery_questions') {
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage: 'discovery_questions_reordering',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                          },
                        ),
                      );
                    } else if (
                      onboardingStepV2 === 'discovery_questions_reordering'
                    ) {
                      setUpdatingOrg(true);
                      dispatch(
                        updateGlobalOrganizationConfig(
                          {
                            selectedDiscoveryTreeQuestion: qList
                              ?.filter((qs) => !qs?.deleted && qs?.pinned)
                              ?.map((dq) => dq?.id),
                          },
                          () => {
                            dispatch(getOrganizationConfigs(() => {}));
                            dispatch(
                              updateUserDetails(
                                {
                                  onboardingStage: 'deal_creation',
                                },
                                () => {
                                  dispatch(getUserDetails((data) => {}));
                                },
                              ),
                            );
                            setUpdatingOrg(false);
                          },
                        ),
                      );
                    } else if (onboardingStepV2 === 'deal_creation') {
                      // setUpdatingOrg(true);
                      // dispatch(
                      //   updateGlobalOrganizationConfig(
                      //     {
                      //       selectedDiscoveryTreeQuestion: qList
                      //         ?.filter((qs) => !qs?.deleted && qs?.pinned)
                      //         ?.map((dq) => dq?.id),
                      //     },
                      //     () => {
                      //       dispatch(getOrganizationConfigs(() => {}));
                      dispatch(
                        updateUserDetails(
                          {
                            onboardingStage: 'onboarding_completed',
                          },
                          () => {
                            dispatch(getUserDetails((data) => {}));
                            dispatch({
                              type: 'UPDATE_UI_STATE',
                              payload: {
                                key: 'showOnboardingDialogV2',
                                value: false,
                              },
                            });
                          },
                        ),
                      );
                      //       setUpdatingOrg(false);
                      //       dispatch({
                      //         type: 'UPDATE_UI_STATE',
                      //         payload: {
                      //           key: 'showOnboardingDialogV2',
                      //           value: false,
                      //         },
                      //       });
                      //     },
                      //   ),
                      // );
                    }
                  }}
                >
                  {onboardingStepV2 === 'value_prop'
                    ? 'Generate Value Prop'
                    : onboardingStepV2 === 'deal_creation'
                    ? dealCreated
                      ? 'Done'
                      : "I'll do this later"
                    : onboardingStepV2 === 'crm_integration' && !crmConnected
                    ? "I'll do this later"
                    : onboardingStepV2 === 'tools_integrations' &&
                      !toolsConnected
                    ? "I'll do this later"
                    : 'Next'}
                </Button>
              </Box>
              {/* )} */}
            </Box>
          </Box>
        </Dialog>
        {/* ) : null} */}
        <Modal
          open={Boolean(showNewQuestionDialog)}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
              setShowNewQuestionDialog(null);
            else return;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
              // maxHeight: '30vh',
              maxWidth: '50vw',
              minWidth: '500px',
              outline: 'none',
              borderRadius: 1,
              // padding: 3,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: 1,
                justifyContent: 'space-between',
                p: 2,
              }}
            >
              <Typography>Add new discovery question</Typography>
            </Box>
            <Divider sx={{ width: 1 }} />
            <Box p={2} display={'flex'} flexDirection={'column'} gap={2}>
              <TextField
                autoFocus
                placeholder="Enter Question"
                variant="outlined"
                color="primary"
                size="small"
                name="newQuestion"
                multiline
                rows={4}
                value={newQuestion}
                onChange={(e) => setNewQuestion(e.target.value)}
                fullWidth
              />
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <InputLabel id="newQuestionSellingStyle">
                  Selling Style
                </InputLabel>
                <Select
                  variant="outlined"
                  size="small"
                  labelId={'newQuestionSellingStyle'}
                  label={'Selling Style'}
                  id={'newQuestionSellingStyle'}
                  name={'newQuestionSellingStyle'}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  onChange={(e) => {
                    setNewQuestionSellingStyle(e.target.value);
                  }}
                  value={
                    newQuestionSellingStyle ||
                    'persuasive_or_traditional_selling'
                  }
                >
                  {sellingStyles.map((ss) => (
                    <MenuItem key={ss?.sellingStyle} value={ss?.sellingStyle}>
                      <Typography variant="subtitle2">{ss?.title}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1,
                borderTop: '1px solid #d3d3d3',
              }}
            >
              <Button
                data-trackid={'send_calendar_event_to_email'}
                size="small"
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  setShowNewQuestionDialog(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  creatingNewQuestion ? <CircularProgress size={20} /> : <Add />
                }
                disabled={creatingNewQuestion}
                data-trackid={'download_event_ics_file'}
                color="primary"
                onClick={(e) => {
                  setCreatingNewQuestion(true);
                  dispatch(
                    createNewDiscoveryQuestion(
                      treeId,
                      subtopicIdForNewQuestion,
                      {
                        q: newQuestion,
                        sellingStyle: newQuestionSellingStyle,
                      },
                      (data, error) => {
                        console.log(data);
                        setCreatingNewQuestion(false);
                        if (error) {
                          dispatch(
                            showAlert({
                              message:
                                'Failed to create new question. Please try again!',
                              showCross: true,
                              title: null,
                              type: 'error',
                              autoHideDuration: 2000,
                              vertical: 'top',
                              horizontal: 'center',
                            }),
                          );
                        } else {
                          setNewQuestion('');
                          setNewQuestionSellingStyle(
                            'persuasive_or_traditional_selling',
                          );
                          dispatch({
                            type: 'UPDATE_DISCOVERY_QUESTIONS_IN_BULK',
                            payload: {
                              data: [...qList, data],
                            },
                          });
                        }
                      },
                    ),
                  );
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={Boolean(showNewRiskDialog)}
          onClose={(e, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown')
              setShowNewRiskDialog(null);
            else return;
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: theme.shadows[5],
              // maxHeight: '30vh',
              maxWidth: '50vw',
              minWidth: '500px',
              outline: 'none',
              borderRadius: 1,
              // padding: 3,
              position: 'relative',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: 1,
                justifyContent: 'space-between',
                p: 2,
              }}
            >
              <Typography>Add new discovery risk</Typography>
            </Box>
            <Divider sx={{ width: 1 }} />
            <Box p={2} display={'flex'} flexDirection={'column'} gap={2}>
              <TextField
                autoFocus
                placeholder="Enter Risk*"
                variant="outlined"
                color="primary"
                size="small"
                name="newRisk"
                value={newRisk}
                onChange={(e) => setNewRisk(e.target.value)}
                fullWidth
              />
              <TextField
                // autoFocus
                placeholder="Enter Risk Explanation"
                variant="outlined"
                color="primary"
                size="small"
                name="riskExplanation"
                multiline
                rows={4}
                value={riskExplanation}
                onChange={(e) => setRiskExplanation(e.target.value)}
                fullWidth
              />
            </Box>
            <Box
              sx={{
                width: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                p: 1,
                borderTop: '1px solid #d3d3d3',
              }}
            >
              <Button
                size="small"
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  setShowNewRiskDialog(null);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="small"
                startIcon={
                  creatingRisk ? <CircularProgress size={20} /> : <Add />
                }
                disabled={creatingRisk || !newRisk?.trim()}
                color="primary"
                onClick={(e) => {
                  // setCreatingRisk(true);
                  const newDealId = uuidv4();
                  setDealRisks((prev) => {
                    return [
                      {
                        id: newDealId,
                        riskTitle: newRisk,
                        explanation: riskExplanation,
                        custom: true,
                      },
                      ...prev,
                    ];
                  });
                  setSelectedDealRisks((prev) => {
                    return [...prev, newDealId];
                  });
                  setShowNewRiskDialog(false);
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Modal>
        <Dialog
          open={Boolean(showHubspotKeyDialog)}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            minWidth: 400,
            // '& .MuiPaper-root-MuiDialog-paper': {
            //   maxWidth: '70vw',
            // },
          }}
        >
          <DialogTitle id="alert-dialog-title">HubSpot Integration</DialogTitle>
          <DialogContent>
            <Box>
              <Typography color={'text.secondary'}>
                <Link
                  target="_blank"
                  title="Create HubSpot app"
                  href="https://developers.hubspot.com/docs/api/private-apps#create-a-private-app"
                >
                  Create your Private HubSpot App
                </Link>{' '}
                (takes only a few minutes), if you don&apos;t already have one.
              </Typography>
              <Typography color={'text.secondary'}>
                When asked for permissions please specify the following:
              </Typography>
              <ul style={{ marginTop: 10, marginLeft: -20 }}>
                <li>
                  <Typography variant="body2" color={'text.secondary'}>
                    crm.objects.contacts - Read & Write
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color={'text.secondary'}>
                    crm.objects.owners - Read
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color={'text.secondary'}>
                    crm.objects.deals - Read & Write
                  </Typography>
                </li>
                <li>
                  <Typography variant="body2" color={'text.secondary'}>
                    crm.schemas.deals - Read
                  </Typography>
                </li>
              </ul>
              <Typography color={'text.secondary'} mt={1}>
                Then copy over your{' '}
                <Link
                  target="_blank"
                  title="copy integration key"
                  href="https://developers.hubspot.com/docs/api/private-apps#make-api-calls-with-your-app-s-access-token"
                >
                  Integration Key
                </Link>{' '}
                in the field provided.
              </Typography>
            </Box>
            <Box>
              <TextField
                placeholder="HubSpot key"
                variant="outlined"
                name={'hubspotKey'}
                size="small"
                sx={{
                  width: '100%',
                }}
                value={hubspotKey}
                onChange={(e) => setHubspotKey(e.target.value)}
                type={keyVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-trackid={`toggle_password_visibility`}
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          setKeyVisible(!keyVisible);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {keyVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(hubspotIntegrationError)}
                helperText={hubspotIntegrationError}
              />
              {hubspotIntegrationError && requiredScopes?.length > 0 ? (
                <ul
                  style={{ marginTop: 10, marginLeft: -20, listStyle: 'none' }}
                >
                  {requiredScopes.map((scope) => (
                    <li key={scope}>
                      <Typography variant="body2" color={'error'}>
                        {scope}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              width={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setShowHubspotKeyDialog(false);
                  setHubspotIntegrationError('');
                  setRequiredScopes([]);
                  setHubspotKey('');
                }}
                color="primary"
                // autoFocus
              >
                Cancel
              </Button>
              <Button
                disabled={integratingHubspot || !hubspotKey?.trim()}
                onClick={(e) => {
                  setIntegratingHubspot(true);
                  dispatch(
                    integrateHubspot(
                      {
                        hubSpotKey: hubspotKey?.trim(),
                        hubspot: true,
                      },
                      'org',
                      () => {
                        setHubspotKey('');
                        setHubspotIntegrationError('');
                        setRequiredScopes([]);
                        setIntegratingHubspot(false);
                        setShowHubspotKeyDialog(false);
                        dispatch(getUserDetails(() => {}));
                        dispatch(getOrganizationConfigs(() => {}));
                        dispatch(
                          showAlert({
                            message: 'HubSpot Integrated successfully',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                      (error) => {
                        // setShowHubspotKeyDialog(false);
                        console.log('error', error);
                        if (
                          error?.message?.startsWith(
                            'api key is missing required scopes',
                          )
                        ) {
                          setHubspotIntegrationError(
                            'Please add required scopes in your hubspot app.',
                          );
                          setRequiredScopes(error?.data || []);
                        }
                        setIntegratingHubspot(false);
                        dispatch(
                          showAlert({
                            message:
                              'Failed to integrate hubspot. Please try again later!',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Integrate HubSpot
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(showZohoKeyDialog)}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            minWidth: 400,
            // '& .MuiPaper-root-MuiDialog-paper': {
            //   maxWidth: '70vw',
            // },
          }}
        >
          <DialogTitle id="alert-dialog-title">Zoho Integration</DialogTitle>
          <DialogContent>
            <Box>
              <Typography color={'text.secondary'}>
                <Link
                  target="_blank"
                  title="Create Zoho app"
                  href="https://www.zoho.com/accounts/protocol/oauth-setup.html"
                >
                  Create your Zoho App
                </Link>{' '}
                (takes only a few minutes), if you don&apos;t already have one.
              </Typography>
              <Typography color={'text.secondary'}>
                Note: Use the Zoho API console to login and create a
                Server-based application.
              </Typography>
              <ul
                style={{
                  listStyleType: 'number',
                  color: theme.palette.text.secondary,
                }}
              >
                <li>Click Add Client</li>
                <li>Give name of your app in the Client Name field</li>
                <li>
                  Provide the following URL in the homepage URL field:{' '}
                  <Link
                    target="_blank"
                    title="homepage url"
                    // href="https://app.qualification.ai/"
                  >
                    https://app.qualification.ai/
                  </Link>
                </li>
                <li>
                  Provide the following URL in the Authorised Redirect URIs
                  field:{' '}
                  <Link
                    target="_blank"
                    title="redirect URIs"
                    // href="https://app.qualification.ai/oauth/zoho-crm"
                  >
                    https://app.qualification.ai/oauth/zoho-crm
                  </Link>
                </li>
              </ul>
              <Typography color={'text.secondary'} mt={1}>
                Then copy over your{' '}
                <Link
                  target="_blank"
                  title="copy integration keys"
                  href="https://www.zoho.com/accounts/protocol/oauth-setup.html"
                >
                  Client ID and Client Secret
                </Link>{' '}
                in the fields provided.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                placeholder="Zoho Client ID"
                variant="outlined"
                name={'clientId'}
                size="small"
                sx={{
                  width: '100%',
                }}
                value={zohoClientId}
                onChange={(e) => setZohoClientId(e.target.value)}
                type={clientIdVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-trackid={`toggle_password_visibility`}
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          setClientIdVisible(!clientIdVisible);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {clientIdVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(zohoIntegrationError)}
                helperText={zohoIntegrationError}
              />
              <TextField
                placeholder="Zoho Client Secret"
                variant="outlined"
                name={'clientSecret'}
                size="small"
                sx={{
                  width: '100%',
                }}
                value={zohoClientSecret}
                onChange={(e) => setZohoClientSecret(e.target.value)}
                type={clientSecretVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-trackid={`toggle_password_visibility`}
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          setCleintSecretVisible(!keyVisible);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {clientSecretVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(zohoIntegrationError)}
                helperText={zohoIntegrationError}
              />
              {zohoIntegrationError && requiredScopes?.length > 0 ? (
                <ul
                  style={{ marginTop: 10, marginLeft: -20, listStyle: 'none' }}
                >
                  {requiredScopes.map((scope) => (
                    <li key={scope}>
                      <Typography variant="body2" color={'error'}>
                        {scope}
                      </Typography>
                    </li>
                  ))}
                </ul>
              ) : null}
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              width={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setShowZohoKeyDialog(false);
                  setZohoIntegrationError('');
                  setRequiredScopes([]);
                  setZohoClientId('');
                  setZohoClientSecret('');
                }}
                color="primary"
                // autoFocus
              >
                Cancel
              </Button>
              <Button
                disabled={
                  integratingZoho ||
                  !zohoClientId?.trim() ||
                  !zohoClientSecret?.trim()
                }
                onClick={(e) => {
                  setIntegratingZoho(true);
                  dispatch(
                    getZohoCrmOAuthUrl(
                      zohoClientId,
                      zohoClientSecret,
                      (url) => {
                        console.log(url);
                        window.open(url, '_self');
                      },
                    ),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Integrate Zoho
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(showGmailIntegrationDialog)}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            minWidth: 400,
            // '& .MuiPaper-root-MuiDialog-paper': {
            //   maxWidth: '70vw',
            // },
          }}
        >
          <DialogTitle id="alert-dialog-title">Gmail Integration</DialogTitle>
          <DialogContent>
            <Box>
              <Typography color={'text.secondary'}>
                To integrate Gmail you need to create an app password on Google
                Account Settings.
                <br />
                <Link
                  target="_blank"
                  title="Create Google App Password"
                  href="https://myaccount.google.com/apppasswords"
                >
                  Click here to Create your Google App password
                </Link>
              </Typography>
              <Typography color={'text.secondary'}>
                Note: Make sure you enable 2-Factor Authentication (2FA) if not
                already active for your google account.
              </Typography>
              <Typography color={'text.secondary'} mt={1}>
                Then copy over your App Password here to continue.
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <TextField
                placeholder="Gmail App Password"
                variant="outlined"
                name={'gmailAppPassword'}
                size="small"
                sx={{
                  width: '100%',
                }}
                value={gmailAppPassword}
                onChange={(e) => setGmailAppPassword(e.target.value)}
                type={appPasswordVisible ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        data-trackid={`toggle_password_visibility`}
                        aria-label="toggle password visibility"
                        onClick={(e) => {
                          setAppPasswordVisible(!appPasswordVisible);
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        {appPasswordVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // error={Boolean(zohoIntegrationError)}
                // helperText={zohoIntegrationError}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              width={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setShowGmailIntegrationDialog(false);
                  setGmailAppPassword('');
                  setAppPasswordVisible(false);
                }}
                color="primary"
                // autoFocus
              >
                Cancel
              </Button>
              <Button
                disabled={integratingGmail || !gmailAppPassword?.trim()}
                onClick={(e) => {
                  setIntegratingGmail(true);
                  dispatch(
                    integrateHubspot(
                      {
                        gmailPass: gmailAppPassword?.trim(),
                        gmail: true,
                      },
                      'user',
                      () => {
                        setGmailAppPassword('');
                        setIntegratingGmail(false);
                        setShowGmailIntegrationDialog(false);
                        dispatch(getUserDetails(() => {}));
                        dispatch(getOrganizationConfigs(() => {}));
                        dispatch(
                          showAlert({
                            message: 'Gmail Integrated successfully',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                      (error) => {
                        // setShowHubspotKeyDialog(false);
                        console.log('error', error);
                        setIntegratingGmail(false);
                        dispatch(
                          showAlert({
                            message:
                              'Failed to integrate gmail. Please try again later!',
                            showCross: true,
                            title: null,
                            type: 'success',
                            autoHideDuration: 2000,
                            vertical: 'top',
                            horizontal: 'center',
                          }),
                        );
                      },
                    ),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Integrate Gmail
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        <Dialog
          open={Boolean(showDisclosure)}
          onClose={() => {}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{
            zIndex: 10002,
            minWidth: 400,
            // '& .MuiPaper-root-MuiDialog-paper': {
            //   maxWidth: '70vw',
            // },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            <Typography variant="body1" fontWeight={'bold'}>
              Disclosure
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Box
              sx={{
                minWidth: 400,
              }}
            >
              <Typography variant="body1">
                {userInfo?.custom?.orgType === 'n_org'
                  ? ''
                  : parse(
                      'Google Calendar integration is provided by our partner <strong>Qualification AI</strong>. ',
                    )}{' '}
                By turning on Google Calendar integration you will be able to
                have our meeting bot join your meetings to record your calls. To
                provide you this capability we share your meeting URL, date and
                time of your upcoming external meetings to third-party tools.
              </Typography>
              <Typography variant="body1" mt={1}>
                Qualification AI&apos;s use and transfer to any other app of
                information received from Google APIs will adhere to{' '}
                <Link
                  // underline="none"
                  component="a"
                  target="_blank"
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  // color="text.primary"
                  variant={'body1'}
                >
                  Google API Services User Data Policy
                </Link>
                , including the Limited Use requirements.
              </Typography>
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Checkbox
                    size="small"
                    data-trackid={`toggle_terms_accepted`}
                    checked={termsAccepted}
                    onChange={(e) => {
                      setTermsAccepted(e.target.checked);
                    }}
                  />
                }
                label={
                  <Typography variant="body2">
                    I consent to share my meeting URL, date and time to
                    third-party tools.
                  </Typography>
                }
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Box
              width={1}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Button
                // data-trackid={'cta_permission_denied_dialog_close'}
                onClick={(e) => {
                  setShowDisclosure(null);
                }}
                color="primary"
                // autoFocus
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={!termsAccepted}
                onClick={(e) => {
                  dispatch(
                    getGoogleOAuthUrl((url) => {
                      console.log(url);
                      window.open(url, '_self');
                    }),
                  );
                }}
                color="primary"
                // autoFocus
              >
                Proceed
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
        {onboardingSettings.onBoardingFor === 'orgAdmin' &&
        onboardingSettings.onBoardingStep === 'start' ? (
          <Tour
            steps={[
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      This is a step-by-step overview to familiarize you with
                      the deal discovery feature. Click &quot;Start&quot; to get
                      started.
                    </Typography>
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                // hideFooter: true,
                hideBackButton: true,
                showSkipButton: false,
                placement: 'center',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: 'body',
                title: 'Welcome',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      These are the initial setup things which you would have
                      already setup during the initial onboarding. You can also
                      modify the global discovery template or meeting plan.
                    </Typography>
                    <br />
                    <Typography>
                      Note: Every time you create new deal, deal will have this
                      template and meeting plan as the starting point to
                      continue analyzing your deals.
                    </Typography>
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: false,
                showSkipButton: false,
                placement: 'right',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.setupLeftNav',
                title: 'Setup Options',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      These are the initial setup things which you would have
                      already setup during the initial onboarding. You can also
                      modify the global discovery template or meeting plan.
                    </Typography>
                    <br />
                    <Typography>
                      Note: Every time you create new deal, deal will have this
                      template and meeting plan as the starting point to
                      continue analyzing your deals.
                    </Typography>
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                showSkipButton: false,
                hideFooter: false,
                placement: 'right',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                  spotlight: {
                    // maxHeight: '150px', // Or a pixel value
                  },
                },
                target: '.dealsLeftNav',
                title: 'Deals Options',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Here is the list of deals you have created so far.
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                hideCloseButton: true,
                hideFooter: false,
                showSkipButton: false,
                placement: 'left',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                  tooltip: {
                    // maxHeight: '200px', // Or a pixel value
                    maxWidth: '200px',
                  },
                },
                target: '.dealsList',
                title: 'All Deals',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Click here to go to deal manage page.
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'auto',
                spotlightClicks: true,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.dealDetail0',
                title: 'Manage Deal',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Here you can scrap your customer website to get the
                      stakeholder&apos;s linkedIn profiles and more.{' '}
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: false,
                placement: 'right',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.manageDeal',
                title: 'Deal Setup',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Click here to upload an artifact for the deal.
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'auto',
                spotlightClicks: true,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.uploadArtifactTab',
                title: 'Upload Artifact',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Fill out the artifact details and click Next.
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: false,
                placement: 'auto',
                spotlightClicks: true,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.uploadArtifactForm',
                title: 'Upload Artifact',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>Click Upload</Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: true,
                placement: 'auto',
                spotlightClicks: true,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.uploadArtifactBtn',
                title: 'Upload Artifact',
              },
              {
                content: (
                  <TourStepContent>
                    <Typography>
                      Here is the list of all the artifacts you have uploaded.
                    </Typography>
                    <br />
                  </TourStepContent>
                ),
                disableBeacon: true,
                disableOverlayClose: true,
                showSkipButton: false,
                hideCloseButton: true,
                hideFooter: false,
                placement: 'auto',
                spotlightClicks: false,
                styles: {
                  options: {
                    zIndex: 10000,
                  },
                },
                target: '.artifactsList',
                title: 'All Artifacts',
              },
            ]}
          />
        ) : null}
      </Box>
    </ErrorBoundary>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node,
  colorInvert: PropTypes.bool,
  bgcolor: PropTypes.string,
  noTopbar: PropTypes.bool,
  noFooter: PropTypes.bool,
  leftSidebar: PropTypes.bool,
};

RenderValuePropJSON.propTypes = {
  valueProp: PropTypes.any,
  key: PropTypes.any,
};

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default Dashboard;
