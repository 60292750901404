import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Popper,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import LineGraph from 'components/LineGraph/LineGraph';
import parse from 'html-react-parser';
import {
  Add,
  Check,
  CheckCircle,
  Close,
  Delete,
  DeleteOutlined,
  EditOutlined,
  Sync,
} from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDrag, useDrop } from 'react-dnd';

const DealStageNode = memo(function DealStageNode({
  id,
  stage,
  index,
  moveCard,
  findCard,
  setDealStages,
  readOnly,
}) {
  const theme = useTheme();
  const history = useHistory();
  const pathParams = useParams();
  const dispatch = useDispatch();

  const originalIndex = findCard(id).index;
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: 'stage',
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveCard(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveCard],
  );
  const [, drop] = useDrop(
    () => ({
      accept: 'stage',
      hover({ id: draggedId }) {
        if (draggedId !== id) {
          const { index: overIndex } = findCard(id);
          moveCard(draggedId, overIndex);
        }
      },
    }),
    [findCard, moveCard],
  );

  const opacity = isDragging ? 0 : 1;

  const [editMode, setEditMode] = useState(null);
  const [newStageTitle, setNewStageTitle] = useState(stage?.name || '');

  return (
    <Box
      ref={(node) => (readOnly ? null : drag(drop(node)))}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        flex: 1,
        minWidth: 300,
        opacity,
      }}
    >
      {editMode ? (
        <form
          style={{
            width: '100%',
          }}
          onSubmit={(e) => {
            e.preventDefault();
            setDealStages((prev) => {
              return prev?.map((stage) =>
                stage?.id === editMode?.id
                  ? { ...stage, name: newStageTitle }
                  : stage,
              );
            });
            setTimeout(() => {
              setEditMode(false);
              setNewStageTitle('');
            }, 500);
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              minWidth: 300,
              flex: 1,
              justifyContent: 'space-between',
            }}
          >
            <TextField
              placeholder="Stage Name"
              variant="outlined"
              color="primary"
              size="small"
              name="newStageTitle"
              value={newStageTitle}
              onChange={(e) => {
                setNewStageTitle(e.target.value);
              }}
              fullWidth
              sx={{
                borderRadius: 2,
                '& .MuiOutlinedInput-root': {
                  background: '#F2F6FC',
                  borderRadius: 2,
                },
                '& .MuiOutlinedInput-input': {
                  borderRadius: 2,
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        // gap: 1,
                      }}
                    >
                      <IconButton
                        onClick={() => {
                          setEditMode(null);
                          setNewStageTitle('');
                        }}
                      >
                        <Close sx={{ fontSize: 16 }} />
                      </IconButton>
                      <IconButton
                        disabled={!newStageTitle?.trim()}
                        type="submit"
                      >
                        <Check
                          color={newStageTitle?.trim() ? 'primary' : 'disabled'}
                          sx={{ fontSize: 16 }}
                        />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </form>
      ) : (
        <Box
          sx={{
            p: 1,
            background: '#C2DBFF',
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            cursor: 'cursor',
            gap: 2,
            justifyContent: 'space-between',
            flex: 1,
          }}
          onClick={() => {}}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Typography>{index + 1}.</Typography>
            <Typography>{stage?.name}</Typography>
          </Box>
        </Box>
      )}
      {readOnly ? null : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setEditMode(stage);
            }}
          >
            <EditOutlined sx={{ fontSize: 16 }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setDealStages((prev) =>
                prev?.filter((stg) => stg?.id !== stage?.id),
              );
            }}
          >
            <DeleteOutlined sx={{ fontSize: 16 }} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
});

DealStageNode.propTypes = {
  id: PropTypes.string,
  stage: PropTypes.string,
  index: PropTypes.number,
  moveCard: PropTypes.func,
  findCard: PropTypes.func,
  setDealStages: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default DealStageNode;
