import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import firebaseCheckSessionReducer from './Auth/firebaseCheckSessionReducer';
import firebaseLogoutReducer from './Auth/firebaseLogoutReducer';
import firebaseSigninReducer from './Auth/firebaseSigninReducer';
import firebaseSignupReducer from './Auth/firebaseSignupReducer';
import uploadProfilePictureReducer from './Auth/uploadProfilePictureReducer';
import validateInvitationTokenReducer from './Auth/validateInvitationTokenReducer';
import alertReducer from './Common/alertReducer';
import uiReducer from './Candidate/uiReducer';
import getUserDetailsReducer from './Common/getUserDetailsReducer';
import candidateProfileCompletionReducer from './Candidate/candidateProfileCompletionReducer';
import getAllConversationsReducer from './Candidate/getAllConversationsReducer';
import createSalesAnalysisThreadReducer from './Candidate/createSalesAnalysisThreadReducer';
import getSalesCallReviewsReducer from './Common/getSalesCallReviewsReducer';
import getAllConversationsMainReducer from './Common/getAllConversationsMainReducer';
import checkMergedAudioStatusReducer from './Common/checkMergedAudioStatusReducer';
import getAllInvitationsReducer from './Common/getAllInvitationsReducer';
import getAllTeamMembersReducer from './Common/getAllTeamMembersReducer';
import getAllConversationsMainByUserIdReducer from './Common/getAllConversationsMainByUserIdReducer';
import getSalesCallReviewsByUserIdReducer from './Common/getSalesCallReviewsByUserIdReducer';
import getFeedbacksOnEntityReducer from './Common/getFeedbacksOnEntityReducer';
import getOrganizationConfigsReducer from './Common/getOrganizationConfigsReducer';
import getWhitelabelDetailsByDomainReducer from './Common/getWhitelabelDetailsByDomainReducer';
import getWlCustomersReducer from './Common/getWlCustomersReducer';
import getLeaderboardReducer from './Common/getLeaderboardReducer';
import getConversationByIdReducer from './Candidate/getConversationByIdReducer';
import getAllGeneratedPlaybooksReducer from './Common/getAllGeneratedPlaybooksReducer';
import getAllTaskItemsReducer from './Candidate/getAllTaskItemsReducer';
import getCROInterviewsReducer from './Compass/getCROInterviewsReducer';
import getAccountsReducer from './Compass/getAccountsReducer';
import getCompanyProspectsReducer from './Compass/getCompanyProspectsReducer';
import getMembersByOrgIdReducer from './Compass/getMembersByOrgIdReducer';
import getAllDealActivitesReducer from './Compass/getAllDealActivitesReducer';
import getTeamsReducer from './Common/getTeamsReducer';
import getOutreachInvitationsReducer from './Common/getOutreachInvitationsReducer';
import pendoReducer from './Candidate/pendoReducer';
import getDealsReducer from './Common/getDealsReducer';
import getDiscoveryQuestionsListReducer from './Common/getDiscoveryQuestionsListReducer';
import setOnboardingReducer from './Common/setOnboardingReducer';
import setTourStateReducer from './Common/setTourStateReducer';
import addDealArtifactReducer from './Common/addDealArtifactReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'firebaseSession',
    'firebaseSigninState',
    'uiStates',
    'discoveryQList',
  ],
};

const appReducer = combineReducers({
  firebaseSession: firebaseCheckSessionReducer,
  firebaseLogoutState: firebaseLogoutReducer,
  firebaseSigninState: firebaseSigninReducer,
  firebaseSignupState: firebaseSignupReducer,
  profilePictureUpload: uploadProfilePictureReducer,
  invitationValidationData: validateInvitationTokenReducer,
  alertState: alertReducer,
  uiStates: uiReducer,
  userDetails: getUserDetailsReducer,
  profileCompletionState: candidateProfileCompletionReducer,
  allConversations: getAllConversationsReducer,
  createSalesAnalysisThreadState: createSalesAnalysisThreadReducer,
  salesAnalysisReviews: getSalesCallReviewsReducer,
  allConversationsMain: getAllConversationsMainReducer,
  mergedAudioProgressIds: checkMergedAudioStatusReducer,
  allTeamInvitations: getAllInvitationsReducer,
  allTeamMembers: getAllTeamMembersReducer,
  allConversationsByUserId: getAllConversationsMainByUserIdReducer,
  salesAnalysisReviewsByUserId: getSalesCallReviewsByUserIdReducer,
  feedbacksOnEntity: getFeedbacksOnEntityReducer,
  orgConfigs: getOrganizationConfigsReducer,
  whitelabelDetails: getWhitelabelDetailsByDomainReducer,
  wlCustomers: getWlCustomersReducer,
  leaderboard: getLeaderboardReducer,
  conversationDetailsById: getConversationByIdReducer,
  allGeneratedPlaybooks: getAllGeneratedPlaybooksReducer,
  allActionItems: getAllTaskItemsReducer,
  croInterviews: getCROInterviewsReducer,
  companyAccounts: getAccountsReducer,
  companyProspects: getCompanyProspectsReducer,
  membersByOrgId: getMembersByOrgIdReducer,
  dealActivities: getAllDealActivitesReducer,
  teamsGroup: getTeamsReducer,
  outreachInvitations: getOutreachInvitationsReducer,
  pendoState: pendoReducer,
  deals: getDealsReducer,
  addDealArtifactState: addDealArtifactReducer,
  discoveryQList: getDiscoveryQuestionsListReducer,
  onboardingSettings: setOnboardingReducer,
  tourState: setTourStateReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_REQUEST') {
    localStorage.removeItem('persist:root');
    state = undefined;
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
