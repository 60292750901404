const initialstate = {
  fetching: null,
  fetchingSuccessful: null,
  fetchingFailed: null,
  error: null,
  errorText: null,
  conversationDetails: null,
};

const getConversationByIdReducer = (state = initialstate, action) => {
  switch (action.type) {
    case 'FETCHING_CONVERSATION_BY_ID':
      return Object.assign({}, state, {
        fetching: true,
      });
    case 'FETCHING_CONVERSATION_BY_ID_DONE':
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        error: false,
        errorText: '',
        conversationDetails: action.payload,
      });
    case 'RESET_CONVERSATION_BY_ID':
      return Object.assign({}, state, {
        fetching: null,
        fetchingSuccessful: null,
        fetchingFailed: null,
        error: null,
        errorText: null,
        conversationDetails: null,
      });
    case 'UPDATE_CONVERSATION_MESSAGES': {
      const updatedConversationDetails = {
        ...state.conversationDetails,
        messages: [...state.conversationDetails.messages, ...action.payload],
      };
      return Object.assign({}, state, {
        fetching: false,
        fetchingSuccessful: true,
        fetchingFailed: false,
        conversationDetails: updatedConversationDetails,
        error: false,
        errorText: '',
      });
    }
    case 'FETCHING_CONVERSATION_BY_ID_FAILED':
      return Object.assign({}, state, {
        fetching: false,
        fetchingFailed: true,
        error: true,
        errorText: action.payload,
        conversationDetails: null,
      });
    default:
      return state;
  }
};

export default getConversationByIdReducer;
